import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ReferralMenu from "../../components/menus/referralMenu";
import ReferralList from "./referralList";
import Referral from "./show";
import ReferralEdit from "./forms/editReferralForm";
import AddNote from "./forms/addNoteForm";
import getReferralNote from "./forms/getNoteForm";

export default class ReferralPages extends Component {
  state = {};

  render() {
    return (
      <div className="pageWrapper pageHolder">
        <ReferralMenu />
        <Switch>
          <Route
            path={`${this.props.match.url}/edit/:id`}
            component={ReferralEdit}
          />
          <Route
              exact
              path={`${this.props.match.url}/:id/addnote`}
              component={AddNote}
            />
          <Route exact path={`${this.props.match.url}/:id/note/:id2`} component={getReferralNote} />
          <Route path={`${this.props.match.url}/:id`} component={Referral} />
          <Route path={`${this.props.match.url}`} component={ReferralList} />
        </Switch>
      </div>
    );
  }
}
