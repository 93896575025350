import React, { Component } from "react";
import TopNav from "./common/topNav";
import { Route, Switch } from "react-router-dom";
import { Authentication } from "../../lib/sso";
import SettingsPages from "./settings";
import StaffPages from "./staff";
import Company from "./company";
import Employee from "./employee";
import ReferralPages from "./referrals";
import AssessmentPages from "./assessment";
import CasePages from "./cases";
import FilesPages from "./files";
import PNDash from "./pnDash";
import Footer from "./common/footer";
import ResetPasswordForm from "./common/forms/resetPasswordForm";
import ReportsPages from "./reports";

export default class Index extends Component {
  state = {
    drawerPos: 1,
    userDetails: [],
  };

  updateWidth = (data) => {
    this.setState({
      drawerPos: data,
    });
  };

  render() {
    return (
      <div className="wrapper">
        <TopNav
          drawerPos={this.state.drawerPos}
          updateWidth={this.updateWidth}
          match={this.props.match}
          history={this.props.history}
        />
        <div className="pageWrapper">
          <Switch>
            {Authentication.can("navigator_admin.permission") && (
              <Route path={`/settings`} component={SettingsPages} />
            )}
            {/* {Authentication.can("navigator_admin.permission") ? ( */}
            <Route path={`/staff`} component={StaffPages} />
            {/* )} */}
            <Route path={`/assessment`} component={AssessmentPages} />
            {(Authentication.can("navigator_admin.permission") ||
              Authentication.can("organisation_case_manager.permission") ||
              Authentication.can("navigator_case_manager.permission")) && (
              <Route path={`/referral`} component={ReferralPages} />
            )}
            {(Authentication.can("navigator_admin.permission") ||
              Authentication.can("organisation_case_manager.permission") ||
              Authentication.can("navigator_case_manager.permission")) && (
              <Route path={`/case`} component={CasePages} />
            )}
            {Authentication.can("fileManager.view") && (
              <Route path={`/files`} component={FilesPages} />
            )}
            {/* {Authentication.can("fileManager.view") && ( */}
              <Route path={`/reports`} component={ReportsPages} />
            {/* )} */}
            {Authentication.can("navigator_admin.permission") && (
              <Route
                path={`/reset_password/:id`}
                component={ResetPasswordForm}
              />
            )}
            <Route path={`/company`} component={Company} />
            <Route path={`/employee`} component={Employee} />
            {(Authentication.can("navigator_admin.permission") ||
              Authentication.can("organisation_case_manager.permission") ||
              Authentication.can("navigator_case_manager.permission")) && (
              <Route exact path={``} component={PNDash} />
            )}
          </Switch>
        </div>
        <Footer match={this.props.match} history={this.props.history} />
      </div>
    );
  }
}
