import React, { Component } from "react";
import { Segment, Modal, Header, Loader } from "semantic-ui-react";
import api from "../../../../actions/api";
import AddForm from "./forms/add";
import PaginateWrapper from "../../common/OLD_PaginateWrapper";
import List from "../../common/lists/splashScreenList";

export default class index extends Component {
  state = {
    splash_screens: [],
    addModal: false,
    refreshList: false,
  };

  getData = (pageNum, pageSize, search, filters) => {
    return api.mobile_splash_screens.allPaged({
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  renderAddModal() {
    const handleClose = () => this.setState({ addModal: false });

    const handleAdd = (data) => {
      this.setState({ refreshList: true });
      api.mobile_splash_screens
        .add(data)
        .then(() => {
          this.setState({
            addModal: false,
            refreshList: false,
          });
        })
        .catch((e) => {
          this.setState({ refreshList: false });
        });
    };

    return (
      <Modal
        open={this.state.addModal}
        onClose={handleClose}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="mobile" content="Add Mobile Splash Screen" />
        <Modal.Content>
          <AddForm submit={handleAdd} close={handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Mobile Splash Screen List
        </Header>
        <Segment className="border">
          {this.renderAddModal()}
          {this.state.refreshList === false ? (
            <PaginateWrapper
              dataQuery={this.getData}
              search={true}
              active={"Archived"}
              render={(items) => (
                <List
                  data={items}
                  action={(data) => {
                    if (data) {
                      this.props.history.push(
                        `/settings/splash_screens/${data}/edit`
                      );
                    }
                  }}
                  add={() => this.setState({ addModal: true })}
                  allowAdd={true}
                />
              )}
            />
          ) : (
            <Segment basic>
              <Loader active />
            </Segment>
          )}
        </Segment>
      </Segment>
    );
  }
}
