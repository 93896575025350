import React, { Component } from "react";
import { Segment, Header, Grid, Card, Table, Loader } from "semantic-ui-react";
import api from "../../../actions/api";
import moment from "moment";
import { Authentication } from "../../../lib/sso";

export default class CasesDash extends Component {
  state = {
    data: {},
    loading: true,
    error: false,
  };

  componentDidMount() {
    api.case
      .getDashStats()
      .then((res) => {
        this.setState({ data: res, loading: false });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ error: true });
      });
  }

  renderOverdueActivitiesAdmin() {
    const list = this.state.data.overdue_activities_all_staff.map(
      (item, index) => {
        return (
          <Table.Row
            textAlign="center"
            key={index}
            onClick={() => {
              this.props.history.push(`/case/${item.case && item.case._id}`);
            }}
          >
            <Table.Cell>{item.case && "C" + item.case.refNum}</Table.Cell>
            <Table.Cell>
              {item.case && item.case.referral && item.case.referral.employee
                ? item.case.referral.employee.firstName +
                  " " +
                  item.case.referral.employee.lastName
                : "[ERROR]"}
            </Table.Cell>
            <Table.Cell>
              {item.case && item.case.referral
                ? item.case.referral.condition
                : "[ERROR]"}
            </Table.Cell>
            <Table.Cell>{moment(item.logDue).format("DD/MM/YYYY")}</Table.Cell>
            <Table.Cell>
              {moment.utc().diff(moment.utc(item.logDue), "days")}
            </Table.Cell>
            <Table.Cell>{item.logType}</Table.Cell>
          </Table.Row>
        );
      }
    );

    return (
      <Table striped celled>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>Case</Table.HeaderCell>
            <Table.HeaderCell>Patient Name</Table.HeaderCell>
            <Table.HeaderCell>Condition</Table.HeaderCell>
            <Table.HeaderCell>Due</Table.HeaderCell>
            <Table.HeaderCell>Days Overdue</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.length !== 0 ? (
            list
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                No Overdue Activities
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }

  renderUpcomingCaseActivitiesCM() {
    const list = this.state.data.upcoming_case_activities.map((item, index) => {
      return (
        <Table.Row
          textAlign="center"
          key={index}
          onClick={() => {
            this.props.history.push(`/case/${item.case && item.case._id}`);
          }}
        >
          <Table.Cell>{item.case && "C" + item.case.refNum}</Table.Cell>
          <Table.Cell>{moment(item.logDue).format("DD/MM/YYYY")}</Table.Cell>
          <Table.Cell>{item.logType}</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table striped celled>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>Case</Table.HeaderCell>
            <Table.HeaderCell>Due</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.length !== 0 ? (
            list
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                No Upcoming Activities
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }

  renderOverdueCaseActivitiesCM() {
    const list = this.state.data.overdue_case_activities.map((item, index) => {
      return (
        <Table.Row
          textAlign="center"
          key={index}
          onClick={() => {
            this.props.history.push(`/case/${item.case && item.case._id}`);
          }}
        >
          <Table.Cell>{item.case && "C" + item.case.refNum}</Table.Cell>
          <Table.Cell>{moment(item.logDue).format("DD/MM/YYYY")}</Table.Cell>
          <Table.Cell>{item.logType}</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table striped celled>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>Case</Table.HeaderCell>
            <Table.HeaderCell>Due</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.length !== 0 ? (
            list
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                No Overdue Activities
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }

  renderCaseNoAcitvity(data) {
    const list = data
      ? data.map((item, index) => {
          return (
            <Table.Row
              textAlign="center"
              key={index}
              onClick={() => {
                this.props.history.push(`/case/${item._id}`);
              }}
            >
              <Table.Cell>{"C" + item.refNum}</Table.Cell>
              <Table.Cell>{item.type}</Table.Cell>
              {/* <Table.Cell>{item.reason}</Table.Cell> */}
              <Table.Cell>{item.condition}</Table.Cell>
            </Table.Row>
          );
        })
      : [];

    return (
      <Table striped celled>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>Case Number</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            {/* <Table.HeaderCell>Reason</Table.HeaderCell> */}
            <Table.HeaderCell>Condition</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.length !== 0 ? (
            list
          ) : (
            <Table.Row>
              <Table.Cell textAlign="center" colSpan="3">
                No Cases
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }

  render() {
    if (this.state.loading === false && this.state.error === false) {
      return (
        <Segment basic className="no-pad">
          <Header as={"h1"} textAlign="center" className="pageTitle case">
            Cases Dashboard
          </Header>
          {Authentication.can("navigator_admin.permission") === true ? (
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Card fluid className="actionCard">
                    <Card.Content>
                      <Card.Header>Cases With Overdue Activities</Card.Header>
                      <Card.Description className="stat">
                        <div>
                          <Header className="statValue">
                            {this.state.data.overdue_activities}
                          </Header>
                        </div>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card fluid className="dashCard">
                    <Card.Content>
                      <Card.Header>Cases No Activity (All Staff)</Card.Header>
                      <Card.Description>
                        {this.renderCaseNoAcitvity(
                          this.state.data.cases_no_activity
                        )}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Card fluid className="dashCard">
                    <Card.Content>
                      <Card.Header>Overdue Activities (All Staff)</Card.Header>
                      <Card.Description>
                        {this.renderOverdueActivitiesAdmin()}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card fluid className="dashCard">
                    <Card.Content>
                      <Card.Header>
                        Cases No Activity 30 Days (All Staff)
                      </Card.Header>
                      <Card.Description>
                        {this.renderCaseNoAcitvity(
                          this.state.data.cases_no_activity_30
                        )}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ) : Authentication.can("navigator_case_manager.permission") ===
              true ||
            Authentication.can("organisation_case_manager.permission") ===
              true ? (
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Card
                    fluid
                    className="actionCard"
                    onClick={() => this.props.history.push("/case/list")}
                  >
                    <Card.Content>
                      <Card.Header>Active Assigned Cases</Card.Header>
                      <Card.Description className="stat">
                        <div>
                          <Header className="statValue">
                            {this.state.data.assigned_cases}
                          </Header>
                        </div>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card
                    fluid
                    className="actionCard"
                    onClick={() => this.props.history.push("/case/list")}
                  >
                    <Card.Content>
                      <Card.Header>Total Cases</Card.Header>
                      <Card.Description className="stat">
                        <div>
                          <Header className="statValue">
                            {this.state.data.total_cases}
                          </Header>
                        </div>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Card fluid className="dashCard">
                    <Card.Content>
                      <Card.Header>Upcoming Case Activites</Card.Header>
                      <Card.Description>
                        {this.renderUpcomingCaseActivitiesCM()}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card fluid className="dashCard">
                    <Card.Content>
                      <Card.Header>Cases With Overdue Activities</Card.Header>
                      <Card.Description>
                        {this.renderOverdueCaseActivitiesCM()}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ) : (
            <React.Fragment />
          )}
        </Segment>
      );
    } else if (this.state.error === true || this.state.loading === true) {
      return (
        <Segment basic padded="very">
          <Loader active indeterminate={this.state.error === true}>
            {this.state.error === true
              ? "Error Fetching Data, Please Try Reloading..."
              : ""}
          </Loader>
        </Segment>
      );
    }
  }
}
