import React, { Component } from "react";
import { Form, Segment, Icon, Divider } from "semantic-ui-react";
import api from "../../../../../../actions/api";

class add extends Component {
  state = {
    data: {
      title: "",
      description_short: "",
      description_long: "",
      resource_category: "",
      display_type: "None",
    },
    resource_categories: [],
    submit_load: false,
    errors: {},
  };

  componentDidMount() {
    api.resource_categories.list().then((res) => {
      this.setState({
        resource_categories: res
          .filter((item) => item.archived === false)
          .map((item) => {
            return { text: item.title, value: item._id };
          }),
      });
    });
  }

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleDropdownChange = (e, data) => {
    this.setState({
      data: { ...this.state.data, [data.name]: data.value },
    });
  };

  validate = (data) => {
    const errors = {};

    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.description_short) errors.description_short = "Can't Be Empty";
    if (!data.display_type) errors.display_type = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ submit_load: true });
      this.props.submit({
        ...this.state.data,
        resource_category:
          this.state.data.resource_category === ""
            ? null
            : this.state.data.resource_category,
      });
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="title"
              label="Resource Name"
              value={this.state.data.title}
              onChange={this.handleChange}
              placeholder="Title"
              error={this.state.errors.title}
            />
            <Form.Dropdown
              name="resource_category"
              label="Resource Category"
              value={this.state.data.resource_category}
              onChange={this.handleDropdownChange}
              options={this.state.resource_categories}
              error={this.state.errors.resource_categories}
              selection
              placeholder="Resource Category"
              clearable
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.TextArea
              name="description_short"
              label="Description (Short)"
              value={this.state.data.description_short}
              onChange={this.handleChange}
              placeholder="Description (Short)"
              error={this.state.errors.description_short}
            />
            <Form.TextArea
              name="description_long"
              label="Description (Long)"
              value={this.state.data.description_long}
              onChange={this.handleChange}
              placeholder="Description (Long)"
            />
          </Form.Group>
          <Form.Dropdown
            name="display_type"
            label="Additional Data Display Type"
            value={this.state.data.display_type}
            onChange={this.handleDropdownChange}
            options={[
              { text: "None", value: "None" },
              { text: "PDF", value: "PDF" },
              { text: "WYSIWYG", value: "WYSIWYG" },
            ]}
            error={this.state.errors.display_type}
            selection
            placeholder="Display Type"
          />
          <Divider />
          <Form.Group widths="equal">
            <Form.Button
              floated="left"
              color="red"
              onClick={() => this.props.close()}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              floated="right"
              color="green"
              onClick={() => this.handleSubmit()}
              loading={this.state.submit_load}
            >
              <Icon name="plus" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default add;
