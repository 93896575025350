import React, { Component } from "react";
import {
  Table,
  Message,
  Button,
  Icon,
  Popup,
  Segment,
} from "semantic-ui-react";

class serviceList extends Component {
  renderList() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        const link = () => this.props.action(item._id);

        return (
          <Table.Row key={item._id}>
            <Table.Cell onClick={link}>{item.title}</Table.Cell>
            <Table.Cell onClick={link}>{item.description_short}</Table.Cell>
            <Table.Cell onClick={link}>
              {item.service_resources &&
                item.service_resources.map((resource, index) => {
                  return index < item.service_resources.length - 1
                    ? resource.service_type + ", "
                    : resource.service_type;
                })}
            </Table.Cell>
            {this.props.popularity && (
              <Table.Cell onClick={link}>{item.activity_counter}</Table.Cell>
            )}
            {this.props.allowAdd && (
              <React.Fragment>
                <Table.Cell textAlign="center">
                  <Button.Group vertical>
                    <Button
                      icon="angle up"
                      onClick={() => this.props.position_change(item, "up")}
                    />
                    <Button
                      icon="angle down"
                      onClick={() => this.props.position_change(item, "down")}
                    />
                  </Button.Group>
                </Table.Cell>
                <Table.Cell onClick={link} />
              </React.Fragment>
            )}
          </Table.Row>
        );
      });
    }
  }
  render() {
    if (this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>No Services To Display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add Service
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <Table stackable selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Service</Table.HeaderCell>
            <Table.HeaderCell>Short Description</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            {this.props.popularity && (
              <Table.HeaderCell>Popularity</Table.HeaderCell>
            )}
            {this.props.allowAdd && (
              <React.Fragment>
                <Table.HeaderCell>Order</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  <Popup
                    position="top right"
                    trigger={
                      <Button positive icon onClick={this.props.add}>
                        <Icon name="plus" />
                      </Button>
                    }
                    content={"Assign Benefit To Company"}
                  />
                </Table.HeaderCell>
              </React.Fragment>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}

export default serviceList;
