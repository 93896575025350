import React, { Component } from "react";
import {
  Form,
  Segment,
  Icon,
  Checkbox,
  Header,
  Label,
} from "semantic-ui-react";
import api from "../../../../actions/api";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import { Authentication } from "../../../../lib/sso";

import ReactPhoneInput from "react-phone-input-2";

const gender = [
  { text: "Male", value: "Male" },
  { text: "Female", value: "Female" },
  { text: "Other / Prefer Not To Say", value: "Other / Prefer Not To Say" },
];

class editStaffForm extends Component {
  state = {
    data: {
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      home_email: "",
      mobile_number: "",
      phone_number: "",
      meta: {
        height: 0,
        gender: "",
      },
      email: "",
      work_number: "",
      position: "",
      archived: false,
      groups: [],
    },
    errors: {},
    benefits: [],
    permissions: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Promise.all([
      api.employee.getEmployee(this.props.match.params.id),
      api.team.listPermission(),
    ])
      .then((res) => {
        this.setState({
          data: {
            ...this.state.data,
            ...res[0],
            dob:
              res[0].dob !== undefined
                ? moment(res[0].dob).format("DD-MM-YYYY")
                : "",
          },
          permissions: res[1],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDropdownChange = (e, data) => {
    if (data.value.length === 2) {
      data.value.splice(0, 1);

      this.setState({
        data: { ...this.state.data, [data.name]: data.value },
      });
    } else if (data.value.length < 2) {
      this.setState({
        data: { ...this.state.data, [data.name]: data.value },
      });
    }
  };

  onMetaChange = (e) =>
    this.setState({
      data: {
        ...this.state.data,
        meta: {
          ...this.state.data.meta,
          [e.target.name]: e.target.value,
        },
      },
    });

  onMetaDropdownChange = (props, e) => {
    this.setState({
      data: {
        ...this.state.data,
        meta: {
          ...this.state.data.meta,
          [e.name]: e.value,
        },
      },
    });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleDateChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  validate = (data) => {
    let errors = {};

    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.firstName) errors.firstName = "Can't Be Empty";
    if (!data.lastName) errors.lastName = "Can't Be Empty";
    if (!data.email) errors.email = "Can't Be Empty.";
    if (
      data.email &&
      /^[\w\.\+\-]+@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(data.email) ===
        false
    )
      errors.email = "Invalid Format.";
    if (
      data.home_email &&
      /^[\w\.\+\-]+@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(
        data.home_email
      ) === false
    )
      errors.home_email = "Invalid Format.";
    if (
      !data.dob ||
      (data.dob && moment(data.dob, "DD-MM-YYYY", true).isValid() === false)
    )
      errors.dob = "Invalid Format.";
    if (!data.work_number) errors.work_number = "Can't Be Empty.";
    if (data.work_number && data.work_number.toString().length < 4)
      errors.work_number = "Invalid Number.";
    if (data.groups && data.groups.length === 0)
      errors.groups = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors: errors });

    if (Object.keys(errors).length === 0) {
      api.team
        .editMember(this.props.match.params.id, this.state.data)
        .then((res) => {
          this.props.history.push(`/staff/${res._id}`);
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.message === "Dupe email not allowed."
          ) {
            this.setState({
              errors: { ...errors, email: e.response.data.message },
            });
          }
        });
    }
  };

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="no-marg pageTitle">
          Edit Staff
        </Header>
        <Segment>
          <Form>
            <h3>Personal Details</h3>
            <Form.Group widths="equal">
              <Form.Input
                name="firstName"
                label="First Name"
                value={this.state.data.firstName}
                onChange={this.handleChange}
                placeholder="First Name"
                error={this.state.errors.firstName}
              />
              <Form.Input
                name="middleName"
                label="Middle Name"
                value={
                  this.state.data.middleName ? this.state.data.middleName : ""
                }
                onChange={this.handleChange}
                placeholder="Middle Name"
              />
              <Form.Input
                name="lastName"
                label="Last Name"
                value={this.state.data.lastName}
                onChange={this.handleChange}
                placeholder="Last Name"
                error={this.state.errors.lastName}
              />
              <Form.Field
                error={this.state.errors.dob !== undefined ? true : false}
              >
                <DateInput
                  autoComplete={"off"}
                  animation="off"
                  label="Date of Birth"
                  name="dob"
                  value={this.state.data.dob ? this.state.data.dob : ""}
                  iconPosition="left"
                  onChange={this.handleDateChange}
                  startMode={"year"}
                  placeholder="Date Of Birth"
                  closable
                  dateFormat={"DD-MM-YYYY"}
                />
                {this.state.errors.dob && (
                  <Label pointing prompt>
                    {this.state.errors.dob}
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="home_email"
                label="Home Email"
                value={
                  this.state.data.home_email ? this.state.data.home_email : ""
                }
                onChange={this.handleChange}
                placeholder="Home Email"
                error={this.state.errors.home_email}
              />

              <Form.Field>
                <label>Mobile Number</label>
                <ReactPhoneInput
                  placeholder="07973 123456"
                  buttonClass="reactPhoneInputButton"
                  inputStyle={{
                    marginLeft: "30px",
                    maxWidth: "calc(100% - 30px)",
                    fontFamily:
                      "Lato, Helvetica Neue, Arial, Helvetica, sans-serif !important",
                  }}
                  country={"gb"}
                  value={this.state.data.mobile_number}
                  onChange={(value) => {
                    this.setState({
                      data: { ...this.state.data, mobile_number: value },
                    });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Home Number</label>
                <ReactPhoneInput
                  placeholder="01233 123456"
                  buttonClass="reactPhoneInputButton"
                  inputStyle={{
                    marginLeft: "30px",
                    maxWidth: "calc(100% - 30px)",
                    fontFamily:
                      "Lato, Helvetica Neue, Arial, Helvetica, sans-serif !important",
                  }}
                  country={"gb"}
                  value={this.state.data.phone_number}
                  onChange={(value) => {
                    this.setState({
                      data: { ...this.state.data, phone_number: value },
                    });
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Dropdown
                  name="gender"
                  label="Gender"
                  value={this.state.data.meta && this.state.data.meta.gender}
                  onChange={this.onMetaDropdownChange}
                  placeholder="Please Select Gender"
                  options={gender}
                  selection
                />
              </Form.Field>
              <Form.Input
                type="number"
                name="height"
                label="Height"
                value={
                  this.state.data.meta && this.state.data.meta.height
                    ? this.state.data.meta.height
                    : ""
                }
                onChange={this.onMetaChange}
                placeholder="Height"
              />
            </Form.Group>
            {!Authentication.can('organisation_case_manager.permission') &&
            <Form.Group widths="equal">
              <Form.Dropdown
                label="Select User Role(s)"
                name="groups"
                value={this.state.data.groups}
                onChange={this.handleDropdownChange}
                options={this.state.permissions}
                multiple
                search
                selection
                placeholder="Permission Groups"
                error={this.state.errors.groups}
              />
            </Form.Group>}
            <h3>Work Details</h3>
            <Form.Group widths="equal">
              <Form.Input
                type="email"
                name="email"
                label="Work Email"
                value={this.state.data.email}
                onChange={this.handleChange}
                placeholder="Email"
                error={this.state.errors.email}
              />
              <Form.Field
                error={
                  this.state.errors.work_number !== undefined ? true : false
                }
              >
                <div
                  style={{
                    paddingBottom: "14px",
                    paddingTop: "5px",
                    fontSize: ".92857143em",
                  }}
                >
                  <label>
                    <strong>Work Phone</strong>
                  </label>
                  <ReactPhoneInput
                    inputStyle={{
                      marginLeft: "30px",
                      maxWidth: "calc(100% - 30px)",
                      fontFamily:
                        "Lato, Helvetica Neue, Arial, Helvetica, sans-serif !important",
                    }}
                    country={"gb"}
                    value={this.state.data.work_number}
                    placeholder="Work Phone"
                    onChange={(value) => {
                      this.setState({
                        data: { ...this.state.data, work_number: value },
                      });
                    }}
                  />
                </div>
                {this.state.errors.work_number && (
                  <Label pointing prompt>
                    {this.state.errors.work_number}
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            {!Authentication.can('organisation_case_manager.permission') &&
            <Form.Group inline>
              <Checkbox
                label="Archived"
                name="archived"
                checked={this.state.data.archived}
                onClick={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      archived: !this.state.data.archived,
                    },
                  });
                }}
              />
            </Form.Group>}
            <Form.Group widths="equal">
              <Form.Button
                floated="left"
                color="red"
                onClick={() => {
                  window.history.back();
                }}
              >
                <Icon name="arrow left" />
                Back
              </Form.Button>
              <Form.Button
                floated="right"
                type="submit"
                color="orange"
                onClick={this.handleSubmit}
              >
                <Icon name="pencil" />
                Update
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default editStaffForm;
