import React from "react";
import {
  Segment,
  Modal,
  Button,
  Header,
  Message,
  Form,
  Table,
  Select,
  Loader,
  Label,
  Divider,
  Checkbox,
} from "semantic-ui-react";
import api from "../../../actions/api";
import API from "../../../actions/api";
import _appConfig from "../../../_appConfig";
import FileDropbox from "./FileDropbox";

const importConfig = {
  fields: [
    {
      title: "Title",
      field: "title",
      required: true,
    },
    {
      title: "Reference",
      field: "reference",
      required: false,
    },
    {
      title: "First Name",
      field: "first name",
      required: true,
    },
    {
      title: "Middle Name",
      field: "middle name",
      required: false,
    },
    {
      title: "Last Name",
      field: "last name",
      required: true,
    },
    {
      title: "Work Email",
      field: "email",
      required: true,
    },
    {
      title: "Date of Birth",
      field: "date of birth",
      required: false,
    },
    {
      title: "Home Email",
      field: "home email",
      required: false,
    },
    {
      title: "Mobile Number",
      field: "mobile number",
      required: false,
    },
    {
      title: "Home Number",
      field: "home number",
      required: false,
    },
    {
      title: "Gender",
      field: "gender",
      required: false,
    },
    {
      title: "Height",
      field: "height",
      required: false,
    },
    {
      title: "Work Number",
      field: "work number",
      required: false,
    },
    {
      title: "Position",
      field: "position",
      required: false,
    },
    {
      title: "Employee ID/Number",
      field: "employee id",
      required: false,
    },
    {
      title: "National Insurance Number",
      field: "ni",
      required: false,
    },
    {
      title: "Archived",
      field: "archived",
      required: false,
    },
    {
      title: "Leaver Date",
      field: "leaver date",
      required: false,
    },
  ],
};

export default class EmployeeImporter extends React.Component {
  step3_interval = null;
  step3_intervalRunning = false;
  state = {
    loading: false,
    pendingImports: [],
    currentStep: 1,
    currentImportId: null,
    isComplete: false,
    imported: 0,
    updated: 0,
    processed: 0,
    import_errors: [],
    step_1: {
      uploading: false,
      uploadError: null,
    },
    step_2: {
      job: {},
      headerMap: {},
      options: {
        updateExisting: false,
        sendWelcomeEmail: true,
      },
      loading: false,
      error: null,
    },
  };

  componentWillUnmount() {
    if (this.step3_interval) {
      clearInterval(this.step3_interval);
    }
  }

  // Step actions/handlers
  step1_onFileToUpload = (file) => {
    this.setState(
      {
        step_1: {
          uploading: true,
          uploadError: null,
        },
      },
      () => {
        API.company.employeeImporter
          .create(this.props.company_id, file)
          .then((res) => {
            let headerMap = {};
            importConfig.fields.forEach((item) => {
              let matchedHeader = res.csvHeaders.filter((ii) => {
                return (
                  ii.toLowerCase() ===
                  (item.field === "email" ? "work email" : item.field)
                );
              });

              headerMap[item.field] =
                matchedHeader[0] !== undefined ? matchedHeader[0] : "";
            });
            this.setState({
              step_1: {
                uploading: false,
                uploadError: null,
              },
              step_2: {
                ...this.state.step_2,
                job: res,
                headerMap,
              },
              currentImportId: res._id,
              currentStep: 2,
            });
          })
          .catch((err) => {
            let errorMessage =
              "There was an unexpected error, please try again later";
            if (err.response !== undefined) {
              errorMessage = err.response.data.message;
            }
            this.setState({
              step_1: {
                ...this.state.step_1,
                uploadError: errorMessage,
              },
            });
          });
      }
    );
  };
  step2_onSelection = (e, { name, value }) => {
    this.setState({
      step_2: {
        ...this.state.step_2,
        headerMap: {
          ...this.state.step_2.headerMap,
          [name]: value,
        },
      },
    });
  };
  step2_onCheckbox = (e, { name }) => {
    console.log("email", name);
    this.setState({
      step_2: {
        ...this.state.step_2,
        options: {
          ...this.state.step_2.options,
          [name]: !this.state.step_2.options[name],
        },
      },
    });
  };
  step2_submit = () => {
    this.setState(
      {
        step_2: {
          ...this.state.step_2,
          loading: true,
        },
      },
      () => {
        API.company.employeeImporter
          .setFieldMapping(this.props.company_id, this.state.currentImportId, {
            headerMappedFields: this.state.step_2.headerMap,
            importOptions: this.state.step_2.options,
          })
          .then((res) => {
            this.setState(
              {
                currentStep: 3,
              },
              () => {
                this.step3_import();
              }
            );
          })
          .catch((err) => {
            let errorMessage =
              "There was an unexpected error, please try again later";
            if (err.response !== undefined) {
              errorMessage = err.response.data.message;
            }
            this.setState({
              step_2: {
                ...this.state.step_2,
                error: errorMessage,
              },
            });
          })
          .finally(() => {
            // Delay this just in case we override the current step_2 state
            setTimeout(() => {
              this.setState({
                step_2: {
                  ...this.state.step_2,
                  loading: false,
                },
              });
            }, 100);
          });
      }
    );
  };
  step3_intervalTask = () => {
    if (this.step3_intervalRunning) {
      return;
    } // Only allow one instance to be running at any point in time

    API.company.employeeImporter
      .status(this.props.company_id, this.state.step_2.job._id)
      .then((res) => {
        console.log(
          "🚀 ~ file: EmployeeImporter.js ~ line 277 ~ EmployeeImporter ~ .then ~ res",
          res
        );
        if (res.status === "complete") {
          this.setState({
            isComplete: true,
            loading: false,
            imported: res.imported,
            updated: res.updated,
            processed: res.processed,
            import_errors: res.errors,
          });
          clearInterval(this.step3_interval);
        }
      });
  };
  step3_import() {
    API.company.employeeImporter
      .start(this.props.company_id, this.state.currentImportId)
      .then((res) => {
        this.step3_interval = setInterval(this.step3_intervalTask, 5000);
      })
      .catch((err) => {});
  }

  // Renders
  render_step1() {
    return (
      <React.Fragment>
        <Header>Step 1 - Upload CSV</Header>
        <p>
          To start an import process please click and drag you CSV file into the
          below box.
        </p>
        <Segment basic loading={this.state.step_1.uploading}>
          {this.state.step_1.uploadError && (
            <Message negative>
              <Message.Header>Upload Error</Message.Header>
              {this.state.step_1.uploadError}
            </Message>
          )}
          <FileDropbox
            allowedFileType={(type) => {
              if (type === "text/csv") {
                return true;
              } else if (type === "application/vnd.ms-excel") {
                return true;
              }
              return false;
            }}
            onFileToUpload={this.step1_onFileToUpload}
          />
        </Segment>
      </React.Fragment>
    );
  }
  render_step2() {
    let csvHeaderOptions = [
      {
        key: "[empty]",
        value: "",
        text: "[No Selection]",
      },
    ].concat(
      this.state.step_2.job.csvHeaders.map((item, index) => {
        return {
          key: index,
          value: item,
          text: item,
        };
      })
    );
    console.log(this.state.step_2);
    return (
      <Segment basic loading={this.state.step_2.loading}>
        <Header>Step 2 - Map CSV headers & import options</Header>
        <p>
          You will now need to select any import options you like to use and map
          the headers in the uploaded CSV to the relevant data fields.
        </p>
        <Form>
          <Header as={"h4"}>Importer Options</Header>
          <Form.Field>
            <Checkbox
              checked={this.state.step_2.options.updateExisting}
              name={"updateExisting"}
              onChange={this.step2_onCheckbox}
              slider
              label={"Update existing employee records"}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              checked={this.state.step_2.options.sendWelcomeEmail}
              name={"sendWelcomeEmail"}
              onChange={this.step2_onCheckbox}
              slider
              label={"Send welcome email to new users"}
            />
          </Form.Field>

          <Header as={"h4"}>CSV Mapping</Header>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Data Field</Table.HeaderCell>
                <Table.HeaderCell>CSV Field</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {importConfig.fields.map((item, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>
                      {item.title}{" "}
                      {item.required && (
                        <Label size={"mini"} color={"red"}>
                          Required
                        </Label>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Select
                        placeholder={"Please select"}
                        options={csvHeaderOptions}
                        onChange={this.step2_onSelection}
                        name={item.field}
                        value={
                          this.state.step_2.headerMap[item.field] !== undefined
                            ? this.state.step_2.headerMap[item.field]
                            : ""
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <Button positive floated={"right"} onClick={this.step2_submit}>
            Validate and Start CSV Import
          </Button>
          <Divider clearing hidden />
        </Form>
      </Segment>
    );
  }
  render_step3() {
    return (
      <Segment basic textAlign={"center"}>
        <Message color={"purple"}>
          <Loader active inline inverted size={"large"} />
          <br />
          <br />
          Validating CSV and starting import process...
        </Message>
      </Segment>
    );
  }
  renderSteps() {
    if (this.state.isComplete) {
      if (this.state.processed > 0 && (this.state.imported > 0 || this.state.updated > 0)) {
        return (
          <Message positive={!this.state.import_errors.length > 0} warning={this.state.import_errors.length > 0}>
            <Message.Header>
              Imported: {this.state.imported} |  Updated: {this.state.updated} | Out of {this.state.processed} records
            </Message.Header>
            {this.state.import_errors.length > 0 ? <>
              The importer was partly successful, errors are displayed below, all
              the successful rows have new accounts have been created and they
              will receive their welcome email shortly.
              <br />
              <br />
              <b>Errors:</b>
              <br />
              {this.state.import_errors.map((err) => {
                return (
                  <span>
                    {`Row: ${err.row} - ${err.errors.map((e) => `${e} `)}`}
                    <br />
                  </span>
                );
              })}
            </> : <>
              The import is complete. New employees will receive their welcome email shortly (if selected). Any updates for employees complete.
            </>}
          </Message>
        );
      }else {
        return (
          <Message error>
            <Message.Header>Error Importing</Message.Header>
            The importer was unsuccessfull, errors are displayed below. <br />
            <br />
            <b>Errors:</b>
            <br />
            {this.state.import_errors.map((err) => {
              return (
                <span>
                  {`Row: ${err.row} - ${err.errors.map((e) => `${e} `)}`}
                  <br />
                </span>
              );
            })}
          </Message>
        );
      }
    }
    if (this.state.currentStep === 1) {
      return this.render_step1();
    } else if (this.state.currentStep === 2) {
      return this.render_step2();
    } else if (this.state.currentStep === 3) {
      return this.render_step3();
    }
    return null;
  }
  render() {
    return (
      <React.Fragment>
        <Modal.Content>
          <Segment loading={this.state.loading}>{this.renderSteps()}</Segment>
        </Modal.Content>
        <Modal.Actions>
          {this.state.isComplete ? (
            <>
              <Button
                loading={this.state.loadingExport}
                onClick={() => {
                  this.setState({
                    loadingExport: true,
                  });
                  api.company.employeeImporter
                    .export_errors(
                      this.props.company_id,
                      this.state.import_errors
                    )
                    .then((res) => {
                      this.setState({
                        loadingExport: false,
                      });
                      window.open(_appConfig.api_server + res, "_blank");
                    });
                }}
                floated={"right"}
              >
                Export
              </Button>
              <Button
                positive
                onClick={() => this.props.onClose(true)}
                floated={"right"}
              >
                Close
              </Button>
            </>
          ) : (
            <Button negative onClick={this.props.onClose} floated={"left"}>
              Cancel
            </Button>
          )}
        </Modal.Actions>
        <Divider clearing hidden />
      </React.Fragment>
    );
  }
}
