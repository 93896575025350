import React, { Component } from "react";
import { Segment, Header, Button, Icon } from "semantic-ui-react";
import api from "../../../../../actions/api";
import { toast } from "react-semantic-toasts";

class index extends Component {
  state = {
    syncing: false,
    c1hApiUpdated: "",
    genders: [],
    sampleTypes: [],
    specifics: [],
    returned: false,
  };

  __getData() {
    api.settings.all().then((res) => {
      this.setState({ c1hApiUpdated: res.c1hApiUpdated });
    });
  }

  componentDidMount() {
    this.__getData();
  }

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Circular1 Lab API
        </Header>
        <Segment className="border">
          <Header as={"h4"}>
            API Sync for Genders, Specimen and Speciment Types
          </Header>
          <p>
            <i>Last Updated: {this.state.c1hApiUpdated}</i>
          </p>
          {this.state.returned && (
            <>
              <p>
                <b>
                  Returned API Arrays, Companies have been updated with the
                  following if not already existing
                </b>
              </p>
              <p>Genders: {this.state.genders.join(", ")}</p>
              <p>Sample Types: {this.state.sampleTypes.join(", ")}</p>
              <p>Specifics: {this.state.specifics.join(", ")}</p>
            </>
          )}

          <Button
            disabled={this.state.syncing}
            onClick={() => {
              this.setState({ syncing: true, returned: false });
              api.settings.syncC1().then((res) => {
                this.setState({ syncing: false });
                if (res.success) {
                  this.setState({
                    genders: res.genders,
                    sampleTypes: res.sampleTypes,
                    specifics: res.specifics,
                    returned: true,
                  });
                  this.__getData();
                  toast({
                    type: "success",
                    icon: "refresh",
                    title: <h3>API Sync Successfully</h3>,
                    description: (
                      <span>
                        <p>Successfully updated Company Circular1 Data</p>
                      </span>
                    ),
                    time: 4000,
                  });
                }
              });
            }}
          >
            <Icon name="refresh" loading={this.state.syncing} />
            Sync with API
          </Button>
        </Segment>
      </Segment>
    );
  }
}

export default index;
