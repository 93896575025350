import React, { Component } from "react";
import {
  Segment,
  Header,
  Pagination,
  Select,
  Message,
  Input,
  Button,
  Form,
} from "semantic-ui-react";
import ServiceList from "../../../common/lists/serviceList";
import api from "../../../../../actions/api";
import { DateInput } from "semantic-ui-calendar-react";

const pageSizes = [10, 25, 50, 100];

export default class service_report extends Component {
  state = {
    pageSize: pageSizes[1],
    pageNum: 1,
    totalPages: 0,
    totalItems: 0,
    search: "",
    filters: { show_archived: false },
    items: [],
    loading: true,
    globalError: null,
    company_list: [],
  };

  getServices = (search, filters) => {
    return api.reports.service_report({
      search,
      filters,
    });
  };

  componentDidMount() {
    api.company.getAll().then((res) => {
      this.setState({
        company_list: res.map((item) => {
          return { text: item.name, value: item._id };
        }),
      });
    });

    this.queryData();
  }

  queryData() {
    this.setState(
      {
        loading: true,
        globalError: null,
      },
      () => {
        this.getServices(this.state.search, this.state.filters)
          .then((data) => {
            this.setState({
              totalPages: Math.ceil(data.total / this.state.pageSize),
              totalItems: data.total,
              items: data.docs,
            });
          })
          .catch((err) => {
            let globalError =
              "There was an unexpected error while retrieving data from the server";

            if (err.response !== undefined) {
              globalError = err.response.data.message;
            }

            this.setState({
              globalError,
            });
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  }

  resetQuery() {
    this.setState(
      {
        pageNum: 1,
        search: "",
        filters: {},
      },
      () => {
        this.queryData();
      }
    );
  }

  handleSearch = (e, { name, value }) =>
    this.setState({ [name]: value }, () => {
      this.queryData();
    });

  handleDateChange = (event, { name, value }) => {
    this.setState({ filters: { ...this.state.filters, [name]: value } }, () =>
      this.queryData()
    );
  };

  handleDropdownChange = (e, data) => {
    this.setState(
      {
        filters: { ...this.state.filters, [data.name]: data.value },
      },
      () => this.queryData()
    );
  };

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Service Report
        </Header>
        <Segment className="border">
          {this.state.globalError && (
            <Message negative>
              <Message.Header>ERROR</Message.Header>
              {this.state.globalError}
            </Message>
          )}
          <Segment basic className="no-hpad no-marg">
            <Header as="h3" textAlign="right">
              Total Item Count: {this.state.totalItems}
            </Header>
            <Form>
              <Form.Group widths="equal">
                <Form.Dropdown
                  name="company"
                  selection
                  clearable
                  multiple
                  label="Company"
                  placeholder="Company"
                  onChange={this.handleDropdownChange}
                  options={this.state.company_list}
                />
                <Form.Field>
                  <DateInput
                    autoComplete={"off"}
                    animation="off"
                    label="Start Date"
                    name="start_date"
                    value={
                      this.state.filters.start_date
                        ? this.state.filters.start_date
                        : ""
                    }
                    iconPosition="left"
                    onChange={this.handleDateChange}
                    startMode={"year"}
                    placeholder="Start Date"
                    closable
                    clearable
                    onClear={() => {
                      if (this.state.filters.start_date) {
                        this.setState(
                          {
                            filters: {
                              ...this.state.filters,
                              start_date: undefined,
                            },
                          },
                          () => this.queryData()
                        );
                      }
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <DateInput
                    autoComplete={"off"}
                    animation="off"
                    label="End Date"
                    name="end_date"
                    value={
                      this.state.filters.end_date
                        ? this.state.filters.end_date
                        : ""
                    }
                    iconPosition="left"
                    onChange={this.handleDateChange}
                    startMode={"year"}
                    placeholder="End Date"
                    closable
                    clearable
                    onClear={() => {
                      if (this.state.filters.end_date) {
                        this.setState(
                          {
                            filters: {
                              ...this.state.filters,
                              end_date: undefined,
                            },
                          },
                          () => this.queryData()
                        );
                      }
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
          <Input
            style={{ width: "20%" }}
            name="search"
            value={this.state.search}
            onChange={this.handleSearch}
            placeholder="Search..."
          />
          <Button.Group style={{ paddingLeft: "15%" }}>
            <Button
              content={`Show Archived`}
              positive={this.state.filters.show_archived === true}
              onClick={() =>
                this.setState(
                  {
                    filters: {
                      ...this.state.filters,
                      show_archived: true,
                    },
                  },
                  () => {
                    this.queryData();
                  }
                )
              }
            />
            <Button
              content={`Hide Archived`}
              negative={this.state.filters.show_archived === false}
              onClick={() =>
                this.setState(
                  {
                    filters: {
                      ...this.state.filters,
                      show_archived: false,
                    },
                  },
                  () => {
                    this.queryData();
                  }
                )
              }
            />
          </Button.Group>
          <div style={{ float: "right" }}>
            <label>Page Size: </label>
            <Select
              options={pageSizes.map((i) => {
                return {
                  key: i,
                  value: i,
                  text: i,
                };
              })}
              value={this.state.pageSize}
              onChange={(e, data) => {
                this.setState(
                  {
                    totalPages: Math.ceil(this.state.totalItems / data.value),
                    pageSize: data.value,
                    pageNum: 1,
                  },
                  () => {
                    this.queryData();
                  }
                );
              }}
            />
          </div>
          <div style={{ paddingTop: "1rem " }}>
            <ServiceList
              data={this.state.items}
              action={(id) =>
                this.props.history.push(`/settings/services/${id}/edit`)
              }
              popularity={true}
            />
          </div>
          {this.state.items.length >= 1 && (
            <div style={{ textAlign: "right", paddingTop: "1rem" }}>
              <Pagination
                defaultActivePage={this.state.pageNum}
                totalPages={this.state.totalPages}
                onPageChange={(e, { activePage }) => {
                  this.setState({ pageNum: activePage }, () =>
                    this.queryData()
                  );
                }}
              />
            </div>
          )}
        </Segment>
      </Segment>
    );
  }
}
