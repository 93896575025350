import React, { Component } from "react";
import {
  Table,
  Message,
  Button,
  Icon,
  Segment,
  Label,
} from "semantic-ui-react";
// import moment from "moment";

import api from "../../../../actions/api"

// Requires data field names
class emailTemplateList extends Component {

  state = {
    emails: []
  }

  componentDidMount(){
    api.emails.getAll().then((res) => {
      this.setState({emails: res})
    })
  }

  renderList() {
    if (this.state.emails && this.state.emails.length) {
      return this.state.emails.map((item) => {
        return (
          <Table.Row key={item._id}>
            <Table.Cell>{item.template_name} {item.archived && <Label content="Archived" color="red" size="tiny" />}</Table.Cell>
            <Table.Cell>{item.subject}</Table.Cell>
            <Table.Cell><Button icon="pencil" color="orange" content="Edit" onClick={() => this.props.history.push(`/settings/email_templates/edit/${item._id}`)}/></Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  render() {
    if (this.state.emails.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>No Email Templates To Display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.history.push("/settings/email_templates/add")}
              >
                <Icon name="plus" /> Add Email Template
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <Table stackable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Template Name</Table.HeaderCell>
            <Table.HeaderCell>Subject</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              <Button positive icon onClick={()=> 
                  this.props.history.push("/settings/email_templates/add")
                }>
                <Icon name="plus" /> Add
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}

export default emailTemplateList;
