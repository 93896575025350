import React, { Component } from "react";
import { Form, Icon, Header, Divider, Table, Button } from "semantic-ui-react";
import api from "../../../../../actions/api";
import PostCoder from "../../../common/postCoder";
import AppConfig from "../../../../../_appConfig";

var payment_frequency = [
  { text: "Monthly", value: "Monthly" },
  { text: "Quarterly", value: "Quarterly" },
  { text: "Annually", value: "Annually" },
];

var category_cover = [
  { text: "Individual", value: "Individual" },
  { text: "Dependents", value: "Dependents" },
];

export default class EditBenefitForm extends Component {
  state = {
    data: {
      title: "",
      insurer: "",
      allowance: "",
      // underwritten: "",
      address: {
        address: "",
        addressline1: "",
        addressline2: "",
        street: "",
        posttown: "",
        county: "",
        postcode: "",
      },
      payment_frequency: "",
      category_of_cover: "",
      notes: "",
      systems: [],
      assigned_services: [],
      archived: false,
    },
    submit_load: false,
    service_select: "",
    services: [],
    servicesList: [],
    errors: {},
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.benefit !== prevProps.benefit) {
      this.setState(
        {
          data: {
            ...this.props.benefit,
            assigned_services:
              this.props.benefit.assigned_services !== undefined
                ? this.props.benefit.assigned_services.map((item) => {
                    return item._id;
                  })
                : [],
          },
        },
        () => {
          api.services.all().then((res) =>
            this.setState({
              services: res,
              servicesList: res.map((item) => {
                return { text: item.title, value: item._id, key: item._id };
              }),
            })
          );
        }
      );
    }
  }

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  handleServiceDropdownChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleDateChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  handleAddressChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        address: {
          ...this.state.data.address,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  validate = (data) => {
    const errors = {};
    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.insurer) errors.insurer = "Can't Be Empty";
    if (!data.allowance) errors.allowance = "Can't Be Empty";
    // if (!data.underwritten) errors.underwritten = "Can't Be Empty";
    if (!data.category_of_cover) errors.category_of_cover = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ submit_load: true });
      this.props.submit(this.state.data);
    }
  };

  addressUpdate = (addr) => {
    this.setState({
      data: {
        ...this.state.data,
        address: {
          address: addr.summaryline,
          addressline1: addr.addressline1,
          addressline2: addr.addressline2,
          street: addr.street,
          posttown: addr.posttown,
          county: addr.county,
          postcode: addr.postcode,
          latitude: addr.latitude,
          longitude: addr.longitude,
          organisation: addr.organisation,
        },
      },
    });
  };

  renderServiceTable() {
    return this.state.data.assigned_services.map((item, index) => {
      let findService = this.state.services.filter(
        (service) => service !== undefined && service._id.toString() === item
      );

      if (findService.length !== 0) {
        return (
          <Table.Row key={findService[0]._id}>
            <Table.Cell>{findService[0].title}</Table.Cell>
            <Table.Cell>{findService[0].description_short}</Table.Cell>
            <Table.Cell>
              {findService[0].service_resources &&
                findService[0].service_resources.map((resource, index) => {
                  return index < findService[0].service_resources.length - 1
                    ? resource.service_type + ", "
                    : resource.service_type;
                })}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Button
                color="red"
                icon="unlink"
                onClick={() => {
                  let list = this.state.data.assigned_services;

                  list.splice(index, 1);

                  this.setState({
                    data: { ...this.state.data, assigned_services: list },
                  });
                }}
              />
            </Table.Cell>
          </Table.Row>
        );
      } else {
        return (
          <Table.Row key={item}>
            <Table.Cell colSpan="3" warning>
              This Service Is Missing Either Due To An Error Or It Has Been
              Archived
            </Table.Cell>
            <Table.Cell textAlign="center" warning>
              <Button
                color="red"
                icon="unlink"
                onClick={() => {
                  let list = this.state.data.assigned_services;

                  list.splice(index, 1);

                  this.setState({
                    data: { ...this.state.data, assigned_services: list },
                  });
                }}
              />
            </Table.Cell>
          </Table.Row>
        );
      }
    });
  }

  render() {
    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            name="title"
            label="Benefit Title"
            value={this.state.data.title}
            onChange={this.handleChange}
            placeholder="Benefit Title"
            error={this.state.errors.title}
          />
          <Form.Input
            name="insurer"
            label="Insurer"
            value={this.state.data.insurer}
            onChange={this.handleChange}
            placeholder=""
            error={this.state.errors.insurer}
          />
          {/* <Form.Input
              name="benefit_number"
              label="Benefit Number"
              value={this.state.data.benefit_number}
              onChange={this.handleChange}
              placeholder=""
              error={this.state.errors.benefit_number}
            /> */}
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            name="allowance"
            label="Allowance"
            type="number"
            value={this.state.data.allowance}
            onChange={this.handleChange}
            placeholder="Allowance"
            error={this.state.errors.allowance}
          />
          {/* <Form.Input
              name="underwritten"
              label="Underwritten"
              value={this.state.data.underwritten}
              onChange={this.handleChange}
              placeholder=""
              error={this.state.errors.underwritten}
            /> */}
          <Form.Dropdown
            name="payment_frequency"
            label="Payment Frequency"
            value={this.state.data.payment_frequency}
            onChange={this.handleDropdownChange}
            options={payment_frequency}
            selection
            placeholder="Payment Frequency"
          />
          {/*
              <Form.Input
                name="hospital_scales"
                label="Hospital Scales*"
                value={this.state.data.hospital_scales}
                onChange={this.handleChange}
                placeholder=""
              />*/}
          <Form.Dropdown
            name="category_of_cover"
            label="Category Of Cover"
            value={this.state.data.category_of_cover}
            onChange={this.handleDropdownChange}
            options={category_cover}
            selection
            error={this.state.errors.category_of_cover}
            placeholder="Category Of Cover"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Address Lookup</label>
            {/* <label>Please Use Demo Postcode: NR14 7PZ</label> */}
            <PostCoder
              apiKey={AppConfig.postcoder_api_key}
              addressSelectedCallback={this.addressUpdate}
              identifier={AppConfig.postcoder_identifier}
              country={"UK"}
              showAddress={false}
            />
          </Form.Field>
        </Form.Group>
        {this.state.data.address !== undefined &&
        this.state.data.address.addressline1 !== undefined ? (
          <React.Fragment>
            <Form.Group widths="equal">
              <Form.Input
                name="addressline1"
                label="Address Line 1"
                value={this.state.data.address.addressline1}
                onChange={this.handleAddressChange}
                placeholder="Address Line 1"
                error={this.state.errors.addressline1}
              />
              <Form.Input
                name="addressline2"
                label="Address Line 2"
                value={this.state.data.address.addressline2}
                onChange={this.handleAddressChange}
                placeholder="Address Line 2"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="street"
                label="Street"
                value={this.state.data.address.street}
                onChange={this.handleAddressChange}
                placeholder="Street"
                error={this.state.errors.street}
              />
              <Form.Input
                name="posttown"
                label="Town / City"
                value={this.state.data.address.posttown}
                onChange={this.handleAddressChange}
                placeholder="Postal Town / City"
                error={this.state.errors.posttown}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="county"
                label="County"
                value={this.state.data.address.county}
                onChange={this.handleAddressChange}
                placeholder="County"
                error={this.state.errors.county}
              />
              <Form.Input
                name="postcode"
                label="Postcode"
                value={this.state.data.address.postcode}
                onChange={this.handleAddressChange}
                placeholder="Postcode"
                error={this.state.errors.postcode}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.TextArea
                name="notes"
                label="Benefit Notes"
                value={this.state.data.notes}
                onChange={this.handleChange}
                placeholder="Benefit Notes"
              />
            </Form.Group>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
        <Form.Group widths="equal">
          <Form.Checkbox
            label="Archived"
            name="archived"
            style={{ floated: "right" }}
            checked={this.state.data.archived}
            onClick={() => {
              this.setState({
                data: {
                  ...this.state.data,
                  archived: !this.state.data.archived,
                },
              });
            }}
          />
        </Form.Group>
        <Header>Services</Header>
        <Divider />
        <Form.Group>
          <Form.Field width="15">
            <Form.Dropdown
              name="service_select"
              label="Select Service To Assign"
              value={this.state.service_select}
              onChange={this.handleServiceDropdownChange}
              options={
                this.state.servicesList.length !== 0
                  ? this.state.servicesList.filter(
                      (item) =>
                        this.state.data.assigned_services.find(
                          (service) => service === item.value
                        ) === undefined
                    )
                  : []
              }
              selection
              search
              noResultsMessage="No Found Services"
            />
          </Form.Field>
          <Form.Field width="1" style={{ marginTop: "23px" }}>
            <Form.Button
              color="green"
              icon="plus"
              fluid
              disabled={this.state.service_select === ""}
              onClick={() => {
                let list = this.state.data.assigned_services;

                list.push(this.state.service_select);

                this.setState({
                  data: { ...this.state.data, assigned_services: list },
                  service_select: "",
                });
              }}
            />
          </Form.Field>
        </Form.Group>
        <Table stackable selectable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Service</Table.HeaderCell>
              <Table.HeaderCell>Short Description</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell width="1">Unassign</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.data.assigned_services &&
            this.state.data.assigned_services.length !== 0 ? (
              this.renderServiceTable()
            ) : (
              <Table.Row>
                <Table.Cell colSpan="3">No Services Assigned</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Divider />
        <Form.Group widths="equal">
          <Form.Button floated="left" negative onClick={this.props.cancel}>
            <Icon name="close" />
            Cancel
          </Form.Button>
          <Form.Button
            floated="right"
            type="submit"
            onClick={this.handleSubmit}
            color="green"
          >
            <Icon name="plus" />
            Update
          </Form.Button>
        </Form.Group>
      </Form>
    );
  }
}
