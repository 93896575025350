import React, { Component } from "react";
import { DateInput } from "semantic-ui-calendar-react";
import { Form, Icon, Segment, Label } from "semantic-ui-react";

class assignBenefitForm extends Component {
  state = {
    data: {
      benefit: "",
      start_date: "",
      renewal_date: "",
      allowance: "",
    },
    errors: {},
  };

  handleDateChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  validate = (data) => {
    const errors = {};

    if (data.benefit === "") errors.benefit = "Can't Be Empty";
    if (data.start_date === "") errors.start_date = "Can't Be Empty";
    if (data.renewal_date === "") errors.renewal_date = "Can't Be Empty";
    if (data.allowance === "") errors.allowance = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <Form.Select
                label="Benefit"
                options={this.props.benefits}
                name="benefit"
                onChange={this.handleDateChange}
                placeholder="Benefit"
                error={this.state.errors.benefit}
              />
            </Form.Field>
            {/* <Form.Field>
              <Form.Input
                label="Benefit Number"
                name="benefit_number"
                onChange={this.onChange}
                placeholder="Benefit Number"
                error={this.state.errors.benefit_number}
              />
            </Form.Field> */}
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={this.state.errors.start_date}>
              <DateInput
                autoComplete={"off"}
                animation="none"
                name="start_date"
                label="Start Date"
                value={this.state.data.start_date}
                iconPosition="left"
                onChange={this.handleDateChange}
                closable={true}
                placeholder="Start Date"
              />
              {this.state.errors.start_date && (
                <Label pointing prompt>
                  Can't Be Empty
                </Label>
              )}
            </Form.Field>
            <Form.Field error={this.state.errors.renewal_date}>
              <DateInput
                animation="none"
                autoComplete={"off"}
                name="renewal_date"
                label="Renewal Date"
                value={this.state.data.renewal_date}
                iconPosition="left"
                onChange={this.handleDateChange}
                closable={true}
                placeholder="Renewal Date"
              />
              {this.state.errors.renewal_date && (
                <Label pointing prompt>
                  Can't Be Empty
                </Label>
              )}
            </Form.Field>
            <Form.Input
              name="allowance"
              label="Company Allowance"
              type="number"
              value={this.state.data.allowance}
              onChange={this.onChange}
              placeholder="Allowance"
              error={this.state.errors.allowance}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              floated="left"
              color="red"
              onClick={() => this.props.close()}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              floated="right"
              color="green"
              onClick={this.handleSubmit}
            >
              <Icon name="plus" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default assignBenefitForm;
