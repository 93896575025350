import Bridge from "./bridge";

class Authentication {
    constructor(){
        this.com          = null;
        this.userData     = null;
        this.access_token = null;
        this.userScopes   = null;
    }
    setComponent(com){
        this.com = com;
    }
    getComponent(){
        return this.com;
    }
    logout(){
        this.getComponent().logout();
    }
    setUserData(data){
        this.userData = data;
    }
    getUserData(){
        return this.userData;
    }
    setAccessToken(token){
        this.access_token = token;
    }
    getAccessToken(){
        return this.access_token;
    }
    setUserScopes(data){
        this.userScopes = data.map(i => i.toLowerCase());
    }
    getUserScopes(){
        return this.userScopes;
    }
    can(scope, context = null){
        scope = scope.toLowerCase();
        if(this.userScopes === null || !Array.isArray(this.userScopes)){
            return false;
        }
        let hasScope = this.userScopes.indexOf(scope) !== -1;
        let contextHandlers = Bridge.getContextHandlers();
        if(hasScope && contextHandlers[scope]){
            let func = contextHandlers[scope];
            if(typeof func === "function"){
                return func(this.getUserData(), context);
            }
        }
        return hasScope;
    }
}

export default new Authentication();