import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import { AuthContext } from "../../lib/sso";

const TopMasquerade = ({authentication}) => {
  const handleExit = () => {
    authentication.logout(false);
  }

  if(authentication.user._isMasqueraded){
    return (
      <div id="masqueradeBar">
        You are currently masqueraded as ({authentication.user.firstName} {authentication.user.lastName})
        <Button icon="log out" content="Exit" size="tiny" color={"blue"} onClick={handleExit} style={{marginLeft: 15, padding: 8}} />
      </div>
    );
  }
  return null;
}
export default AuthContext(TopMasquerade);