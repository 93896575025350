import React, {useState, useEffect} from "react";
import {Header, Segment, Button, Message, Form, Label} from "semantic-ui-react";
import API from "../../../../actions/api";
import {Can} from "../../../../lib/sso";
import {DateInput} from "semantic-ui-calendar-react";
import Moment from "moment";

const BookingsTester = () => {
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState("");
  const [calendarServices, setCalendarServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [isLoadingTimes, setIsLoadingTimes] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");



  useEffect(() => {
    API.microsoft_bookings.getCalendars().then(res => {
      setCalendars(res);
    })
  }, []);
  useEffect(() => {
    if(selectedCalendar === ""){
      return;
    }
    API.microsoft_bookings.getCalendarServices(selectedCalendar).then(res => {
      setCalendarServices(res);
    })
  }, [selectedCalendar]);
  useEffect(() => {
    if(selectedCalendar === ""){
      setSelectedDate(Moment().format("YYYY-MM-DD"));
      return;
    }
    if(selectedService === ""){
      return;
    }
    setAvailableSlots([]);
    setIsLoadingTimes(true);
    API.microsoft_bookings.getAvailableSlots(selectedCalendar, selectedService, selectedDate).then(res => {
      setAvailableSlots(res);
      setIsLoadingTimes(false);
    })
  }, [selectedService, selectedDate]);

  const renderCalendar = () => {
    if(selectedService === ""){
      return null;
    }
    return (
      <Form.Field
        // error={this.state.errors.dob !== undefined ? true : false}
      >
        <DateInput
          autoComplete={"off"}
          animation="off"
          label="Date"
          name="date"
          inline={true}
          value={selectedDate}
          onChange={(event, { value }) => setSelectedDate(value)}
          initialDate={new Date()}
          minDate={Moment()}
          startMode={"day"}
          dateFormat={"YYYY-MM-DD"}
        />
        {/*{this.state.errors.dob && (*/}
        {/*  <Label pointing prompt>*/}
        {/*    {this.state.errors.dob}*/}
        {/*  </Label>*/}
        {/*)}*/}
      </Form.Field>
    );
  };
  const renderTimePicker = () => {
    if(isLoadingTimes && selectedDate){
      return (
        <Form.Field>
          Loading Slots...
        </Form.Field>
      );
    }
    if(selectedService && selectedDate){
      return (
          <Form.Dropdown
            name="timeSlot"
            label="Time Slot"
            value={selectedSlot}
            onChange={(e, data) => setSelectedSlot(data.value)}
            options={availableSlots.map(a => {
              return {
                text: Moment(a.startAt).format("hh:mm a") + " - (Slots: " + a.staff.length + ")",
                value: a.startAt
              }
            })}
            selection
          />
      );
    }
    return null;
  }

  return (
    <>
      <Form>
        <Form.Group widths={6}>
          <Form.Dropdown
            name="bookingsCalendar"
            label="Bookings Calendar"
            value={selectedCalendar}
            onChange={(e, data) => setSelectedCalendar(data.value)}
            options={calendars.map(a => {
              return {
                text: a.displayName,
                value: a.id
              }
            })}
            selection
          />
          {selectedCalendar !== "" && <Form.Dropdown
            name="calendarServices"
            label="Services"
            value={selectedService}
            onChange={(e, data) => setSelectedService(data.value)}
            options={calendarServices.map(a => {
              return {
                text: a.displayName,
                value: a.id
              }
            })}
            selection
          />}
          {renderCalendar()}
          {renderTimePicker()}



        </Form.Group>
      </Form>
    </>
  );
}
const MicrosoftBookings = () => {
  const [bookingsEnabled, setBookingsEnabled] = useState(false);

  useEffect(() => {
    API.microsoft_bookings.isEnabled().then(isEnabled => {
      setBookingsEnabled(isEnabled);
    })
  }, [])

  const handleAuthorise = () => {
    API.microsoft_bookings.getAuthoriseUrl(window.location).then(res => {
      console.log("link", res);
      window.open(res, "_new");
    });
  }

  return (
    <Segment basic className="no-pad">
      <Header as={"h1"} textAlign="center" className="pageTitle">
        Microsoft Bookings
      </Header>
      <Can has={"microsoft_bookings.setup"} no={() => <Message negative>You do not have permission to access this page.</Message>}>
        <Segment className="border">
          {bookingsEnabled ? <BookingsTester /> : <Message info>Bookings not enabled/auth expired. <br /><Button onClick={handleAuthorise}>Authorises with Microsoft API</Button></Message>}
        </Segment>
      </Can>
    </Segment>
  );
};
export default MicrosoftBookings;