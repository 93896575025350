import Axios from "axios";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;
function applyHeaders(extra = {}) {
  return {
    headers: {
      Authorization: "Bearer " + localStorage.getItem(AppConfig.localStoragePrefix + "access_token"),
    },
    ...extra,
  };
}


export default {
  getAuthoriseUrl: async return_url => Axios.get(`${server}/microsoft_bookings/oauth_link?return_to=${return_url}`, applyHeaders()).then(res => res.data),
  isEnabled: async () => Axios.get(`${server}/microsoft_bookings/is_enabled`, applyHeaders()).then(res => res.data),
  getCalendars: async () => Axios.get(`${server}/microsoft_bookings/calendars`, applyHeaders()).then(res => res.data),
  getCalendarServices: async businessId => Axios.get(`${server}/microsoft_bookings/calendars/${businessId}/services`, applyHeaders()).then(res => res.data),
  getAvailableSlots: async (businessId, serviceId, date) => Axios.get(`${server}/microsoft_bookings/calendars/${businessId}/services/${serviceId}/availability?date=${date}`, applyHeaders()).then(res => res.data),
}