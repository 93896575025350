import React, { Component } from "react";
import api from "../../actions/api";
import {
  Segment,
  Header,
  Grid,
  Statistic,
  Modal,
  Loader,
  Menu,
  Icon,
  Button
} from "semantic-ui-react";
import ReferralList from "../../components/lists/referralList";
import AddReferralForm from "./forms/addReferralForm";
import PaginateWrapper from "../../components/PaginateWrapper";
import { Authentication } from "../../../../lib/sso";
import moment from "moment";
import { toast } from "react-semantic-toasts";

export default class EmployeeShow extends Component {
  state = {
    activeItem: "referrals",
    user: {
      company: {},
      Dash: {},
    },
    referral_list: [],
    referrals: [],
    addReferral: false,
    logLoading: false,
    toggleLogForm: false,
    refreshRef: false,
    loading_resendOnboarding: false,
  };

  refList = React.createRef();

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.employee
      .get(this.props.match.params.id)
      .then((res) => {
        this.setState({
          user: res,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getReferrals = (pageNum, pageSize, search, filters) => {
    return api.employee.getAllEmployeeReferralPaged(
      this.props.match.params.id,
      {
        pageNum,
        pageSize,
        search,
        filters,
      }
    );
  };
  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });

  gotoReferral = (id) => {
    this.props.history.push(`/referral/${id}`);
  };
  renderReferrals() {
    if (this.state.refreshRef === false) {
      return (
        <Segment basic>
          <PaginateWrapper
            dataQuery={this.getReferrals}
            render={(items) => (
              <ReferralList
                data={items}
                action={this.gotoReferral}
                add={() => this.setState({ addReferral: true })}
                allowAdd={true}
              />
            )}
          />
        </Segment>
      );
    } else {
      return (
        <Segment basic>
          <Loader active />
        </Segment>
      );
    }
  }
  renderHeader() {
    const { user } = this.state;
    return (
      <Segment basic className="no-marg no-pad" loading={this.state.loading}>
        <Grid className={"no-marg "}>
          <Grid.Row className="no-pad" stretched>
            <Grid.Column
              computer={12}
              mobile={16}
              className="no-padL compDetails"
            >
              <Grid celled className="detailsGrid">
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Work Status:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.work_status}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Date Of Birth:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>
                      {moment(user.dob).format("DD-MM-YYYY")}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Work Phone:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.work_number}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Work Email:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.email}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Position:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.position}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Mobile:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.mobile_number}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Home Phone:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.phone_number}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Home Email:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.home_email}</Header>
                  </Grid.Column>
                </Grid.Row>{" "}
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Address:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>
                      {user.address && user.address.address}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column
              computer={4}
              mobile={16}
              className="no-padR compDetails"
            >
              <Grid celled className="detailsGrid">
                <Grid.Row>
                  <Grid.Column width={16} textAlign="center">
                    <Segment basic textAlign="center">
                      <Statistic.Group widths={1}>
                        <Statistic>
                          <Statistic.Value>
                            {user.Dash.activeRef}
                          </Statistic.Value>
                          <Statistic.Label>Active Referrals</Statistic.Label>
                        </Statistic>
                        <Statistic>
                          <Statistic.Value>{user.Dash.compRef}</Statistic.Value>
                          <Statistic.Label>Closed Referrals</Statistic.Label>
                        </Statistic>
                      </Statistic.Group>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.renderMenu()}
      </Segment>
    );
  }
  renderAddReferral() {
    const closeReferralModal = () => {
      this.setState({ addReferral: false });
    };

    const addReferral = (formData) => {
      let data = formData;
      this.setState({ refreshRef: true });
      data.company = this.state.user.company._id;
      api.employee
        .addReferal(this.state.user._id, data)
        .then(() => {
          closeReferralModal();
          this.getData();
          this.setState({ refreshRef: false });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ refreshRef: false });
        });
    };

    return (
      <Modal
        open={this.state.addReferral}
        onClose={closeReferralModal}
        centered={false}
      >
        <Header icon="medkit" content="Add Referral" />
        <Modal.Content>
          <AddReferralForm submit={addReferral} cancel={closeReferralModal} />
        </Modal.Content>
      </Modal>
    );
  }

  renderMenu() {
    return (
      <Menu className="border no-marg subMenu">
        <Menu.Item
          onClick={() =>
            this.props.history.push(`/employee/edit/${this.state.user._id}`)
          }
        >
          <Icon name="pencil" /> Edit
        </Menu.Item>
        <Menu.Item loading={this.state.loading_add_hj} onClick={() => {
          api.employee.addAssessment(this.state.user._id).then((res) => {
            this.props.history.push(`/assessment/${res.id}`);
          })
        }}>
          <Icon name="plus" /> Add Health Journey for {this.state.user.firstName}
        </Menu.Item>
        <Menu.Item onClick={() => this.setState({ addReferral: true })}>
          <Icon name="plus" /> Add Referral for {this.state.user.firstName}
        </Menu.Item>
        <Menu.Menu position="right">
          {/*  
          {Authentication.can("organisation_admin.permission") === true ? (
            <Menu.Item
              onClick={() =>
                this.props.history.push(
                  `/reset_password/${this.props.match.params.id}`
                )
              }
            >
              <Icon name="help" />
              Reset Password
            </Menu.Item>
          ) : (
            <React.Fragment />
          )}*/} 
          {Authentication.can("navigator_admin.permission") === true ? (
            <Button
            primary
            loading={this.state.loading_resendOnboarding}
              onClick={() =>
                {
                  this.setState({loading_resendOnboarding: true});
                  api.employee.resendOnboarding(this.props.match.params.id).then((res) => {
                    this.setState({loading_resendOnboarding: false});
                    toast({
                      type: "success",
                      icon: "mail",
                      title: <h3>Onboarding Email Resent</h3>,
                      description: (
                        <span>
                          <p>Successfully resent onboarding email.</p>
                        </span>
                      ),
                      time: 4000,
                    });
                })
                }
                
              }
            >
              <Icon name="mail" />
              Resend Onboarding Email
            </Button>
          ) : (
            <React.Fragment />
          )}
        </Menu.Menu>
      </Menu>
    );
  }

  render() {
    const { user } = this.state;
    return (
      <Segment basic>
        <Header
          as={"h1"}
          textAlign="center"
          className="no-marg sectionTitle main"
        >
          {user.title} {user.firstName} {user.lastName} (#{user.ref})
          {/* <Button size="tiny" basic onClick={() => this.props.history.push(`/employee/edit/${user._id}`)}>edit</Button> */}
        </Header>
        <Segment basic className="no-pad ">
          {this.renderAddReferral()}
          {this.renderHeader()}
        </Segment>
        <Segment className="no-pad border">{this.renderReferrals()}</Segment>
      </Segment>
    );
  }
}
