import React from "react";
import {
  Table,
  Message,
  Segment,
  Button,
  Icon,
  Label,
} from "semantic-ui-react";
import moment from "moment";

export default class Employee_List extends React.PureComponent {
  renderList() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        return (
          <Table.Row key={item._id} onClick={() => this.props.action(item._id)}>
            <Table.Cell>{item.ref}</Table.Cell>
            <Table.Cell>{`${item.firstName} ${item.lastName}`}</Table.Cell>
            <Table.Cell>
              {item.position !== undefined && item.position !== ""
                ? item.position
                : "[UNASSIGNED]"}
            </Table.Cell>
            <Table.Cell>{item.email}</Table.Cell>
            <Table.Cell>
              {item.primary_benefit !== undefined ? (
                <Label
                  color={
                    moment(item.primary_benefit.renewal_date).isBefore(
                      moment()
                    ) === true
                      ? "red"
                      : "grey"
                  }
                  key={item.primary_benefit._id}
                >
                  {item.primary_benefit.benefit.title}
                </Label>
              ) : (
                <React.Fragment />
              )}
              {item.secondary_benefit !== undefined ? (
                <Label
                  color={
                    moment(item.secondary_benefit.renewal_date).isBefore(
                      moment()
                    ) === true
                      ? "red"
                      : "grey"
                  }
                  key={item.secondary_benefit._id}
                >
                  {item.secondary_benefit.benefit.title}
                </Label>
              ) : (
                <React.Fragment />
              )}
              {item.third_benefit !== undefined ? (
                <Label
                  color={
                    moment(item.third_benefit.renewal_date).isBefore(
                      moment()
                    ) === true
                      ? "red"
                      : "grey"
                  }
                  key={item.third_benefit._id}
                >
                  {item.third_benefit.benefit.title}
                </Label>
              ) : (
                <React.Fragment />
              )}
            </Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  render() {
    if (this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add New Employee
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <Table stackable selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Reference</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Position</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Benefits</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}
