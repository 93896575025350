import React from "react";
import moment from "moment";
import {
  Table,
  Message,
  Button,
  Icon,
  Popup,
  Segment,
} from "semantic-ui-react";
import {Can} from "../../../../lib/sso";

export default class BenefitList extends React.PureComponent {
  renderList() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        let past = false;

        if (moment(item.renewal_date).isBefore(moment())) {
          past = true;
        }
        return (
          <Table.Row
            key={item._id}
            onClick={() => this.props.action(item._id)}
            negative={past}
            positive={!past}
          >
            <Table.Cell>{item.benefit.title}</Table.Cell>
            <Table.Cell>
              {moment(item.start_date).format("DD/MM/YYYY")}
            </Table.Cell>
            <Table.Cell>
              {moment(item.renewal_date).format("DD/MM/YYYY")}
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
        );
      });
    }
  }
  render() {
    if (this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>No Benefits Assigned</p>
              <Can has={"company.benefit.assign"}>
                    {this.props.allowAdd && (
                      <Button icon positive onClick={() => this.props.add()}>
                        <Icon name="plus" /> Add Benefit
                      </Button>
                    )}
              </Can>
          </Segment>
        </Message>
      );
    }
    return (
      <Table stackable selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Benefit</Table.HeaderCell>
            <Table.HeaderCell>Start Date</Table.HeaderCell>
            <Table.HeaderCell>Renewal Date</Table.HeaderCell>
            <Table.HeaderCell width={1}>
                <Can has={"company.benefit.assign"}>
                    <Popup
                        position="top right"
                        trigger={
                            <Button positive icon onClick={this.props.add}>
                                <Icon name="plus" />
                            </Button>
                        }
                        content={"Assign Benefit To Company"}
                    />
                </Can>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}
