import React, { Component } from "react";
import { Table, Message, Segment, Button, Icon } from "semantic-ui-react";
import Moment from "react-moment";

class staffNoteList extends Component {
  renderList() {
    if (this.props.data && this.props.data.length) {
      const list = this.props.data.map((item) => {
        return (
          <Table.Row
            key={item._id}
            onClick={() => {
              this.props.action(item._id);
            }}
          >
            <Table.Cell width={3}>
              <Moment format={"DD/MM/YYYY HH:mm"}>{item.createdAt}</Moment>
            </Table.Cell>
            <Table.Cell width={3}>{item.loggedBy}</Table.Cell>

            <Table.Cell width={10}>{item.message}</Table.Cell>
            <Table.Cell>
              <Button color="orange" icon onClick={() => this.props.edit(item)}>
                <Icon name="pencil" />
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
      return list;
    }
  }

  render() {
    if (this.props.data && this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add New Note
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Logged By</Table.HeaderCell>
            <Table.HeaderCell>Message</Table.HeaderCell>
            {this.props.allowAdd && (
              <Table.HeaderCell textAlign="right" width={1}>
                <Button icon positive onClick={() => this.props.add()}>
                  <Icon name="plus" />
                </Button>
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}

export default staffNoteList;
