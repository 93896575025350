import React, { Component } from "react";
import { Header, Grid, Card, Segment, Loader, Table } from "semantic-ui-react";
import { Authentication } from "../../lib/sso";
import UserLookupWidget from "./common/userLookupWidget";
import CompanyLookupWidget from "./common/companyLookupWidget";
import moment from "moment";
import api from "../../actions/api";
import ConsentForm from "../../assets/docs/Consent_Form.pdf";
import ReferralForm from "../../assets/docs/Referral_Form.pdf";
import { FaRegFilePdf } from "react-icons/fa";

export default class PNDash extends Component {
  state = {
    data: {},
    loading: true,
    error: false,
  };

  componentDidMount() {
    api.home
      .getDashStats()
      .then((res) => {
        this.setState({ data: res, loading: false });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ error: true });
      });
  }

  renderUpcomingReferralActivitiesCM() {
    const list = this.state.data.upcoming_referral_activities.map(
      (item, index) => {
        return (
          <Table.Row
            textAlign="center"
            key={index}
            onClick={() => {
              this.props.history.push(
                `/referral/${item.referral && item.referral._id}`
              );
            }}
          >
            <Table.Cell>
              {item.referral && "R" + item.referral.refNum}
            </Table.Cell>
            <Table.Cell>{moment(item.logDue).format("DD/MM/YYYY")}</Table.Cell>
            <Table.Cell>{item.logType}</Table.Cell>
          </Table.Row>
        );
      }
    );

    return (
      <Table striped celled>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>Referral</Table.HeaderCell>
            <Table.HeaderCell>Due</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.length !== 0 ? (
            list
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                No Upcoming Activities
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }

  renderOverdueReferralActivitiesCM() {
    const list = this.state.data.overdue_referral_activities.map(
      (item, index) => {
        return (
          <Table.Row
            textAlign="center"
            key={index}
            onClick={() => {
              this.props.history.push(
                `/referral/${item.referral && item.referral._id}`
              );
            }}
          >
            <Table.Cell>
              {item.referral && "R" + item.referral.refNum}
            </Table.Cell>
            <Table.Cell>{moment(item.logDue).format("DD/MM/YYYY")}</Table.Cell>
            <Table.Cell>{item.logType}</Table.Cell>
          </Table.Row>
        );
      }
    );

    return (
      <Table striped celled>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>Referral</Table.HeaderCell>
            <Table.HeaderCell>Due</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.length !== 0 ? (
            list
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                No Overdue Activities
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }

  renderNewReferralsCM() {
    const list = this.state.data.new_referrals.map((item, index) => {
      return (
        <Table.Row
          textAlign="center"
          key={index}
          onClick={() => {
            this.props.history.push(`/referral/${item._id}`);
          }}
        >
          <Table.Cell>{"R" + item.refNum}</Table.Cell>
          <Table.Cell>{moment(item.logDue).format("DD/MM/YYYY")}</Table.Cell>
          <Table.Cell>{item.condition}</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table striped celled>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>Referral</Table.HeaderCell>
            <Table.HeaderCell>Due</Table.HeaderCell>
            <Table.HeaderCell>Condition</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.length !== 0 ? (
            list
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                No Overdue Activities
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }

  renderOverdueCaseActivitiesCM() {
    const list = this.state.data.overdue_case_activities.map((item, index) => {
      return (
        <Table.Row
          textAlign="center"
          key={index}
          onClick={() => {
            this.props.history.push(`/case/${item.case && item.case._id}`);
          }}
        >
          <Table.Cell>{item.case && "C" + item.case.refNum}</Table.Cell>
          <Table.Cell>{moment(item.logDue).format("DD/MM/YYYY")}</Table.Cell>
          <Table.Cell>{item.logType}</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table striped celled>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>Case</Table.HeaderCell>
            <Table.HeaderCell>Due</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.length !== 0 ? (
            list
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                No Overdue Activities
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }

  renderUpcomingCaseActivitiesCM() {
    const list = this.state.data.upcoming_case_activities.map((item, index) => {
      return (
        <Table.Row
          textAlign="center"
          key={index}
          onClick={() => {
            this.props.history.push(`/case/${item.case && item.case._id}`);
          }}
        >
          <Table.Cell>{item.case && "C" + item.case.refNum}</Table.Cell>
          <Table.Cell>{moment(item.logDue).format("DD/MM/YYYY")}</Table.Cell>
          <Table.Cell>{item.logType}</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table striped celled>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>Case</Table.HeaderCell>
            <Table.HeaderCell>Due</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.length !== 0 ? (
            list
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                No Upcoming Activities
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }

  render() {
    if (this.state.loading === false && this.state.error === false) {
      let notCompanyCaseManager = Authentication.can("organisation_case_manager.permission") !== true;
      return (
        <Segment basic className="no-pad pageHolder">
          <Header
            as={"h1"}
            textAlign="center"
            className="pageTitle"
            style={{ marginTop: "14px" }}
          >
            Referral Dash
          </Header>
          <Grid stackable className={"no-marg"}>

            <Grid.Row>
              <Grid.Column>
                <Segment className="border" style={{ height: "100%" }}>
                  <a href={ConsentForm} download="Consent_Form.pdf">
                    <FaRegFilePdf style={{ color: "#663390" }} size={"1.6rem"} />{" "}
                    Consent Form{" "}
                  </a>
                  <a href={ReferralForm} download="Referral_Form.pdf">
                    <FaRegFilePdf style={{ color: "#663390" }} size={"1.6rem"} />{" "}
                    Referral Form{" "}
                  </a>
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <Card fluid className="dashCard">
                  <Card.Content>
                    <Card.Header>Patient Lookup</Card.Header>
                    <Card.Description>
                      <UserLookupWidget history={this.props.history} />
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
            {notCompanyCaseManager? (
                <Grid.Row>
              <Grid.Column width={16}>
                <Card fluid className="dashCard">
                  <Card.Content>
                    <Card.Header>Company Lookup</Card.Header>
                    <Card.Description>
                      <CompanyLookupWidget history={this.props.history} />
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
              ) : <React.Fragment />}
            
            {Authentication.can("navigator_admin.permission") === true ? (
              <React.Fragment>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Card
                      fluid
                      className="actionCard"
                      onClick={() => this.props.history.push("/case")}
                    >
                      <Card.Content>
                        <Card.Header>Cases With Overdue Activities</Card.Header>
                        <Card.Description className="stat">
                          <div>
                            <Header className="statValue">
                              {this.state.data.overdue_case_activities}
                            </Header>
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column>
                    <Card
                      fluid
                      className="actionCard"
                      onClick={() => this.props.history.push("/referral")}
                    >
                      <Card.Content>
                        <Card.Header>
                          Referrals With Overdue Activities
                        </Card.Header>
                        <Card.Description className="stat">
                          <div>
                            <Header className="statValue">
                              {this.state.data.overdue_referral_activities}
                            </Header>
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Card
                      fluid
                      className="actionCard"
                      onClick={() => this.props.history.push("/referral/list")}
                    >
                      <Card.Content>
                        <Card.Header>
                          Referrals Without Case Manager
                        </Card.Header>
                        <Card.Description className="stat">
                          <div>
                            <Header className="statValue">
                              {this.state.data.unassigned_referrals}
                            </Header>
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>
            ) : Authentication.can("navigator_case_manager.permission") ===
              true || Authentication.can("organisation_case_manager.permission") ===
              true ? (
              <React.Fragment>
                <Grid.Row columns={notCompanyCaseManager ? 2 : 1}>
                  <Grid.Column>
                    <Card
                      fluid
                      className="actionCard"
                      onClick={() => this.props.history.push("/referral/list")}
                    >
                      <Card.Content>
                        <Card.Header>Active Referrals</Card.Header>
                        <Card.Description className="stat">
                          <div>
                            <Header className="statValue">
                              {this.state.data.active_referrals}
                            </Header>
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  {notCompanyCaseManager ? 
                  <Grid.Column>
                    <Card
                      fluid
                      className="actionCard"
                      onClick={() => this.props.history.push("/case/list")}
                    >
                      <Card.Content>
                        <Card.Header>Active Cases</Card.Header>
                        <Card.Description className="stat">
                          <div>
                            <Header className="statValue">
                              {this.state.data.active_cases}
                            </Header>
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  : ""}
                  
                </Grid.Row>
                <Grid.Row columns={notCompanyCaseManager ? 2 : 1}>
                  <Grid.Column>
                    <Card fluid className="dashCard">
                      <Card.Content>
                        <Card.Header>Upcoming Referral Activities</Card.Header>
                        <Card.Description>
                          {this.renderUpcomingReferralActivitiesCM()}
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  {notCompanyCaseManager ? 
                  <Grid.Column>
                    <Card fluid className="dashCard">
                      <Card.Content>
                        <Card.Header>Upcoming Case Activites</Card.Header>
                        <Card.Description>
                          {this.renderUpcomingCaseActivitiesCM()}
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column> : ""}
                </Grid.Row>
                <Grid.Row columns={notCompanyCaseManager ? 2 : 1}>
                  <Grid.Column>
                    <Card fluid className="dashCard">
                      <Card.Content>
                        <Card.Header>Overdue Referral Activies</Card.Header>
                        <Card.Description>
                          {this.renderOverdueReferralActivitiesCM()}
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  {notCompanyCaseManager ? 
                  <Grid.Column>
                    <Card fluid className="dashCard">
                      <Card.Content>
                        <Card.Header>Cases With Overdue Activities</Card.Header>
                        <Card.Description>
                          {this.renderOverdueCaseActivitiesCM()}
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column> : "" }
                </Grid.Row>
                {/* <Grid.Row>
                  <Grid.Column>
                    <Card fluid className="dashCard">
                      <Card.Content>
                        <Card.Header>New Referrals</Card.Header>
                        <Card.Description>
                          {this.renderNewReferralsCM()}
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid.Row> */}
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </Grid>
        </Segment>
      );
    } else if (this.state.error === true || this.state.loading === true) {
      return (
        <Segment basic padded="very">
          <Loader active indeterminate={this.state.error === true}>
            {this.state.error === true
              ? "Error Fetching Data, Please Try Reloading..."
              : ""}
          </Loader>
        </Segment>
      );
    }
  }
}
