import React, { Component } from "react";
import {
  Form,
  Segment,
  Icon,
  Checkbox,
  Header,
  Message,
} from "semantic-ui-react";
import api from "../../../../../actions/api";
import moment from "moment";
import AppConfig from "../../../../../_appConfig";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, ContentState, Modifier } from "draft-js";
import PropTypes from "prop-types";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

class ShortcodeDropdown extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  state = {
    open: false,
  };

  openPlaceholderDropdown = () => this.setState({ open: !this.state.open });

  addPlaceholder = (placeholder) => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      placeholder,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  placeholderOptions = [
    { key: "to", value: "{TO}", text: "To Email" },
    {
      key: "employee_firstname",
      value: "{EMP_FIRSTNAME}",
      text: "Employee FirstName",
    },
    {
      key: "employee_lastname",
      value: "{EMP_LASTNAME}",
      text: "Employee LastName",
    },
    { key: "employee_ID", value: "{EMP_ID}", text: "Employee ID" },
    { key: "url_portal", value: "{PORTAL_LINK}", text: "URL Portal" },
    { key: "url_app", value: "{APP_LINK}", text: "URL App" },
    { key: "username", value: "{USERNAME}", text: "Username" },
    { key: "password", value: "{PASSWORD}", text: "Password" },
    {
      key: "password_reset_link",
      value: "{PASSWORD_RESET_LINK}",
      text: "Password Reset Link",
    },
    { key: "2FA", value: "{2FA}", text: "2FA" },
    {
      key: "ref_name",
      value: "{REFERRAL_MAN_NAME}",
      text: "Referral Manager Name",
    },
    {
      key: "ref_email",
      value: "{REFERRAL_MAN_EMAIL}",
      text: "Referral Manager Email",
    },
    {
      key: "ca_email",
      value: "{COMPANY_ADMINS}",
      text: "Company Admins Name & Email",
    },
    // {key: "ca_name", value: "{COMPANY_ADMIN_NAME}", text: "Company Admin Name"},
    // {key: "ca_email", value: "{COMPANY_ADMIN_EMAIL}", text: "Company Admin Email"},
    {
      key: "ncm_name",
      value: "{NURSE_CASEMAN_NAME}",
      text: "Nurse Case Manager Name",
    },
    {
      key: "ncm_email",
      value: "{NURSE_CASEMAN_EMAIL}",
      text: "Nurse Case Manager Email",
    },
    { key: "company_name", value: "{COMPANY_NAME}", text: "Company Name" },
    { key: "c_number", value: "{CASE_NUMBER}", text: "Case Number" },
    { key: "r_number", value: "{REFERRAL_NUMBER}", text: "Referral Number" },
    {
      key: "case_note_type",
      value: "{CASE_NOTE_TYPE}",
      text: "Case Note Type",
    },
    // {key: "case_note_subject", value: "{CASE_NOTE_SUBJECT}", text: "Case Note Subject"},
    {
      key: "case_note_message",
      value: "{CASE_NOTE_MESSAGE}",
      text: "Case Note Message",
    },
    {
      key: "case_note_date",
      value: "{CASE_NOTE_DATE}",
      text: "Case Note Due Date",
    },
    {
      key: "case_note_files",
      value: "{CASE_NOTE_FILES}",
      text: "Num. of Case Note Files",
    },
    //ASSESSMENT
    {
      key: "clinician_decision",
      value: "{CLINICIAN_DECISION}",
      text: "Clinician Decision Selected",
    },
    {
      key: "clinician_decision_date",
      value: "{CLINICIAN_DECISION_DATE}",
      text: "Date of Clinician Decision ",
    },
    {
      key: "health_assessment_date",
      value: "{HEALTH_ASSESSMENT_DATE}",
      text: "Health Assessment Booking Date",
    },
    {
      key: "followup_consultation_date",
      value: "{FOLLOWUP_CONSULTATION_DATE}",
      text: "Follow Up Consultation Date",
    },
    {
      key: "assessment_test_date",
      value: "{ASSESSMENT_TEST_DATE}",
      text: "Date Test Result Returned",
    },
    {
      key: "clinician_decision_notes",
      value: "{CLINICIAN_DECISION_NOTES}",
      text: "Clinician Decision Notes",
    },
    {
      key: "clinician_name",
      value: "{CLINICIAN_NAME}",
      text: "Clinician Name",
    },
    {
      key: "health_assessment_clinician_name",
      value: "{HEALTH_ASSESSMENT_CLINICIAN_NAME}",
      text: "Health Assessment Clinician Name ",
    },
  ];

  listItem = this.placeholderOptions.map((item) => (
    <li
      onClick={this.addPlaceholder.bind(this, item.value)}
      key={item.key}
      className="rdw-dropdownoption-default placeholder-li"
    >
      {item.text}
    </li>
  ));

  render() {
    return (
      <div
        onClick={this.openPlaceholderDropdown}
        className="rdw-block-wrapper"
        aria-label="rdw-block-control"
      >
        <div
          className="rdw-dropdown-wrapper rdw-block-dropdown"
          aria-label="rdw-dropdown"
          style={{ minWidth: "250px" }}
        >
          <div className="rdw-dropdown-selectedtext" title="Shortcodes">
            <span>Shortcodes </span>
            <div
              className={`rdw-dropdown-caretto${
                this.state.open ? "close" : "open"
              }`}
            ></div>
          </div>
          <ul
            className={`rdw-dropdown-optionwrapper ${
              this.state.open ? "" : "placeholder-ul"
            }`}
          >
            {this.listItem}
          </ul>
        </div>
      </div>
    );
  }
}

export default class edit extends Component {
  state = {
    editing: false,
    data: {
      subject: "",
      body_data: "",
      footer_data: "",
      template_name: "",
      archived: false,
      defaultFooter: false,
    },
    defaultFooterContent: "",
    errors: {},
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ editing: true });
      this.getData();
    }
  }

  getHtml = (content) => {
    const bodyFromHtml = htmlToDraft(content);
    const { contentBlocks, entityMap } = bodyFromHtml;
    const bodyState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );

    return EditorState.createWithContent(bodyState);
  };

  getData = () => {
    api.emails.getEmail(this.props.match.params.id).then((res) => {
      this.setState({
        data: {
          code: res.code,
          archived: res.archived,
          subject: res.subject,
          body_data: this.getHtml(res.content),
          footer_data: res.footer_content
            ? this.getHtml(res.footer_content)
            : "",
          template_name: res.template_name,
        },
      });

      api.settings.all().then((settings) => {
        this.setState({
          defaultFooterContent: this.getHtml(settings.defaultFooter),
        });
      });
    });
  };

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  handlePermissionDropdownChange = (e, data) => {
    if (data.value.length === 2) {
      data.value.splice(0, 1);

      this.setState({
        data: { ...this.state.data, [data.name]: data.value },
      });
    } else if (data.value.length < 2) {
      this.setState({
        data: { ...this.state.data, [data.name]: data.value },
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  validate = (data) => {
    let errors = {};
    if (!data.template_name) errors.template_name = "Can't Be Empty";
    if (!data.subject) errors.subject = "Can't Be Empty";
    if (!data.body_data) errors.body_data = "Can't Be Empty";
    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors: errors });

    if (Object.keys(errors).length === 0) {
      let data = {
        archived: this.state.data.archived,
        template_name: this.state.data.template_name,
        subject: this.state.data.subject,
        content: draftToHtml(
          convertToRaw(this.state.data.body_data.getCurrentContent())
        ),
        footer_content: draftToHtml(
          convertToRaw(this.state.data.footer_data.getCurrentContent())
        ),
      };

      if (this.state.editing) {
        api.emails
          .editEmail(data, this.props.match.params.id)
          .then((res) => {});
      } else {
        api.emails.addEmail(data).then((res) => {
          if (!res.success) {
            return false;
          }
        });
      }

      this.props.history.push(`/settings/email_templates/`);
    }
  };

  handleWYSIWYGUpload = (file) => {
    // return;
    return new Promise((resolve, reject) => {
      api.resources
        .uploadFile(this.props.match.params.id, {
          type: "WYSIWYG",
          file: file,
        })

        .then((res) => {
          resolve({ data: { link: AppConfig.api_server + res } });
        });
    });
  };

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="no-marg pageTitle ">
          {this.state.editing ? "Edit" : "Add"} Email template
        </Header>
        <Segment>
          <Form>
            <h3>Email</h3>
            <Form.Group widths="equal">
              <Form.Input
                name="template_name"
                label="Template Name"
                value={this.state.data.template_name}
                onChange={this.handleChange}
                placeholder="Title"
                error={this.state.errors.template_name}
              />
              <Form.Input
                name="subject"
                label="Subject"
                value={this.state.data.subject}
                onChange={this.handleChange}
                placeholder="Subject"
                error={this.state.errors.subject}
              />
            </Form.Group>
            <p>
              <strong>Body</strong>
            </p>
            {this.state.errors.body_data && (
              <Message danger>{this.state.errors.body_data}</Message>
            )}
            <Editor
              editorState={this.state.data.body_data}
              onEditorStateChange={(editorState) => {
                this.setState({
                  data: {
                    ...this.state.data,
                    body_data: editorState,
                  },
                  temporary_html: "",
                });
              }}
              editorStyle={{
                borderLeft: "1px solid #F1F1F1",
                borderRight: "1px solid #F1F1F1",
                borderBottom: "1px solid #F1F1F1",
                height: "300px",
              }}
              toolbarStyle={{ marginBottom: "0px" }}
              toolbarCustomButtons={[<ShortcodeDropdown />]}
              toolbar={{
                image: {
                  uploadCallback: this.handleWYSIWYGUpload,
                  previewImage: true,
                  urlEnabled: false,
                  alignmentEnabled: true,
                  defaultSize: { width: "100%", height: "100%" },
                },
              }}
            />
            <p>
              <strong>Footer </strong>{" "}
              <Checkbox
                onChange={(e) => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      defaultFooter: !this.state.data.defaultFooter,
                      footer_data: !this.state.data.defaultFooter
                        ? this.state.defaultFooterContent
                        : this.state.data.footer_data,
                    },
                  });
                  console.log(this.state.data);
                }}
                checked={this.state.data.defaultFooter}
                label="Use Settings Default"
              />
            </p>
            {!this.state.data.defaultFooter && (
              <Editor
                editorState={this.state.data.footer_data}
                onEditorStateChange={(editorState) => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      footer_data: editorState,
                    },
                    temporary_html: "",
                  });
                }}
                editorStyle={{
                  borderLeft: "1px solid #F1F1F1",
                  borderRight: "1px solid #F1F1F1",
                  borderBottom: "1px solid #F1F1F1",
                  height: "300px",
                }}
                toolbarStyle={{ marginBottom: "0px" }}
                toolbar={{
                  image: {
                    uploadCallback: this.handleWYSIWYGUpload,
                    previewImage: true,
                    urlEnabled: false,
                    alignmentEnabled: true,
                    defaultSize: { width: "100%", height: "100%" },
                  },
                }}
              />
            )}
            <Form.Group inline>
              <Checkbox
                label="Archived"
                name="archived"
                disabled={this.state.data.code === "DEFAULT_EMAIL_TEMPLATE"}
                checked={this.state.data.archived}
                onClick={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      archived: !this.state.data.archived,
                    },
                  });
                }}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Button
                floated="left"
                color="red"
                onClick={() => {
                  window.history.back();
                }}
              >
                <Icon name="arrow left" />
                Back
              </Form.Button>
              <Form.Button
                floated="right"
                type="submit"
                color="orange"
                onClick={this.handleSubmit}
              >
                <Icon name="pencil" />
                {this.state.editing ? "Edit" : "Add"}
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
      </Segment>
    );
  }
}
