import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

export default class ResultsSelect extends Component {
  state = {
    activeCheck: false,
  };

  handleSelect = (props, e) => {
    if (this.props.addressSelectedCallback != null) {
      this.props.addressSelectedCallback(this.props.data[e.value]);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Form.Dropdown
          onChange={this.handleSelect}
          onClick={() => {
            if (this.state.activeCheck === false) {
              this.setState({ activeCheck: true });
            }
          }}
          placeholder=" Please Select An Address..."
          options={this.props.data.map((address, index) => {
            return {
              key: index,
              value: index,
              text: address.summaryline,
            };
          })}
          selection
          search
          clearable
          style={
            this.state.activeCheck === true
              ? {}
              : { borderColor: "#8543a6", boxShadow: " 0 0 10px #8543a6" }
          }
        />
      </React.Fragment>
    );
  }
}

ResultsSelect.propTypes = {
  data: PropTypes.array,
  addressSelectedCallback: PropTypes.func,
};
