import React, { Component } from "react";
import { Menu, Header } from "semantic-ui-react";
import { NavLink, withRouter } from "react-router-dom";
// import { FaListUl } from "react-icons/fa";

class FilesMenu extends Component {
  state = {
    activeItem: "",
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  render() {
    const { activeItem } = this.state;
    return (
      <Menu className="subMenu" stackable>
        <Menu.Item>
          <Header>Files Menu</Header>
        </Menu.Item>
        <Menu.Item
          exact
          as={NavLink}
          to={this.props.match.url}
          name="folders"
          active={activeItem === "folders"}
          onClick={this.handleItemClick}
        >
          Folders
        </Menu.Item>
      </Menu>
    );
  }
}
export default withRouter(FilesMenu);
