import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import AssessmentDash from "./dash";
import AssessmentList from "./list";
import AssessmentMenu from "./menu";
import Assessment from "./show";
import AddNote from "./forms/addNoteForm";
import getReferralNote from "./forms/getNoteForm";

export default class AssessmentPages extends Component {
  render() {
    return (
      <Segment basic className="no-pad no-marg">
        <AssessmentMenu />
        <Segment basic className="pageHolder">
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}/list`}
              component={AssessmentList}
            />

            <Route
              exact
              path={`${this.props.match.url}/:id/addnote`}
              component={AddNote}
            />
            <Route
              exact
              path={`${this.props.match.url}/:id/note/:id2`}
              component={getReferralNote}
            />
            <Route
              path={`${this.props.match.url}/:id`}
              component={Assessment}
            />
            <Route
              exact
              path={`${this.props.match.url}`}
              component={AssessmentDash}
            />
          </Switch>
        </Segment>
      </Segment>
    );
  }
}
