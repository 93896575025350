import React, { Component } from "react";
import { Segment, Header } from "semantic-ui-react";
import PaginateWrapper from "../common/OLD_PaginateWrapper";
import CompanyList from "../common/lists/companyList";
import api from "../../../actions/api";

class companyListDash extends Component {
  getCompanies = (pageNum, pageSize, search, filters) => {
    return api.company.getAllPaged({
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className=" pageTitle">
          Company List
        </Header>
        <Segment className="border">
          <PaginateWrapper
            dataQuery={this.getCompanies}
            search={true}
            active={"Archived"}
            render={(items) => (
              <CompanyList
                data={items}
                action={(id) => this.props.history.push(`/company/${id}`)}
              />
            )}
          />
        </Segment>
      </Segment>
    );
  }
}

export default companyListDash;
