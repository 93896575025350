import React, { Component } from "react";
import { Segment, Header, Form, Grid, Checkbox, Icon, Table, Button, Modal } from "semantic-ui-react";
import {FaFileImage,FaFilePdf,FaFileWord,FaFile} from "react-icons/fa";
import { DateTimeInput } from "semantic-ui-calendar-react";
import moment from "moment";
import api from "../../../../actions/api";
import { Link } from "react-router-dom";
import UploadFileForm from "../../common/forms/uploadFile";

export default class GetCaseNote extends Component {
  state = {
    case: {},
    settings: {},
    cas: [],
    data: {
      logType: "Info",
      message: "",
      logDue: "",
      visible: false,
      myActivities: false,
      resendEmail: false,
      sendEmailCM: false,
      sendEmailCA: false,
      sendEmailEmp: false,
      files: []
    },
    errors: {},
    uploadFileModal: false
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.case.getCase(this.props.match.params.id).then((data) => {
      this.setState({
        case: data,
      });

      api.case.getCaseNoteDetails(this.props.match.params.id, this.props.match.params.id2).then((data)=>{
        this.setState({data: data});
      })

      api.company.getCompanyAdminDropdown(data.company).then((res) => {
        let arr = res;
        arr.unshift({
          value: "All",
          text: "- All Company Admins -",
          key: "All",
        });
        this.setState({
          cas: arr,
        });
      });
    });

    

    api.settings.formLists().then((settings) => {
      this.setState({
        settings,
      });
    });
  };
  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  validate = (data) => {
    const errors = {};
    if (!data.logType) errors.logType = "Can't Be Empty";
    if (!data.message) errors.message = "Can't Be Empty";

    return errors;
  };

  onSubmit = (e) => {

    const errors = this.validate(this.state.data);

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      if (Object.keys(this.state.errors).length === 0) {
        api.case
          .editCaseNote(this.props.match.params.id, this.props.match.params.id2, this.state.data)
          .then(() => {
            this.props.history.push(`/case/${this.state.case._id}`);
          });
      }
    }
  };

  renderUploadFile() {
    const close = () => {
      this.setState({ uploadFileModal: false });
    };

    const handleSubmit = (data) => {
      api.case
        .uploadCaseFile(this.props.match.params.id, {
          // type: this.state.uploadFileModal,
          file: data.file,
          description: data.description,
          selectedFile: data.selectedFile,
          note_id: this.props.match.params.id2
        })
        .then((res) => {
          if(res.file){

            let files = this.state.data.files;
            files.push(res.file);

            this.setState({
              data: {
                ...this.state.data,
                files
              }
            });
          }
          close();
          this.getData();
        })
        .catch((e) => {
          console.log(e);
        });
    };

    return (
      <Modal
        open={this.state.uploadFileModal}
        onClose={close}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="upload" content="Upload File" />
        <Modal.Content>
          <UploadFileForm submit={handleSubmit} close={close} enableFileManager fileManagerType={{type: "cases", case: this.state.case}} />
        </Modal.Content>
      </Modal>
    );
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

  render() {
    const { data } = this.state;
    return (
      <Segment basic className="no-pad">
        {this.renderUploadFile()}
        <Header as={"h1"} textAlign="center" className="no-marg pageTitle main">
          Edit Case Note for{" "}
          {this.state.case.employee && (
            <span>
              <Link to={`/employee/${this.state.case.employee._id}`}>
                {this.state.case.employee.firstName}{" "}
                {this.state.case.employee && this.state.case.employee.lastName}
              </Link>
            </span>
          )}
        </Header>

        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Segment>
                      <Form>
                        <Form.Group widths={2}>
                          <Form.Dropdown
                            name="logType"
                            label="Note Type"
                            value={data.logType}
                            onChange={this.handleDropdownChange}
                            options={
                              this.state.settings.caseNoteTypes !== undefined
                                ? this.state.settings.caseNoteTypes
                                : []
                            }
                            selection
                            placeholder="Note Type"
                            error={this.state.errors.logType}
                          />
                          <DateTimeInput
                            label="Due Date (If Required)"
                            autoComplete="off"
                            animation="off"
                            name="logDue"
                            popupPosition={"bottom left"}
                            dateTimeFormat="YYYY/MM/DD HH:mm"
                            dateFormat="YYYY/MM/DD"
                            closable={true}
                            disableMinute={false}
                            placeholder="Due Date"
                            value={
                              data.logDue
                                ? moment(data.logDue).format("YYYY/MM/DD HH:mm")
                                : ""
                            }
                            iconPosition="left"
                            onChange={this.handleDropdownChange}
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.TextArea
                            rows={18}
                            label="Message"
                            name={"message"}
                            onChange={this.onChange}
                            value={data.message}
                            placeholder="Message"
                            error={this.state.errors.message}
                          />
                        </Form.Group>
                        {/* <Form.Field>
                          <Checkbox
                            label="Check To Make Visible To User"
                            name="visible"
                            checked={this.state.data.visible}
                            onClick={() => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  visible: !this.state.data.visible,
                                },
                              });
                            }}
                          />
                        </Form.Field> */}
                        <Form.Group widths="equal">
                          <Form.Button
                            floated="left"
                            color="grey"
                            onClick={() =>
                              this.props.history.push(
                                `/case/${this.state.case._id}`
                              )
                            }
                          >
                            <Icon name="arrow left" />
                            Back
                          </Form.Button>
                          <Form.Button
                            floated="right"
                            type="submit"
                            color="orange"
                            onClick={() => this.onSubmit()}
                          >
                            <Icon name="pencil" />
                            Edit Note
                          </Form.Button>
                        </Form.Group>
                      </Form>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Segment className="switchBox">
                      <Header textAlign="center">Updates</Header>
                      <Checkbox
                        label={
                          data.visible
                            ? "This note is visible to " +
                              this.state.case.employee.firstName
                            : " This note is private"
                        }
                        name="visible"
                        toggle
                        checked={this.state.data.visible}
                        onClick={() => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              visible: !this.state.data.visible,
                            },
                          });
                        }}
                      />
                      <Checkbox
                        label={
                          data.myActivities
                            ? "Added to your activities"
                            : "Not added to your activities "
                        }
                        name="addToActivities"
                        toggle
                        checked={this.state.data.myActivities}
                        onClick={() => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              myActivities: !this.state.data.myActivities,
                            },
                          });
                        }}
                      />
                    </Segment>
                    <Segment className="switchBox">
                      <Header textAlign="center">Notifications</Header>
                      <Checkbox
                        label={
                          (data.sendEmailCA ? "": "Do not ") + "Send email notification to Company Admin"
                        }
                        name="sendEmailCA"
                        toggle
                        checked={this.state.data.sendEmailCA}
                        onClick={() => {

                          if(!data.resendEmail){
                            return;
                          }

                          this.setState({
                            data: {
                              ...this.state.data,
                              sendEmailCA: !this.state.data.sendEmailCA,
                            },
                          });
                        }}
                        disabled={!data.resendEmail}
                      />
                      {data.sendEmailCA && (
                        <Form.Dropdown
                          name="sendEmailCAOption"
                          label="Select who to notify: "
                          value={data.sendEmailCAOption}
                          onChange={this.handleDropdownChange}
                          options={
                            this.state.cas !== undefined ? this.state.cas : []
                          }
                          selection
                          placeholder="Select Company Admin"
                          defaultValue="All"
                          disabled={!data.resendEmail}
                        />
                      )}
                      <Checkbox
                        label={
                          (data.sendEmailCM ? "": "Do not ") + "Send email notification to Company Referral Manager"
                        }
                        name="sendEmailCM"
                        toggle
                        checked={this.state.data.sendEmailCM}
                        onClick={() => {
                          if(!data.resendEmail){
                            return;
                          }

                          this.setState({
                            data: {
                              ...this.state.data,
                              sendEmailCM: !this.state.data.sendEmailCM,
                            },
                          });
                        }}
                        disabled={!data.resendEmail}
                      />
                      <Checkbox
                        label={
                          (data.sendEmailEmp ? "": "Do not ") + "Send email notification to Employee"
                        }
                        name="sendEmailEmp"
                        toggle
                        checked={this.state.data.sendEmailEmp}
                        onClick={() => {
                          if(!data.resendEmail){
                            return;
                          }

                          this.setState({
                            data: {
                              ...this.state.data,
                              sendEmailEmp: !this.state.data.sendEmailEmp,
                            },
                          });
                        }}
                        disabled={!data.resendEmail}
                      />
                      <Checkbox
                        label={
                          (data.resendEmail ? "": "Do not ") + "Resend email notifications"
                        }
                        name="resendEmail"
                        toggle
                        checked={this.state.data.resendEmail}
                        onClick={() => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              resendEmail: !this.state.data.resendEmail,
                            },
                          });
                        }}
                      />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column width={16}>
                <Segment>
                      <Header textAlign="center">Documents</Header>
                      <Button size="tiny" color="green" content="Add File" icon="file" floated="right" onClick={()=>{
                        this.setState({uploadFileModal: true});
                      }}/>
                      <Header>&nbsp;</Header>
                      <Table selectable>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Description </Table.HeaderCell>
                            <Table.HeaderCell>Size </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {data.files.length > 0 ? data.files.map((f, index)=>{

                            let type = f.store.contentType;
                            let fileIcon = null;

                            switch(type){
                              case "image/png":
                              case "image/jpeg":
                                fileIcon = <FaFileImage size="1.5em" color="purple"/>
                              break;
                              case "application/pdf":
                                fileIcon = <FaFilePdf size="1.5em" color="purple"/>
                              break;
                              case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                              case "application/msword":
                                fileIcon = <FaFileWord size="1.5em" color="purple"/>
                              break;
                              default: 
                                fileIcon = <FaFile size="1.5em" color="purple"/>
                              break;
                            }

                            let sliceFrom = 0;

                            if(f.store.filename){
                              sliceFrom = f.store.filename.indexOf("SCANNED__") + 9;
                            }

                            return (
                              <Table.Row key={"file_"+index}>
                                <Table.Cell>{fileIcon}{f.store.filename.slice(sliceFrom,f.store.filename.length)}</Table.Cell>
                                <Table.Cell>{f.fileDescription ? f.fileDescription : ""}</Table.Cell>
                                <Table.Cell>{this.formatBytes(f.store.length) }</Table.Cell>
                              </Table.Row>
                            );
                          }) : <Table.Row><Table.Cell colSpan="2">No Documents Attached</Table.Cell></Table.Row>}
                          
                        </Table.Body>
                      </Table>
                    </Segment>
                  </Grid.Column>
                
                </Grid.Row>
              </Grid>
            </Grid.Column>
            {/* <Grid.Column width={6}></Grid.Column> */}
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
