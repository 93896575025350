import React, { Component } from "react";
import {
  Segment,
  Header,
  Grid,
  Statistic,
  Menu,
  Icon,
  Modal, Message, Button,
} from "semantic-ui-react";
import api from "../../../actions/api";
import { Link } from "react-router-dom";
import CasesList from "../common/lists/caseList";
import PaginateWrapper from "../common/OLD_PaginateWrapper";
import NoteList from "../common/lists/staffLogList";
import NoteForm from "./forms/StaffNoteForm";
import { AuthContext, Authentication, Can } from "../../../lib/sso";

class StaffPage extends Component {
  state = {
    staff: {},
    note: {},
    activeItem: "cases",
    toggleLogForm: false,
    loading: false,
    startMasqueradeSession: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.team.getMember(this.props.match.params.id).then((staff) => {
      this.setState({
        staff,
      });
    });
  };
  getLogs = (pageNum, pageSize, search, filters) => {
    return api.team.notes.allPaged(this.props.match.params.id, {
      pageNum,
      pageSize,
      search,
      filters,
    });
  };
  getCases = (pageNum, pageSize, search, filters) => {
    return Authentication.can("navigator_admin.permission") === true
      ? api.case.getAllPaged({
          pageNum,
          pageSize,
          search,
          filters,
        })
      : Authentication.can("navigator_case_manager.permission") === true || Authentication.can("organisation_case_manager.permission") === true
      ? api.case.getAllCMPaged({
          pageNum,
          pageSize,
          search,
          filters,
        })
      : [];
  };
  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });

  renderNoteForm() {
    const closeLoglModal = () => {
      this.setState({ toggleLogForm: false });
    };

    const addNote = (data) => {
      this.setState({ loading: true });
      api.team.notes.add(this.props.match.params.id, data).then(() => {
        this.setState({ loading: false, toggleLogForm: false });
      });
    };

    const editNote = (data) => {
      this.setState({ loading: true });
      api.team.notes
        .update(this.props.match.params.id, this.state.note._id, data)
        .then(() => {
          this.setState({ loading: false, toggleLogForm: false });
        });
    };

    const editCheck = Object.keys(this.state.note);

    return (
      <Modal
        open={this.state.toggleLogForm}
        onClose={closeLoglModal}
        centered={false}
      >
        <Header
          icon="list"
          content={editCheck.length !== 0 ? "Edit Note" : "Add Note"}
        />
        <Modal.Content>
          <NoteForm
            data={editCheck.length !== 0 ? this.state.note : undefined}
            submit={editCheck.length !== 0 ? editNote : addNote}
            close={closeLoglModal}
          ></NoteForm>
        </Modal.Content>
      </Modal>
    );
  }
  renderHeader() {
    const { staff } = this.state;
    return (
      <Segment basic className="no-marg no-pad" loading={this.state.loading}>
        <Grid className={"no-marg "}>
          <Grid.Row className="no-pad" stretched>
            <Grid.Column computer={8} mobile={16} className=" compDetails">
              <Grid celled className="detailsGrid">
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Work Phone:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{staff.work_number}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Work Email:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{staff.email}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Mobile:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{staff.mobile_number}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Position:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>
                      {staff.groups && staff.groups.length !== 0
                        ? staff.groups.map((item) => {
                            return item;
                          })
                        : "[UNASSIGNED]"}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column computer={8} mobile={16} className=" compDetails">
              <Grid celled className="detailsGrid case">
                <Grid.Row>
                  <Grid.Column width={16} verticalAlign={"middle"}>
                    <Segment basic>
                      <Statistic.Group widths={2}>
                        <Statistic>
                          <Statistic.Value>
                            0{/* {staff.Dash.activeRef} */}
                          </Statistic.Value>
                          <Statistic.Label>Active Cases</Statistic.Label>
                        </Statistic>
                        <Statistic>
                          <Statistic.Value>
                            0{/* {staff.Dash.compRef} */}
                          </Statistic.Value>
                          <Statistic.Label>Closed Cases</Statistic.Label>
                        </Statistic>
                      </Statistic.Group>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.renderMenu()}
      </Segment>
    );
  }
  renderMenu() {
    const { activeItem } = this.state;

    return (
      <Menu className="subMenu" stackable>
        <Menu.Item
          name="cases"
          active={activeItem === "cases"}
          onClick={this.handleMenuClick}
        >
          Cases
        </Menu.Item>
        <Menu.Item
          name="notes"
          active={activeItem === "notes"}
          onClick={this.handleMenuClick}
        >
          Notes
        </Menu.Item>
        <Menu.Menu position="right">
          {Authentication.can("navigator_admin.permission") && 
          <Menu.Item as={Link} to={`/reset_password/${this.props.match.params.id}`}><Icon name="help" />Reset Password</Menu.Item> }
          <Menu.Item onClick={() => this.setState({startMasqueradeSession: true})}><Icon name="user secret" />Masquerade User</Menu.Item>
          <Menu.Item as={Link} to={`/staff/${this.props.match.params.id}/edit`}><Icon name="pencil" />Edit Staff</Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
  renderNotes() {
    if (!this.state.loading) {
      return (
        <Segment>
          <PaginateWrapper
            dataQuery={this.getLogs}
            render={(items) => (
              <NoteList
                data={items}
                add={() => this.setState({ toggleLogForm: true, note: {} })}
                edit={(item) =>
                  this.setState({ toggleLogForm: true, note: item })
                }
                allowAdd={true}
              />
            )}
          />
        </Segment>
      );
    }
  }
  renderCases() {
    if (!this.state.loading) {
      return (
        <Segment>
          <PaginateWrapper
            dataQuery={this.getCases}
            render={(items) => (
              <CasesList
                data={items}
                action={(id) => this.props.history.push(`/case/${id}`)}
              />
            )}
          />
        </Segment>
      );
    }
  }
  renderStartMasquerade(){
    return (
      <Modal open={this.state.startMasqueradeSession} onClose={() => this.setState({startMasqueradeSession: false})} centered={true}>
        <Header icon="user secret" content={"Start a new masquerade session with " + this.state.staff.firstName + " " + this.state.staff.lastName}/>
        <Modal.Content>
          <div style={{padding: 5}}>
            <Message warning>
              You are about to be logged in as {this.state.staff.firstName + " " + this.state.staff.lastName}. This will trigger a audit log entry and you will have full access to the staff account.<br />
              <br />Are you sure you want to continue?
            </Message>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => this.setState({startMasqueradeSession: false})}>Cancel</Button>
          <Button negative onClick={() => {
            api.employee.startMasqueradeSession(this.state.staff._id).then(res => {
              if(res._redirectTo !== undefined){
                window.location = res._redirectTo;
                return;
              }
              this.props.authentication.switchUser(res.code);
            }).catch(err => {

            });
          }}>Start Session</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    const { staff, activeItem } = this.state;

    return (
      <Segment basic className="no-pad">
        {this.renderNoteForm()}
        <Header as={"h1"} textAlign="center" className="no-marg pageTitle main">
          {`${staff.title} ${staff.firstName} ${staff.lastName}`}
        </Header>
        <Segment basic className="no-pad">
          {this.renderHeader()}
          <Can has={"navigator_admin.permission"}>{this.renderStartMasquerade()}</Can>
          {activeItem === "cases" && this.renderCases()}
          {activeItem === "notes" && this.renderNotes()}
        </Segment>
      </Segment>
    );
  }
}
export default AuthContext(StaffPage);