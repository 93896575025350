import React, { Component } from "react";
import { Form, Segment, Icon, Button, Table, Divider } from "semantic-ui-react";
import ServiceTypeList from "../../../../common/ServiceTypeList";

class addServiceForm extends Component {
  state = {
    data: {
      title: "",
      description_short: "",
      description_long: "",
      service_resources: [],
    },
    temp_data: {
      service_type: "",
      service_link: "",
      button_text: "",
    },
    submit_load: false,
    errors: {},
  };

  handleTempDropdownChange = (e, data) => {
    this.setState({
      temp_data: {
        service_type: data.value,
        service_link: "",
        button_text: "",
      },
    });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleTempChange = (event) => {
    this.setState({
      temp_data: {
        ...this.state.temp_data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleTempAdd = (data) => {
    const errors = {};
    let final_data = data;

    if (!data.service_type){
        errors.service_type = "Can't Be Empty";
    }
    if (!data.service_link && ServiceTypeList.filter(i => i.isIntegrator).map(i => i.value).indexOf(data.service_type) === -1){
        errors.service_link = "Can't Be Empty";
    }
    if (data.service_type === "Website Link" && data.service_link && data.service_link.search("https://") !== -1) {
      final_data.service_link = data.service_link.replace("https://", "");
    }
    console.log("errors", errors);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        data: {
          ...this.state.data,
          service_resources: [...this.state.data.service_resources, final_data],
        },
      });
    }
  };

  validate = (data) => {
    const errors = {};

    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.description_short) errors.description_short = "Can't Be Empty";
    if (data.service_resources && data.service_resources.length === 0)
      errors.service_resources = "No Services Created.";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ submit_load: true });
      this.props.submit(this.state.data);
    }
  };

  renderBody() {
    return this.state.data.service_resources.map((item, index) => {
      return (
        <Table.Row key={index}>
          <Table.Cell>{item.service_type}</Table.Cell>
          <Table.Cell>
            {item.service_link !== "" ? item.service_link : "N/A"}
          </Table.Cell>
          <Table.Cell>
            {item.button_text !== "" ? item.button_text : "None Supplied"}
          </Table.Cell>
          <Table.Cell>
            <Button
              icon="trash"
              color="red"
              onClick={() => {
                let data = this.state.data.service_resources;
                data.splice(index, 1);
                this.setState({
                  data: {
                    ...this.state.data,
                    service_resources: data,
                  },
                });
              }}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="title"
              label="Service Name"
              value={this.state.data.title}
              onChange={this.handleChange}
              placeholder="Title"
              error={this.state.errors.title}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.TextArea
              name="description_short"
              label="Description (Short)"
              value={this.state.data.description_short}
              onChange={this.handleChange}
              placeholder="Description (Short)"
              error={this.state.errors.description_short}
            />
            <Form.TextArea
              name="description_long"
              label="Description (Long)"
              value={this.state.data.description_long}
              onChange={this.handleChange}
              placeholder="Description (Long)"
            />
          </Form.Group>
          <Divider />
          <Form.Group widths="equal">
            <Form.Dropdown
              name="service_type"
              label="Service Type"
              value={this.state.temp_data.service_type}
              onChange={this.handleTempDropdownChange}
              options={ServiceTypeList.map(i => {
                  return {
                      key: i.key,
                      value: i.value,
                      text: i.text
                  }
              })}
              error={this.state.errors.service_type}
              selection
              placeholder="Service Type"
            />
            {ServiceTypeList.filter(i => i.isIntegrator).map(i => i.value).indexOf(this.state.temp_data.service_type) === -1 && (
                <React.Fragment>
                    <Form.Input
                        name="service_link"
                        label={`Service ${
                            this.state.temp_data.service_type === "Telephone Number"
                                ? "Number"
                                : this.state.temp_data.service_type === "Website Link"
                                ? `Link (Don't Include "https://")`
                                : "Link"
                        }`}
                        value={this.state.temp_data.service_link}
                        onChange={this.handleTempChange}
                        placeholder={`Service ${
                            this.state.temp_data.service_type === "Telephone Number"
                                ? "Number"
                                : "Link"
                        }`}
                        error={this.state.errors.service_link}
                    />
                    <Form.Input
                        name="button_text"
                        label="Button Replacement Text (Optional)"
                        value={this.state.temp_data.button_text}
                        onChange={this.handleTempChange}
                        placeholder="Replacement Text"
                        error={this.state.errors.button_text}
                    />
                </React.Fragment>
            )}
            <Form.Button
              width="2"
              icon="plus"
              style={{ marginTop: "23px" }}
              color="green"
              onClick={() => this.handleTempAdd(this.state.temp_data)}
            />
          </Form.Group>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Link</Table.HeaderCell>
                <Table.HeaderCell>Replacement Text</Table.HeaderCell>
                <Table.HeaderCell>Options</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.data.service_resources.length !== 0 ? (
                this.renderBody()
              ) : (
                <Table.Row
                  style={
                    this.state.errors.service_resources
                      ? { background: "#fff6f6", color: "#9f3a38" }
                      : {}
                  }
                >
                  <Table.Cell colSpan="4">No Resources Created</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Form.Group widths="equal">
            <Form.Button
              floated="left"
              color="red"
              onClick={() => this.props.close()}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              floated="right"
              color="green"
              onClick={() => this.handleSubmit()}
              loading={this.state.submit_load}
            >
              <Icon name="plus" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default addServiceForm;
