import React, { Component } from "react";
import { Menu, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FaListUl, FaPlus } from "react-icons/fa";
import { Authentication } from "../../../lib/sso";
import api from "../../../actions/api";

export default class CompanyMenu extends Component {
  state = {
    activeItem: "dash",
    company: {},
  };

  componentDidMount() {
    api.company.myCompany().then((company) => {
      this.setState({ company });
    });
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  render() {
    const { activeItem } = this.state;
    return (
      <Menu className="subMenu">
        <Menu.Item>
          <Header>Company Menu</Header>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={"/company/list"}
          name="list"
          active={activeItem === "list"}
          onClick={this.handleItemClick}
        >
          <FaListUl />
          List
        </Menu.Item>

        {Authentication.can("navigator_admin.permission") && (
          <Menu.Item
            as={Link}
            to={"/company/add"}
            name="add"
            active={activeItem === "add"}
            onClick={this.handleItemClick}
          >
            <FaPlus />
            Add
          </Menu.Item>
        )}
        {Authentication.can("organisation_admin.permission") &&
          this.state.company &&
          this.state.company.c1h === true && (
            <Menu.Item
              as={Link}
              to={"/company/add"}
              name="add"
              active={activeItem === "add"}
              onClick={this.handleItemClick}
            >
              <FaPlus />
              Add
            </Menu.Item>
          )}
      </Menu>
    );
  }
}
