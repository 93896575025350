import React, { Component } from "react";
import { Grid, Header, Segment, Statistic } from "semantic-ui-react";
import api from "../actions/api";
import Company_Receiver from "../contexts/company_context/Company_Receiver";
import HomeMenu from "../components/menus/homeMenu";
import Iframe from "react-iframe";
import ConsentForm from "../../../assets/docs/Consent_Form.pdf";
import ReferralForm from "../../../assets/docs/Referral_Form.pdf";
import { FaRegFilePdf } from "react-icons/fa";

class Home extends Component {
  state = {
    company: {},
    dash: {},
  };

  componentDidMount() {
    this.props.company._refresh();
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.company !== prevProps.company) {
      this.getData();
    }
  }

  getData = () => {
    if (this.props.company._id) {
      api.company.getDash(this.props.company._id).then((res) => {
        this.setState({
          dash: {
            emp: res.emp,
            enr: res.enr,
            activeRef: res.activeRef,
            closedRef: res.closedRef,
          },
        });
      });
    }
  };
  renderStats() {
    return (
      <Segment className="border" style={{ height: "100%" }}>
        <Statistic.Group widths={3}>
          <Statistic>
            <Statistic.Value>{this.state.dash.emp}</Statistic.Value>
            <Statistic.Label>Employees</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{this.state.dash.activeRef}</Statistic.Value>
            <Statistic.Label>Active Referrals</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{this.state.dash.closedRef}</Statistic.Value>
            <Statistic.Label>Closed Referrals</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Segment>
    );
  }
  renderWidget() {
    return (
      <Segment basic className="no-pad">
        <Iframe
          url="https://api-bridge.azurewebsites.net/conditions/?p=coronavirus-covid-19&aspect=name,overview_short,symptoms_short,symptoms_long,treatments_overview_short,other_treatments_long,self_care_long,prevention_short,causes_short&tab=3"
          title="NHS website - health a-z"
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            border: "2px solid #015eb8",
          }}
          width="100%"
          height="500px"
          id="myId"
          className="border"
          display="initial"
          position="relative"
        />

        {/* <div style="position: relative; max-width: 500px; height: 300px"><iframe src="https://api-bridge.azurewebsites.net/conditions/?p=coronavirus-covid-19&aspect=name,overview_short,symptoms_short,symptoms_long,treatments_overview_short,other_treatments_long,self_care_long,prevention_short,causes_short&tab=3&uid=https://www.patientnavigator.co.uk/" title="NHS website - health a-z" style="position: absolute; height: 100%; width: 100%; border: 2px solid #015eb8;"></iframe></div> */}
      </Segment>
    );
  }
  render() {
    return (
      <Segment basic className="no-pad">
        <HomeMenu />
        <Grid className="no-marg">
          <Grid.Row>
            <Grid.Column width={10}>
              <Header className={"sectionTitle"}>Info</Header>
              {this.renderStats()}
            </Grid.Column>
            <Grid.Column width={6}>
              <Header className={"sectionTitle"}>Downloads</Header>
              <Segment className="border" style={{ height: "100%" }}>
                <a href={ConsentForm} download="Consent_Form.pdf">
                  <FaRegFilePdf style={{ color: "#663390" }} size={"1.6rem"} />{" "}
                  Consent Form{" "}
                </a>
                <a href={ReferralForm} download="Referral_Form.pdf">
                  <FaRegFilePdf style={{ color: "#663390" }} size={"1.6rem"} />{" "}
                  Referral Form{" "}
                </a>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10} style={{ marginTop: "1rem" }}>
              <Header className={"sectionTitle"}>COVID-19</Header>
              {this.renderWidget()}
            </Grid.Column>
            <Grid.Column width={6}></Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
export default Company_Receiver(Home, (company) => {
  return {
    company,
  };
});
