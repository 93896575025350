import React from "react";
import IdleTimer from 'react-idle-timer';
import Moment from "moment"
import {Button, Label, Message, Modal, Segment} from "semantic-ui-react";

export default class IdleTimeout extends React.Component {
    idleTimer = null;
    finalCountdown = null;
    backgroundHeartbeat = null;

    state = {
        showInactiveModal: false,
        lastActive: 0,
        countdownText: ""
    };
    componentDidMount(){
        this.handleBackgroundOnActive();
    }
    componentWillUnmount(){
        if(this.finalCountdown !== null){
            clearInterval(this.finalCountdown);
            this.finalCountdown = null;
        }
    }

    handleBackgroundOnActive = () => {
        if(this.backgroundHeartbeat === null){
            this.backgroundHeartbeat  = setInterval(() => this.props.onHeartbeat(), 2 * 60 * 1000)
        }
    }
    handleBackgroundOnIdle = () => {
        if (this.backgroundHeartbeat !== null){
            clearInterval(this.backgroundHeartbeat);
            this.backgroundHeartbeat = null;
        }
    }
    handleOnActive = () => {
        this.setState({
            showInactiveModal: false
        });
        if(this.finalCountdown !== null){
            clearInterval(this.finalCountdown);
            this.finalCountdown = null;
        }
        this.props.onActive();
    };
    handleFinalIdleTick = () => {
        let secondsLeft = this.getTimeLeft();
        if(secondsLeft <= 0){
            this.props.onIdleExpiry();
            return;
        }
        this.setState({
            countdownText: this.getTimeLeftText()
        });
    };
    handleOnIdle = () => {
        this.setState({
            lastActive: this.idleTimer.getLastActiveTime(),
        }, () => {
            this.setState({
                showInactiveModal: true,
                countdownText: this.getTimeLeftText()
            })
            this.finalCountdown = setInterval(this.handleFinalIdleTick, 1000);
        });
    };

    getTimeLeft = () => {
        if(this.idleTimer === null){
            return 0;
        }
        return (this.props.preWarningTime * 60) - (Moment().diff(this.state.lastActive, "seconds") - ((this.props.maxIdleTime - this.props.preWarningTime) * 60));
    };
    getTimeLeftText = () => {
        let secondsLeft = this.getTimeLeft();
        let mins = Math.floor(((secondsLeft % 3600) / 60));
        let secs = Math.floor(secondsLeft % 60);
        return ((mins < 10 ? "0" : "") + mins) + ":" + ((secs < 10 ? "0" : "") + secs);
    };

    render(){
        if(this.props.maxIdleTime <= this.props.preWarningTime){
            throw new Error("[Misconfiguration] props: 'maxIdleTime' must be larger then 'preWarningTime for IdleTimeout React.Component");
        }
        return (
            <React.Fragment>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={(this.props.maxIdleTime - this.props.preWarningTime) * (1000 * 60)}
                    onIdle={this.handleOnIdle}
                    debounce={250}
                />
                <IdleTimer
                    timeout={5 * (1000 * 60)}
                    onActive={this.handleBackgroundOnActive}
                    onIdle={this.handleBackgroundOnIdle}
                    debounce={250}
                />

                <Modal open={this.state.showInactiveModal}>
                    <Modal.Header>Account Inactivity</Modal.Header>
                    <Modal.Content>
                        <Segment>
                            <Message warning>You appear to be inactive. To maintain your account security you will be automatically logged off in: <Label color={"red"}>{this.state.countdownText}</Label></Message>
                        </Segment>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive onClick={this.handleOnActive}>Keep me logged in</Button>
                    </Modal.Actions>
                </Modal>
            </React.Fragment>
        );
    }
}