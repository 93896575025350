import React, { Component } from "react";
import { Menu, Image, Dropdown, Icon } from "semantic-ui-react";
import logo from "../../../assets/images/logo.png";
import { withRouter, NavLink } from "react-router-dom";
import {
  FaHome,
  FaUser,
  FaBuilding,
  FaFolder,
  FaFile,
  FaMedkit,
} from "react-icons/fa";
import { Authentication } from "../../../lib/sso";

class TopNav extends Component {
  state = {
    activeItem: "",
  };

  goto = (data) => {
    this.props.history.push(data);
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  handleLogout = () => {
    Authentication.logout();
  };
  render() {
    const { activeItem } = this.state;
    let context = Authentication.getUserData();

    return (
      <Menu className="mainNav" stackable>
        <Menu.Item>
          <Image src={logo} className="logo" />
        </Menu.Item>
        <Menu.Item
          exact
          as={NavLink}
          to={`/`}
          name={"home"}
          active={activeItem === "home"}
          onClick={this.handleItemClick}
        >
          <FaHome size="1.4rem" style={{ margin: "0px" }} />
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to="/company"
          name={"company"}
          active={activeItem === "company"}
          onClick={this.handleItemClick}
        >
          <FaBuilding size="1.4rem" /> Company
        </Menu.Item>
        {/*  
        <Menu.Item
          as={NavLink}
          to="/employee"
          name={"employee"}
          active={activeItem === "employee"}
          onClick={this.handleItemClick}
        >
          Employees
        </Menu.Item>*/}
        {/*  
        <Menu.Item
          exact
          as={NavLink}
          to="/assessment"
          name={"assessments"}
          active={activeItem === "assessments"}
          onClick={this.handleItemClick}
        >
          <FaMedkit size="1.4rem" /> Health Journeys
        </Menu.Item>*/}
        {!Authentication.can("organisation_clinician.permission") && (
          <Menu.Item
            exact
            as={NavLink}
            to="/referral"
            name={"referral"}
            active={activeItem === "referral"}
            onClick={this.handleItemClick}
          >
            <FaFolder size="1.4rem" /> Referrals
          </Menu.Item>
        )}
        {/* {Authentication.can("fileManager.view") && ( *-/}
          <Menu.Item
            exact
            as={NavLink}
            to="/reports"
            name={"reports"}
            active={activeItem === "reports"}
            onClick={this.handleItemClick}
          >
            <FaFile size="1.4rem" />
            Reports
          </Menu.Item>
        {/* )} */}

        <Menu.Menu position={"right"}>
          <Dropdown item icon={<FaUser />}>
            <Dropdown.Menu>
              <Dropdown.Header>
                {context.firstName} {context.lastName}
              </Dropdown.Header>
              <Dropdown.Item as={NavLink} to={`/employee/${context.sub}`}>
                <Icon name="user" />
                My Profile
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.handleLogout()}>
                <Icon name="sign out" />
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    );
  }
}
export default withRouter(TopNav);
