import React from "react";
import Company from "./Company_Context";

export default class Company_Receiver extends React.Component {
  state = {
    company: {}
  };
  componentDidMount() {
    this._refreshCompany();
  }
  _refreshCompany = () => {
    this.props.api.company.show().then((res) => {
      this.setState({
        company: res,
      });
    });

  };
  render() {
    return (
      <Company.Provider
        value={{
          _refresh: this._refreshCompany,
          ...this.state.company
        }}
      >
        {this.props.children}
      </Company.Provider>
    );
  }
}
