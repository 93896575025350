import React, { Component } from "react";
import { Menu, Header, Dropdown } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import {Can} from "../../../lib/sso";

class SettingsMenu extends Component {
  state = {
    activeItem: "",
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  render() {
    const { activeItem } = this.state;

    return (
      <Menu className="subMenu" style={{ display: "flex", flexWrap: "wrap" }}>
        <Menu.Item>
          <Header>Settings Menu</Header>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={"/settings"}
          name="basic"
          active={activeItem === "basic"}
          onClick={this.handleItemClick}
        >
          Basic Settings
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={"/settings/benefits"}
          name="benefit"
          active={activeItem === "benefit"}
          onClick={this.handleItemClick}
        >
          Benefits
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={"/settings/services"}
          name="service"
          active={activeItem === "service"}
          onClick={this.handleItemClick}
        >
          Services
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={"/settings/templates"}
          name="templates"
          active={activeItem === "templates"}
          onClick={this.handleItemClick}
        >
          Templates
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={"/settings/health_notices"}
          name="health_notices"
          active={activeItem === "health_notices"}
          onClick={this.handleItemClick}
        >
          Health Notices
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={"/settings/help_categories"}
          name="help_categories"
          active={activeItem === "help_categories"}
          onClick={this.handleItemClick}
        >
          Help Category Management
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={"/settings/splash_screens"}
          name="splash_screens"
          active={activeItem === "splash_screens"}
          onClick={this.handleItemClick}
        >
          Mobile Splash Screens
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/settings/system_legal_docs"
          name={"system_legal_docs"}
          active={activeItem === "system_legal_docs"}
          onClick={this.handleItemClick}
        >
          System Legal Docs
        </Menu.Item>
        <Dropdown item text="Emails" className="menuDropdownLight">
          <Dropdown.Menu>
            <Dropdown.Item
              as={Link}
              to="/settings/email_templates"
              name={"email_templates"}
              active={activeItem === "email_templates"}
              onClick={this.handleItemClick}
            >
              Email Templates
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to="/settings/email_automations"
              name={"email_automations"}
              active={activeItem === "email_automations"}
              onClick={this.handleItemClick}
            >
              Automated Emails
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item text="Resources" className="menuDropdownLight">
          <Dropdown.Menu>
            <Dropdown.Item
              as={Link}
              to="/settings/resource_categories"
              name={"resource_categories"}
              active={activeItem === "resource_categories"}
              onClick={this.handleItemClick}
            >
              Resource Categories
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to="/settings/resources"
              name={"resources"}
              active={activeItem === "resources"}
              onClick={this.handleItemClick}
            >
              Resources
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to="/settings/circular1"
              name={"circular1"}
              active={activeItem === "circular1"}
              onClick={this.handleItemClick}
            >
              Circular1 API Sync
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item
          as={Link}
          to="/settings/reports"
          name={"reports"}
          active={activeItem === "reports"}
          onClick={this.handleItemClick}
        >
          Reports
        </Menu.Item>
        <Can has={"microsoft_bookings.setup"}>
          <Menu.Item
            as={Link}
            to="/settings/microsoft_bookings"
            name={"microsoft_bookings"}
            active={activeItem === "microsoft_bookings"}
            onClick={this.handleItemClick}
          >
            Microsoft Bookings
          </Menu.Item>
        </Can>
      </Menu>
    );
  }
}
export default withRouter(SettingsMenu);
