import React, { Component } from "react";
import api from "../../../actions/api";
import { Segment, Header, Form, Icon, Table, Radio} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import { toast } from "react-semantic-toasts";
import company from "../company";
import { Authentication } from "../../../lib/sso";
/*
import {
  FaFile,
} from "react-icons/fa"; */

export default class ReportsList extends Component {
  state = { 
    data: {
      status: "all",
    }, 
    forms: [],
    disabled_run: true,
    loading_run: false,
    loading_report: false,
    export_report: false,
    companies: [],
    report: {},
  };

  componentDidMount() {
    api.company.getAll().then((r)=>{

      this.setState({
        companies: r.map((c) => {
          return {
            text: c.name,
            value: c._id
          }
        })
      })

    })
  }

  csvExport(items, header, filename) {
    // generating csv rows and then downloading
    if (items && items.length !== 0) {
      let csv_rows = [];
      let csv_header = [];
      let csv_header_fields = [];

      header.forEach((item) => {
        csv_header.push(item.csv_field);
        csv_header_fields.push(item.database_field);
      });

      items.forEach((item) => {
        Object.keys(item).forEach((item) => {
          if (
            item !== "_id" &&
            csv_header.find((header) => header === item) === undefined &&
            header.find((header) => header.database_field === item) ===
              undefined
          ) {
            csv_header.push(item);
            csv_header_fields.push(item);
          }
        });
      });

      csv_rows.push(csv_header.join(","));

      items.forEach((item) => {
        csv_rows.push(
          csv_header_fields
            .map((header) => {
              // console.log("🚀 ~ file: company.js ~ line 291 ~ CompanyPage ~ .map ~ header", header)
              let clean_value = (
                item[header] === undefined ? "" : "" + item[header]
              ).replace(/"|'/g, "");

              return `"${clean_value}"`;
            })
            .join(",")
        );
            
      });

      let data_blob = new Blob([csv_rows.join("\n")], { type: "text/csv" });
      let a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", window.URL.createObjectURL(data_blob));
      a.setAttribute(
        "download",
        `${filename}.csv`
      );
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      this.setState({ loading_report: false});
    }else{
      toast({
        type: "error",
        icon: "file",
        title: <h3>Report Data Error</h3>,
        description: (
          <span>
            <p>
              No data was found between the dates, please try again with a different date range
            </p>
          </span>
        ),
        time: 4000,
      });
      this.setState({ loading_report: false});
    }
  }

  runReport = () => {

    let header = {};

    switch(this.state.data.report){
      case "health-journey-outcome":

        header = [
          { csv_field: "Health Journey ID", database_field: "assNum" },
          { csv_field: "Outcome Date", database_field: "decision_referral_sent_date" },
          { csv_field: "Employee Company", database_field: "company" },
          { csv_field: "Outcome Option", database_field: "status" },
          { csv_field: "Lifestyle Advice", database_field: "lifestyle_advice" },
          { csv_field: "Outcome Notes", database_field: "decision_referral_notes" },
          { csv_field: "Started", database_field: "createdAt" },
          { csv_field: "Health Journey Notes", database_field: "ass_notes" },
        ];
    
        api.assessment.getAssessmentCompletedBy(this.state.data).then((res) => {  
          if(this.state.export_report){
            this.csvExport(res, header, `Health Journey Outcome Report - ${moment().format("YYYY-MM-DD")}`)
          } else{
            this.setState({
              report: {
                data: res,
                header: header,
              },
              loading_run: false,
            });
          }      
        });

      break;
      case "health-journey-progress":

        let companies = this.state.data.company ? this.state.data.company : this.state.companies;

        api.reports.hj_progress(this.state.data).then((res) => {
          console.log("🚀 ~ file: dash.js ~ line 151 ~ ReportsList ~ api.reports.hj_progress ~ res", res)

          if(this.state.export_report){
            this.csvExport(res[0] ? res[0].data : [], res[0] ? res[0].header : [], `Health Journey Progress Report - ${moment().format("YYYY-MM-DD")}`)
          } else{
            this.setState({
              report: {
                data: res[0] ? res[0].data : [],
                header: res[0] ? res[0].header: [],
              },
              loading_run: false,
            });
          }      
        });

      break;
      case "health-journey-form":
        api.reports.hj_form(this.state.data).then((res) => {
          console.log("🚀 ~ file: dash.js ~ line 151 ~ ReportsList ~ api.reports.hj_progress ~ res", res)

          if(this.state.export_report){
            this.csvExport(res.data, res.header, `Health Journey Form Report - ${moment().format("YYYY-MM-DD")}`)
          } else{
            this.setState({
              report: {
                data: res.data,
                header: res.header,
              },
              loading_run: false,
            });
          }      
        });
      break;
      default: 
        // None selected error
      break;
    }

  };

  handleDataChange = (event, data) => { 

    if(data.name === "company"){
      if (data.value.includes("all")) {
        data.value = ["all"];
      }

      if(this.state.data.report === "health-journey-form"){
        api.forms.getCompanyForms(data.value).then((forms) => {
          this.setState({
            forms
          });
        });
      }else{
        this.setState({
          forms: []
        });
      }

    }

    if(this.state.data.report == "health-journey-form"){
      let temp = this.state.companies.filter(e => e.value !== "all");
      this.setState({
        companies: temp
      })
    }

    this.setState({
      data: { ...this.state.data, [data.name]: data.value },
      }, () => {
        let d = this.state.data;
        switch(this.state.data.report){
          case "health-journey-outcome":
          case "health-journey-progress":
            if(d.to !== undefined && d.from !== undefined){
              this.setState({disabled_run: false});
            }
          break;
          case "health-journey-form":
            if(d.to !== undefined && d.from !== undefined && d.form !== undefined){
              this.setState({disabled_run: false});
            }
          break;
        }
      });
  }

  render() {
    console.log("state", this.state);

    let reports = [];

    if(!Authentication.can("organisation_admin.permission")){
      reports = [
        {text: "Health Journey Outcome", value: "health-journey-outcome"},
        {text: "Health Journey Progress", value: "health-journey-progress"},
        {text: "Health Journey Form Data", value: "health-journey-form"}
      ];
    }else{
      reports = [
        {text: "Health Journey Progress", value: "health-journey-progress"}
      ];
    }

    return (
      <Segment basic className="no-pad" loading={this.state.loading}>
        
        <Header as={"h1"} textAlign="center" className=" pageTitle">
          Reports
        </Header>
        <Segment className="border">
          <Form>
            <Form.Dropdown
              name="report"
              label="Report"
              value={this.state.data.report}
              onChange={this.handleDataChange}
              placeholder="Select report"
              options={reports}
              selection
            />
             <Form.Field>
              Status:
            </Form.Field>
            <Form.Field>
              <Radio
                label='All'
                name='status'
                value='all'
                checked={this.state.data.status === 'all'}
                onChange={(e, { value }) => this.setState({ data: {
                  ...this.state.data,
                  status: value
                } })}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='Active'
                name='status'
                value='active'
                checked={this.state.data.status === 'active'}
                onChange={(e, { value }) => this.setState({ data: {
                  ...this.state.data,
                  status: value
                } })}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='Completed'
                name='status'
                value='completed'
                checked={this.state.data.status === 'completed'}
                onChange={(e, { value }) => this.setState({ data: {
                  ...this.state.data,
                  status: value
                } })}
              />
            </Form.Field>
            <DateInput
              label="From Date"
              autoComplete="off"
              animation="off"
              name="from"
              popupPosition={"bottom left"}
              dateTimeFormat="YYYY/MM/DD"
              dateFormat="YYYY/MM/DD"
              closable={true}
              disableMinute={false}
              placeholder="Select Date"
              value={
                this.state.data.from
                  ? moment(this.state.data.from).format(
                      "YYYY/MM/DD"
                    )
                  : ""
              }
              iconPosition="left"
              onChange={this.handleDataChange}
            />
            <DateInput
              label="To Date"
              autoComplete="off"
              animation="off"
              name="to"
              popupPosition={"bottom left"}
              dateTimeFormat="YYYY/MM/DD"
              dateFormat="YYYY/MM/DD"
              closable={true}
              disableMinute={false}
              placeholder="Select Date"
              value={
                this.state.data.to
                  ? moment(this.state.data.to).format(
                      "YYYY/MM/DD"
                    )
                  : ""
              }
              iconPosition="left"
              onChange={this.handleDataChange}
            />
            <Form.Dropdown
              name="company"
              label="Company"
              value={this.state.data.company ? this.state.data.company : this.state.data.report !== "health-journey-form" ? [] : ""}
              onChange={this.handleDataChange}
              placeholder="Select company/s"
              options={[{text: "- All Companies -", value: "all"}].concat(this.state.companies)}
              selection
              clearable
              multiple={this.state.data.report !== "health-journey-form"}
            />
            {this.state.data.report === "health-journey-form" &&
            <Form.Dropdown
              name="form"
              label="Form"
              value={this.state.data.form}
              onChange={this.handleDataChange}
              placeholder="Select Form"
              options={this.state.forms.map((form) => {
                return {
                  text: form.name,
                  value: form._id,
                  key: form._id,
                }
              })}
              selection
              clearable
            />}
            <Form.Button
              color="green"
              loading={this.state.loading_run}
              disabled={this.state.disabled_run}
              onClick={() => {
                this.setState({ export_report: false, loading_run: true});
                this.runReport()
              }}
            >
              <Icon name="file" /> Run Report
            </Form.Button>
            <Form.Button
              color="orange"
              loading={this.state.loading_report}
              disabled={this.state.disabled_run}
              onClick={() => {
                this.setState({ loading_report: true, export_report: true});
                this.runReport()
              }}
            >
              <Icon name="download" /> Export Report
            </Form.Button>
          </Form>
        </Segment>
        {this.state.report.header && this.state.report.data &&
        <Segment style={{overflowY: "scroll"}}>
          <Table>
            <Table.Header>
              <Table.Row>
                {this.state.report && this.state.report.header && this.state.report.header.map((h) => {
                  return <Table.HeaderCell>{h.csv_field}</Table.HeaderCell>
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.report && this.state.report.data && this.state.report.data.length > 0 ? this.state.report.data.map((d) => {

                  return <Table.Row>
                    {this.state.report && this.state.report.header && this.state.report.header.map((h) => {
                      return <Table.Cell>{d[h.database_field]}</Table.Cell>
                    })}
                  </Table.Row>
                }
              ) : this.state.report && this.state.report.data && <Table.Row>
                <Table.Cell>No data was found between the dates, please try again with a different date range</Table.Cell></Table.Row>}
            </Table.Body>
          </Table>
        </Segment>}
      </Segment>
    );
  }
}
