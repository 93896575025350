import React, { Component } from "react";
import { Form, Divider, Icon, Label } from "semantic-ui-react";
import PasswordChangeFields from "../../../components/forms/passwordChangeFields";
import PhoneInput from "react-phone-input-2";

class addEmployeeForm extends Component {
  state = {
    data: {
      title: "",
      firstName: "",
      lastName: "",
      middleName: "",
      work_number: "",
      email: "",
      password: null,
      groups: ["organisation_employee"],
    },
    permissions: [],
    showPassword: false,
    errors: {},
  };

  handleChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  handleDropdownChange = (e, data) => {
    if (data.value.length === 2) {
      data.value.splice(0, 1);
      this.setState({
        data: { ...this.state.data, [data.name]: data.value },
      });
    } else if (data.value.length < 2) {
      this.setState({
        data: { ...this.state.data, [data.name]: data.value },
      });
    }
  };

  validate = (data) => {
    let errors = {};

    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.firstName) errors.firstName = "Can't Be Empty";
    if (!data.lastName) errors.lastName = "Can't Be Empty";
    if (!data.email) errors.email = "Can't Be Empty.";
    if (
      data.email &&
      /^[\w\.\+\-]+@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(data.email) ===
        false
    )
      errors.email = "Invalid Format.";
    if (!data.work_number) errors.work_number = "Can't Be Empty.";
    if (data.work_number && data.work_number.toString().length < 4)
      errors.work_number = "Invalid Number.";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    }
  };

  render() {
    return (
      <Form>
        <Form.Group widths={3}>
          <Form.Input
            width={2}
            name="title"
            label="Tile"
            value={this.state.data.title}
            onChange={this.handleChange}
            placeholder="Title"
            error={this.state.errors.title}
          />
          <Form.Input
            name="firstName"
            label="First Name"
            value={this.state.data.firstName}
            onChange={this.handleChange}
            placeholder="First Name"
            error={this.state.errors.firstName}
          />
          <Form.Input
            name="middleName"
            label="Middle Name"
            value={this.state.data.middleName}
            onChange={this.handleChange}
            placeholder="Middle Name"
          />
          <Form.Input
            name="lastName"
            label="Surname"
            value={this.state.data.lastName}
            onChange={this.handleChange}
            placeholder="Last Name"
            error={this.state.errors.lastName}
          />
        </Form.Group>
        <Form.Group widths={2}>
          <Form.Field
            error={this.state.errors.work_number !== undefined ? true : false}
          >
            <div
              style={{
                paddingBottom: "14px",
                paddingTop: "5px",
                fontSize: ".92857143em",
              }}
            >
              <label>
                <strong>Work Phone</strong>
              </label>
              <PhoneInput
                inputStyle={{
                  marginLeft: "30px",
                  maxWidth: "calc(100% - 30px)",
                  fontFamily:
                    "Lato, Helvetica Neue, Arial, Helvetica, sans-serif !important",
                }}
                country={"gb"}
                value={this.state.data.work_number}
                placeholder="Work Phone"
                onChange={(value) => {
                  this.setState({
                    data: { ...this.state.data, work_number: value },
                  });
                }}
              />
            </div>
            {this.state.errors.work_number && (
              <Label pointing prompt>
                {this.state.errors.work_number}
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <Form.Input
              name="email"
              label="Email"
              value={this.state.data.email}
              onChange={this.handleChange}
              placeholder="Email"
              error={
                this.state.errors.email ||
                (this.props.emailError === true ? "Duplicate email." : false)
              }
            />
          </Form.Field>
        </Form.Group>
        <PasswordChangeFields
          labels={true}
          onChange={(password) =>
            this.setState({ data: { ...this.state.data, password } })
          }
        />
        {/* <Form.Input
          name="password"
          label="Password"
          type={this.state.showPassword === true ? "text" : "password"}
          value={this.state.data.password}
          onChange={this.handleChange}
          placeholder="Password"
          error={this.state.errors.password}
          action={
            <Button
              icon={this.state.showPassword === true ? "eye slash" : "eye"}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showPassword: !this.state.showPassword });
              }}
            />
          }
        /> */}
        <Divider />
        <Form.Group widths="equal">
          <Form.Button floated="left" onClick={this.props.close} negative>
            <Icon name="close" />
            Cancel
          </Form.Button>
          <Form.Button
            floated="right"
            onClick={this.handleSubmit}
            color="green"
          >
            <Icon name="plus" />
            Submit
          </Form.Button>
        </Form.Group>
      </Form>
    );
  }
}

export default addEmployeeForm;
