import React, { Component } from "react";
import { Form, Divider, Segment, Icon, Label } from "semantic-ui-react";
import PhoneInput from "react-phone-input-2";
import api from "../../../../actions/api";
import PostCoder from "../../common/postCoder";
import AppConfig from "../../../../_appConfig";

class addEmployeeForm extends Component {
  state = {
    data: {
      ref: "",
      title: "",
      firstName: "",
      lastName: "",
      middleName: "",
      work_number: "",
      email: "",
      address: {
        address: "",
        addressline1: "",
        addressline2: "",
        street: "",
        posttown: "",
        county: "",
        postcode: "",
      },
      groups: [],
    },
    permissions: [],
    errors: {},
  };

  componentDidMount() {
    api.employee.listPermission().then((res) => {
      this.setState({
        permissions: res,
      });
    });
  }

  handleChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  handleAddressChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        address: {
          ...this.state.data.address,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  handleDropdownChange = (e, data) => {
    if (data.value.length === 2) {
      data.value.splice(0, 1);

      this.setState({
        data: { ...this.state.data, [data.name]: data.value },
      });
    } else if (data.value.length < 2) {
      this.setState({
        data: { ...this.state.data, [data.name]: data.value },
      });
    }
  };

  addressUpdate = (addr) => {
    this.setState({
      data: {
        ...this.state.data,
        address: {
          address: addr.summaryline,
          addressline1: addr.addressline1,
          addressline2: addr.addressline2,
          street: addr.street,
          posttown: addr.posttown,
          county: addr.county,
          postcode: addr.postcode,
          latitude: addr.latitude,
          longitude: addr.longitude,
          organisation: addr.organisation,
        },
      },
    });
  };

  validate = (data) => {
    let errors = {};

    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.ref) errors.ref = "Can't Be Empty";
    if (!data.firstName) errors.firstName = "Can't Be Empty";
    if (!data.lastName) errors.lastName = "Can't Be Empty";
    if (!data.email) errors.email = "Can't Be Empty.";
    if (
      data.email &&
      /^[\w\.\+\-]+@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(data.email) === false
    )
      errors.email = "Invalid Format.";
    if (!data.work_number) errors.work_number = "Can't Be Empty.";
    if (data.work_number && data.work_number.toString().length < 4)
      errors.work_number = "Invalid Number.";
    if (data.groups && data.groups.length === 0)
      errors.groups = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors: errors });

    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group>
            <Form.Input
              name="ref"
              label="Reference"
              value={this.state.data.ref}
              onChange={this.handleChange}
              placeholder="Ref"
              error={this.state.errors.ref}
            />
          </Form.Group>
          <Form.Group widths={3}>
            <Form.Input
              width={2}
              name="title"
              label="Tile"
              value={this.state.data.title}
              onChange={this.handleChange}
              placeholder="Title"
              error={this.state.errors.title}
            />
            <Form.Input
              name="firstName"
              label="First Name"
              value={this.state.data.firstName}
              onChange={this.handleChange}
              placeholder="First Name"
              error={this.state.errors.firstName}
            />
            <Form.Input
              name="middleName"
              label="Middle Name"
              value={this.state.data.middleName}
              onChange={this.handleChange}
              placeholder="Middle Name"
            />
            <Form.Input
              name="lastName"
              label="Surname"
              value={this.state.data.lastName}
              onChange={this.handleChange}
              placeholder="Last Name"
              error={this.state.errors.lastName}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Address Lookup</label>
              {/* <label>Please Use Demo Postcode: NR14 7PZ</label> */}
              <PostCoder
                apiKey={AppConfig.postcoder_api_key}
                addressSelectedCallback={this.addressUpdate}
                identifier={AppConfig.postcoder_identifier}
                country={"UK"}
                showAddress={false}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="addressline1"
              label="Address Line 1"
              value={this.state.data.address.addressline1}
              onChange={this.handleAddressChange}
              placeholder="Address Line 1"
              error={this.state.errors.addressline1}
            />
            <Form.Input
              name="addressline2"
              label="Address Line 2"
              value={this.state.data.address.addressline2}
              onChange={this.handleAddressChange}
              placeholder="Address Line 2"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="street"
              label="Street"
              value={this.state.data.address.street}
              onChange={this.handleAddressChange}
              placeholder="Street"
              error={this.state.errors.street}
            />
            <Form.Input
              name="posttown"
              label="Town / City"
              value={this.state.data.address.posttown}
              onChange={this.handleAddressChange}
              placeholder="Postal Town / City"
              error={this.state.errors.posttown}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="county"
              label="County"
              value={this.state.data.address.county}
              onChange={this.handleAddressChange}
              placeholder="County"
              error={this.state.errors.county}
            />
            <Form.Input
              name="postcode"
              label="Postcode"
              value={this.state.data.address.postcode}
              onChange={this.handleAddressChange}
              placeholder="Postcode"
              error={this.state.errors.postcode}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field
              error={this.state.errors.work_number !== undefined ? true : false}
            >
              <div
                style={{
                  paddingBottom: "14px",
                  paddingTop: "5px",
                  fontSize: ".92857143em",
                }}
              >
                <label>
                  <strong>Work Phone</strong>
                </label>
                <PhoneInput
                  inputStyle={{
                    marginLeft: "30px",
                    maxWidth: "calc(100% - 30px)",
                    fontFamily:
                      "Lato, Helvetica Neue, Arial, Helvetica, sans-serif !important",
                  }}
                  country={"gb"}
                  value={this.state.data.work_number}
                  placeholder="Work Phone"
                  onChange={(value) => {
                    this.setState({
                      data: { ...this.state.data, work_number: value },
                    });
                  }}
                />
              </div>
              {this.state.errors.work_number && (
                <Label pointing prompt>
                  {this.state.errors.work_number}
                </Label>
              )}
            </Form.Field>
            <Form.Field>
              <Form.Input
                name="email"
                label="Email"
                value={this.state.data.email}
                onChange={this.handleChange}
                placeholder="Email"
                error={
                  this.state.errors.email ||
                  (this.props.emailError === true ? "Duplicate email." : false)
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Dropdown
            label="Select User Role(s)"
            placeholder="Permission Groups"
            name="groups"
            value={this.state.data.groups}
            onChange={this.handleDropdownChange}
            options={this.state.permissions}
            search
            selection
            multiple
            error={this.state.errors.groups}
          />
          <Divider />
          <Form.Group widths="equal">
            <Form.Button floated="left" onClick={this.props.close} negative>
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              floated="right"
              onClick={this.handleSubmit}
              color="green"
            >
              <Icon name="plus" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default addEmployeeForm;
