import React, { Component } from "react";
import { Segment, Table, Message } from "semantic-ui-react";
import moment from "moment";

export default class CasesList extends Component {
  renderBody() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        return (
          <Table.Row key={item._id} onClick={() => this.props.action(item._id)}>
            <Table.Cell collapsing>C{item.refNum}</Table.Cell>
            <Table.Cell collapsing>
              {item.employee && item.employee.firstName}{" "}
              {item.employee && item.employee.lastName}
              {item.employee && item.employee.ref
                ? " (#" + item.employee.ref + ")"
                : ""}
            </Table.Cell>
            {this.props.showCM && (
              <Table.Cell>
                {item.cm
                  ? item.cm.firstName + " " + item.cm.lastName
                  : "Not Assigned"}
              </Table.Cell>
            )}
            <Table.Cell>
              {item.referral && item.referral.cm
                ? item.referral.cm.firstName + " " + item.referral.cm.lastName
                : "Not Assigned"}
            </Table.Cell>
            <Table.Cell></Table.Cell>
            {this.props.cm && <Table.Cell>{item.company.name}</Table.Cell>}
            <Table.Cell collapsing>
              {moment(item.createdAt).format("DD-MM-YYYY")}
            </Table.Cell>
            <Table.Cell>
              {item.referral ? item.referral.type : "[UNDEFINED]"}
            </Table.Cell>
            <Table.Cell>
              {item.referral ? item.referral.condition : "[UNDEFINED]"}
            </Table.Cell>
            {/* 
            {!this.props.cm && (
              <Table.Cell>
                {item.cm && item.cm.firstName} {item.cm && item.cm.lastName}
              </Table.Cell>
            )} */}
            <Table.Cell collapsing>{item.status}</Table.Cell>
            <Table.Cell collapsing></Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  render() {
    if (!this.props.data || this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {/* {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add New Staff Member
              </Button>
            )} */}
          </Segment>
        </Message>
      );
    }

    return (
      <Table stackable celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Ref</Table.HeaderCell>
            <Table.HeaderCell>Patient</Table.HeaderCell>
            {this.props.showCM && (
              <Table.HeaderCell>
                Assigned To Nurse Case Manager
              </Table.HeaderCell>
            )}
            <Table.HeaderCell>
              Assigned To Referral Case Manager
            </Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            {this.props.cm && <Table.HeaderCell>Company</Table.HeaderCell>}
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Condition</Table.HeaderCell>
            {/*  
            {!this.props.cm && (
              <Table.HeaderCell>Case Manager</Table.HeaderCell>
            )}*/}
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Last Activity</Table.HeaderCell>
            {/* <Table.HeaderCell width={1} textAlign="right">
              <Button fluid icon positive size="tiny" onClick={this.props.add}>
                <Icon name="plus" />
              </Button>
            </Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderBody()}</Table.Body>
      </Table>
    );
  }
}
