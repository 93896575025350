import React, { Component } from "react";
import {
  Form,
  Segment,
  Icon,
  Message,
  Divider,
  Header,
} from "semantic-ui-react";

export default class add extends Component {
  state = {
    data: {
      type: "",
      file: null,
    },
    isHovered: false,
    loading: false,
    errors: {},
  };

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  onFileLoad = (e) => {
    if (e.currentTarget.files[0] === undefined) {
      return;
    }

    let file = e.currentTarget.files[0];
    let allowedTypes = ["application/pdf"];

    let isAllowed = false;

    for (let i in allowedTypes) {
      let type = allowedTypes[i];
      if (file.type === type) {
        isAllowed = true;
      }
    }

    if (!isAllowed) {
      alert("Only PDF files are allowed to be uploaded.");
      return;
    }

    this.setState({
      data: {
        ...this.state.data,
        file: file,
      },
    });
  };

  validate = (data) => {
    const errors = {};

    if (!data.type) errors.type = "Can't Be Empty";
    if (!data.file) errors.file = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      let formData = new FormData();
      let keys = Object.keys(this.state.data);

      for (let i in keys) {
        formData.append(keys[i], this.state.data[keys[i]]);
      }

      this.props.submit(formData);
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Dropdown
              selection
              name="type"
              label="Type"
              value={this.state.data.type}
              onChange={this.handleDropdownChange}
              placeholder="Type"
              error={this.state.errors.type}
              options={[
                { text: "Privacy Policy", value: "Privacy Policy" },
                { text: "Terms And Conditions", value: "Terms And Conditions" },
              ]}
            />
          </Form.Group>
          <Divider hidden />
          {this.state.errors.file && (
            <Header as="h3" textAlign="center" color="red" className="no-vmarg">
              Please Upload A File
            </Header>
          )}
          <i>This upload only accepts PDF files.</i>
          {this.state.data.file === null ? (
            <Segment basic className="inner-container no-hpad">
              <div
                className={`draggable-container ${
                  this.state.isHovered && "draggable-container-hovered"
                }`}
              >
                <input
                  type="file"
                  id="file-browser-input"
                  name="file-browser-input"
                  ref={(input) => (this.fileInput = input)}
                  onDragOver={() => this.setState({ isHovered: true })}
                  onDragLeave={() => this.setState({ isHovered: false })}
                  onChange={(e) => this.onFileLoad(e)}
                />
                <div className="helper-text">
                  <p>
                    Drop File Here Or <br />
                    Click To Browse (<b>MAX 5MB</b>)
                  </p>
                </div>
              </div>
            </Segment>
          ) : (
            <Segment basic className="no-hpad">
              <Message
                positive
                icon
                onDismiss={() =>
                  this.setState({
                    data: { ...this.state.data, file: null },
                  })
                }
              >
                <Icon name="checkmark" />
                <Message.Header>File Uploaded Successfully</Message.Header>
              </Message>
            </Segment>
          )}
          <Divider clearing />
          <Form.Group widths="equal">
            <Form.Button
              floated="left"
              color="red"
              onClick={() => this.props.close()}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              floated="right"
              color="green"
              onClick={() => this.handleSubmit()}
              loading={this.state.loading}
            >
              <Icon name="plus" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
