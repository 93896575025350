import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import EmployeeMenu from "../../components/menus/employeeMenu";
import EmployeeList from "./employeeList";
import EmployeeShow from "./show";
import AddEmployee from "./addEmployee";
import EditEmployee from "./editEmployee";
export default class EmployeePages extends Component {
  state = {
    userDetails: [],
  };
  render() {
    return (
      <div className="pageWrapper pageHolder">
        <EmployeeMenu />
        <Switch>
          <Route path={`${this.props.match.url}/add`} component={AddEmployee} />
          <Route
            path={`${this.props.match.url}/edit/:id`}
            component={EditEmployee}
          />
          <Route
            path={`${this.props.match.url}/:id`}
            component={EmployeeShow}
          />
          <Route
            exact
            path={`${this.props.match.url}`}
            component={EmployeeList}
          />
        </Switch>
      </div>
    );
  }
}
