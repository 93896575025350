import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import EmployeeMenu from "./employeeMenu";
import showEmployee from "./show";
import EditEmployee from "./forms/editEmployee";
import addReferralForm from "./forms/addReferralForm";

export default class EmployeePages extends Component {
  render() {
    return (
      <Segment basic className="no-pad no-marg ">
        <EmployeeMenu />
        <Segment basic className="pageHolder">
          <Switch>
            {/* <Route
              exact
              path={`/employee/:id/referral/:id/edit`}
              component={editReferralForm}
            /> */}
            <Route
              exact
              path={`/employee/:id/referral/add`}
              component={addReferralForm}
            />
            <Route exact path={`/employee/:id/edit`} component={EditEmployee} />
            <Route path={`/employee/:id`} component={showEmployee} />
          </Switch>
        </Segment>
      </Segment>
    );
  }
}
