import React, { Component } from "react";
import { Segment, Button } from "semantic-ui-react";

export default class FileDropbox extends Component {
  state = {
    uploading: false,
    isHovered: false,
    file: {},
  };

  onFileLoad = (e) => {
    if (e.currentTarget.files[0] === undefined) {
      return;
    }
    const file = e.currentTarget.files[0];
    if (!this.props.allowedFileType(file.type)) {
      alert("File type not allowed, please select a different file.");
      return false;
    }
    this.setState({ file });
  };

  render() {
    return (
      <Segment
        basic
        loading={this.state.uploading}
        className="inner-container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          className={`draggable-container ${
            this.state.isHovered && "draggable-container-hovered"
          }`}
        >
          <input
            type="file"
            id="file-browser-input"
            name="file-browser-input"
            onDragOver={() => this.setState({ isHovered: true })}
            onDragLeave={() => this.setState({ isHovered: false })}
            onChange={(e) => this.onFileLoad(e)}
            ref={(ref) => (this.fileInput = ref)}
          />
          <div className="helper-text">Drag and Drop here (max 5mb)</div>
        </div>
        <div className="file-browser-container">
          <Button basic color="blue" onClick={() => this.fileInput.click()}>
            Browse for File
          </Button>
        </div>
        {this.state.file.name !== undefined ? (
          <React.Fragment>
            Selected File: {this.state.file.name} <br />
            Size: {Math.round(this.state.file.size / 1024)} Kb
            <Button
              onClick={() => {
                this.props.onFileToUpload(this.state.file);
              }}
              primary
            >
              Upload
            </Button>
          </React.Fragment>
        ) : (
          ""
        )}
      </Segment>
    );
  }
}
