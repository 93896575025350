import Axios from "axios";
import AppConfig from "../../../_appConfig";

const server = AppConfig.api_server;

const quickObjectToQueryString = (query) => {
  return (
    "?" +
    Object.keys(query)
      .map((key) =>
        key === "filters"
          ? key + "=" + JSON.stringify(query[key])
          : key + "=" + query[key]
      )
      .join("&")
  );
};

function applyHeaders(extra) {
  if (extra === undefined) {
    extra = {};
  }
  return {
    headers: {
      Authorization:
        "Bearer " +
        localStorage.getItem(AppConfig.localStoragePrefix + "access_token"),
    },
    ...extra,
  };
}
export default {
  company: {
    getAllClinicians: () =>
      Axios.get(server + "/company/getAllClinicians", applyHeaders()).then(
        (res) => res.data
      ),
    getManagedCompanyEmployeesDropdown: (id) =>
      Axios.get(
        server + `/company/managed/employees/dropdown`,
        applyHeaders()
      ).then((res) => res.data),
    getAssessments: (id) =>
      Axios.get(server + `/company/${id}/assessments`, applyHeaders()).then(
        (res) => res.data
      ),
    getDash: (id) =>
      Axios.get(server + `/company/${id}/dash`, applyHeaders()).then(
        (res) => res.data
      ),
    getStatuses: (id) =>
      Axios.get(server + `/company/${id}/statuses`, applyHeaders()).then(
        (res) => res.data
      ),
    show: () =>
      Axios.get(server + `/company/mycompany`, applyHeaders()).then(
        (res) => res.data
      ),
    addEmployee: (id, data) =>
      Axios.post(
        server + `/company/${id}/employees`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    getCompanyEmployeesPaged: (id, query) =>
      Axios.get(
        server +
          `/company/${id}/employees/paged` +
          quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    listReferralsPaged: (id, query) =>
      Axios.get(
        server +
          `/company/${id}/referrals/paged` +
          quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    getCompanyAdminDropdown: (id) =>
      Axios.get(
        server + `/company/${id}/employees/admins/dropdown`,
        applyHeaders()
      ).then((res) => res.data),
    getClinicians: (company_id) =>
      Axios.get(
        server + `/company/${company_id}/clinicians`,
        applyHeaders()
      ).then((res) => res.data),
    getAPPSteps: (id) =>
      Axios.get(server + `/company/${id}/getAPPSteps`, applyHeaders()).then(
        (res) => res.data
      ),
  },
  employee: {
    addAssessment: (employee) =>
      Axios.post(server + `/assessment/${employee}`, {}, applyHeaders()).then(
        (res) => res.data
      ),
    listPermission: () =>
      Axios.get(server + `/employee/permissions`, applyHeaders()).then(
        (res) => res.data
      ),
    get: (id) =>
      Axios.get(server + `/employee/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    getAllEmployeeReferralPaged: (id, query) =>
      Axios.get(
        server + `/referral/${id}/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    addReferal: (id, data) =>
      Axios.post(
        server + `/employee/${id}/referral`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    editEmployee: (id, data) =>
      Axios.patch(server + `/employee/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    reset_password: (staffID, data) =>
      Axios.post(
        server + `/employee/${staffID}/password_reset`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    resendOnboarding: (id) =>
      Axios.get(server + `/employee/${id}/resendOnboarding`, applyHeaders()).then(
        (res) => res.data
      ),
    password_policy: () =>
      Axios.get(server + `/../authentication/password_policy`).then(
        (res) => res.data
      ),
  },
  files: {
    getAll: () =>
      Axios.get(server + "/fileManager", applyHeaders()).then(
        (res) => res.data
      ),
    getFolder: (id) =>
      Axios.get(server + `/fileManager/folder/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    getFolderPath: (id) =>
      Axios.get(
        server + `/fileManager/folder/route/${id}`,
        applyHeaders()
      ).then((res) => res.data),
    getFilelink: (id) =>
      Axios.get(
        server + `/fileManager/folder/file/${id}/link`,
        applyHeaders()
      ).then((res) => res.data),
    searchFiles: (data) =>
      Axios.post(
        server + `/fileManager/files/search`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    deleteFile: (id) =>
      Axios.delete(server + `/fileManager/files/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    addFolder: (data) =>
      Axios.post(server + "/fileManager/folder/", data, applyHeaders()).then(
        (res) => res.data
      ),
    move: (data) =>
      Axios.post(server + "/fileManager/move", data, applyHeaders()).then(
        (res) => res.data
      ),
    deleteFolder: (id) =>
      Axios.delete(server + `/fileManager/folder/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    renameFolder: (id, data) =>
      Axios.patch(
        server + `/fileManager/folder/${id}`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    uploadFile: (data) => {
      let formData = new FormData();
      let keys = Object.keys(data);
      for (let i in keys) {
        formData.append(keys[i], data[keys[i]]);
      }

      return Axios.post(
        server + `/fileManager/file_upload`,
        formData,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data);
    },
  },
  referral: {
    getReferral: (id) =>
      Axios.get(server + `/referral/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    editReferal: (id, data) =>
      Axios.patch(server + `/referral/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    uploadReferralFile: (id, data) => {
      let formData = new FormData();
      let keys = Object.keys(data);
      for (let i in keys) {
        formData.append(keys[i], data[keys[i]]);
      }

      return Axios.patch(
        server + `/referral/${id}/file_upload`,
        formData,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data);
    },
    getReferralFilelink: (id) =>
      Axios.get(server + `/referral/${id}/link`, applyHeaders()).then(
        (res) => res.data
      ),
    notes: {
      add: (id, data) =>
        Axios.post(server + `/referral/${id}/logs`, data, applyHeaders()).then(
          (res) => res.data
        ),
      getReferralNoteDetails: (id, id2) =>
        Axios.get(server + `/referral/${id}/logs/${id2}`, applyHeaders()).then(
          (res) => res.data
        ),
      edit: (id, id2, data) =>
        Axios.patch(
          server + `/referral/${id}/logs/${id2}`,
          data,
          applyHeaders()
        ).then((res) => res.data),
      listAllPaged: (id, query) =>
        Axios.get(
          server +
            `/referral/${id}/logs/paged` +
            quickObjectToQueryString(query),
          applyHeaders()
        ).then((res) => res.data),
    },
  },
  //CCM
  team: {
    getCaseManagers: () =>
      Axios.get(server + `/team/cm`, applyHeaders()).then((res) => res.data),
    getNurseManagers: () =>
      Axios.get(server + `/team/nurses`, applyHeaders()).then(
        (res) => res.data
      ),
  },
  case: {
    addCase: (data) =>
      Axios.post(server + "/case", data, applyHeaders()).then(
        (res) => res.data
      ),
    assignMultiCaseManager: (data) =>
      Axios.post(server + "/case/assignMulti", data, applyHeaders()).then(
        (res) => res.data
      ),
    editCase: (id, data) =>
      Axios.patch(server + `/case/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    getCase: (id) =>
      Axios.get(server + `/case/${id}`, applyHeaders()).then((res) => res.data),
  },

  assessment: {
    getAll: () =>
      Axios.get(server + "/assessment", applyHeaders()).then((res) => res.data),
    uploadCaseFile: (id, data) => {
      let formData = new FormData();
      let keys = Object.keys(data);
      for (let i in keys) {
        formData.append(keys[i], data[keys[i]]);
      }

      return Axios.patch(
        server + `/assessment/${id}/file_upload`,
        formData,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data);
    },
    addAssessmentTest: (id, data) =>
      Axios.post(
        server + `/assessment/${id}/addAssessmentTest`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    getAllPaged: (query) =>
      Axios.get(
        server + "/assessment/paged" + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    getAllCM: () =>
      Axios.get(server + `/assessment/cm`, applyHeaders()).then(
        (res) => res.data
      ),
    getAllCMPaged: (query) =>
      Axios.get(
        server + `/assessment/cm/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    getAssessment: (id) =>
      Axios.get(server + `/assessment/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    getTestResults: (id) =>
      Axios.get(
        server + `/assessment/${id}/getTestResults`,
        applyHeaders()
      ).then((res) => res.data),
    listTestsPaged: (id, query) =>
      Axios.get(
        server +
          `/assessment/${id}/tests/paged` +
          quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    getAssessmentFilelink: (id) =>
      Axios.get(server + `/assessment/${id}/link`, applyHeaders()).then(
        (res) => res.data
      ),
    getEmployeeAssessments: (id) =>
      Axios.get(server + `/assessment/${id}/all`, applyHeaders()).then(
        (res) => res.data
      ),
    getDashStats: () =>
      Axios.get(server + `/assessment/dash`, applyHeaders()).then(
        (res) => res.data
      ),
    getAllEmployeeAssessmentsPaged: (id, query) =>
      Axios.get(
        server + `/assessment/${id}/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    addAssessment: (employee) =>
      Axios.post(server + `/assessment/${employee}`, {}, applyHeaders()).then(
        (res) => res.data
      ),
    editAssessment: (id, data) =>
      Axios.patch(server + `/assessment/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    uploadAssessmentFile: (id, data) => {
      let formData = new FormData();
      let keys = Object.keys(data);
      for (let i in keys) {
        formData.append(keys[i], data[keys[i]]);
      }

      return Axios.patch(
        server + `/assessment/${id}/file_upload`,
        formData,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data);
    },
    updateStatus: (data, id) =>
      Axios.post(
        server + `/assessment/${id}/updateStatus`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    notes: {
      add: (id, data) =>
        Axios.post(
          server + `/assessment/${id}/logs`,
          data,
          applyHeaders()
        ).then((res) => res.data),
      getAssessmentNoteDetails: (id, id2) =>
        Axios.get(
          server + `/assessment/${id}/logs/${id2}`,
          applyHeaders()
        ).then((res) => res.data),
      edit: (id, id2, data) =>
        Axios.patch(
          server + `/assessment/${id}/logs/${id2}`,
          data,
          applyHeaders()
        ).then((res) => res.data),
      listAllPaged: (id, query) =>
        Axios.get(
          server +
            `/assessment/${id}/logs/paged` +
            quickObjectToQueryString(query),
          applyHeaders()
        ).then((res) => res.data),
      getAssessmentNotes: (id) =>
        Axios.get(server + `/assessment/${id}/logs`, applyHeaders()).then(
          (res) => res.data
        ),
    },
  },

  forms: {
    getCompanyForms: (id) =>
      Axios.get(server + `/forms/companyForms/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    addField: (id, data) =>
      Axios.post(server + `/forms/${id}/addField`, data, applyHeaders()).then(
        (res) => res.data
      ),
    addCompanyForm: (id, data) =>
      Axios.post(
        server + `/forms/${id}/addCompanyForm`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    editCompanyForm: (id, id2, data) =>
      Axios.patch(
        server + `/forms/${id}/editCompanyForm/${id2}`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    removeCompanyForm: (id, id2) =>
      Axios.delete(
        server + `/forms/${id}/removeCompanyForm/${id2}`,
        applyHeaders()
      ).then((res) => res.data),
    editField: (id, id2, data) =>
      Axios.patch(
        server + `/forms/${id}/editField/${id2}`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    removeField: (id, id2) =>
      Axios.delete(
        server + `/forms/${id}/removeField/${id2}`,
        applyHeaders()
      ).then((res) => res.data),
    getFieldTypes: () =>
      Axios.get(server + `/forms/getFieldTypes`, applyHeaders()).then(
        (res) => res.data
      ),
  },

  home: {
    getDashStats: () =>
      Axios.get(server + `/home/dash`, applyHeaders()).then((res) => res.data),
  },
  templates: {
    getDefaultLinks: () =>
      Axios.get(server + `/template/default_links`, applyHeaders()).then(
        (res) => res.data
      ),
  },
  settings: {
    all: () =>
      Axios.get(server + `/settings`, applyHeaders()).then((res) => res.data),
    formLists: () =>
      Axios.get(server + `/settings/formlists`, applyHeaders()).then(
        (res) => res.data
      ),
  },
};
