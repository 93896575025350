import React, { Component } from "react";
import { Segment, Table, Message, Form } from "semantic-ui-react";
import api from "../../../actions/api";

class userLookupWidget extends Component {
  state = { companyList: [], companyLookup: "", error: false };

  getData(search) {
    if (this.state.error === true) {
      this.setState({ error: false });
    }

    api.company
      .companyLookup(search)
      .then((res) => this.setState({ companyList: res }))
      .catch((e) => {
        console.log(e);
        this.setState({ error: true });
      });
  }

  handleCompanySearch = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      if (this.state.companyLookup.length > 2) {
        this.getData(this.state.companyLookup);
      }
    });
  };

  renderCompanyLookupBody() {
    return this.state.companyList.map((item) => {
      return (
        <Table.Row
          key={item._id}
          onClick={() => {
            this.props.history.push(`/company/${item._id}`);
          }}
        >
          <Table.Cell>{item.ref}</Table.Cell>
          <Table.Cell>{item.name}</Table.Cell>
          <Table.Cell>{item.country}</Table.Cell>
          <Table.Cell>{item.archived ? "Archived" : "Active"}</Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    return (
      <Segment basic>
        <Form>
          <Form.Group>
            <Form.Input
              width={this.state.companyLookup.length > 2 ? 15 : 16}
              fluid
              name="companyLookup"
              onChange={this.handleCompanySearch}
              value={this.state.companyLookup}
              placeholder="Search For Company..."
            />
            {this.state.companyLookup.length > 2 ? (
              <Form.Button
                width={1}
                icon="close"
                color="red"
                onClick={() => {
                  this.setState({ companyLookup: "", companyList: [] });
                }}
              />
            ) : (
              <></>
            )}
          </Form.Group>
        </Form>
        {this.state.companyLookup.length > 2 ? (
          <React.Fragment>
            {this.state.error === true ? (
              <Message
                error
                icon="warning"
                content="Error Fetching Data"
                className="messageColourFix"
              />
            ) : (
              <React.Fragment />
            )}
            <Table selectable celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Ref</Table.HeaderCell>
                  <Table.HeaderCell>Company</Table.HeaderCell>
                  <Table.HeaderCell>Country</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.companyList.length !== 0 ? (
                  this.renderCompanyLookupBody()
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan="4">No Data</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </Segment>
    );
  }
}

export default userLookupWidget;
