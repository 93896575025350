import React, { Component } from "react";
import { Segment, Header, Form, Icon, Message, Image } from "semantic-ui-react";
import api from "../../../../../actions/api";
import AppConfig from "../../../../../_appConfig";

export default class edit extends Component {
  state = {
    data: {
      title: "",
      description: "",
      archived: false,
      splash_image: null,
    },
    uploaded_file_link: null,
    preview_upload: null,
    loading: true,
    errors: {},
  };

  componentDidMount() {
    this._getData();
  }

  _getData() {
    if (this.state.loading === false) {
      this.setState({ loading: true });
    }

    api.mobile_splash_screens
      .show(this.props.match.params.id)
      .then((res) => {
        this.setState({
          data: res.splash_screen,
          uploaded_file_link: res.splash_image,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ loading: false });
      });
  }

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  onFileLoad = (e) => {
    if (e.currentTarget.files[0] === undefined) {
      return;
    }

    let file = e.currentTarget.files[0];
    let allowedTypes = ["image/jpeg", "image/png"];

    let isAllowed = false;

    for (let i in allowedTypes) {
      let type = allowedTypes[i];
      if (file.type === type) {
        isAllowed = true;
      }
    }

    if (!isAllowed) {
      alert("Only Image files are allowed to be uploaded.");
      return;
    }

    this.setState(
      {
        data: {
          ...this.state.data,
          splash_image: file,
        },
        uploaded_file_link: null,
        preview_upload: URL.createObjectURL(file),
      },
      () => {
        URL.revokeObjectURL(file);
      }
    );
  };

  validate = (data) => {
    const errors = {};

    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.description) errors.description_short = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      let formData = new FormData();
      let keys = Object.keys(this.state.data);

      for (let i in keys) {
        formData.append(keys[i], this.state.data[keys[i]]);
      }

      api.mobile_splash_screens
        .update(this.props.match.params.id, formData)
        .then(() => this.props.history.push(`/settings/splash_screens`));
    }
  };

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Edit Mobile Splash Screen
        </Header>
        <Segment className="no-marg border">
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                name="title"
                label="Title"
                value={this.state.data.title}
                onChange={this.handleChange}
                placeholder="Title"
                error={this.state.errors.title}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.TextArea
                name="description"
                label="Description"
                value={this.state.data.description}
                onChange={this.handleChange}
                placeholder="Description"
                error={this.state.errors.description}
              />
            </Form.Group>
            <Form.Checkbox
              label="Archived"
              name="archived"
              checked={this.state.data.archived}
              onClick={() => {
                this.setState({
                  data: {
                    ...this.state.data,
                    archived: !this.state.data.archived,
                  },
                });
              }}
            />
            {this.state.data.splash_image === null ? (
              <Segment basic className="inner-container no-hpad">
                <div
                  className={`draggable-container ${
                    this.state.isHovered && "draggable-container-hovered"
                  }`}
                >
                  <input
                    type="file"
                    id="file-browser-input"
                    name="file-browser-input"
                    ref={(input) => (this.fileInput = input)}
                    onDragOver={() => this.setState({ isHovered: true })}
                    onDragLeave={() => this.setState({ isHovered: false })}
                    onChange={(e) => this.onFileLoad(e)}
                  />
                  <div className="helper-text">
                    <p>
                      Drop Picture Here Or <br />
                      Click To Browse (<b>MAX 5MB</b>)
                    </p>
                  </div>
                </div>
              </Segment>
            ) : (
              <Segment basic className="no-hpad">
                {this.state.uploaded_file_link === null ? (
                  <Message
                    positive
                    icon
                    onDismiss={() =>
                      this.setState({
                        data: { ...this.state.data, splash_image: null },
                        preview_upload: null,
                      })
                    }
                  >
                    <Icon name="checkmark" />
                    <Message.Header>File Uploaded Successfully</Message.Header>
                  </Message>
                ) : (
                  <React.Fragment />
                )}
                <Image
                  onClick={() => {
                    this.setState({
                      data: { ...this.state.data, splash_image: null },
                      uploaded_file_link: null,
                    });
                  }}
                  className="preview-image"
                  src={
                    this.state.uploaded_file_link !== null
                      ? AppConfig.api_server + this.state.uploaded_file_link
                      : this.state.preview_upload
                  }
                />
              </Segment>
            )}
            <Form.Group widths="equal">
              <Form.Button
                floated="left"
                color="red"
                onClick={() =>
                  this.props.history.push(`/settings/splash_screens`)
                }
              >
                <Icon name="left arrow" />
                Close
              </Form.Button>
              <Form.Button
                floated="right"
                color="green"
                onClick={() => this.handleSubmit()}
                loading={this.state.loading}
              >
                <Icon name="plus" />
                Submit
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
      </Segment>
    );
  }
}
