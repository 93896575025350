import React, { Component } from "react";
import { Segment, Header } from "semantic-ui-react";
import api from "../../actions/api";
import AddEmployeeForm from "./forms/addEmployeeForm";
import Company_Receiver from "../../contexts/company_context/Company_Receiver";

class AddEmployee extends Component {
  state = {
    loading: false,
    emailError: false,
  };

  handleAdd = (data) => {
    this.setState({
      loading: true,
    });

    api.company
      .addEmployee(this.props.company._id, data)
      .then(() => {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.props.history.push(`/employee`);
            this.setState({ emailError: false });
          }
        );
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.message === "Dupe email not allowed."
        ) {
          this.setState({ emailError: true });
        }

        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <Segment basic loading={this.state.loading}>
        <Header className={"sectionTitle"}>Add Employee</Header>
        <Segment className="border">
          <AddEmployeeForm
            submit={this.handleAdd}
            emailError={this.state.emailError}
          />
        </Segment>
      </Segment>
    );
  }
}
export default Company_Receiver(AddEmployee, (company) => {
  return {
    company,
  };
});
