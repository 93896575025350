import React, { Component } from "react";
import {
  Segment,
  Header,
  Form,
  Icon,
  Image,
  Modal,
  Loader,
  Table,
  Button,
  Divider,
} from "semantic-ui-react";
import api from "../../../../../actions/api";
import UploadFileForm from "../../../common/forms/uploadFile";
import AppConfig from "../../../../../_appConfig";
import ServiceTypeList from "../../../../common/ServiceTypeList";

class editServiceForm extends Component {
  state = {
    data: {
      title: "",
      description_short: "",
      description_long: "",
      service_resources: [],
      service_icon: "",
      archived: false,
    },
    temp_data: {
      service_type: "",
      service_link: "",
      button_text: "",
    },
    service_icon_url: "",
    upload_file: false,
    submit_load: false,
    loading: false,
    errors: {},
  };

  componentDidMount() {
    this._getData();
  }

  _getData() {
    this.setState({ loading: true });
    api.services
      .findOne(this.props.match.params.id)
      .then((res) => {
        this.setState({ data: res }, () => {
          if (res.service_icon !== undefined && res.service_icon !== "") {
            api.services.getFilelink(res.service_icon).then((res_two) => {
              this.setState({ service_icon_url: res_two, loading: false });
            });
          } else {
            this.setState({ loading: false });
          }
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  }

  handleTempDropdownChange = (e, data) => {
    this.setState({
      temp_data: {
        service_type: data.value,
        service_link: "",
        button_text: "",
      },
    });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleTempChange = (event) => {
    this.setState({
      temp_data: {
        ...this.state.temp_data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleTempAdd = (data) => {
    const errors = {};
    let final_data = data;

    if (!data.service_type){
        errors.service_type = "Can't Be Empty";
    }
    if (!data.service_link && ServiceTypeList.filter(i => i.isIntegrator).map(i => i.value).indexOf(data.service_type) === -1){
      errors.service_link = "Can't Be Empty";
    }
    if (data.service_type === "Website Link" && data.service_link && data.service_link.search("https://") !== -1) {
      final_data.service_link = data.service_link.replace("https://", "");
    }

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        data: {
          ...this.state.data,
          service_resources: [...this.state.data.service_resources, final_data],
        },
      });
    }
  };

  validate = (data) => {
    const errors = {};

    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.description_short) errors.description_short = "Can't Be Empty";
    if (data.service_resources && data.service_resources.length === 0)
      errors.service_resources = "No Services Created.";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ submit_load: true });
      api.services
        .update(this.props.match.params.id, this.state.data)
        .then(() => this.props.history.push(`/settings/services`))
        .catch((e) => {
          console.log(e);
          this.setState({ submit_load: false });
        });
    }
  };

  renderUploadFile() {
    const close = () => {
      this.setState({ upload_file: false });
    };

    const handleSubmit = (data) => {
      api.services
        .uploadFile(this.props.match.params.id, {
          file: data.file,
          description: data.description,
          selectedFile: data.selectedFile
        })
        .then(() => {
          close();
          this._getData();
        })
        .catch((e) => {
          console.log(e);
        });
    };

    return (
      <Modal
        open={this.state.upload_file === true}
        onClose={close}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="upload" content="Upload File" />
        <Modal.Content>
          <UploadFileForm submit={handleSubmit} close={close} />
        </Modal.Content>
      </Modal>
    );
  }

  renderBody() {
    return this.state.data.service_resources.map((item, index) => {
      return (
        <Table.Row key={index}>
          <Table.Cell>{item.service_type}</Table.Cell>
          <Table.Cell>
            {item.service_link !== "" ? item.service_link : "N/A"}
          </Table.Cell>
          <Table.Cell>
            {item.button_text !== "" ? item.button_text : "None Supplied"}
          </Table.Cell>
          <Table.Cell>
            <Button
              icon="trash"
              color="red"
              onClick={() => {
                let data = this.state.data.service_resources;
                data.splice(index, 1);
                this.setState({
                  data: {
                    ...this.state.data,
                    service_resources: data,
                  },
                });
              }}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Edit Service
        </Header>
        <Segment className="no-marg border">
          {this.renderUploadFile()}
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                name="title"
                label="Service Name"
                value={this.state.data.title}
                onChange={this.handleChange}
                placeholder="Title"
                error={this.state.errors.title}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.TextArea
                name="description_short"
                label="Description (Short)"
                value={this.state.data.description_short}
                onChange={this.handleChange}
                placeholder="Description (Short)"
                error={this.state.errors.description_short}
              />
              <Form.TextArea
                name="description_long"
                label="Description (Long)"
                value={this.state.data.description_long}
                onChange={this.handleChange}
                placeholder="Description (Long)"
              />
            </Form.Group>
            <Divider />
            <Form.Group widths="equal">
              <Form.Dropdown
                name="service_type"
                label="Service Type"
                value={this.state.temp_data.service_type}
                onChange={this.handleTempDropdownChange}
                options={ServiceTypeList.map(i => {
                    return {
                        key: i.key,
                        value: i.value,
                        text: i.text
                    }
                })}
                error={this.state.errors.service_type}
                selection
                placeholder="Service Type"
              />
              {ServiceTypeList.filter(i => i.isIntegrator).map(i => i.value).indexOf(this.state.temp_data.service_type) === -1 && (
                  <React.Fragment>
                      <Form.Input
                          name="service_link"
                          label={`Service ${
                              this.state.temp_data.service_type === "Telephone Number"
                                  ? "Number"
                                  : this.state.temp_data.service_type === "Website Link"
                                  ? `Link (Don't Include "https://")`
                                  : "Link"
                          }`}
                          value={this.state.temp_data.service_link}
                          onChange={this.handleTempChange}
                          placeholder={`Service ${
                              this.state.temp_data.service_type === "Telephone Number"
                                  ? "Number"
                                  : "Link"
                          }`}
                          error={this.state.errors.service_link}
                      />
                      <Form.Input
                          name="button_text"
                          label="Button Replacement Text (Optional)"
                          value={this.state.temp_data.button_text}
                          onChange={this.handleTempChange}
                          placeholder="Replacement Text"
                          error={this.state.errors.button_text}
                      />
                  </React.Fragment>
              )}
              <Form.Button
                width="2"
                icon="plus"
                style={{ marginTop: "23px" }}
                color="green"
                onClick={() => this.handleTempAdd(this.state.temp_data)}
              />
            </Form.Group>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Link</Table.HeaderCell>
                  <Table.HeaderCell>Replacement Text</Table.HeaderCell>
                  <Table.HeaderCell>Options</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.data.service_resources.length !== 0 ? (
                  this.renderBody()
                ) : (
                  <Table.Row
                    style={
                      this.state.errors.service_resources
                        ? { background: "#fff6f6", color: "#9f3a38" }
                        : {}
                    }
                  >
                    <Table.Cell colSpan="4">No Resources Created</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            {this.state.service_icon_url !== "" ? (
              <Segment
                basic
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Image
                  src={AppConfig.api_server + this.state.service_icon_url}
                  size="medium"
                  className="pointer preview-image"
                  onClick={() => {
                    this.setState({ upload_file: true });
                  }}
                />
              </Segment>
            ) : this.state.loading === false &&
              this.state.service_icon_url === "" ? (
              <Form.Button
                fluid
                color="green"
                content="Upload Icon"
                icon="image"
                onClick={() => {
                  this.setState({ upload_file: true });
                }}
              />
            ) : (
              <Segment padded="very" basic>
                <Loader active />
              </Segment>
            )}
            <Form.Checkbox
              label="Archived"
              name="archived"
              checked={this.state.data.archived}
              onClick={() => {
                this.setState({
                  data: {
                    ...this.state.data,
                    archived: !this.state.data.archived,
                  },
                });
              }}
            />
            <Form.Group widths="equal">
              <Form.Button
                floated="left"
                color="red"
                onClick={() => this.props.history.push(`/settings/services`)}
              >
                <Icon name="close" />
                Cancel
              </Form.Button>
              <Form.Button
                floated="right"
                color="green"
                onClick={() => this.handleSubmit()}
                loading={this.state.submit_load}
              >
                <Icon name="plus" />
                Submit
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default editServiceForm;
