import React, { Component } from "react";
import { Segment, Header, Loader } from "semantic-ui-react";
import List from "../../common/lists/emailAutomationsList ";

export default class index extends Component {
  state = {
    refreshList: false,
  };

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Email Automations Management
        </Header>
        <Segment className="border">
          {this.state.refreshList === false ? (
              <List
                allowAdd={false}
              />
          ) : (
            <Segment basic>
              <Loader active />
            </Segment>
          )}
        </Segment>
      </Segment>
    );
  }
}
