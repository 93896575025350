import React, { Component } from "react";
import { Menu, Header } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { FaListUl, FaPlus } from "react-icons/fa";

export default class EmployeeMenu extends Component {
  state = {
    activeItem: "dash",
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  render() {
    const { activeItem } = this.state;
    return (
      <Menu className="subMenu">
        <Menu.Item>
          <Header>Employee Menu</Header>
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to={"/employee"}
          exact
          name="list"
          active={activeItem === "list"}
          onClick={this.handleItemClick}
        >
          <FaListUl />
          List
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to={"/employee/add"}
          exact
          name="add"
          active={activeItem === "add"}
          onClick={this.handleItemClick}
        >
          <FaPlus />
          Add
        </Menu.Item>
      </Menu>
    );
  }
}
