import React from "react";
import {SemanticToastContainer} from "react-semantic-toasts";
import logo from "./assets/images/logo.png";
import {AuthenticationWrapper} from "./lib/sso";
import AppConfig from "./_appConfig";
import FrontendUpdateChecker from "./components/common/FrontendUpdateChecker";
import TopMasquerade from "./components/common/topMasquerade";
import Index from "./components";
import "semantic-ui-css/semantic.min.css";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import "./main.css";

class App extends React.Component {
  render(){
    return (
      <AuthenticationWrapper
        logo={logo}
        config={{
          applicationServer: AppConfig.api_server, // required during local development
          localPrefix: AppConfig.localStoragePrefix,
          clientId: AppConfig.auth.clientId,
          loginServer: AppConfig.auth.loginServer
        }}
        dynamicScopes={{}}
        maxIdleTime={AppConfig.auth.sessionTimeout}
        preWarningTime={2}
      >
        <TopMasquerade/>
        <SemanticToastContainer position="top-right"/>
        <FrontendUpdateChecker/>
        <Index/>
      </AuthenticationWrapper>
    );
  }
}
export default App;