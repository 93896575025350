import React, { Component } from "react";
import {
  Table,
  Message,
  Segment,
  Button,
  Icon,
  Popup,
} from "semantic-ui-react";

class settingsBenefitList extends Component {
  renderBody() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((data) => {
        return (
          <Table.Row
            className="pointer"
            key={data._id}
            onClick={() => {
              this.props.action(data._id);
            }}
          >
            <Table.Cell>{data.title}</Table.Cell>
            <Table.Cell>
              {data.notes !== undefined || data.notes !== ""
                ? data.notes
                : "N/A"}
            </Table.Cell>
            <Table.Cell>{data.payment_frequency}</Table.Cell>
            <Table.Cell>{data.category_of_cover}</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  render() {
    if (!this.props.data || this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add New Benefit
              </Button>
            )}
          </Segment>
        </Message>
      );
    }

    return (
      <Table celled selectable singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Notes</Table.HeaderCell>
            <Table.HeaderCell>Payment Frequency</Table.HeaderCell>
            <Table.HeaderCell>Category Of Cover</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              <Popup
                position="top center"
                trigger={
                  <Button positive icon onClick={this.props.add}>
                    <Icon name="plus" />
                  </Button>
                }
                content={"Add Benefit"}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderBody()}</Table.Body>
      </Table>
    );
  }
}

export default settingsBenefitList;
