import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Icon,
  Menu,
  Modal,
  Select,
  Divider,
  Table,
  Button,
  Loader,
  Message,
  Form,
  Radio,
  Confirm,
  Label,
  TextArea,
} from "semantic-ui-react";
import { DateTimeInput } from "semantic-ui-calendar-react";
import api from "../../../actions/api";
import { Link } from "react-router-dom";
import CancelReferralForm from "./forms/cancelReferralForm";
import UploadFileForm from "../common/forms/uploadFile";
import AppConfig from "../../../_appConfig";
import { Authentication } from "../../../lib/sso";
import FileManagerList from "../common/lists/fileManagerList";
import CaseNotesList from "../common/lists/caseNotes";
import moment from "moment";
import { DateInput } from "semantic-ui-calendar-react";
import PaginateWrapper from "../common/OLD_PaginateWrapper";
import TestList from "../common/lists/testList";
import { toast } from "react-semantic-toasts";
import _ from "lodash";
import AdditionalQuestions from "./_components/additionalQuestions";

export default class Assessment extends Component {
  state = {
    referral: {},
    activeItem: "progression",
    editAssessment: false,
    assignStatusModal: false,
    addCM: false,
    addNM: false,
    cancelModal: false,
    refreshNotes: false,
    uploadFileModal: "",
    referral_default_link: null,
    consent_default_link: null,
    referral_form_link: null,
    consent_form_link: null,
    CM: {
      data: [],
      formList: [],
    },
    NM: {
      data: [],
      formList: [],
    },
    add_test: {},
    data: {
      referral: this.props.match.params.id,
      employee: "",
      company: "",
      cm: "",
    },
    settings: {},
    assessmentStatuses: [],
    files: [],
    folder: [],
    folders: [],
    path: "",
    filtered: [],
    ref_notes: [],
    APPSteps: [],

    form_modal: "",
    form_title: "",
    iframe_modal: "",
    iframe_url: "",
    form_data: {},
    form_errors: {},
    decision_errors: {},
    company_forms: [],
    forms_progression: [],
    form_submitted: false,
    submittedSteps: 0,
    decision_option: {},
    test_results: [],
    addTestSubmitted: false,
    addTestError: null,
    sendingNotification: false,

    booking_modal: "",
    booking_form: {},
    booked_slot: "",
    booking_data: {},
    booking_slots: [],
    booking_services: [],
    selected_date: "",
    selected_slot: false,
    booking_date: null,
    booking_step: null,

    ref_type_list: [],  
    ref_reason_list: [],  
    ref_condition_list: [], 
  };

  componentDidMount() {
    this._getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.referral !== prevState.referral) {
      this.setState({
        data: {
          ...this.state.data,
          employee: this.state.referral.employee._id,
          company: this.state.referral.employee.company,
        },
      });
    }
  }

  _getData = () => {
    this.setState({ refreshNotes: true });
    Promise.all([
      api.assessment.getAssessment(this.props.match.params.id),
      // api.company.getClinicians(1),
      api.team.getNurseManagers(),
      // api.templates.getDefaultLinks(),
      api.assessment.notes.getAssessmentNotes(this.props.match.params.id),
      api.settings.all(),
    ])
      .then((res) => {
        this.loadFolders(res[0].assFolder, true);

        if (res[0].company) {
          let forms_progression = [];

          api.forms
            .getCompanyForms(res[0].company._id)
            .then((company_forms) => {
              console.log(
                "🚀 ~ file: show.js ~ line 115 ~ Referral ~ .then ~ company_forms",
                company_forms
              );
              company_forms.map((f) => {
                let form_data = res[0].form_data.filter((e) => e.form == f._id);

                if (form_data && form_data[0]) {
                  forms_progression.push({
                    form: f._id,
                    submitted: form_data[0].submitted,
                  });
                }
              });

              this.setState({ company_forms, forms_progression });
            });
        }

        api.company
          .getStatuses(res[0].company ? res[0].company._id : null)
          .then((statuses) => {
            this.setState({ assessmentStatuses: statuses });
          });

        api.company
          .getClinicians(res[0].company ? res[0].company._id : null)
          .then((clinicians) => {
            this.setState({ CM: clinicians });
          });

        api.company
          .getAPPSteps(res[0].company ? res[0].company._id : null)
          .then((APPSteps) => {
            let currentSteps = [
              { title: "Outcome", type: "Decision" },
              { title: "Health Journey Documents", type: "Documents" },
              { title: "Health Journey Report", type: "Report" },
              { title: "Test Results", type: "Tests" },
            ];
            this.setState({
              APPSteps: currentSteps.concat(APPSteps.reverse()),
            });
            let submittedSteps = 0;
            let form_data = res[0].form_data;
            let iframe_data = res[0].iframe_data;
            currentSteps
              .concat(APPSteps)
              .filter((e) => e.archived !== true)
              .map((step, index) => {
                let progress = "";

                let data = null;

                if (step.type === "form") {
                  data = form_data.filter((e) => {
                    return e.form == step.form;
                  });
                  if (data.length > 0) {
                    if (data[0].submitted) {
                      progress = "submitted";
                    } else {
                      progress = "incomplete";
                    }
                  } else {
                    progress = "not started";
                  }
                } else if (step.type === "iframe") {
                  data = iframe_data.filter((e) => {
                    return e.iframe == step.title;
                  });
                  if (data.length > 0) {
                    if (data[0].submitted) {
                      progress = "submitted";
                    } else {
                      progress = "incomplete";
                    }
                  } else {
                    progress = "not started";
                  }
                } else if (step.type === "Tests") {
                  if (this.state.referral.numbTests > 0) {
                    submittedSteps++;
                  }
                } else if (step.type === "Documents") {
                  if (this.state.referral.numbFiles > 0) {
                    submittedSteps++;
                  }
                } else if (step.type === "Decision") {
                  if (
                    this.state.referral.status &&
                    this.state.referral.status !== "New"
                  ) {
                    submittedSteps++;
                  }
                }

                if (progress === "submitted") {
                  submittedSteps++;
                }
              });

            this.setState({ submittedSteps });
          });

        this.setState({
          referral: res[0],
          // CM: res[1],
          NM: res[1],
          refreshNotes: false,
          filtered: res[2],
          ref_notes: res[2],
          settings: res[3],

          ref_type_list: res[3].refTypes.map((item, i) => {
            return { text: item, value: item, key: i };
          }),
          ref_reason_list: res[3].refReasons.map((item, i) => {
            return { text: item, value: item, key: i };
          }),
          ref_condition_list: res[3].refConditions.map((item, i) => {
            return { text: item, value: item, key: i };
          }),
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ refreshNotes: false });
      });
  };


  getNotes = (pageNum, pageSize, search, filters) => {
    return api.referral.notes.listAllPaged(this.props.match.params.id, {
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  handleMenuClick = (e, { name }) => {
    if (name === "progression" && this.state.activeItem !== "progression") {
      this._getData();
    }
    this.setState({ activeItem: name });
  };

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  gotoReferral = (id) => {
    if (id) {
      this.props.history.push(`/referral/${id}`);
    }
  };

  assignNM = () => {
    let data = this.state.data;

    api.assessment
      .editAssessment(this.state.referral._id, { cm: data.nm })
      .then(() => {
        this._getData();
        this.setState({ addNM: false });
      });

    /*

    if (this.state.referral.case) {
      api.case
        .editCase(this.state.referral.case._id, { cm: data.nm })
        .then(() => {
          this.setState({
            addNM: false,
          });
          this._getData();
        });
    } else {
      api.case
        .addCase({
          referral: data.referral,
          employee: data.employee,
          company: data.company,
          cm: data.nm,
        })
        .then((res) => {
          this.setState({
            addNM: false,
          });
          this._getData();
        });
    } */
  };

  getTests = (pageNum, pageSize, search, filters) => {
    return api.assessment.listTestsPaged(this.props.match.params.id, {
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  renderAddTest() {
    const handleClose = () => {
      if (this.state.addTestSuccessful) {
        this.setState({ activeItem: "progression" });
        this._getData();
      }

      this.setState({ addTest: false, addTestSuccessful: false });
    };

    function objToString(obj) {
      return Object.entries(obj).reduce((str, [p, val]) => {
        return `${str}${p}: ${val}\n`;
      }, "");
    }

    const handleSubmit = () => {
      //validation

      //barcode: /^5000[0-9]{9}$/'

      let barcode = this.state.add_test.barcode;

      if (barcode && barcode.match(/^5000[0-9]{9}$/)) {
        this.state.form_errors.barcode = false;

        this.setState({ addTestSubmitted: true });
        api.assessment
          .addAssessmentTest(this.props.match.params.id, this.state.add_test)
          .then((res) => {
            if (res.success) {
              this.setState({
                addTestSuccessful: true,
                addTestSubmitted: false,
                data: {
                  ...this.state.data,
                  test_assessment: null,
                  test_type: null,
                },
              });
            } else {
              this.setState({
                addTestError: res.error,
              });
            }
          })
          .catch((e) => {
            console.log(
              "🚀 ~ file: company.js ~ line 790 ~ CompanyPage ~ handleSubmit ~ e",
              e
            );
          });
      } else {
        let form_errors = { ...this.state.form_errors, barcode: true };
        this.setState({ form_errors });
      }
    };

    return (
      <Modal
        open={this.state.addTest}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="medkit" content="Submit Test" />
        <Modal.Content>
          <Segment>
            <p>
              <strong>
                Test for Patient:{" "}
                {this.state.referral && this.state.referral.employee && (
                  <>
                    {this.state.referral.employee.firstName}{" "}
                    {this.state.referral.employee.lastName} (
                    {this.state.referral.employee.dob &&
                      moment(this.state.referral.employee.dob).format(
                        "DD/MM/YYYY"
                      )}
                    )
                  </>
                )}
              </strong>
            </p>
          </Segment>
          {this.state.addTestSuccessful ? (
            <Segment placeholder>
              <Header icon>
                <Icon name="check circle outline" color="green" />
                Your test has been sent for the Lab to process. Check for the
                Results using Collect Results button <br /> <br />
                <Button onClick={handleClose} content="Close" />
              </Header>
            </Segment>
          ) : this.state.addTestSubmitted ? (
            this.state.addTestError ? (
              <Segment placeholder>
                <Header icon>
                  <Icon name="exclamation triangle" color="red" />
                  Error: Their was an error submitting your test! <br /> <br />{" "}
                  {objToString(this.state.addTestError)} <br /> <br />
                  <Button
                    onClick={() => {
                      this.setState({
                        addTestSubmitted: false,
                        addTestError: {},
                      });
                    }}
                    content="Try Again"
                  />
                </Header>
              </Segment>
            ) : (
              <Segment placeholder>
                <Header icon>
                  <Icon loading name="circle notch" color="blue" />
                  Submitting your Test to the lab, please wait for a response on
                  if the submission was successful or if their was an error.
                </Header>
              </Segment>
            )
          ) : (
            <Segment>
              <Form>
                <Form.Input
                  label="Barcode"
                  name="barcode"
                  error={
                    this.state.form_errors.barcode &&
                    "Incorrect format of barcode! Example of correct format: 5000008797150"
                  }
                  placeholder={"5000008797150"}
                  value={this.state.add_test.barcode}
                  onChange={(event, { name, value }) => {
                    this.setState({
                      add_test: { ...this.state.add_test, [name]: value },
                    });
                  }}
                />
                <DateTimeInput
                  label="Sample Taken"
                  autoComplete="off"
                  animation="off"
                  name="taken"
                  popupPosition={"bottom left"}
                  dateTimeFormat="YYYY/MM/DD HH:mm"
                  dateFormat="YYYY/MM/DD"
                  closable={true}
                  disableMinute={false}
                  placeholder="Select Date"
                  value={
                    this.state.add_test.taken
                      ? moment(this.state.add_test.taken).format(
                          "YYYY/MM/DD HH:mm"
                        )
                      : ""
                  }
                  iconPosition="left"
                  onChange={(e, data) => {
                    this.setState({
                      add_test: {
                        ...this.state.add_test,
                        [data.name]: data.value,
                      },
                    });
                  }}
                />
                <Form.Dropdown
                  label="Gender"
                  placeholder="Select Gender"
                  name="gender"
                  value={this.state.add_test.gender}
                  onChange={(e, data) => {
                    this.setState({
                      add_test: {
                        ...this.state.add_test,
                        [data.name]: data.value,
                      },
                    });
                  }}
                  options={
                    this.state.referral.company &&
                    this.state.referral.company.genders
                      ? this.state.referral.company.genders.map((item) => {
                          return { text: item.description, value: item.id };
                        })
                      : []
                  }
                  search
                  selection
                  // error={this.state.errors.groups}
                />
                <Form.Dropdown
                  label="Test Type"
                  placeholder="Select Test Type"
                  name="type"
                  value={this.state.add_test.type}
                  onChange={(e, data) => {
                    this.setState({
                      add_test: {
                        ...this.state.add_test,
                        [data.name]: data.value,
                      },
                    });
                  }}
                  options={
                    this.state.referral.company &&
                    this.state.referral.company.sampleTypes
                      ? this.state.referral.company.sampleTypes.map((item) => {
                          return { text: item.name, value: item.id };
                        })
                      : []
                  }
                  search
                  selection
                  // error={this.state.errors.groups}
                />
                <Form.Dropdown
                  label="Specimen Type"
                  placeholder="Select Specimen"
                  name="specific"
                  value={this.state.add_test.specific}
                  onChange={(e, data) => {
                    this.setState({
                      add_test: {
                        ...this.state.add_test,
                        [data.name]: data.value,
                      },
                    });
                  }}
                  options={
                    this.state.referral.company &&
                    this.state.referral.company.specifics
                      ? this.state.referral.company.specifics.map((item) => {
                          return { text: item.name, value: item.id };
                        })
                      : []
                  }
                  search
                  selection
                  // error={this.state.errors.groups}
                />
                <Form.Group widths="equal">
                  <Form.Button floated="left" onClick={handleClose} negative>
                    <Icon name="close" />
                    Cancel
                  </Form.Button>
                  <Form.Button
                    floated="right"
                    onClick={handleSubmit}
                    color="green"
                    disabled={
                      !this.state.add_test.barcode ||
                      !this.state.add_test.taken ||
                      !this.state.add_test.type ||
                      !this.state.add_test.specific
                    }
                  >
                    <Icon name="plus" />
                    Submit
                  </Form.Button>
                </Form.Group>
              </Form>
            </Segment>
          )}
        </Modal.Content>
      </Modal>
    );
  }

  renderCollectResults() {
    const handleClose = () => {
      if (this.state.collectResultsSuccessful) {
        this.setState({ activeItem: "progression" });
        this._getData();
      }

      this.setState({ collectResults: false, collectResultsSuccessful: false });
    };

    return (
      <Modal
        open={this.state.collectResults}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="file" content="Test Results" />
        <Modal.Content>
          {/*  
          <AddEmployeeForm
            submit={handleSubmit}
            close={handleClose}
            emailError={this.state.emailError}
          />*/}

          <Segment>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Barcode</Table.HeaderCell>
                  <Table.HeaderCell>Test Taken At</Table.HeaderCell>
                  <Table.HeaderCell>Result</Table.HeaderCell>
                  <Table.HeaderCell>Result At</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.test_results.length > 0 ? (
                  this.state.test_results.map((result, index) => {
                    return (
                      <Table.Row key={"results_" + index}>
                        <Table.Cell>{result.testNum}</Table.Cell>
                        <Table.Cell>#</Table.Cell>
                        <Table.Cell>{result.barcode}</Table.Cell>
                        <Table.Cell>{result.taken}</Table.Cell>
                        <Table.Cell>
                          {result.result_received ? (
                            <>
                              Result Received{" "}
                              {/*  
                              <Button
                                color="orange"
                                onClick={() => {
                                  //download
                                  api.files
                                    .getFilelink(result.result_file)
                                    .then((res) => {
                                      window.open(
                                        AppConfig.api_server + res,
                                        "_blank"
                                      );
                                    });
                                }}
                              >
                                <Icon name="file" /> View Result
                              </Button>*/}
                            </>
                          ) : (
                            "No Results Found"
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {result.result_date ? result.result_date : "-"}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan="6">No Tests</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  renderTests() {
    return (
      <Segment>
        {this.renderAddTest()}
        <p>
          Results ({this.state.referral.numbResults}/
          {this.state.referral.numbTests})
        </p>
        <Button positive onClick={() => this.setState({ addTest: true })}>
          <Icon name="file" /> Add Test
        </Button>
        <Button
          loading={this.state.loading_results}
          color="orange"
          onClick={() => {
            this.setState({ loading_results: true });
            api.assessment
              .getTestResults(this.props.match.params.id)
              .then((res) => {
                this.setState({
                  collectResults: true,
                  test_results: res.results,
                  loading_results: false,
                  collectResultsSuccessful: true,
                });
              });
          }}
        >
          <Icon name="file" /> Collect/View Results
        </Button>
        <PaginateWrapper
          dataQuery={this.getTests}
          render={(items) => (
            <TestList
              allowAdd
              add={() => this.setState({ addTest: true })}
              data={items}
              showCM={true}
            />
          )}
        />
      </Segment>
    );
  }

  renderMenu() {
    const { activeItem, referral } = this.state;
    let canView =
      Authentication.can("navigator_admin.permission") ||
      Authentication.can("organisation_clinician.permission");

    return (
      <Menu className="subMenu" stackable>
        {canView &&
          (referral.status !== "Cancelled" ? (
            <Menu.Item
              name="notes"
              onClick={() =>
                this.props.history.push(
                  `/assessment/${this.state.referral._id}/addnote`
                )
              }
            >
              <Icon name="plus" /> Add Health Journey Note&nbsp;
            </Menu.Item>
          ) : (
            <React.Fragment />
          ))}
        <Menu.Item
          name="progression"
          active={activeItem === "progression"}
          onClick={this.handleMenuClick}
        >
          Progression
        </Menu.Item>
        <Menu.Item
          name="tests"
          active={activeItem === "tests"}
          onClick={this.handleMenuClick}
        >
          Tests
        </Menu.Item>
        {canView && (
          <Menu.Item
            name="notes"
            active={activeItem === "notes"}
            onClick={this.handleMenuClick}
          >
            Notes
          </Menu.Item>
        )}
        {canView && (
          <Menu.Item
            name="documents"
            active={activeItem === "documents"}
            onClick={this.handleMenuClick}
          >
            Health Journey Documents
          </Menu.Item>
        )}

        {referral.status !== "Cancelled" && (
          <React.Fragment>
            {referral && referral.cm ? (
              <Menu.Item onClick={() => this.setState({ addNM: true })}>
                <Icon name="medkit" />
                Change Clinician
              </Menu.Item>
            ) : (
              <Menu.Item onClick={() => this.setState({ addNM: true })}>
                <Icon name="medkit" />
                Assign Clinician
              </Menu.Item>
            )}
          </React.Fragment>
        )}
        {canView && (
          <Menu.Item
            // disabled={this.state.submittedSteps < this.state.APPSteps.length}
            onClick={() =>
              this.setState({
                assignStatusModal: true,
                data: {
                  ...this.state.data,
                  status: this.state.referral.status,
                  lifestyle_advice: this.state.referral.lifestyle_advice,
                  decision_referral_notes:
                    this.state.referral.decision_referral_notes,
                  private_note: this.state.referral.private_note,
                  decision_referral_sent_date:
                    this.state.referral.decision_referral_sent_date,
                },
              })
            }
            style={
              // this.state.submittedSteps < this.state.APPSteps.length
              // ? { color: "#000" }
              // : {}
              {}
            }
          >
            <Icon name="tags" />
            {this.state.referral.status &&
              this.state.referral.status !== "New" &&
              "Modify "}{" "}
            Health Journey Outcome
          </Menu.Item>
        )}
        {/*  
        <Menu.Item
          disabled={
            !this.state.referral.status || this.state.referral.status === "New"
          }
          onClick={() => {
            api.assessment
              .notifyPatient(this.props.match.params.id)
              .then(() => {
                this._getData();
                //toast
              });
          }}
          style={
            !this.state.referral.status || this.state.referral.status === "New"
              ? { color: "#000 !important" }
              : {}
            // {}
          }
        >
          <Icon name="mail" />
          Notify Patient
        </Menu.Item>*/}
      </Menu>
    );
  }

  renderAssignStatus() {
    const closeModal = () => {
      this.setState({ assignStatusModal: false });
    };

    const updateAssessment = () => {
      api.assessment
        .editAssessment(this.props.match.params.id, {
          status: this.state.data.status,
          lifestyle_advice: this.state.data.lifestyle_advice,
          decision_referral_notes: this.state.data.decision_referral_notes,
          decision_referral_sent_date:
            this.state.data.decision_referral_sent_date,
        })
        .then(() => {
          closeModal();
          this._getData();
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const validate = (data) => {
      const errors = {};
      if (!data.status) errors.status = "Can't be empty";

      if (this.state.decision_option && this.state.decision_option.required) {
        if (!data.decision_referral_notes)
          errors.decision_referral_notes =
            "Descision Option requires this data";
      }

      if (!data.decision_referral_sent_date)
        errors.decision_referral_sent_date = "Can't be empty";

      return errors;
    };

    const outcomeSubmit = (data) => {
      handleUpload(data, true);
    };

    const outcomeSave = (data) => {
      handleUpload(data);
    };

    const handleUpload = (data, submit = false) => {
      console.log("🚀 ~ file: show.js ~ line 919 ~ Assessment ~ handleUpload ~ data", data)
      if (data && data.file) {
        api.assessment
          .uploadAssessmentFile(this.props.match.params.id, {
            type: "decision_document",
            file: data.file,
            description: data.description,
            selectedFile: data.selectedFile,
            private_note: data.private_note
          }).then((res) => {
            console.log("🚀 ~ file: show.js ~ line 927 ~ Assessment ~ handleUpload ~ res", res)
            
          })
          .catch((e) => {
            console.log(e);
          });
      }

      let decision_errors = validate(this.state.data);

      this.setState({ decision_errors });

      if (Object.keys(decision_errors).length === 0) {
        let edit = {
          status: this.state.data.status,
          lifestyle_advice: this.state.data.lifestyle_advice,
          private_note: this.state.data.private_note,
          decision_referral_notes: this.state.data.decision_referral_notes,
          decision_referral_sent_date:
            this.state.data.decision_referral_sent_date,

          ref_type: this.state.data.ref_type,
          ref_reason: this.state.data.ref_reason,
          ref_condition: this.state.data.ref_condition,
        };

        if (submit) {
          edit.status_submitted = true;
          this.setState({ generating_report: true });
          edit.completed = true;
          /*
          api.assessment
            .notifyPatient(this.props.match.params.id)
            .then((res) => {
              this.setState({ sendingNotification: false });
              toast({
                type: "success",
                icon: "mail",
                title: <h3>Patient Notified</h3>,
                description: (
                  <span>
                    <p>Successfully notified patient</p>
                  </span>
                ),
                time: 4000,
              });
            })
            .catch((e) => {
              console.log("notify errors", e);
            });*/
          api.assessment
            .generateReport(this.props.match.params.id)
            .then((res) => {
              this.setState({ generating_report: false });
              if (res.success) {
                toast({
                  type: "success",
                  icon: "file",
                  title: <h3>Report Generated</h3>,
                  description: (
                    <span>
                      <p>Successfully generated report for Assessment</p>
                    </span>
                  ),
                  time: 4000,
                });
                this._getData();
              }
            })
            .catch((e) => {
              console.log("report errors", e);
            });
        }

        api.assessment
          .editAssessment(this.props.match.params.id, edit)
          .then(() => {
            closeModal();
            this._getData();
          })
          .catch((e) => {
            console.log("errors", e);
          });
      }
    };

    let curent_option = this.state.assessmentStatuses.find(
      (e) => e.title === this.state.referral.status
    );

    let has_new_ref = false;

    if(this.state.decision_option && this.state.decision_option.action === "new"){
      has_new_ref = true;
    }else if(curent_option && curent_option.action === "new"){
      has_new_ref = true;
    }

    return (
      <Modal
        open={this.state.assignStatusModal}
        onClose={closeModal}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="tags" content={`Outcome for A${this.state.referral.assNum}`} />
        <Modal.Content>
          <Segment>
            <Form>
              <Form.Group widths="equal">
                <Form.Dropdown
                  name="status"
                  label="Outcome Options"
                  value={this.state.data.status}
                  onChange={(e, data) => {
                    let option = this.state.assessmentStatuses.find(
                      (e) => e.title === data.value
                    );

                    if(option.action === "new"){
                      has_new_ref = true;
                    }

                    this.setState({
                      data: { ...this.state.data, [data.name]: data.value },
                      decision_option: option,
                    });
                  }}
                  placeholder="Please Select Outcome Option"
                  options={
                    this.state.assessmentStatuses
                      ? this.state.assessmentStatuses.map((item) => {
                          let data =
                            typeof item === "object" ? item.title : item;
                          return { value: data, text: data };
                        })
                      : []
                  }
                  selection
                  error={this.state.decision_errors.status}
                />
              </Form.Group>
              {has_new_ref &&
              <Form.Group widths="equal">
               <Form.Dropdown
                  error={this.state.decision_errors.type}
                  name="ref_type"
                  label="Referral Type"
                  value={this.state.data.ref_type ? this.state.data.ref_type : this.state.referral.referral ? this.state.referral.referral.type : ""}
                  onChange={this.handleDropdownChange}
                  placeholder="Please Select Referral Type"
                  options={this.state.ref_type_list}
                  selection
                  search
                  noResultsMessage={"Could Not Find Any Referral Types"}
                />
                <Form.Dropdown
                  error={this.state.decision_errors.reason}
                  name="ref_reason"
                  label="Referral Reason"
                  value={this.state.data.ref_reason ? this.state.data.ref_reason : this.state.referral.referral ? this.state.referral.referral.reason : ""}
                  onChange={this.handleDropdownChange}
                  placeholder="Please Select Referral Reason"
                  options={this.state.ref_reason_list}
                  selection
                  search
                  noResultsMessage={"Could Not Find Any Referral Reasons"}
                />
                <Form.Dropdown
                  error={this.state.decision_errors.condition}
                  name="ref_condition"
                  label="Referral Condition"
                  value={this.state.data.ref_condition ? this.state.data.ref_condition : this.state.referral.referral ? this.state.referral.referral.condition : ""}
                  onChange={this.handleDropdownChange}
                  placeholder="Please Condition"
                  options={this.state.ref_condition_list}
                  selection
                  search
                  noResultsMessage={"Could Not Find Any Conditions"}
                />
              </Form.Group> }
              <Form.Group widths="equal">
                <Form.Checkbox
                  name="lifestyle_advice"
                  label="Lifestyle Advice"
                  checked={this.state.data.lifestyle_advice}
                  onChange={() =>
                    this.setState({
                      data: {
                        ...this.state.data,
                        lifestyle_advice: !this.state.data.lifestyle_advice,
                      },
                    })
                  }
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.TextArea
                  required={
                    this.state.decision_option &&
                    this.state.decision_option.required
                  }
                  name="decision_referral_notes"
                  label="Notes"
                  onChange={(e) => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        [e.target.name]: e.target.value,
                      },
                    });
                  }}
                  value={this.state.data.decision_referral_notes}
                  error={this.state.decision_errors.decision_referral_notes}
                />
              </Form.Group>
              <Form.Group>
              <Form.Checkbox
                  name="private_note"
                  label="Make Notes Private"
                  checked={this.state.data.private_note}
                  onChange={() =>
                    this.setState({
                      data: {
                        ...this.state.data,
                        private_note: !this.state.data.private_note,
                      },
                    })
                  }
                />
              </Form.Group>
              <Form.Group widths="equal">
                <DateTimeInput
                  required
                  label="Date/Time"
                  autoComplete="off"
                  animation="off"
                  name="decision_referral_sent_date"
                  popupPosition={"bottom left"}
                  dateTimeFormat="YYYY/MM/DD HH:mm"
                  dateFormat="YYYY/MM/DD"
                  closable={true}
                  disableMinute={false}
                  placeholder="Select Date and Time"
                  value={
                    this.state.data.decision_referral_sent_date
                      ? moment(
                          this.state.data.decision_referral_sent_date
                        ).format("YYYY/MM/DD HH:mm")
                      : ""
                  }
                  iconPosition="left"
                  onChange={this.handleDropdownChange}
                  error={this.state.decision_errors.decision_referral_sent_date}
                />
              </Form.Group>
              <label>
                <strong>Outcome File: </strong>
                <br />
                {this.state.referral.decision_document &&
                  "Currently uploaded: " +
                    this.state.referral.decision_document.filename.split(
                      "SCANNED__"
                    )[1]}
              </label>
              <UploadFileForm
                bypassEmpty
                bypassLoading={
                  Object.keys(this.state.decision_errors).length > 0
                }
                submit={outcomeSubmit}
                submitTitle={"Complete"}
                submitIcon={"tag"}
                secondarySubmit={outcomeSave}
                secondarySubmitTitle={"Save"}
                close={closeModal}
              />
              {/*  
              <Form.Group widths="equal">
                <Form.Button
                  type="submit"
                  color="red"
                  floated="left"
                  content="Cancel"
                  icon="close"
                  onClick={() => closeModal()}
                />
                <Form.Button
                  color="green"
                  floated="right"
                  content="Assign"
                  icon="tag"
                  onClick={() => updateAssessment()}
                />
              </Form.Group>*/}
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  renderUploadFile() {
    const close = () => {
      this.setState({ uploadFileModal: "" });
    };

    const handleSubmit = (data) => {
      api.assessment
        .uploadAssessmentFile(this.props.match.params.id, {
          type: this.state.uploadFileModal,
          file: data.file,
          description: data.description,
          selectedFile: data.selectedFile,
        })
        .then(() => {
          close();
          this._getData();
        })
        .catch((e) => {
          console.log(e);
        });
    };

    return (
      <Modal
        open={this.state.uploadFileModal !== ""}
        onClose={close}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="upload" content="Upload File" />
        <Modal.Content>
          <UploadFileForm submit={handleSubmit} close={close} />
        </Modal.Content>
      </Modal>
    );
  }

  renderCancelAssessment() {
    const handleClose = () => this.setState({ cancelModal: false });

    const handleSubmit = (data) => {
      api.assessment
        .editAssessment(this.props.match.params.id, {
          ...this.state.data,
          cancellation_meta: data,
        })
        .then(() => {
          handleClose();
          this._getData();
        })
        .catch((e) => {
          console.log(e);
          handleClose();
        });
    };

    return (
      <Modal
        open={this.state.cancelModal}
        onClose={handleClose}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="close" content="Cancel Assessment" />
        <Modal.Content>
          <CancelReferralForm
            submit={handleSubmit}
            cancel={handleClose}
            data={
              this.state.referral.cancellation_meta !== undefined
                ? this.state.referral.cancellation_meta
                : {}
            }
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderAddClinician() {
    const { CM, data } = this.state;
    let disabled = true;
    if (data.nm !== "") {
      disabled = false;
    }
    let NMs = [];

    CM.formList.map((c) => {
      // if (this.state.referral.company._id === c.company) {
      NMs.push(c);
      // }
    });

    return (
      <Modal
        open={this.state.addNM}
        onClose={() => this.setState({ addNM: false })}
        centered={false}
        size="small"
        closeOnDimmerClick={true}
      >
        <Header icon="medkit" content="Assign Clinician" />
        <Modal.Content>
          <Segment textAlign="center">
            <Header>Pick Clinician</Header>
            <Select
              options={NMs.length > 0 ? NMs : []}
              name="nm"
              onChange={this.handleDropdownChange}
              value={data.nm}
              search
            />
            <Button
              positive
              onClick={this.assignNM}
              attached={"right"}
              disabled={disabled}
            >
              Update Clinician
            </Button>
            <Divider />
            <Header>Clinician List</Header>
            <Segment className={"scrollList1"} basic>
              <Table sortable striped selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Clinician</Table.HeaderCell>
                    <Table.HeaderCell>Active Cases</Table.HeaderCell>
                    <Table.HeaderCell>Closed Cases</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {CM.data.map((c) => {
                    // if (this.state.referral.company._id === c.company) {
                    return (
                      <Table.Row
                        key={c._id}
                        onClick={() =>
                          this.setState({
                            data: {
                              ...this.state.data,
                              nm: c._id,
                            },
                          })
                        }
                      >
                        <Table.Cell>
                          {c.firstName} {c.lastName}
                        </Table.Cell>
                        <Table.Cell>{c.ActiveCases}</Table.Cell>
                        <Table.Cell>{c.ClosedCases}</Table.Cell>
                      </Table.Row>
                    );
                    // }
                  })}
                </Table.Body>
              </Table>
            </Segment>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  handleClientFilter = (query) => {
    let updatedList = this.state.ref_notes;
    updatedList = updatedList.filter((item) => {
      return (
        item.logType.toLowerCase().search(query.search.toLowerCase()) !== -1 ||
        item.message.toLowerCase().search(query.search.toLowerCase()) !== -1
      );
    });
    this.setState({ filtered: updatedList });
  };

  renderNotes() {
    return (
      <Segment>
        {this.state.refreshNotes === false ? (
          <CaseNotesList
            logs={this.state.filtered}
            onQuery={this.handleClientFilter}
            action={(a) =>
              this.props.history.push(
                `/assessment/${this.props.match.params.id}/note/${a}`
              )
            }
            add={() =>
              this.props.history.push(`/assessment/${this.props.match.params.id}/addnote`)
            }
          />
        ) : (
          /*
          <PaginateWrapper
            dataQuery={this.getNotes}
            render={(items) => <NotesList action={(a) => this.props.history.push()} data={items} />}
          />*/
          <Segment basic padded>
            <Loader active />
          </Segment>
        )}
      </Segment>
    );
  }

  loadFolders(item, search = false, findFile) {
    this.setState({ loading: true });
    if (findFile) {
      api.files.searchFiles({ search: findFile }).then((res) => {
        this.setState({
          folders: [],
          files: res,
          loading: false,
        });
      });

      return true;
    }
    if (item) {
      api.files.getFolder(item).then((res) => {
        this.setState({
          files: res.files,
          folder: res.folder,
          folders: res.folders,
          loading: false,
        });
      });
      api.files.getFolderPath(item).then((res) => {
        this.setState({
          path: res,
        });
      });
    } else {
      api.files.getAll().then((res) => {
        this.setState({
          path: "/home",
          folder: null,
          folders: res.folders,
          files: res.files,
          loading: false,
        });
      });
    }
  }

  renderDocuments() {
    return (
      <Segment>
        <FileManagerList
          hideExisting
          rootFolder={""}
          assFolder={this.state.referral.assFolder}
          folder={this.state.folder}
          folders={this.state.folders}
          files={this.state.files}
          path={"/assessment"}
          loadFolders={(e, b, s) => this.loadFolders(e, b, s)}
        />
      </Segment>
    );
  }

  openProgressionForm(title, form, data = {}, form_submitted = false) {
    this.setState({
      form_title: title,
      form_modal: form,
      form_data: data,
      form_submitted,
    });
  }
  openProgressionIFrame(iframe, url, form_submitted = false) {
    this.setState({
      iframe_modal: iframe,
      iframe_url: url,
      iframe_submitted: form_submitted,
    });
  }

  openBookingModal(booking, step, submitted, data, booking_form) {
    this.setState({
      booking_step: step,
      booking_services: [],
      loading_services: true,
      booking_modal: booking,
      booking_submitted: submitted,
      booking_data: data ? data : {},
      selected_service: null,
      booking_form,
      booking_slot_selected_error: null,
      booking_customQuestions: []
    });
    api.bookings.getServices(booking_form.businessId).then(res => {
      this.setState({
        booking_services: res.map((s) => {
          return {
            id: s.id,
            name: s.displayName,
            customQuestions: s.customQuestions
          };
        }),
        loading_services: false,
      });
      this._getData();
    });
  }

  renderIframeModal() {
    const close = (submit = false) => {
      if (!submit) {
        this.setState({
          iframe_modal: "",
          iframe_url: "",
          iframe_submitted: false,
        });
      } else {
        api.assessment
          .editAssessment(this.props.match.params.id, {
            iframe_data: {
              iframe: this.state.iframe_modal,
              iframe_url: this.state.iframe_url,
              submitted: true,
              submittedAt: moment(),
            },
          })
          .then(() => {
            this.setState({
              iframe_modal: "",
              iframe_url: "",
              iframe_submitted: false,
            });
            this._getData();
          })
          .catch((e) => {
            console.log("error", e);
          });
      }
    };

    return (
      <Modal open={this.state.iframe_modal !== ""} centered={true} size="large">
        <Modal.Header>
          {this.state.iframe_modal}
          <Button content={"Close"} floated="right" onClick={() => close()} />
          {!this.state.iframe_submitted && (
            <Button
              content={"Completed"}
              floated="right"
              onClick={() => close(true)}
            />
          )}
        </Modal.Header>
        <Modal.Content>
          <p>
            Please schedule a booking then click on the Completed button to
            return to the App.
          </p>
          <iframe
            width="100%"
            style={{ height: "80vh" }}
            src={
              this.state.iframe_url.indexOf("http") !== -1
                ? this.state.iframe_url
                : // : ""
                  "https://" + this.state.iframe_url
            }
          ></iframe>
        </Modal.Content>
      </Modal>
    );
  }

  renderBookingModal() {
    const close = (submit = false, extra_data = {}) => {
      if (!submit) {
        this.setState({
          booking_modal: "",
          booking_form: {},
          booking_submitted: false,
          booking_slots: [],
          selected_date: null,
          selected_slot: null,
          booking_date: null,
          booking_step: null
        });
      } else {
        this.setState({ loading_book: true });
        api.assessment
          .editAssessment(this.props.match.params.id, {
            booking_data: {
              extra_data,
              booking_form: this.state.booking_form,
              selected_date: this.state.booking_date,
              additional_info: this.state.booking_info,
              selected_slot: this.state.booking_slots[this.state.selected_slot] && this.state.booking_slots[this.state.selected_slot].startAt,
              submitted: true,
              submittedAt: moment(),
              booking_step: this.state.booking_step,
              booking_customQuestions: this.state.booking_customQuestions,
            },
          })
          .then((res) => {
            if (res.success) {
              toast({
                type: "success",
                icon: "calendar",
                title: <h3>Booking Successful</h3>,
                description: (
                  <span>
                    <p>Successfully booked patient on selected day</p>
                  </span>
                ),
                time: 4000,
              });

              this.setState({
                booking_modal: "",
                booking_data: {},
                selected_date: null,
                selected_slot: null,
                booking_submitted: false,
                booking_slots: [],
                booking_date: null,
                loading_book: false,
              });
              this._getData();
            } else {
              toast({
                type: "error",
                icon: "calendar",
                title: <h3>Booking Error</h3>,
                description: (
                  <span>
                    <p>An error occurred whilst trying to book appointment. Error: {res.message}</p>
                  </span>
                ),
                time: 4000,
              });
            }
          })
          .catch((e) => {
            console.log("error", e);
            if(e.isAxiosError){
              this.setState({
                booking_slot_selected_error: e.response.data.message,
                loading_book: false
              });
            } else {
              this.setState({
                booking_slot_selected_error: "An unknown error has occurred, please close the app and try again.",
                loading_book: false
              });
            }
          });
      }
    };

    let booking_data = this.state.booking_data;

    let service = {
      customQuestions: []
    };
    const serviceFilter = this.state.booking_services.filter(a => a.id === this.state.booking_form.serviceId);
    if(serviceFilter[0] !== undefined){
      service = serviceFilter[0];
    }

    return (
      <Modal
        dimmer
        open={this.state.booking_modal !== ""}
        centered={true}
        size="large"
      >
        <Modal.Header>
          {this.state.booking_modal}
          <Button content={"Close"} floated="right" onClick={() => close()} />
        </Modal.Header>
        <Modal.Content>
          <Segment>
            <Grid style={{ marginBottom: "10px" }}>
              <Grid.Row>
                <Grid.Column>
                  {booking_data && booking_data.selected_slot ? 
                  "This is your booking." : 
                  "Please schedule a booking then click on the Book button to book slot."}
                  {!booking_data.selected_slot && (
                    <Button
                      floated="right"
                      loading={this.state.loading_book}
                      disabled={!this.state.booking_date || isNaN(this.state.selected_slot) || this.state.selected_slot == null || !this.state.booking_customQuestionsValid}
                      content={"Book"}
                      onClick={() => close(true)}
                    />
                  )}

                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  {booking_data && booking_data.selected_slot && (
                    <>
                      <Header
                        content={`Booked for: ${moment(
                          this.state.booking_data.selected_slot
                        ).format("Do MMMM H:mm A")}`}
                      />
                      <Button
                        loading={this.state.loading_cancel}
                        onClick={() => {
                          let businessId =
                            booking_data.booking_form &&
                            booking_data.booking_form.businessId;

                          let serviceId =
                            booking_data.booking_form &&
                            booking_data.booking_form.serviceId;

                          if (
                            businessId &&
                            serviceId &&
                            booking_data.msc_booking
                          ) {
                            this.setState({ loading_cancel: true });

                            api.bookings
                              .cancelBooking(
                                businessId,
                                serviceId,
                                booking_data.msc_booking
                              )
                              .then((res) => {
                                this.setState({ loading_cancel: false });
                                if (res.success) {
                                  this.setState({
                                    loading_cancel: false,
                                  });

                                  close(true, { cancel_booking: true, /* serviceId: booking_data.booking_form.serviceId */ });
                                } else {
                                  //error
                                }
                              });
                          }
                        }}
                      >
                        <Icon name="times" /> Cancel Booking
                      </Button>
                      {booking_data.additional_info && (
                        <>
                          <Header as="h4">Additional Information:</Header>
                          <p>{booking_data.additional_info}</p>
                        </>
                      )}
                    </>
                  )}
                  <Header>
                    {this.state.booking_date && "Book for:"}
                    {this.state.booking_date &&
                      moment(this.state.booking_date, "DD/MM/YYYY").format(
                        "Do MMMM"
                      )}{" "}
                    {this.state.booking_slots[this.state.selected_slot] &&
                      moment(
                        this.state.booking_slots[this.state.selected_slot]
                          .startAt
                      ).format("H:mm A")}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Header>
                    <Icon name="cogs" /> Service:
                  </Header>
                  {this.state.loading_services && (
                    <>
                      <Icon name="refresh" loading size="large" /> Loading
                      Services
                    </>
                  )}
                  <Grid columns="equal" padded>
                    {this.state.booking_services.map((service, i) => {
                      // <Grid.Column key={"slot_" + i}>

                      let serviceId =
                        booking_data.booking_form &&
                        booking_data.booking_form.serviceId;

                      if (
                        booking_data &&
                        booking_data.selected_slot &&
                        serviceId !== service.id
                      ) {
                        return "";
                      }

                      return (
                        <Button
                          active={this.state.selected_service === service.id}
                          width="4"
                          style={{ margin: "5px" }}
                          onClick={() => {
                            this.setState({
                              selected_service: service.id,
                              booking_date: "",
                              selected_slot: null,
                              booking_form: {
                                ...this.state.booking_form,
                                serviceId: service.id,
                              },
                            });
                          }}
                        >
                          {service.name}
                        </Button>
                      );
                      // </Grid.Column>
                    })}
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              {!booking_data.selected_slot &&
                this.state.booking_form &&
                this.state.booking_form.serviceId && (
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Header>
                        <Icon name="calendar" /> Date:
                      </Header>
                      <DateInput
                        minDate={moment()}
                        inline
                        name="booking_date"
                        value={this.state.booking_date}
                        onChange={(event, { name, value }) => {
                          this.setState({
                            booking_date: value,
                            loading_slots: true,
                          });
                          let date = moment(value, "DD/MM/YYYY");
                          // let bussiness = "LiquidTest@liquid-computing.co.uk";
                          // let service = "6abc18b4-20e7-4940-ab35-dd31562cd130";

                          if (
                            this.state.booking_form &&
                            this.state.booking_form.businessId &&
                            this.state.booking_form.serviceId
                          ) {
                            api.bookings
                              .getAvailableSlots(
                                this.state.booking_form.businessId,
                                this.state.booking_form.serviceId,
                                date.format("YYYY-MM-DD")
                              )
                              .then((times) => {
                                let slots = times[0]
                                  ? times.filter((e) => e.staff.length > 0)
                                  : [];

                                this.setState({
                                  booking_slots: slots,
                                  loading_slots: false,
                                  selected_slot: null,
                                });
                              });
                          }
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Header>
                        <Icon name="clock" /> Time:
                      </Header>

                      {this.state.booking_slot_selected_error && <Message negative>
                        <Message.Header>Error</Message.Header>
                        {this.state.booking_slot_selected_error}
                      </Message>}

                      {this.state.loading_slots ? (
                        <>
                          <Icon name="refresh" loading size="large" /> Loading
                          Slots
                        </>
                      ) : this.state.booking_date ? (
                        this.state.booking_slots.length > 0 ? (
                          <Grid columns="equal" padded>
                            {this.state.booking_slots.map((slot, i) => (
                              // <Grid.Column key={"slot_" + i}>
                              <Button
                                active={this.state.selected_slot === i}
                                width="4"
                                style={{ margin: "5px" }}
                                onClick={() => {
                                  this.setState({ selected_slot: i });
                                }}
                              >
                                {moment(slot.startAt).format("H:mm A")}
                              </Button>
                              // </Grid.Column>
                            ))}
                          </Grid>
                        ) : (
                          <Header>
                            No slots avaliable on this day, please select a
                            different day !
                          </Header>
                        )
                      ) : (
                        <Header>Select a Day!</Header>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                )}
              {!booking_data.selected_slot && (
                <Grid.Row>
                  <Grid.Column>
                    <Form>
                      <AdditionalQuestions questions={service.customQuestions} onChange={(data, isValid) => {
                        if(JSON.stringify(this.state.booking_customQuestions) !== JSON.stringify(data)){
                          this.setState({
                            booking_customQuestions: data
                          })
                        }
                        if(this.state.booking_customQuestionsValid !== isValid){
                          this.setState({
                            booking_customQuestionsValid: isValid
                          })
                        }
                      }} />
                      <Form.Field
                        id="additional_info"
                        control={TextArea}
                        onChange={(e, { name, value }) => {
                          this.setState({
                            booking_info: value,
                          });
                        }}
                        label="Additional Information"
                        placeholder="Enter any additional information for the booking"
                      />
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
            {!booking_data.selected_slot && (
              <Button
                loading={this.state.loading_book}
                disabled={!this.state.booking_date || isNaN(this.state.selected_slot) || this.state.selected_slot == null || !this.state.booking_customQuestionsValid}
                content={"Book"}
                onClick={() => close(true)}
              />
            )}
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  handleDataChange = (event, { name, value }) => {
    this.setState({ form_data: { ...this.state.form_data, [name]: value } });
  };

  handleFormChange = (event) => {
    this.setState({
      form_data: {
        ...this.state.form_data,
        [event.target.name]: event.target.value,
      },
    });
  };

  renderFormModal() {
    const close = () => {
      this.setState({
        form_modal: "",
        form_data: {},
        form_errors: [],
        form_submitted: false,
      });
    };

    const errors = (data, form) => {
      const form_errors = {};

      if (form && form.fields) {
        form.fields.map((field) => {
          if (field.required && !field.archived) {
            if (field.type.name == "AuditSelect" && field.audit_group) {
              if (!data[`audit_${field.audit_group}`]) {
                form_errors[`audit_${field.audit_group}`] = {
                  [field.field_name]: "Required Field",
                };
              } else {
                if (
                  !data[`audit_${field.audit_group}`][field.field_name] ||
                  data[`audit_${field.audit_group}`][field.field_name] == ""
                ) {
                  form_errors[`audit_${field.audit_group}`][field.field_name] =
                    "Required Field";
                }
              }
            } else if (field.type.name == "TableChecklist") {
              //todo: check for radio buttons
            } else if (field.type.name == "BMI" || field.type.name == "BMIManual") {
              if (
                !data[`${field.field_name}_weight`] ||
                data[`${field.field_name}_weight`] == ""
              ) {
                form_errors[`${field.field_name}_weight`] = "Required Field";
              }
            } else if (field.type.name == "BloodPressure") {
              if (
                !data[`${field.field_name}_diastolic`] ||
                data[`${field.field_name}_diastolic`] == ""
              ) {
                form_errors[`${field.field_name}_diastolic`] = "Required Field";
              }
              if (
                !data[`${field.field_name}_systolic`] ||
                data[`${field.field_name}_systolic`] == ""
              ) {
                form_errors[`${field.field_name}_systolic`] = "Required Field";
              }
            } else {
              if (!data[field.field_name] || data[field.field_name] == "") {
                form_errors[field.field_name] = "Required Field";
              }
            }
          }
        });
      }

      this.setState({ form_errors });

      return form_errors;
    };

    const complete = () => {
      let data = this.state.form_data;
      let form = this.state.company_forms.filter(
        (e) => e._id == this.state.form_modal
      );

      if (form && form[0]) {
        if (!_.isEmpty(errors(data, form[0]))) {
          console.log(
            "🚀 ~ file: show.js ~ line 1526 ~ Assessment ~ complete ~ form_errors",
            this.state.form_errors,
            errors(data, form[0])
          );
          return;
        }

        this.setState({ complete_form_confirm: true });
      }
    };
    const save = () => {
      let data = this.state.form_data;
      let form = this.state.company_forms.filter(
        (e) => e._id == this.state.form_modal
      );

      if (form && form[0]) {
        // if (Object.keys(errors(data, form[0])).length !== 0) {
        // return;
        // }

        this.setState({ save_form_confirm: true });
      }
    };

    const submit = (submitted) => {
      let data = this.state.form_data;

      let form = this.state.company_forms.filter(
        (e) => e._id == this.state.form_modal
      );

      if (form && form[0]) {
        if (submitted) {
          if (!_.isEmpty(errors(data, form[0]))) {
            console.log("there were errors");
            return;
          }
        }
        api.assessment
          .editAssessment(this.props.match.params.id, {
            form_data: {
              form: form[0]._id,
              data,
              submitted,
              updatedAt: moment(),
              submittedAt: submitted ? moment() : null,
            },
          })
          .then(() => {
            this.setState({
              form_data: {},
              form_modal: "",
              save_form_confirm: false,
              complete_form_confirm: false,
            });
            close();
            this._getData();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };

    let form = this.state.company_forms.filter(
      (e) => e._id == this.state.form_modal
    );

    /*
    let form_data =
      this.state.data.form_data &&
      form[0] &&
      this.state.data.form_data.filter((e) => e.form == form[0]._id); 
      submitted = form_data && form_data[0] && form_data[0].submitted;
      
      */
    let submitted = this.state.form_submitted;

    return (
      <Modal
        dimmer
        open={this.state.form_modal !== ""}
        onClose={close}
        centered={true}
        closeIcon
        size="large"
        closeOnDimmerClick={true}
      >
        <Modal.Header>{this.state.form_title}</Modal.Header>
        {form && form[0] ? (
          <Modal.Content>
            <Segment>
              {submitted ? (
                <p>
                  Submitted Form, Read Only{" "}
                  {/*| Submitted At: {form_data[0].submittedAt}*/}
                </p>
              ) : this.state.form_data ? (
                <p>Incomplete Form, Started: </p>
              ) : (
                <p>Form not started</p>
              )}
              <Form>
                {form[0].fields
                  .filter((e) => !e.archived)
                  .sort((a, b) => {
                    return a.order_number - b.order_number;
                  })
                  .map((field) => {
                    switch (field.type.name) {
                      case "TextField":
                      case "NumberField":
                        return (
                          <Form.Input
                            type={
                              field.type.name == "NumberField"
                                ? "Number"
                                : "Text"
                            }
                            value={
                              this.state.form_data[field.field_name]
                                ? this.state.form_data[field.field_name]
                                : ""
                            }
                            disabled={submitted}
                            label={field.title}
                            onChange={this.handleFormChange}
                            name={field.field_name}
                            required={field.required}
                            error={this.state.form_errors[field.field_name]}
                          />
                        );
                      case "DateField":
                        return (
                          <DateInput
                            label={field.title}
                            name={field.field_name}
                            required={field.required}
                            autoComplete={"off"}
                            animation="off"
                            error={this.state.form_errors[field.field_name]}
                            value={
                              this.state.form_data[field.field_name]
                                ? this.state.form_data[field.field_name]
                                : ""
                            } /*
                          value={
                            this.state.form_data[field.field_name] !== undefined
                              ? this.state.form_data[field.field_name]
                              : form_data[0] &&
                                form_data[0].data &&
                                form_data[0].data[field.field_name]
                          } */
                            iconPosition="left"
                            onChange={this.handleDataChange}
                            placeholder="Select Date"
                            closable
                            dateFormat={"DD-MM-YYYY"}
                            disabled={submitted}
                          />
                        );
                      case "HTMLHeader":
                        return <Header as={"h4"} content={field.title} />;
                      case "BloodPressure":
                        return (
                          <React.Fragment>
                            <p>
                              <strong>
                                {field.title} {field.required && "*"}
                              </strong>
                            </p>
                            <Form.Input
                              name={`${field.field_name}_diastolic`}
                              onChange={this.handleDataChange}
                              label="Diastolic"
                              required={field.required}
                              error={
                                this.state.form_errors[
                                  `${field.field_name}_diastolic`
                                ]
                              }
                              type="Number"
                              value={
                                this.state.form_data[
                                  `${field.field_name}_diastolic`
                                ]
                                  ? this.state.form_data[
                                      `${field.field_name}_diastolic`
                                    ]
                                  : ""
                              }
                            />
                            <Form.Input
                              name={`${field.field_name}_systolic`}
                              onChange={this.handleDataChange}
                              label="Systolic"
                              required={field.required}
                              error={
                                this.state.form_errors[
                                  `${field.field_name}_systolic`
                                ]
                              }
                              type="Number"
                              value={
                                this.state.form_data[
                                  `${field.field_name}_systolic`
                                ]
                                  ? this.state.form_data[
                                      `${field.field_name}_systolic`
                                    ]
                                  : ""
                              }
                            />
                          </React.Fragment>
                        );
                      case "BMI":
                        let height = 0;
                        height =
                          this.state.referral.employee &&
                          this.state.referral.employee.meta &&
                          this.state.referral.employee.meta.height;
                        return (
                          <React.Fragment>
                            <p>
                              <strong>
                                {field.title} {field.required && "*"}
                              </strong>
                            </p>
                            <p>
                              Height(m): <strong>{height}</strong>
                              <br />
                              <i>Note: Height pulled from Personal Details </i>
                            </p>
                            <Form.Input
                              label="Weight(kg)"
                              name={`${field.field_name}_weight`}
                              required={field.required}
                              value={
                                this.state.form_data[
                                  `${field.field_name}_weight`
                                ]
                                  ? this.state.form_data[
                                      `${field.field_name}_weight`
                                    ]
                                  : ""
                              }
                              error={
                                this.state.form_errors[
                                  `${field.field_name}_weight`
                                ]
                              }
                              onChange={(event, { name, value }) => {
                                this.setState({
                                  form_data: {
                                    ...this.state.form_data,
                                    [name]: value,
                                    [`${field.field_name}_bmi`]:
                                      value / (height * height),
                                  },
                                });
                              }}
                            />
                            <Form.Input
                              label="BMI (generated)"
                              name={`${field.field_name}_bmi`}
                              value={
                                this.state.form_data[`${field.field_name}_bmi`]
                                  ? this.state.form_data[
                                      `${field.field_name}_bmi`
                                    ].toFixed(0)
                                  : this.state.form_data[
                                      `${field.field_name}_weight`
                                    ]
                                  ? (
                                      this.state.form_data[
                                        `${field.field_name}_weight`
                                      ] /
                                      (height * height)
                                    ).toFixed(0)
                                  : ""
                              }
                              disabled
                            />
                          </React.Fragment>
                        );

                      case "BMIManual":
                        let theHeight = this.state.form_data[
                          `${field.field_name}_height`
                        ]
                          ? this.state.form_data[`${field.field_name}_height`]
                          : 0;
                        return (
                          <React.Fragment>
                            <p>
                              <strong>
                                {field.title} {field.required && "*"}
                              </strong>
                            </p>
                            <Form.Input
                              label="Height(m)"
                              name={`${field.field_name}_height`}
                              required={field.required}
                              value={
                                this.state.form_data[
                                  `${field.field_name}_height`
                                ]
                                  ? this.state.form_data[
                                      `${field.field_name}_height`
                                    ]
                                  : ""
                              }
                              error={
                                this.state.form_errors[
                                  `${field.field_name}_height`
                                ]
                              }
                              onChange={(event, { name, value }) => {
                                this.setState({
                                  form_data: {
                                    ...this.state.form_data,
                                    [name]: value,
                                    [`${field.field_name}_bmi`]:
                                      value / (theHeight * theHeight),
                                  },
                                });
                              }}
                            />
                            <Form.Input
                              label="Weight(kg)"
                              name={`${field.field_name}_weight`}
                              required={field.required}
                              value={
                                this.state.form_data[
                                  `${field.field_name}_weight`
                                ]
                                  ? this.state.form_data[
                                      `${field.field_name}_weight`
                                    ]
                                  : ""
                              }
                              error={
                                this.state.form_errors[
                                  `${field.field_name}_weight`
                                ]
                              }
                              onChange={(event, { name, value }) => {
                                this.setState({
                                  form_data: {
                                    ...this.state.form_data,
                                    [name]: value,
                                    [`${field.field_name}_bmi`]:
                                      value / (theHeight * theHeight),
                                  },
                                });
                              }}
                            />
                            <Form.Input
                              label="BMI (generated)"
                              name={`${field.field_name}_bmi`}
                              value={
                                this.state.form_data[`${field.field_name}_bmi`]
                                  ? this.state.form_data[
                                      `${field.field_name}_bmi`
                                    ].toFixed(0)
                                  : this.state.form_data[
                                      `${field.field_name}_weight`
                                    ]
                                  ? (
                                      this.state.form_data[
                                        `${field.field_name}_weight`
                                      ] /
                                      (theHeight * theHeight)
                                    ).toFixed(0)
                                  : ""
                              }
                              disabled
                            />
                          </React.Fragment>
                        );

                      case "OptionSelect":
                        return (
                          <Form.Select
                            clearable
                            label={field.title}
                            name={field.field_name}
                            required={field.required}
                            onChange={this.handleDataChange}
                            error={this.state.form_errors[field.field_name]}
                            options={field.options.map((o) => {
                              return { text: o, value: o };
                            })}
                            disabled={submitted}
                            value={
                              this.state.form_data[field.field_name]
                                ? this.state.form_data[field.field_name]
                                : ""
                            }
                          />
                        );
                      case "AuditSelect":
                        return (
                          <Form.Select
                            clearable
                            label={field.title}
                            required={field.required}
                            disabled={submitted}
                            error={
                              this.state.form_errors[
                                `audit_${field.audit_group}`
                              ] &&
                              this.state.form_errors[
                                `audit_${field.audit_group}`
                              ][field.field_name]
                            }
                            value={
                              this.state.form_data[
                                `audit_${field.audit_group}`
                              ] &&
                              this.state.form_data[
                                `audit_${field.audit_group}`
                              ][field.field_name]
                                ? this.state.form_data[
                                    `audit_${field.audit_group}`
                                  ][field.field_name].value
                                : ""
                            }
                            onChange={(event, { name, value }) => {
                              let thepoint = 0;

                              field.options.map((o, index) => {
                                if (o == value) {
                                  thepoint =
                                    field.audit_points &&
                                    field.audit_points[0][
                                      `option_${index}_points`
                                    ];
                                }
                              });

                              let the_group = this.state.form_data[
                                `audit_${field.audit_group}`
                              ]
                                ? this.state.form_data[
                                    `audit_${field.audit_group}`
                                  ]
                                : {};

                              the_group[name] = {
                                value: value,
                                points: thepoint,
                              };

                              let current_total = 0;

                              if (
                                this.state.form_data[
                                  `audit_${field.audit_group}`
                                ]
                              ) {
                                Object.keys(the_group).map((f) => {
                                  let point = the_group[f];

                                  if (point.points) {
                                    current_total =
                                      current_total + parseInt(point.points);
                                  }
                                });
                              }

                              the_group.total = current_total;

                              this.setState({
                                form_data: {
                                  ...this.state.form_data,
                                  [`audit_${field.audit_group}`]: the_group,
                                },
                              });
                            }}
                            options={field.options.map((o, index) => {
                              let audit_point =
                                field.audit_points &&
                                field.audit_points[0][`option_${index}_points`];
                              return {
                                // text: o + " (" + audit_point + ")",
                                text: o,
                                value: o,
                              };
                            })}
                            name={field.field_name}
                          />
                        );
                      case "AuditTotal":
                        return (
                          <React.Fragment>
                            <p>
                              <strong>
                                Total:{" "}
                                {this.state.form_data[
                                  `audit_${field.audit_group}`
                                ]
                                  ? this.state.form_data[
                                      `audit_${field.audit_group}`
                                    ].total
                                  : 0}
                              </strong>
                            </p>
                          </React.Fragment>
                        );
                      case "TableChecklist":
                        return (
                          <React.Fragment>
                            <Table>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    {field.title}
                                  </Table.HeaderCell>
                                  {field.headers &&
                                    field.headers.map((item, index) => {
                                      let audit_point =
                                        field.audit_points &&
                                        field.audit_points[0][
                                          `option_${index}_points`
                                        ];
                                      return (
                                        <Table.HeaderCell
                                          key={`${field.field_name}_header_${index}`}
                                        >
                                          {/* {item} ({audit_point}) */}
                                          {item}
                                        </Table.HeaderCell>
                                      );
                                    })}
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {field.options &&
                                  field.options.map((item, index) => {
                                    let data_blob = item
                                      .toLowerCase()
                                      .replace(/[^a-zA-Z ]/g, "")
                                      .replace(/[0-9]/g, "")
                                      .replace(/\s/g, "_")
                                      .substr(0, 25);

                                    return (
                                      <Table.Row
                                        key={`${field.field_name}_row_${index}`}
                                      >
                                        <Table.Cell>{item}</Table.Cell>
                                        {field.headers &&
                                          field.headers.map((header, index) => {
                                            let audit_point =
                                              field.audit_points &&
                                              field.audit_points[0][
                                                `option_${index}_points`
                                              ];
                                            return (
                                              <Table.Cell
                                                key={`${field.field_name}_cell_${index}`}
                                              >
                                                <Radio
                                                  disabled={submitted}
                                                  value={header}
                                                  name={data_blob}
                                                  checked={
                                                    this.state.form_data[
                                                      field.field_name
                                                    ] &&
                                                    this.state.form_data[
                                                      field.field_name
                                                    ][data_blob] &&
                                                    this.state.form_data[
                                                      field.field_name
                                                    ][data_blob].text == header
                                                  }
                                                  onChange={() => {
                                                    let current_total = 0;

                                                    let fields = this.state
                                                      .form_data[
                                                      field.field_name
                                                    ]
                                                      ? this.state.form_data[
                                                          field.field_name
                                                        ]
                                                      : {};

                                                    fields[data_blob] = {
                                                      text: header,
                                                      points: audit_point,
                                                    };

                                                    if (
                                                      this.state.form_data[
                                                        field.field_name
                                                      ]
                                                    ) {
                                                      Object.keys(fields).map(
                                                        (f) => {
                                                          let point = fields[f];

                                                          if (point.points) {
                                                            current_total =
                                                              current_total +
                                                              parseInt(
                                                                point.points
                                                              );
                                                          }
                                                        }
                                                      );
                                                    }

                                                    fields.total =
                                                      current_total;

                                                    this.setState({
                                                      form_data: {
                                                        ...this.state.form_data,
                                                        [field.field_name]:
                                                          fields,
                                                      },
                                                    });
                                                  }}
                                                />
                                              </Table.Cell>
                                            );
                                          })}
                                      </Table.Row>
                                    );
                                  })}
                              </Table.Body>
                              <Table.Footer>
                                <Table.Row>
                                  <Table.HeaderCell
                                    colSpan={field.headers.length + 1}
                                  >
                                    Total:
                                    {this.state.form_data[field.field_name]
                                      ? this.state.form_data[field.field_name]
                                          .total
                                      : 0}
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Footer>
                            </Table>
                          </React.Fragment>
                        );
                    }
                  })}
              </Form>

              <br />

              {/* <Form.Actions> */}
              <Button onClick={() => close()}>Cancel</Button>
              {!submitted && (
                <>
                  <Confirm
                    open={this.state.save_form_confirm}
                    confirmButton={"Save"}
                    header={`Save Form`}
                    content={
                      <Segment basic textAlign={"center"}>
                        <h4>
                          Your current progress will be saved. The form will not
                          be completed and you can make further changes in
                          future. Click 'Save' to confirm or 'Cancel' to make
                          further changes.
                        </h4>
                      </Segment>
                    }
                    onCancel={() => {
                      this.setState({ save_form_confirm: false });
                    }}
                    onConfirm={() => submit(false)}
                  />
                  <Button onClick={() => save()}>Save</Button>
                  <Button onClick={() => complete()}>Complete</Button>
                </>
              )}
              <Confirm
                open={this.state.complete_form_confirm}
                confirmButton={"Submit as Complete"}
                header={`Complete Form`}
                content={
                  <Segment basic textAlign={"center"}>
                    <h4 style={{ color: "red" }}>
                      You <b>cannot</b> make any further changes once you
                      complete the form. Please ensure all details are correct.
                      Click 'Complete' to confirm form is complete or 'Cancel'
                      to make further changes.
                    </h4>
                  </Segment>
                }
                onCancel={() => {
                  this.setState({ complete_form_confirm: false });
                }}
                onConfirm={() => submit(true)}
              />
              {/* </Form.Actions> */}
            </Segment>
          </Modal.Content>
        ) : (
          <Modal.Content>No Form Found!</Modal.Content>
        )}
        <Modal.Actions></Modal.Actions>
      </Modal>
    );
  }

  renderSummary() {
    let form_data = this.state.referral.form_data;
    let iframe_data = this.state.referral.iframe_data;
    let booking_data = this.state.referral.booking_data;

    let canView =
      Authentication.can("navigator_admin.permission") ||
      Authentication.can("organisation_clinician.permission");

    return (
      <Segment>
        <Header
          as={"h4"}
          content={`Current Progression - ${(
            (this.state.submittedSteps / this.state.APPSteps.length) *
            100
          ).toFixed(0)}% (${this.state.submittedSteps}/${
            this.state.APPSteps.length
          })`}
        />
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Step</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Progression</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.referral.company &&
              this.state.APPSteps &&
              this.state.APPSteps.filter((e) => e.archived !== true).map(
                (step, index) => {
                  let progress = "";

                  let data = [];

                  if (step.type === "form") {
                    data = form_data.filter((e) => {
                      return e.form == step.form;
                    });
                    if (data.length > 0) {
                      if (data[0].submitted) {
                        progress = "submitted";
                      } else {
                        progress = "incomplete";
                      }
                    } else {
                      progress = "not started";
                    }
                  } else if (step.type === "iframe") {
                    data = iframe_data.filter((e) => {
                      return e.iframe == step.title;
                    });
                    if (data.length > 0) {
                      if (data[0].submitted) {
                        progress = "submitted";
                      } else {
                        progress = "incomplete";
                      }
                    } else {
                      progress = "not started";
                    }
                  } else if (step.type === "booking" && booking_data) {

                    data = booking_data.filter((e) => {

                      return e && e.booking_step == step._id;

                      return e
                        ? e.booking_form
                          ? e.booking_form.businessId ==
                              step.booking_form.businessId && !e.cancelled
                          : []
                        : [];
                    });

                    // let lastItem = filtered[filtered.length - 1];
                    // data = lastItem ? [lastItem] : [];

                    if (data.length > 0 && data[0]) {
                      if (data[0].submitted) {
                        progress = "submitted";
                      } else {
                        progress = "incomplete";
                      }
                    } else {
                      progress = "not started";
                    }
                  } else if (step.type === "Decision") {
                    progress =
                      this.state.referral.status &&
                      this.state.referral.status !== "New"
                        ? "has-decision"
                        : "no-decision";
                  } else if (step.type === "Tests") {
                    progress =
                      this.state.referral.numbTests > 0
                        ? "has-tests"
                        : "no-tests";
                  } else if (step.type === "Documents") {
                    progress =
                      this.state.referral.numbFiles > 0
                        ? "has-documents"
                        : "no-documents";
                  } else if (step.type === "Report") {
                    progress = this.state.referral.report_form
                      ? "has-report"
                      : "no-report";
                  }

                  let report_name = this.state.referral.report_form
                    ? this.state.referral.report_form.filename
                    : "";

                  let report_name_split = report_name.split("__");

                  return (
                    <Table.Row key={`APPStep_${index}`}>
                      <Table.Cell>{step.title}</Table.Cell>
                      <Table.Cell>
                        {step.type} {/*step.url*/}
                      </Table.Cell>
                      <Table.Cell>
                        {progress === "submitted" && (
                          <>
                            <Icon name="check" color="green" /> Completed by{" "}
                            {data[0].clinician_saved ? "Clinician" : "Client"}
                          </>
                        )}
                        {progress === "incomplete" && (
                          <>
                            <Icon name="clock" color="blue" /> In Progress by{" "}
                            {data[0].clinician_saved ? "Clinician" : "Client"}
                          </>
                        )}
                        {progress === "not started" && (
                          <>
                            <Icon name="times" color="red" /> Not Started
                          </>
                        )}

                        {step.type === "booking" &&
                          data[0] &&
                          data[0].selected_date && (
                            <>
                              <br />
                              Booked for: {data[0] &&
                                data[0].selected_date} At:{" "}
                              {data[0] &&
                                moment(data[0].selected_slot).format("H:mm A")}
                            </>
                          )}

                        {progress === "has-decision" && (
                          <>
                            <Icon name="check" color="green" /> Outcome
                            <br />
                            Lifestyle Advice:{" "}
                            {this.state.referral.lifestyle_advice
                              ? "Yes"
                              : "No"}
                            {" | "}
                            {this.state.referral.decision_referral_sent_date &&
                              "Sent " +
                                moment(
                                  this.state.referral
                                    .decision_referral_sent_date
                                ).format("DD/MM/YYYY HH:mm")}
                            {this.state.referral.decision_referral_notes &&
                              " + Notes "}
                            {this.state.referral.private_note &&
                              "(Private)"}
                            {this.state.referral.decision_document &&
                              " + File "}
                          </>
                        )}
                        {progress === "no-decision" && (
                          <>
                            <Icon name="times" color="red" /> No Outcome
                          </>
                        )}

                        {progress === "has-tests" && (
                          <>
                            <Icon name="check" color="green" />{" "}
                            {this.state.referral.numbTests} Test
                            {this.state.referral.numbTests > 1 && "s"} {" - "}{" "}
                            {this.state.referral.numbResults} Result
                            {this.state.referral.numbTests > 1 && "s"}
                          </>
                        )}
                        {progress === "no-tests" && (
                          <>
                            <Icon name="times" color="red" /> No Tests
                          </>
                        )}

                        {progress === "has-documents" && (
                          <>
                            <Icon name="check" color="green" />{" "}
                            {this.state.referral.numbFiles} Document
                            {this.state.referral.numbFiles > 1 && "s"}
                          </>
                        )}
                        {progress === "no-documents" && (
                          <>
                            <Icon name="times" color="red" /> No Documents
                          </>
                        )}

                        {progress === "has-report" && (
                          <>
                            <Icon name="check" color="green" /> Has Report (
                            {/* {this.state.referral.report_form.filename} */}
                            {report_name_split[report_name_split.length - 1]})
                          </>
                        )}
                        {progress === "no-report" && (
                          <>
                            <Icon name="times" color="red" /> No Report Uploaded
                          </>
                        )}
                      </Table.Cell>
                      {canView || step.org_admin ? (
                        <Table.Cell>
                          {step.type === "form" && (
                            <Button
                              color="blue"
                              // disabled={progress === "submitted"}
                              onClick={() =>
                                this.openProgressionForm(
                                  step.title,
                                  step.form,
                                  data[0] && data[0].data,
                                  data[0] && data[0].submitted
                                )
                              }
                            >
                              <Icon name="file" /> View Form
                            </Button>
                          )}
                          {step.type === "booking" && (
                            <Button
                              color="blue"
                              // disabled={progress === "submitted"}
                              onClick={() =>
                                this.openBookingModal(
                                  step.title,
                                  step._id,
                                  data[0] && data[0].submitted,
                                  data[0],
                                  step.booking_form
                                )
                              }
                            >
                              <Icon name="file" /> View Booking
                            </Button>
                          )}
                          {step.type === "Decision" && (
                            <>
                              <Button
                                color="blue"
                                // disabled={progress === "submitted"}
                                onClick={() =>
                                  this.setState({ 
                                    assignStatusModal: true, 
                                    data: {
                                      ...this.state.data,
                                        status: this.state.referral.status !== "New" ? this.state.referral.status : "",
                                        lifestyle_advice: this.state.referral.lifestyle_advice,
                                        decision_referral_notes: this.state.referral.decision_referral_notes,
                                        private_note: this.state.referral.private_note,
                                        decision_referral_sent_date: this.state.referral.decision_referral_sent_date,
                                    } 
                                  })
                                }
                              >
                                <Icon name="tags" />{" "}
                                {this.state.referral.status &&
                                  this.state.referral.status !== "New" &&
                                  "Modify "}
                                Health Journey Outcome
                              </Button>
                              {this.state.referral.status &&
                                this.state.referral.status !== "New" && (
                                  <Button
                                    loading={this.state.sendingNotification}
                                    disabled={this.state.sendingNotification}
                                    color="blue"
                                    onClick={() => {
                                      this.setState({
                                        sendingNotification: true,
                                      });
                                      api.assessment
                                        .notifyPatient(
                                          this.props.match.params.id
                                        )
                                        .then((res) => {
                                          this.setState({
                                            sendingNotification: false,
                                          });
                                          this._getData();
                                          toast({
                                            type: "success",
                                            icon: "mail",
                                            title: <h3>Patient Notified</h3>,
                                            description: (
                                              <span>
                                                <p>
                                                  Successfully notified patient
                                                </p>
                                              </span>
                                            ),
                                            time: 4000,
                                          });
                                        });
                                    }}
                                    icon="mail"
                                    content="Notify Patient"
                                  />
                                )}
                              <Button
                                icon="check"
                                content={`Mark ${
                                  this.state.referral.completed
                                    ? "Incompleted"
                                    : "Completed"
                                }`}
                                onClick={() =>
                                  api.assessment
                                    .editAssessment(
                                      this.props.match.params.id,
                                      {
                                        completed:
                                          !this.state.referral.completed,
                                      }
                                    )
                                    .then(() => {
                                      this._getData();
                                      toast({
                                        type: "success",
                                        icon: "check",
                                        title: <h3>Mark Completed</h3>,
                                        description: (
                                          <span>
                                            <p>Complettion Mark Updated</p>
                                          </span>
                                        ),
                                        time: 4000,
                                      });
                                    })
                                }
                              />
                            </>
                          )}
                          {step.type === "Documents" && (
                            <Button
                              color="blue"
                              onClick={() =>
                                this.setState({ activeItem: "documents" })
                              }
                            >
                              <Icon name="file" /> View Files
                            </Button>
                          )}
                          {step.type === "Report" && (
                            <>
                              <Button
                                loading={this.state.generating_report}
                                color="purple"
                                onClick={() => {
                                  this.setState({ generating_report: true });
                                  api.assessment
                                    .generateReport(this.props.match.params.id)
                                    .then((res) => {
                                      if (res.success) {
                                        toast({
                                          type: "success",
                                          icon: "file",
                                          title: <h3>Report Generated</h3>,
                                          description: (
                                            <span>
                                              <p>
                                                Successfully generated report
                                                for Assessment
                                              </p>
                                            </span>
                                          ),
                                          time: 4000,
                                        });
                                        this._getData();
                                      }
                                      this.setState({
                                        generating_report: false,
                                      });
                                    });
                                  //generate
                                }}
                                icon="file"
                                content="Generate Report"
                              />
                              {/*  
                              <Button
                                color="blue"
                                onClick={() =>
                                  this.setState({
                                    uploadFileModal: "report_form",
                                  })
                                }
                              >
                                <Icon name="upload" /> Upload{" "}
                                {this.state.referral.report_form && "New"}{" "}
                                Report
                              </Button>*/}
                              {this.state.referral.report_form && (
                                <>
                                  <Button
                                    color="orange"
                                    onClick={() => {
                                      //download
                                      api.files
                                        .getFilelink(
                                          this.state.referral.report_form._id
                                        )
                                        .then((res) => {
                                          window.open(
                                            AppConfig.api_server + res,
                                            "_blank"
                                          );
                                        });
                                    }}
                                  >
                                    <Icon name="file" /> View Report
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                          {step.type === "Tests" && (
                            <Button
                              color="blue"
                              onClick={() =>
                                this.setState({ activeItem: "tests" })
                              }
                            >
                              <Icon name="file" /> View Tests
                            </Button>
                          )}
                        </Table.Cell>
                      ) : <Table.Cell></Table.Cell>}
                    </Table.Row>
                  );
                }
              )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }

  render() {
    const { activeItem } = this.state;
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="no-marg pageTitle">
          Health Journey A{this.state.referral.assNum}
        </Header>
        {this.renderBookingModal()}
        {this.renderAssignStatus()}
        {this.renderAddClinician()}
        {this.renderCancelAssessment()}
        {this.renderUploadFile()}
        <Segment basic className="no-marg no-pad">
          <Segment basic className="no-pad">
            <Grid stackable>
              <Grid.Row stretched>
                <Grid.Column width={8}>
                  <Grid className="no-marg dataGrid" celled>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Patient:</Header>
                        {this.state.referral.employee && (
                          <span>
                            <Link
                              to={`/employee/${this.state.referral.employee._id}`}
                            >
                              {this.state.referral.employee.firstName}{" "}
                              {this.state.referral.employee &&
                                this.state.referral.employee.lastName}
                            </Link>
                          </span>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Patient Notified:</Header>
                        {this.state.referral.patient_notified
                          ? moment(this.state.referral.patient_notified).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : "Patient has not been notified yet"}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Company: </Header>
                        {this.state.referral.company && (
                          <span>
                            <Link
                              to={`/company/${this.state.referral.company._id}`}
                            >
                              {this.state.referral.company.name}
                            </Link>
                          </span>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Outcome:</Header>
                        <span>{this.state.referral.status}</span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Completed:</Header>
                        <span>
                          {this.state.referral.completed ? (
                            <Icon name="check" />
                          ) : (
                            <Icon name="times" />
                          )}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Clinician:</Header>
                        <span>
                          {this.state.referral.cm
                            ? this.state.referral.cm.firstName +
                              " " +
                              this.state.referral.cm.lastName
                            : "Not Assigned"}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                {/*  
                <Grid.Column width={6}>
                  <Grid className="no-marg dataGrid" celled>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Details:</Header>
                        <p style={{ textAlign: "justify" }}>
                          {this.state.referral.additional_details}{" "}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>*/}
                <Grid.Column width={8}>
                  <Grid
                    className={
                      this.state.referral.referral &&
                      this.state.referral.referral.refNum
                        ? "no-marg caseVGrid selectable"
                        : this.state.referral &&
                          this.state.referral.status === "Cancelled"
                        ? "no-marg dataGrid"
                        : "no-marg caseVGrid"
                    }
                    celled
                    onClick={() =>
                      this.state.referral.referral &&
                      this.gotoReferral(this.state.referral.referral._id)
                    }
                  >
                    {this.state.referral &&
                    this.state.referral.status !== "Cancelled" ? (
                      <Grid.Row>
                        <Grid.Column textAlign="center">
                          <Header className="no-pad no-marg">Referral #</Header>
                          <Header as={"h1"} className="no-pad no-marg">
                            {this.state.referral.referral &&
                            this.state.referral.referral.refNum
                              ? this.state.referral.referral.refNum
                              : "No Referral Created"}
                          </Header>
                          {this.state.referral.referral &&
                          this.state.referral.referral.refNum ? (
                            <React.Fragment>
                              <Header as={"h2"} className="no-pad no-marg">
                                {this.state.referral.referral &&
                                this.state.referral.referral.cm
                                  ? this.state.referral.referral.cm.firstName +
                                    " " +
                                    this.state.referral.referral.cm.lastName
                                  : "No Nurse Manager Assigned!"}
                              </Header>
                              <Header className="no-pad no-marg">&nbsp;</Header>
                            </React.Fragment>
                          ) : (
                            <React.Fragment />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    ) : (
                      <Grid.Column>
                        <Header>Cancellation Reason:</Header>
                        <p>
                          {this.state.referral.cancellation_meta &&
                          this.state.referral.cancellation_meta.reason
                            ? this.state.referral.cancellation_meta.reason
                            : "ERROR"}
                        </p>
                        <Header>Cancellation Details:</Header>
                        <p>
                          {this.state.referral.cancellation_meta &&
                          this.state.referral.cancellation_meta
                            .additional_details
                            ? this.state.referral.cancellation_meta
                                .additional_details
                            : "ERROR"}
                        </p>
                      </Grid.Column>
                    )}
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          {this.renderMenu()}
          {activeItem === "tests" && this.renderTests()}
          {activeItem === "tests" && this.renderCollectResults()}
          {activeItem === "progression" && this.renderSummary()}
          {activeItem === "progression" && this.renderFormModal()}
          {activeItem === "progression" && this.renderIframeModal()}
          {activeItem === "notes" && this.renderNotes()}
          {activeItem === "documents" && this.renderDocuments()}
        </Segment>
      </Segment>
    );
  }
}
