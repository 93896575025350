import React, {useEffect, useState} from "react";
import {Form} from "semantic-ui-react";


const AdditionalQuestions = ({questions, onChange}) => {
  const [questionResponse, setQuestionResponse] = useState({});

  useEffect(() => {
    let data = {};
    questions.forEach(question => {
      data[question.id] = {
        isRequired: question.isRequired,
        value: ""
      };
    });
    setQuestionResponse(data);
  }, [questions]);

  useEffect(() => {
    let validationPassed = true;

    Object.keys(questionResponse).map(a => questionResponse[a]).filter(a => a.isRequired).forEach(b => {
      if(b.value.trim().length <= 0) {
        validationPassed = false;
      }
    });

    onChange(questionResponse, validationPassed);
  }, [questionResponse]);

  const handleTextChange = e => {
    e.persist();
    setQuestionResponse(a => {
      return {
        ...a,
        [e.target.name]: {
          ...a[e.target.name],
          value: e.target.value
        }
      }
    });
  }
  const handleDropdownChange = (e, {name, value}) => {
    setQuestionResponse(a => {
      return {
        ...a,
        [name]: {
          ...a[name],
          value
        }
      }
    });
  }

  return (
    <>{questions.map(question => {
      const currentValue = questionResponse[question.id] !== undefined ? questionResponse[question.id].value : "";

      return (
        <Form.Field key={question.id}>
          <label>{question.displayName}{question.isRequired ? " [Required]" : ""}</label>
          {question.answerInputType.toLowerCase() === "text" && <Form.Input name={question.id} value={currentValue} onChange={handleTextChange} />}
          {question.answerInputType.toLowerCase() === "radiobutton" && <Form.Select
            fluid
            name={question.id}
            value={currentValue}
            onChange={handleDropdownChange}
            options={question.answerOptions.map(b => {
              return {
                key: b,
                text: b,
                value: b
              }
            })}
          />}
        </Form.Field>
      );
    })}</>
  );
}
export default AdditionalQuestions;