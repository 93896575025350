import React, { Component } from "react";
import { Segment, Modal, Header, Loader } from "semantic-ui-react";
import api from "../../../../../actions/api";
import AddForm from "./forms/add";
import PaginateWrapper from "../../../common/OLD_PaginateWrapper";
import List from "../../../common/lists/resourceCategoryList";

class index extends Component {
  state = {
    addModal: false,
    refreshList: false,
  };

  getData = (pageNum, pageSize, search, filters) => {
    return api.resource_categories.listPaged({
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  renderAddModal() {
    const handleClose = () => this.setState({ addModal: false });

    const handleAdd = (data) => {
      this.setState({ refreshList: true });
      api.resource_categories
        .add(data)
        .then(() => {
          this.setState({
            addModal: false,
            refreshList: false,
          });
        })
        .catch((e) => {
          this.setState({ refreshList: false });
        });
    };

    return (
      <Modal
        open={this.state.addModal}
        onClose={handleClose}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="book" content="Add Resource Category" />
        <Modal.Content>
          <AddForm submit={handleAdd} close={handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Resource Cateogry List
        </Header>
        <Segment className="border">
          {this.renderAddModal()}
          {this.state.refreshList === false ? (
            <PaginateWrapper
              dataQuery={this.getData}
              search={true}
              active={"Archived"}
              render={(items) => (
                <List
                  data={items}
                  action={(data) => {
                    if (data) {
                      this.props.history.push(
                        `/settings/resource_categories/${data}/edit`
                      );
                    }
                  }}
                  add={() => this.setState({ addModal: true })}
                  allowAdd={true}
                />
              )}
            />
          ) : (
            <Segment basic>
              <Loader active />
            </Segment>
          )}
        </Segment>
      </Segment>
    );
  }
}

export default index;
