import React from 'react';

const Auth_Context = React.createContext({});

const Auth_Provider = ({value, children}) => {
  return (
    <Auth_Context.Provider value={value}>{children}</Auth_Context.Provider>
  );
}
const Auth_Receiver = (ChildComponent) => {
  return props => {
    return (
      <Auth_Context.Consumer>{a => <ChildComponent {...props} authentication={a} />}</Auth_Context.Consumer>
    );
  }
}

export {
  Auth_Receiver,
  Auth_Provider
};