import React, { Component } from "react";
import { Form, Segment, Icon } from "semantic-ui-react";
import PostCoder from "../../../common/postCoder";
import AppConfig from "../../../../../_appConfig";

var payment_frequency = [
  { text: "Monthly", value: "Monthly" },
  { text: "Quarterly", value: "Quarterly" },
  { text: "Annually", value: "Annually" },
];

var category_cover = [
  { text: "Individual", value: "Individual" },
  { text: "Dependents", value: "Dependents" },
];

export default class AddBenefitForm extends Component {
  state = {
    data: {
      title: "",
      insurer: "",
      allowance: "",
      address: {
        address: "",
        addressline1: "",
        addressline2: "",
        street: "",
        posttown: "",
        county: "",
        postcode: "",
      },
      payment_frequency: "",
      category_of_cover: "",
      notes: "",
    },
    submit_load: false,
    errors: {},
  };

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleDateChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  handleAddressChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        address: {
          ...this.state.data.address,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  validate = (data) => {
    const errors = {};
    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.insurer) errors.insurer = "Can't Be Empty";
    if (!data.allowance) errors.allowance = "Can't Be Empty";
    // if (!data.underwritten) errors.underwritten = "Can't Be Empty";
    if (!data.category_of_cover) errors.category_of_cover = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ submit_load: true });
      this.props.submit(this.state.data);
    }
  };

  addressUpdate = (addr) => {
    this.setState({
      data: {
        ...this.state.data,
        address: {
          address: addr.summaryline,
          addressline1: addr.addressline1,
          addressline2: addr.addressline2,
          street: addr.street,
          posttown: addr.posttown,
          county: addr.county,
          postcode: addr.postcode,
          latitude: addr.latitude,
          longitude: addr.longitude,
          organisation: addr.organisation,
        },
      },
    });
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="title"
              label="Benefit Title"
              value={this.state.data.title}
              onChange={this.handleChange}
              placeholder=""
              error={this.state.errors.title}
            />
            <Form.Input
              name="insurer"
              label="Insurer"
              value={this.state.data.insurer}
              onChange={this.handleChange}
              placeholder=""
              error={this.state.errors.insurer}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="allowance"
              label="Allowance"
              type="number"
              value={this.state.data.allowance}
              onChange={this.handleChange}
              placeholder=""
              error={this.state.errors.allowance}
            />
            {/* <Form.Input
              name="underwritten"
              label="Underwritten*"
              value={this.state.data.underwritten}
              onChange={this.handleChange}
              placeholder=""
              error={this.state.errors.underwritten}
            /> */}
            <Form.Dropdown
              name="payment_frequency"
              label="Payment Frequency"
              value={this.state.data.payment_frequency}
              onChange={this.handleDropdownChange}
              options={payment_frequency}
              selection
            />
            {/*
              <Form.Input
                name="hospital_scales"
                label="Hospital Scales*"
                value={this.state.data.hospital_scales}
                onChange={this.handleChange}
                placeholder=""
              />*/}
            <Form.Dropdown
              name="category_of_cover"
              label="Category Of Cover"
              value={this.state.data.category_of_cover}
              onChange={this.handleDropdownChange}
              options={category_cover}
              selection
              error={this.state.errors.category_of_cover}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Address Lookup</label>
              {/* <label>Please Use Demo Postcode: NR14 7PZ</label> */}
              <PostCoder
                apiKey={AppConfig.postcoder_api_key}
                addressSelectedCallback={this.addressUpdate}
                identifier={AppConfig.postcoder_identifier}
                country={"UK"}
                showAddress={false}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="addressline1"
              label="Address Line 1"
              value={this.state.data.address.addressline1}
              onChange={this.handleAddressChange}
              placeholder="Address Line 1"
              error={this.state.errors.addressline1}
            />
            <Form.Input
              name="addressline2"
              label="Address Line 2"
              value={this.state.data.address.addressline2}
              onChange={this.handleAddressChange}
              placeholder="Address Line 2"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="street"
              label="Street"
              value={this.state.data.address.street}
              onChange={this.handleAddressChange}
              placeholder="Street"
              error={this.state.errors.street}
            />
            <Form.Input
              name="posttown"
              label="Town / City"
              value={this.state.data.address.posttown}
              onChange={this.handleAddressChange}
              placeholder="Postal Town / City"
              error={this.state.errors.posttown}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="county"
              label="County"
              value={this.state.data.address.county}
              onChange={this.handleAddressChange}
              placeholder="County"
              error={this.state.errors.county}
            />
            <Form.Input
              name="postcode"
              label="Postcode"
              value={this.state.data.address.postcode}
              onChange={this.handleAddressChange}
              placeholder="Postcode"
              error={this.state.errors.postcode}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.TextArea
              name="notes"
              label="Benefit Notes"
              value={this.state.data.notes}
              onChange={this.handleChange}
              placeholder=""
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              floated="left"
              color="red"
              onClick={() => this.props.close()}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              floated="right"
              color="green"
              onClick={() => this.handleSubmit()}
              loading={this.state.submit_load}
            >
              <Icon name="plus" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
