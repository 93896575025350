import React, {Component} from "react";
import query_string from "query-string";
import {Loader, Message, Button, Segment, Header} from "semantic-ui-react";
import Bridge from "../bridge";
import Authentication from "../Authentication";
import API from "../actions/api";
import IdleTimeout from "./IdleTimeout";
import {Auth_Provider} from "../context/auth";

const defaultConfig = {
    loginServer: "/",
    applicationRoot: window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/"
};

class AuthenticationWrapper extends Component {
    constructor(props){
        super(props);
        this.state = {
            redirect: false,
            reload: false,

            processing: false,
            loading: true,
            loggedIn: false,
            error: false,
            idleLogout: false,
            user: {},
            access_token: ""
        };
        Bridge.setConfig({
            ...defaultConfig,
            ...props.config
        });
        Authentication.setComponent(this);
        Bridge.setContextHandlers(props.dynamicScopes || {})
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        Bridge.setConfig({
            ...defaultConfig,
            ...this.props.config
        }); // Just in case any of the config options were changed after we have already mounted the config
        Bridge.setContextHandlers(this.props.dynamicScopes || {})
    }
    componentDidMount(){
        let urlParams = query_string.parse(window.location.search);
        if (urlParams.code !== undefined){
            this.setState({
                processing: true
            }, () => {
                this.login(urlParams.code);
            });
            return;
        } else {
            this.setState({
                redirect: true
            });
        }
        this.checkLogin();
    }

    checkLogin(){
        let config = Bridge.getRAWConfig();
        let access_token = localStorage.getItem(config.localPrefix + "access_token");
        if (access_token !== undefined && access_token !== null){
            API.auth.user_details(access_token).then((res) => {
                Authentication.setUserData(res);
                Authentication.setUserScopes(res.scopes);
                Authentication.setAccessToken(access_token);
                localStorage.setItem(config.localPrefix + "access_token", access_token);
                try {
                    this.setState({
                        user: res,
                        loading: false,
                        loggedIn: true,
                        access_token: access_token
                    });
                } catch (e){
                    console.error(e);
                }
            }).catch((err) => {
                console.error(err);
                localStorage.removeItem(config.localPrefix + "access_token");
                this.setState({
                    loading: false,
                    loggedIn: false,
                    user: {},
                    access_token: ""
                });
            })
        } else {
            localStorage.removeItem(config.localPrefix + "access_token");
            this.setState({
                loading: false,
                loggedIn: false,
                user: {},
                access_token: ""
            });
        }
    }
    login(code){
        API.auth.exchange_code(code).then((res) => {
            API.auth.user_details(res.access_token).then((details) => {
                Authentication.setUserData(details);
                Authentication.setAccessToken(res.access_token);
                Authentication.setUserScopes(details.scopes);
                let config = Bridge.getRAWConfig();
                localStorage.setItem(config.localPrefix + "access_token", res.access_token);
                this.setState({
                    user: details,
                    loading: false,
                    processing: false,
                    loggedIn: true,
                    reload: true,
                    access_token: res.access_token
                });
            })
        }).catch((err) => {
            if (err.response){
                if (err.response.status === 401){
                    if (err.response.data.error === "invalid_code"){
                        this.setState({
                            loading: false,
                            loggedIn: false,
                            processing: false,
                            user: {},
                            access_token: "",
                            error: "Code Expired"
                        });
                        return;
                    } else if (err.response.data.error === "access_denied"){
                        this.setState({
                            loading: false,
                            loggedIn: false,
                            processing: false,
                            user: {},
                            access_token: "",
                            error: "Not Allowed"
                        });
                        return;
                    }
                }
                this.setState({
                    loading: false,
                    loggedIn: false,
                    processing: false,
                    user: {},
                    access_token: "",
                    error: "Network Issue"
                });
                return;
            }
            this.setState({
                loading: false,
                loggedIn: false,
                processing: false,
                user: {},
                access_token: "",
                error: "Unknown Issue"
            });
        });
    }
    logout(redirect = true){
      return new Promise(resolve => {
        API.auth.logout().then(() => {
          let config = Bridge.getRAWConfig();
          Authentication.setAccessToken(null);
          Authentication.setUserData(null);
          Authentication.setUserScopes([]);
          localStorage.removeItem(config.localPrefix + "access_token");
          this.setState({
            loading: true,
            loggedIn: false,
            user: {},
            access_token: ""
          }, () => {
            setTimeout(() => {
              if(redirect){
                window.location = config.loginServer + "/oauth/logout";
              } else {
                window.location = "/";
              }
            }, 1000);
          });
        }).catch((e) => {
          console.error(e);
          setTimeout(() => {
            window.location = "/";
          }, 1000);
        }).finally(() => resolve());
      })
    }
    switchUser(authCode){
      this.logout(false).then(() => {
        this.login(authCode);
      });
    }
    getLoginURL(){
        let config = Bridge.getRAWConfig();
        return config.loginServer + "/oauth/authorize?client_id=" + config.clientId + "&redirect_uri=" + config.applicationRoot + "&response_type=code&scope=profile";
    }

    /** Renders **/
    render(){
        let config = Bridge.getRAWConfig();
        if (this.state.reload){
            window.location = config.applicationRoot;
            return (
                <Loader size='large' active={true} indeterminate>Reloading Application...</Loader>
            );
        }
        if ((config.clientId === undefined || config.localPrefix === undefined || config.applicationServer === undefined) && process.env.NODE_ENV !== "production"){
            return (
                <Segment basic textAlign={"center"}>
                    <Message negative compact>
                        <Header as={"h2"}>Error</Header>
                        <br/>
                        There is a misconfiguration with the AuthenticationWrapper. Please ensure: 'clientId', 'localPrefix' & 'applicationServer' are set and passed via the config prop.
                    </Message>
                </Segment>
            );
        }
        if (this.state.error){
            if (this.state.error === "Not Allowed"){
                return (
                    <Segment basic textAlign={"center"}>
                        <Message negative compact>
                            <Header as={"h2"}>Access Denied</Header>
                            <br/>
                            Your account does not have the correct access rights to login into this application therefore access has been denied.
                            <br/><br/>
                            If you believe that you should have access to this application then please contact support.
                        </Message>
                        <br/>
                        <Button onClick={() => window.location = "/"} positive>Retry Login</Button>
                    </Segment>
                );
            } else if (this.state.error === "Code Expired"){
                return (
                    <Segment basic textAlign={"center"}>
                        <Message color={"yellow"} compact>
                            <Header as={"h2"}>Session Expired</Header>
                            <br/>
                            It appears that your login session has expired. You must re-authenticate before continuing.
                        </Message>
                        <br/>
                        <Button onClick={() => window.location = "/"} positive>Login</Button>
                    </Segment>
                );
            }
            return (
                <Segment basic textAlign={"center"}>
                    <Message negative compact>
                        <Header as={"h2"}>Service Error</Header>
                        <br/>
                        We were unable to log you into the application as this time. This could be due to a temporary server issue or a problem with your internet connection. Please try again in a few moments.
                        If the issue continues contact support.
                    </Message>
                    <br/>
                    <Button onClick={() => window.location = "/"} positive>Login</Button>
                </Segment>
            );
        }
        if (this.state.processing){
            return (
                <Loader size='large' active={true}>Processing Login...</Loader>
            );
        }
        if (this.state.loading){
            return (
                <Loader size='large' active={true}>Loading...</Loader>
            );
        }
        if(this.state.idleLogout){
            return (
                <Segment basic textAlign={"center"}>
                    <Message color={"yellow"} compact>
                        <Header as={"h2"}>Account Inactivity</Header>
                        <br/>
                        Due to account inactivity and to protect your account, we have logged you out. To log back in please click on the login button below.
                    </Message>
                    <br/>
                    <Button onClick={() => window.location = "/"} positive>Login</Button>
                </Segment>
            );
        }
        if (!this.state.loggedIn){
            if (this.state.redirect){
                window.location = this.getLoginURL();
                return (
                    <Loader size='large' active={true} indeterminate>Redirecting to Authentication Server...</Loader>
                );
            }
            return (
                <Loader size='large' active={true}>Processing Login...</Loader>
            );
        } else {
            return (
              <Auth_Provider value={{
                logout: (redirect = false) => this.logout(redirect),
                switchUser: (authCode) => this.switchUser(authCode),
                user: this.state.user
              }}>
                <IdleTimeout onIdleExpiry={() => {
                  this.setState({
                    idleLogout: true
                  }, () => {
                    this.logout();
                  });
                }} onActive={() => {
                  API.auth.heartbeat().then(res => {

                  }).catch(err => {
                    if(err.response !== undefined){
                      if(err.response.status === 401){
                        // Session expired
                        this.setState({
                          error: "Code Expired"
                        });
                      }
                    }
                  })
                }} onHeartbeat={() => {
                  API.auth.heartbeat().then(res => {

                  }).catch(err => {
                    if(err.response !== undefined){
                      if(err.response.status === 401){
                        // Session expired
                        this.setState({
                          error: "Code Expired"
                        });
                      }
                    }
                  })
                }} maxIdleTime={this.props.maxIdleTime} preWarningTime={this.props.preWarningTime} />
                {this.props.children}
              </Auth_Provider>
            );
        }
    }
}

export default AuthenticationWrapper;