import React, { Component } from "react";
import {
  Table,
  Button,
  Icon,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import Moment from "react-moment";
import moment from "moment";

export default class AssessmentDataList extends Component {
  renderList() {
    if (this.props.data && this.props.data.length) {
      const list = this.props.data.map((data) => {
        let submittedSteps = 0;
        let form_data = data.form_data ? data.form_data : [];
        let iframe_data = data.iframe_data ? data.iframe_data : [];
        if (data.company_steps) {
          data.company_steps
            .filter((e) => e.archived !== true)
            .map((step, index) => {
              let progress = "";

              let step_data = null;

              if (step.type === "form") {
                step_data = form_data.filter((e) => {
                  return e.form == step.form;
                });
                if (step_data.length > 0) {
                  if (step_data[0].submitted) {
                    progress = "submitted";
                  } else {
                    progress = "incomplete";
                  }
                } else {
                  progress = "not started";
                }
              } else if (step.type === "iframe") {
                step_data = iframe_data.filter((e) => {
                  return e.iframe == step.title;
                });
                if (step_data.length > 0) {
                  if (step_data[0].submitted) {
                    progress = "submitted";
                  } else {
                    progress = "incomplete";
                  }
                } else {
                  progress = "not started";
                }
              }

              if (progress === "submitted") {
                submittedSteps++;
              }
            });
        }

        return (
          <Table.Row
            key={data._id}
            onClick={() => {
              this.props.action(data._id);
            }}
            className={
              moment.utc().diff(moment.utc(data.createdAt), "days") > 7 &&
              data.status === "New"
                ? "redItem"
                : ""
            }
          >
            <Table.Cell>
              <Header>A{data.assNum}</Header>
            </Table.Cell>
            <Table.Cell>
              {data.employee &&
                data.employee.firstName +
                  " " +
                  data.employee.lastName +
                  " (#" +
                  (data.employee.ref ? data.employee.ref : "") +
                  ")"}
            </Table.Cell>
            <Table.Cell>
              {data.employee && moment(data.employee.dob).format("DD/MM/YYYY")}
            </Table.Cell>
            <Table.Cell>
              {data.cm && data.cm.firstName
                ? data.cm.firstName + " " + data.cm.lastName
                : "Not Assigned"}
            </Table.Cell>
            <Table.Cell>
              <Moment format={"DD/MM/YYYY"}>{data.createdAt}</Moment>
            </Table.Cell>
            <Table.Cell>{data.status}</Table.Cell>
            {/* <Table.Cell></Table.Cell> */}
            <Table.Cell>
              {
                data.company_steps &&
                  data.company_steps.length > 0 &&
                  `${(submittedSteps / data.company_steps.length) * 100}% - ${
                    data.company_steps[submittedSteps]
                      ? data.company_steps[submittedSteps].title
                      : "N/A"
                  }`
                // `${submittedSteps} / ${data.company_steps.length}`
              }
            </Table.Cell>
            <Table.Cell>
              <Header>
                {data.referral && data.referral.refNum !== undefined
                  ? "R" + data.referral.refNum
                  : "--"}
              </Header>
            </Table.Cell>
          </Table.Row>
        );
      });
      return list;
    }
  }

  render() {
    if (this.props.data && this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add New Health Journey
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <>
        <Button
          positive
          icon="plus"
          content="Add Health Journey"
          onClick={() => this.props.add()}
        />
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Ref</Table.HeaderCell>
              <Table.HeaderCell>Client</Table.HeaderCell>
              <Table.HeaderCell>Date of Birth</Table.HeaderCell>
              <Table.HeaderCell>Assigned Clinician</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Clinician Decision</Table.HeaderCell>
              <Table.HeaderCell>Progress - Next Step</Table.HeaderCell>
              {/* <Table.HeaderCell>Last Activity</Table.HeaderCell> */}
              <Table.HeaderCell>Referral #</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderList()}</Table.Body>
        </Table>
      </>
    );
  }
}
