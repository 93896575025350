import React, { Component } from "react";
import { Segment, Header } from "semantic-ui-react";
import EditBenefitForm from "./forms/editBenefitForm";
import api from "../../../../actions/api";

export default class EditBenefitPage extends Component {
  state = {
    benefit: {},
  };

  componentDidMount() {
    this.getData();
  }

  onCancel = () => {
    this.props.history.push(`/settings/benefits/${this.props.match.params.id}`);
  };

  getData = () => {
    api.benefits.show(this.props.match.params.id).then((benefit) => {
      this.setState({
        benefit,
      });
    });
  };

  updateBenefit = (data) => {
    api.benefits.update(this.props.match.params.id, data).then(() => {
      this.props.history.push(
        `/settings/benefits/${this.props.match.params.id}`
      );
    });
  };

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Update Benefit {this.state.benefit.benefit_number}
        </Header>
        <Segment className="no-marg border">
          <EditBenefitForm
            submit={this.updateBenefit}
            benefit={this.state.benefit}
            cancel={this.onCancel}
          />
        </Segment>
      </Segment>
    );
  }
}
