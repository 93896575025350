import React, { Component } from "react";
import { Form, Divider, Segment, Icon, Label } from "semantic-ui-react";
import { DateTimeInput } from "semantic-ui-calendar-react";
import moment from "moment";

export default class add extends Component {
  state = {
    data: {
      title: "",
      short_description: "",
      long_description: "",
      file: null,
      end_date: "",
      start_date: "",
      archived: false,
    },
    errors: {},
  };

  handleChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  validate = (data) => {
    let errors = {};

    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.short_description) errors.short_description = "Can't Be Empty";
    if (!data.long_description) errors.long_description = "Can't Be Empty";
    if (!data.end_date) errors.end_date = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors: errors });

    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths={"equal"}>
            <Form.Input
              name="title"
              label="Title"
              value={this.state.data.title}
              onChange={this.handleChange}
              placeholder="Title"
              error={this.state.errors.title}
            />
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.TextArea
              name="short_description"
              label="Short Description"
              value={this.state.data.short_description}
              onChange={this.handleChange}
              placeholder="Short Description"
              error={this.state.errors.short_description}
            />
            <Form.TextArea
              name="long_description"
              label="Long Description"
              value={this.state.data.long_description}
              onChange={this.handleChange}
              placeholder="Long Description"
              error={this.state.errors.long_description}
            />
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <DateTimeInput
                label="Notice Start Date"
                autoComplete="off"
                name="start_date"
                dateTimeFormat="YYYY/MM/DD HH:mm"
                dateFormat="YYYY/MM/DD HH:mm"
                placeholder="Start Date"
                value={
                  this.state.data.start_date
                    ? moment(this.state.data.start_date).format("YYYY/MM/DD HH:mm")
                    : ""
                }
                iconPosition="left"
                onChange={this.handleDropdownChange}
              />
            </Form.Field>
            <Form.Field error={this.state.errors.end_date}>
              <DateTimeInput
                label="Notice End Date"
                autoComplete="off"
                name="end_date"
                dateTimeFormat="YYYY/MM/DD HH:mm"
                dateFormat="YYYY/MM/DD HH:mm"
                placeholder="End Date"
                value={
                  this.state.data.end_date
                    ? moment(this.state.data.end_date).format("YYYY/MM/DD HH:mm")
                    : ""
                }
                iconPosition="left"
                onChange={this.handleDropdownChange}
              />
              {this.state.errors.end_date ? (
                <Label
                  style={{ marginTop: "0px" }}
                  pointing
                  prompt
                  content={this.state.errors.end_date}
                />
              ) : (
                <React.Fragment />
              )}
            </Form.Field>
          </Form.Group>
          
          <Divider />
          <Form.Group widths="equal">
            <Form.Button floated="left" onClick={this.props.close} negative>
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              floated="right"
              onClick={this.handleSubmit}
              color="green"
            >
              <Icon name="plus" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
