import React, { Component } from "react";
import {
  Table,
  Message,
  Button,
  Icon,
  Popup,
  Segment,
} from "semantic-ui-react";

class helpList extends Component {
  renderList() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        return (
          <Table.Row key={item._id} onClick={() => this.props.action(item._id)}>
            <Table.Cell>{item.category_title}</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  render() {
    if (this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>No Help Categories To Display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add Help Category
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <Table stackable selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Help Category Title</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              <Popup
                position="top right"
                trigger={
                  <Button positive icon onClick={this.props.add}>
                    <Icon name="plus" />
                  </Button>
                }
                content={"Add Help Category"}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}

export default helpList;
