import React, { Component } from "react";
import { Segment, Form, Icon, Message } from "semantic-ui-react";
import api from "../../../../actions/api";

class assignUserBenefits extends Component {
  state = {
    data: {
      users_to_assign_to: [],
      primary_benefit_to_assign: "",
      secondary_benefit_to_assign: "",
      third_benefit_to_assign: "",
    },
    users: [],
    benefits: [],
    errors: {},
  };

  componentDidMount() {
    Promise.all([
      api.company.getCompanyEmployeesDropdown(this.props.company),
      api.company.benefits.getBenefits(this.props.company),
    ]).then((res) => {
      this.setState({
        users: res[0],
        benefits: res[1].map((item) => {
          return { value: item._id, text: item.benefit.title, key: item._id };
        }),
      });
    });
  }

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  validate = (data) => {
    const errors = {};
    if (
      data.users_to_assign_to !== undefined &&
      data.users_to_assign_to.length === 0
    )
      errors.users_to_assign_to = "Can't Be Empty";
    if (
      data.primary_benefit_to_assign === "" &&
      data.secondary_benefit_to_assign === "" &&
      data.third_benefit_to_assign === ""
    )
      errors.benefit_to_assign = "Benefits Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group>
            <Form.Dropdown
              name="users_to_assign_to"
              label="Users To Assign To"
              value={this.state.data.users_to_assign_to}
              onChange={this.handleDropdownChange}
              placeholder="Please Select At Least 1 User"
              options={this.state.users !== undefined ? this.state.users : []}
              selection
              multiple
              search
              error={this.state.errors.users_to_assign_to}
              width="14"
            />
            <Form.Button
              style={{ marginTop: "22px" }}
              content="Select All"
              color="blue"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  data: {
                    ...this.state.data,
                    users_to_assign_to: this.state.users.map((item) => {
                      return item.value;
                    }),
                  },
                });
              }}
              width="2"
              fluid
              disabled={
                this.state.users.length ===
                this.state.data.users_to_assign_to.length
              }
            />
          </Form.Group>
          {this.state.errors.benefit_to_assign !== undefined ? (
            <Message
              negative
              icon="warning"
              header={`${this.state.errors.benefit_to_assign}`}
            />
          ) : (
            <React.Fragment />
          )}
          <Form.Group widths="equal">
            <Form.Dropdown
              name="primary_benefit_to_assign"
              label="Primary Benefit To Assign"
              value={this.state.data.primary_benefit_to_assign}
              onChange={this.handleDropdownChange}
              placeholder="Please Select A Benefit"
              options={
                this.state.benefits !== undefined ? this.state.benefits : []
              }
              selection
              search
              clearable
              error={
                this.state.errors.benefit_to_assign !== undefined ? true : false
              }
            />
            <Form.Dropdown
              name="secondary_benefit_to_assign"
              label="Secondary Benefit To Assign"
              value={this.state.data.secondary_benefit_to_assign}
              onChange={this.handleDropdownChange}
              placeholder="Please Select A Benefit"
              options={
                this.state.benefits !== undefined ? this.state.benefits : []
              }
              selection
              search
              clearable
              error={
                this.state.errors.benefit_to_assign !== undefined ? true : false
              }
            />
            <Form.Dropdown
              name="third_benefit_to_assign"
              label="Third Benefit To Assign"
              value={this.state.data.third_benefit_to_assign}
              onChange={this.handleDropdownChange}
              placeholder="Please Select A Benefit"
              options={
                this.state.benefits !== undefined ? this.state.benefits : []
              }
              selection
              search
              clearable
              error={
                this.state.errors.benefit_to_assign !== undefined ? true : false
              }
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              floated="left"
              color="red"
              onClick={() => this.props.close()}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              floated="right"
              color="green"
              onClick={this.handleSubmit}
            >
              <Icon name="plus" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default assignUserBenefits;
