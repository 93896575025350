import React, { Component } from "react";
import { Table, Button, Icon, Message, Segment } from "semantic-ui-react";
// import Moment from "react-moment";

export default class StaffList extends Component {
  renderList() {
    const list = this.props.staff.map((item) => {
      return (
        <Table.Row
          className="pointer"
          key={item._id}
          onClick={() => {
            this.props.action(item._id);
          }}
        >
          <Table.Cell>
            {item.firstName} {item.lastName}
          </Table.Cell>
          <Table.Cell>
            {item.groups.length !== 0
              ? item.groups.map((item) => {
                  return item;
                })
              : "[UNASSIGNED]"}
          </Table.Cell>
          <Table.Cell>{item.email}</Table.Cell>
          <Table.Cell> </Table.Cell>
        </Table.Row>
      );
    });
    return list;
  }

  render() {
    if (this.props.staff.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add New Staff Member
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Position</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>

            {this.props.allowAdd && (
              <Table.HeaderCell textAlign="center" width={1}>
                <Button
                  icon
                  positive
                  size="tiny"
                  onClick={() => this.props.add()}
                >
                  <Icon name="plus" />
                </Button>
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}
