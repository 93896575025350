import React, { Component } from "react";
import {
  Segment,
  Header,
  Pagination,
  Select,
  Message,
  Input,
  Form,
  Button,
  Grid,
} from "semantic-ui-react";
import EmployeeList from "../../../common/lists/employeeList";
import api from "../../../../../actions/api";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";

const pageSizes = [10, 25, 50, 100];

const employment_status = [
  { text: "Live", value: "Live" },
  { text: "Leaver", value: "Leaver" },
  { text: "Archived", value: "Archived" },
];

export default class employee_report extends Component {
  state = {
    pageSize: pageSizes[1],
    pageNum: 1,
    totalPages: 0,
    totalItems: 0,
    search: "",
    filters: { employment_status: ["Live"] },
    items: [],
    loading: true,
    globalError: null,
    company_list: [],
  };

  getEmployees = (pageNum, pageSize, search, filters) => {
    return api.reports.employee_report({
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  componentDidMount() {
    Promise.all([api.company.getAll()]).then((res) => {
      this.setState({
        company_list: res[0].map((item) => {
          return { text: item.name, value: item._id };
        }),
      });
    });

    this.queryData();
  }

  queryData() {
    this.setState(
      {
        loading: true,
        globalError: null,
      },
      () => {
        this.getEmployees(
          this.state.pageNum,
          this.state.pageSize,
          this.state.search,
          this.state.filters
        )
          .then((data) => {
            this.setState({
              pageNum: data.page,
              totalPages: data.pages,
              totalItems: data.total,
              items: data.docs,
            });
          })
          .catch((err) => {
            let globalError =
              "There was an unexpected error while retrieving data from the server";

            if (err.response !== undefined) {
              globalError = err.response.data.message;
            }

            this.setState({
              globalError,
            });
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  }

  resetQuery() {
    this.setState(
      {
        pageNum: 1,
        search: "",
        filters: {},
      },
      () => {
        this.queryData();
      }
    );
  }

  handleSearch = (e, { name, value }) =>
    this.setState({ [name]: value }, () => {
      this.queryData();
    });

  handleDateChange = (event, { name, value }) => {
    this.setState({ filters: { ...this.state.filters, [name]: value } }, () =>
      this.queryData()
    );
  };

  handleDropdownChange = (e, data) => {
    this.setState(
      {
        filters: { ...this.state.filters, [data.name]: data.value },
      },
      () => this.queryData()
    );
  };

  handleCSVGenerate = () => {
    let items = this.state.items.map((item) => {
      let final_item = {
        ...item,
        gender: item.meta ? item.meta.gender : undefined,
        height: item.meta ? item.meta.height : undefined,
        company: item.company ? item.company.name : undefined,
        address: item.address ? item.address.address : undefined,
        practitioners: item.practitioners
          ? item.practitioners.map((prac) => {
              return prac.name;
            })
          : undefined,
        primary_benefit: item.primary_benefit
          ? item.primary_benefit.benefit.title
          : undefined,
        secondary_benefit: item.secondary_benefit
          ? item.secondary_benefit.benefit.title
          : undefined,
        third_benefit: item.third_benefit
          ? item.third_benefit.benefit.title
          : undefined,
        favourite_services: item.favourite_services
          ? item.favourite_services.map((service) => {
              return service.title;
            })
          : undefined,
      };

      delete final_item.meta;

      return final_item;
    });

    if (items && items.length !== 0) {
      let csv_rows = [];
      let csv_header = [];

      items.forEach((item) => {
        Object.keys(item).forEach((item) => {
          if (
            item !== "id" &&
            csv_header.find((header) => header === item) === undefined
          ) {
            csv_header.push(item);
          }
        });
      });

      csv_rows.push(csv_header.join(","));

      items.forEach((item) => {
        csv_rows.push(
          csv_header
            .map((header) => {
              let clean_value = (item[header] === undefined
                ? ""
                : "" + item[header]
              ).replace(/"|'/g, "");
              return `"${clean_value}"`;
            })
            .join(",")
        );
      });

      let data_blob = new Blob([csv_rows.join("\n")], { type: "text/csv" });
      let a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", window.URL.createObjectURL(data_blob));
      a.setAttribute(
        "download",
        `Employee Report Export - ${moment().format("YYYY-MM-DD")}.csv`
      );
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Employee Report
        </Header>
        <Segment className="border">
          <Segment basic loading={this.state.loading}>
            {this.state.globalError && (
              <Message negative>
                <Message.Header>ERROR</Message.Header>
                {this.state.globalError}
              </Message>
            )}
            <Grid columns="2">
              <Grid.Row>
                <Grid.Column>
                  <Button
                    content="Generate CSV Export"
                    onClick={() => this.handleCSVGenerate()}
                    color="blue"
                    icon="download"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Header
                    as="h3"
                    textAlign="right"
                    style={{ marginTop: "6px" }}
                  >
                    Total Item Count: {this.state.totalItems}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Segment basic className="no-hpad no-marg">
              <Form>
                <Form.Group widths="equal">
                  <Form.Dropdown
                    name="company"
                    selection
                    clearable
                    multiple
                    label="Company"
                    placeholder="Company"
                    onChange={this.handleDropdownChange}
                    options={this.state.company_list}
                  />
                  <Form.Field>
                    <DateInput
                      autoComplete={"off"}
                      animation="off"
                      label="Start Date"
                      name="start_date"
                      value={
                        this.state.filters.start_date
                          ? this.state.filters.start_date
                          : ""
                      }
                      iconPosition="left"
                      onChange={this.handleDateChange}
                      startMode={"year"}
                      placeholder="Start Date"
                      closable
                      clearable
                    />
                  </Form.Field>
                  <Form.Field>
                    <DateInput
                      autoComplete={"off"}
                      animation="off"
                      label="End Date"
                      name="end_date"
                      value={
                        this.state.filters.end_date
                          ? this.state.filters.end_date
                          : ""
                      }
                      iconPosition="left"
                      onChange={this.handleDateChange}
                      startMode={"year"}
                      placeholder="End Date"
                      closable
                      clearable
                    />
                  </Form.Field>
                  <Form.Dropdown
                    name="employment_status"
                    selection
                    clearable
                    label="Employment Status"
                    placeholder="Employment Status"
                    onChange={this.handleDropdownChange}
                    value={this.state.filters.employment_status}
                    options={employment_status}
                    multiple
                  />
                </Form.Group>
              </Form>
            </Segment>
            <Input
              style={{ width: "20%" }}
              name="search"
              value={this.state.search}
              onChange={this.handleSearch}
              placeholder="Search..."
            />
            <div style={{ float: "right" }}>
              <label>Page Size: </label>
              <Select
                options={pageSizes.map((i) => {
                  return {
                    key: i,
                    value: i,
                    text: i,
                  };
                })}
                value={this.state.pageSize}
                onChange={(e, data) => {
                  this.setState(
                    {
                      pageSize: data.value,
                      pageNum: 1,
                    },
                    () => {
                      this.queryData();
                    }
                  );
                }}
              />
            </div>
            <div style={{ paddingTop: "1rem " }}>
              <EmployeeList
                data={this.state.items}
                action={(id) => this.props.history.push(`/employee/${id}`)}
                showCompany={true}
              />
            </div>
            {this.state.items.length >= 1 && (
              <div style={{ textAlign: "right", paddingTop: "1rem" }}>
                <Pagination
                  defaultActivePage={this.state.pageNum}
                  totalPages={this.state.totalPages}
                  onPageChange={(e, { activePage }) => {
                    this.setState({ pageNum: activePage }, () =>
                      this.queryData()
                    );
                  }}
                />
              </div>
            )}
          </Segment>
        </Segment>
      </Segment>
    );
  }
}
