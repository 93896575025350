import React, { Component } from "react";
import {
  Button,
  Divider,
  Form,
  Icon,
  Segment,
  Message,
} from "semantic-ui-react";

class addTemplateForm extends Component {
  state = {
    data: {
      description: "",
      consent_default: false,
      referral_default: false,
      file: null,
    },
    isHovered: false,
    loading: false,
    errors: {},
  };

  onFileLoad = (e) => {
    if (e.currentTarget.files[0] === undefined) {
      return;
    }

    let file = e.currentTarget.files[0];
    let allowedTypes = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/pdf",
      "image/jpeg",
      "image/png",
    ];

    let isAllowed = false;

    for (let i in allowedTypes) {
      let type = allowedTypes[i];
      if (file.type === type) {
        isAllowed = true;
      }
    }

    if (!isAllowed) {
      alert("Only PDF files are allowed to be uploaded");
      return;
    }

    this.setState({
      data: {
        ...this.state.data,
        file: file,
      },
    });
  };

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors }, () => console.log(this.state));
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      this.props.submit(this.state.data);
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.description) errors.description = "Can't be empty";
    if (!data.file) errors.file = "Can't be empty";
    return errors;
  };

  render() {
    const { data, errors } = this.state;
    return (
      <Segment>
        <Form>
          <Form.Field>
            <Form.Input
              label="Description"
              error={errors.description}
              autoComplete="off"
              type="text"
              name="description"
              value={data.description}
              onChange={this.onChange}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Checkbox
              label="Consent Default"
              checked={this.state.data.consent_default}
              onClick={() => {
                this.setState({
                  data: {
                    ...this.state.data,
                    consent_default: !this.state.data.consent_default,
                    referral_default: false,
                  },
                });
              }}
            />
            <Form.Checkbox
              label="Referral Default"
              checked={this.state.data.referral_default}
              onClick={() => {
                this.setState({
                  data: {
                    ...this.state.data,
                    referral_default: !this.state.data.referral_default,
                    consent_default: false,
                  },
                });
              }}
            />
          </Form.Group>
          {this.state.data.file === null ? (
            <Segment
              basic
              className="inner-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="sub-header">Drag A .PDF File Here</div>
              <div
                className={`draggable-container ${
                  this.state.isHovered && "draggable-container-hovered"
                }`}
              >
                <input
                  type="file"
                  id="file-browser-input"
                  name="file-browser-input"
                  onDragOver={() => this.setState({ isHovered: true })}
                  onDragLeave={() => this.setState({ isHovered: false })}
                  onChange={(e) => this.onFileLoad(e)}
                />
                <div className="helper-text">
                  Drop file here or <br />
                  click to browse (max 5mb)
                </div>
              </div>
            </Segment>
          ) : (
            <Segment basic>
              <Message
                positive
                icon="checkmark"
                header="File Uploaded Successfully"
                onDismiss={() =>
                  this.setState({ data: { ...this.state.data, file: null } })
                }
              />
            </Segment>
          )}
          <Divider clearing />
          <Form.Group widths={"equal"} className={"form-actions"}>
            <Form.Field>
              <Button color="red" floated={"left"} onClick={this.props.close}>
                <Icon name="close" /> Cancel
              </Button>
            </Form.Field>
            <Form.Field>
              <Button
                color="green"
                onClick={() => this.onSubmit()}
                floated="right"
              >
                <Icon name="plus" /> Add
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default addTemplateForm;
