import React, { Component } from "react";
import {
  Header,
  Segment,
  Message,
  Table,
  Divider,
  Statistic,
  Button,
  Icon,
  Modal,
  Input,
  Form,
} from "semantic-ui-react";
import moment from "moment";
import Moment from "react-moment";
import Chart from "react-apexcharts";
import { DateInput } from "semantic-ui-calendar-react";

class bp extends Component {
  state = {
    readings: [],
    data: {
      systolic: 0,
      diastolic: 0,
      date: new moment().format("DD/MM/YYYY"),
    },
    bpresults_dia: [],
    bpresults_sys: [],
    add: false,
    current: {
      systolic: 0,
      diastolic: 0,
    },
  };

  componentDidMount() {
    if (this.props.data) {
      this.setState(
        {
          readings: this.props.data,
        },
        () => {
          this.getResults();
          if (this.state.readings.length && this.props.user) {
            this.setState({
              current: {
                systolic: this.state.readings[0].systolic,
                diastolic: this.state.readings[0].diastolic,
              },
            });
          }
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.setState(
        {
          readings: this.props.data,
        },
        () => {
          this.getResults();
          this.setState({
            current: {
              systolic: this.state.readings[0].systolic,
              diastolic: this.state.readings[0].diastolic,
            },
          });
        }
      );
    }
  }

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleDateChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  submit = () => {
    if (this.state.data.diastolic !== 0 && this.state.data.systolic !== 0) {
      this.props.add(this.state.data).then(() => {
        this.setState({
          add: false,
        });
      });
    }
  };

  getResults = () => {
    if (this.props.user && this.props.user.height) {
      let bpresults_sys = this.props.data.map((i) => {
        return {
          x: moment(i.date).format("DD/MM/YY HH:MM"),
          y: i.systolic,
        };
      });

      let bpresults_dia = this.props.data.map((i) => {
        return {
          x: moment(i.date).format("DD/MM/YY HH:MM"),
          y: i.diastolic,
        };
      });

      this.setState({
        bpresults_sys: bpresults_sys,
        bpresults_dia: bpresults_dia,
      });
    }
  };

  renderChart() {
    let chartOptions = {
      chart: {
        height: 350,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 5,
        hover: {
          size: 5,
        },
      },
      title: {
        text: "Blood Pressure",
        align: "left",
      },
      yaxis: {
        title: {
          text: "Blood Pressure",
        },
        min: 0,
        forceNiceScale: true,
      },
      xaxis: {
        title: {
          text: "Date",
        },
      },
      series: [
        {
          name: "Systolic Reading",
          type: "line",
          data: this.state.bpresults_sys,
        },
        {
          name: "Diastolic Reading",
          type: "line",
          data: this.state.bpresults_dia,
        },
      ],
    };
    return (
      <Segment basic className="no-pad">
        {this.state.readings.length > 2 ? (
          <Chart
            height={350}
            options={chartOptions}
            series={chartOptions.series}
            type="line"
          />
        ) : (
          <Message
            info
            icon="info circle"
            header="Results Chart"
            content="Chart will appear when sufficient amount of results are added."
          />
        )}
      </Segment>
    );
  }

  renderTable() {
    const list = this.state.readings.map((data, i) => {
      return (
        <Table.Row key={i}>
          <Table.Cell>
            <Moment format={"DD/MM/YY"}>{data.date}</Moment>
          </Table.Cell>
          <Table.Cell>{data.systolic}</Table.Cell>
          <Table.Cell>{data.diastolic}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table unstackable textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Systolic</Table.HeaderCell>
            <Table.HeaderCell>Diastolic</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.length !== 0 ? (
            list
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3">No Data To Display</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }

  renderCurrent() {
    return (
      <Segment basic>
        <Header textAlign="center">Current</Header>
        <Statistic.Group
          widths={
            this.props.user &&
            this.props.user.height &&
            this.state.readings.length !== 0
              ? 2
              : 1
          }
        >
          {this.props.user &&
          this.props.user.height &&
          this.state.readings.length !== 0 ? (
            <React.Fragment>
              <Statistic>
                <Statistic.Value>{this.state.current.systolic}</Statistic.Value>
                <Statistic.Label>Systolic</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>
                  {this.state.current.diastolic}
                </Statistic.Value>
                <Statistic.Label>Diastolic</Statistic.Label>
              </Statistic>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Statistic>
                <Statistic.Value></Statistic.Value>
                <Statistic.Label>No Readings Taken</Statistic.Label>
              </Statistic>
            </React.Fragment>
          )}
        </Statistic.Group>
        <Divider />
        <Button positive fluid onClick={() => this.setState({ add: true })}>
          <Icon name="plus" /> Add Reading
        </Button>
      </Segment>
    );
  }

  renderAddModal() {
    return (
      <Modal
        open={this.state.add}
        onClose={() => {
          this.setState({ add: false });
        }}
        size="small"
        centered={true}
      >
        <Modal.Header>
          <Icon name="file alternate" /> Add Blood Pressure Reading
        </Modal.Header>
        <Modal.Content>
          <Segment>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Date</label>
                  <DateInput
                    autoComplete={"off"}
                    animation="off"
                    name="date"
                    iconPosition="left"
                    value={this.state.data.date}
                    onChange={this.handleDateChange}
                    startMode={"day"}
                    placeholder="Date"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Systolic Reading</label>
                  <Input
                    fluid
                    name="systolic"
                    placeholder={"Systolic Reading"}
                    onChange={this.handleChange}
                    type="number"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Diastolic Reading</label>
                  <Input
                    fluid
                    name="diastolic"
                    placeholder={"Diastolic Reading"}
                    onChange={this.handleChange}
                    type="number"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Button
                  floated="left"
                  color="red"
                  onClick={() => {
                    this.setState({ add: false });
                  }}
                >
                  <Icon name="close" />
                  Cancel
                </Form.Button>
                <Form.Button
                  floated="right"
                  color="green"
                  onClick={() => this.submit()}
                >
                  <Icon name="plus" />
                  Submit
                </Form.Button>
              </Form.Group>
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Segment basic className="pagHolder">
        {this.renderAddModal()}
        <Header textAlign="center" as={"h1"}>
          Blood Pressure
        </Header>
        {this.renderCurrent()}
        {this.renderChart()}
        <Divider />
        {this.renderTable()}
      </Segment>
    );
  }
}

export default bp;
