import React, { Component } from "react";
import api from "../../../actions/api";
import { Segment, Header} from "semantic-ui-react";
import FileManagerList from "../common/lists/fileManagerList";
/*
import {
  FaFile,
} from "react-icons/fa"; */

export default class FilesList extends Component {
  state = { 
    search: {},
    files: [], 
    folders: [], 
    folder: null, 
    loading: true, 
    createFolderModal: false,
    renameFolderModal: false,
    moveModal: false, 
    deleteFolderModal: false, 
    uploadFileModal: false, 
    data: {}, 
    errors: {}, 
    path: "/home"
  };

  componentDidMount() {
    this.loadFolders();
  }

  loadFolders(item, search = false, findFile){
    console.log("searching?", search, item);
    this.setState({loading: true});

    if(findFile){

      api.files.searchFiles({search: findFile}).then((res)=>{
        this.setState({
          folder: null,
          folders: [],
          files: res,
          loading: false
        });
      });

      return true;
    }

    if(item){
      api.files.getFolder(item).then((res) => {

        /*
        let files = [];

          findFile ? res.files.map((f)=>{

            let sliceFrom = 0;

            if(f.file.filename){
              sliceFrom = f.file.filename.indexOf("SCANNED__") + 9;
            }

            let filename = f.file.filename.slice(sliceFrom,f.file.filename.length);

            if(filename.toLowerCase().includes(findFile.toLowerCase())){
              files.push(f);
            }

          }) : files = res.files; */
          
        let ret = {
          files: res.files,
          folder: res.folder,
          folders: res.folders,
          loading: false,
        };

        console.log(ret);

        if(search){
          this.setState({
            loading: false,
            search: ret
          })
        }else{
          this.setState(ret);
        }

        
      });
      api.files.getFolderPath(item).then((res) => {

        if(search){
          this.setState({
            search: {
              ...this.state.search,
              path: res
            }
          });
        }else{
          this.setState({
            path: res,
          });
        }
        
      });
    }else{
      api.files.getAll().then((res) => {
        console.log("🚀 ~ file: dash.js ~ line 110 ~ ReferralsList ~ api.files.getAll ~ res", res)
        let ret = {
          path: "/home",
          folder: null,
          folders: res.folders,
          files: res.files,
          loading: false,
        };

        if(search){
          this.setState({
            loading: false,
            search: ret
          })
        }else{
          this.setState(ret);
        }
      });
    }
    
  }

  render() {
    console.log("state", this.state);
    let {folders, folder, path, files, search} = this.state;
    return (
      <Segment basic className="no-pad" loading={this.state.loading}>
        
        <Header as={"h1"} textAlign="center" className=" pageTitle">
          File Manager {/*this.state.folder ? "- " + this.state.folder.title: ""*/}
        </Header>
        <FileManagerList search={search} folder={folder} folders={folders} path={path} files={files} loadFolders={(a,b,c)=>this.loadFolders(a,b,c)}/>
      </Segment>
    );
  }
}
