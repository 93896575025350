import React, { Component } from "react";
import { Form, Segment, Icon, Header, Divider } from "semantic-ui-react";
import api from "../../../../actions/api";

const status_options = [
  { text: "Active", value: "Active", key: "Active" },
  { text: "Inactive", value: "Inactive", key: "Inactive" },
];

class editCase extends Component {
  state = {
    data: {
      status: "",
      cm: "",
      additional_details: "",
      archive: false,
    },
    caseManagerList: [],
    errors: {},
    loading: false,
  };

  componentDidMount() {
    Promise.all([
      api.case.getCase(this.props.match.params.id),
      api.team.getCaseManagers(),
    ]).then((res) => {
      console.log(res);
      this.setState({
        data: {
          status: res[0].status,
          cm: res[0].cm ? res[0].cm._id : "",
          archive: res[0].archive,
          additional_details: res[0].additional_details,
        },
        caseManagerList: res[1].formList,
      });
    });
  }

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  validate = (data) => {
    const errors = {};
    if (!data.status) errors.status = "Can't Be Empty.";
    if (!data.cm) errors.cm = "Can't Be Empty.";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      api.case
        .editCase(this.props.match.params.id, this.state.data)
        .then((res) => {
          this.props.history.push(`/case/${res._id}`);
        })
        .catch((e) => {
          this.setState({ loading: false });
          console.log(e);
        });
    }
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="no-marg pageTitle ">
          Edit Case
        </Header>
        <Segment className="no-marg border">
          <Form>
            <Form.Group widths="equal">
              <Form.Dropdown
                error={this.state.errors.status}
                name="status"
                label="Case Status"
                value={this.state.data.status}
                onChange={this.handleDropdownChange}
                placeholder="Please Select Case Status"
                options={status_options}
                selection
                search
                noResultsMessage={"Could Not Find Any Status"}
              />
              <Form.Dropdown
                error={this.state.errors.cm}
                name="cm"
                label="Assigned Case Manager"
                value={this.state.data.cm}
                onChange={this.handleDropdownChange}
                placeholder="Please Select The Case Manager"
                options={this.state.caseManagerList}
                selection
                search
                noResultsMessage={"Could Not Find Any Managers"}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.TextArea
                name="additional_details"
                label="Additional Detials"
                value={this.state.data.additional_details}
                onChange={this.handleChange}
                placeholder="Any Additional Information"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Checkbox
                label="Archived"
                name="archived"
                checked={this.state.data.archive}
                onClick={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      archive: !this.state.data.archive,
                    },
                  });
                }}
              />
            </Form.Group>
            {/* <div
                  style={{
                    paddingBottom: "14px",
                    paddingTop: "5px",
                    fontSize: ".92857143em",
                  }}
                >
                  <label>
                    <strong>Preferred Contact Method(s):</strong>
                  </label>
                </div>
                <Form.Group inline>
                  <Form.Checkbox
                    label="Email"
                    name="email"
                    checked={
                      this.state.data.contact_types &&
                      this.state.data.contact_types.email
                    }
                    readOnly
                  />
                  <Form.Checkbox
                    label="Phone"
                    name="phone"
                    checked={
                      this.state.data.contact_types &&
                      this.state.data.contact_types.phone
                    }
                    onClick={() => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          contact_types: {
                            ...this.state.data.contact_types,
                            phone: !this.state.data.contact_types.phone,
                          },
                        },
                      });
                    }}
                  />
                  <Form.Checkbox
                    label="Post"
                    name="post"
                    checked={
                      this.state.data.contact_types &&
                      this.state.data.contact_types.post
                    }
                    onClick={() => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          contact_types: {
                            ...this.state.data.contact_types,
                            post: !this.state.data.contact_types.post,
                          },
                        },
                      });
                    }}
                  />
                </Form.Group> */}
            <Divider />
            <Form.Group widths="equal">
              <Form.Button
                floated="left"
                color="red"
                onClick={() => window.history.back()}
              >
                <Icon name="arrow left" />
                Cancel
              </Form.Button>
              <Form.Button
                floated="right"
                type="submit"
                color="orange"
                onClick={this.handleSubmit}
              >
                <Icon name="pencil" />
                Update
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default editCase;
