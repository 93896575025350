import React, { Component } from "react";
import api from "../../../actions/api";
import { Segment, Header, Button, Icon, Modal } from "semantic-ui-react";
import ReferralList from "../common/lists/referralList";
import PaginateWrapper from "../common/PaginateWrapper";
import { Authentication, Can } from "../../../lib/sso";
import AssignCaseManagers from "../../_cn/components/forms/assignCaseManagers";

export default class ReferralsList extends Component {
  state = { statusList: [], referrals: [], companies: [], cases: [] };

  componentDidMount() {
    api.settings.all().then((res) => {
      this.setState({
        statusList: res.referralStatuses
          ? res.referralStatuses.map((item, i) => {
              return { text: item, value: item, key: i };
            })
          : [],
      });
      if (Authentication.can("navigator_admin.permission")) {
        api.referral.getAll().then((res) => {
          let companies = this.state.companies;
          res.map((s) => {
            if (!this.state.companies.includes(s.company._id)) {
              companies.push(s.company._id);
            }
          });
          this.setState({
            referrals: res,
            companies,
          });
        });
      } else {
        api.referral.getAllCM().then((res) => {
          let companies = this.state.companies;
          res.map((s) => {
            if (!this.state.companies.includes(s.company._id)) {
              companies.push(s.company._id);
            }
          });
          this.setState({
            referrals: res,
            companies,
          });
        });
      }
    });
  }

  getReferrals = (pageNum, pageSize, search, filters) => {
    if (Authentication.can("navigator_admin.permission")) {
      return api.referral.getAllPaged({ pageNum, pageSize, search, filters });
    }
    if (
      Authentication.can("navigator_case_manager.permission") === true ||
      Authentication.can("organisation_case_manager.permission") === true
    ) {
      return api.referral.getAllCMPaged({ pageNum, pageSize, search, filters });
    }
    return new Promise((resolve) => resolve([])); // Return a empty array in a promise as required by the PaginateWrapper
  };

  getCases = (pageNum, pageSize, search, filters) => {
    if (Authentication.can("navigator_admin.permission")) {
      return api.case.getAllPaged({ pageNum, pageSize, search, filters });
    }
    if (
      Authentication.can("navigator_case_manager.permission") === true ||
      Authentication.can("organisation_case_manager.permission") === true
    ) {
      return api.case.getAllCMPaged({ pageNum, pageSize, search, filters });
    }
    return new Promise((resolve) => resolve([])); // Return a empty array in a promise as required by the PaginateWrapper
  };

  renderReassignCaseManagers() {
    console.log("props", this.props, "state", this.state);
    const handleClose = () => this.setState({ reassignCaseManager: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.case
        .assignMultiCaseManager(data)
        .then((res) => {
          this.getData();
          handleClose();
          this.setState({ reload: false });
        })
        .catch((e) => {
          this.setState({ reload: false });
        });
    };

    return (
      <Modal
        open={this.state.reassignCaseManager}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="clone"
          content="ReAssigning referral(s) to company referral manager"
        />
        <Modal.Content>
          <AssignCaseManagers
            close={handleClose}
            submit={handleSubmit}
            reassign={true}
            referrals={this.state.referrals}
            company={
              this.props.company
                ? this.props.company._id
                : this.state.companies.length > 0
                ? this.state.companies[0]
                : null
            }
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderAssignCaseManagers() {
    const handleClose = () => this.setState({ assignCaseManager: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.case
        .assignMultiCaseManager(data)
        .then((res) => {
          this.getData();
          handleClose();
          this.setState({ reload: false });
        })
        .catch((e) => {
          this.setState({ reload: false });
        });
    };

    return (
      <Modal
        open={this.state.assignCaseManager}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="linkify"
          content="Assigning referral(s) to company referral manager"
        />
        <Modal.Content>
          <AssignCaseManagers
            close={handleClose}
            submit={handleSubmit}
            referrals={this.getReferrals}
            company={
              this.props.company
                ? this.props.company._id
                : this.state.companies.length > 0
                ? this.state.companies[0]
                : null
            }
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderReassignNurseManagers() {
    console.log("props", this.props, "state", this.state);
    const handleClose = () => this.setState({ reassignNurseManager: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.case
        .assignMultiCaseManager(data)
        .then((res) => {
          this.getData();
          handleClose();
          this.setState({ reload: false });
        })
        .catch((e) => {
          this.setState({ reload: false });
        });
    };

    return (
      <Modal
        open={this.state.reassignNurseManager}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="clone"
          content="ReAssigning case(s) to nurse case manager"
        />
        <Modal.Content>
          <AssignCaseManagers
            close={handleClose}
            submit={handleSubmit}
            reassign={true}
            referrals={this.state.referrals}
            nurseManager
            company={
              this.props.company
                ? this.props.company._id
                : this.state.companies.length > 0
                ? this.state.companies[0]
                : null
            }
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderAssignNurseManagers() {
    const handleClose = () => this.setState({ assignNurseManager: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.case
        .assignMultiCaseManager(data)
        .then((res) => {
          this.getData();
          handleClose();
          this.setState({ reload: false });
        })
        .catch((e) => {
          this.setState({ reload: false });
        });
    };

    return (
      <Modal
        open={this.state.assignNurseManager}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="linkify"
          content="Assigning case(s) to nurse case manager"
        />
        <Modal.Content>
          <AssignCaseManagers
            close={handleClose}
            submit={handleSubmit}
            referrals={this.getReferrals}
            nurseManager
            company={
              this.props.company
                ? this.props.company._id
                : this.state.companies.length > 0
                ? this.state.companies[0]
                : null
            }
          />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className=" pageTitle">
          Referral List
        </Header>
        <Segment className="border">
          <Can has={"referral.reassign"}>
            <Button
              // floated="right"
              positive
              onClick={() => this.setState({ assignCaseManager: true })}
            >
              <Icon name="linkify" />
              <Icon name="user" /> Assign To Referral Manager
            </Button>
            <Button
              // floated="right"
              color="orange"
              onClick={() => this.setState({ reassignCaseManager: true })}
            >
              <Icon name="clone" />
              <Icon name="user" /> ReAssign To Referral Manager
            </Button>
          </Can>
          {/*  
          {!Authentication.can("organisation_case_manager.permission") &&
            !Authentication.can("organisation_clinician.permission") &&
            !Authentication.can("navigator_admin.permission") && (
              
            )}
          {!Authentication.can("navigator_admin.permission") &&
            !Authentication.can("organisation_clinician.permission")(
              
            )}*/}
          {/*Authentication.can("organisation_case_manager.permission") ||
            (Authentication.can("navigator_admin.permission") && (
              <Button
                // floated="right"
                color="orange"
                onClick={() => this.setState({ reassignNurseManager: true })}
              >
                <Icon name="clone" />
                <Icon name="medkit" /> ReAssign To Nurse Case Manager
              </Button>
            ))}
          {Authentication.can("navigator_admin.permission") && (
            <Button
              // floated="right"
              positive
              onClick={() => this.setState({ assignNurseManager: true })}
            >
              <Icon name="linkify" />
              <Icon name="medkit" /> Assign To Nurse Case Manager
            </Button>
          )*/}
          <p>&nbsp;</p>
          <p>
            <i>
              Use search below to find a Patient, Referral Manager, Nurse Case
              Manager or <strong>exact</strong> Ref number
            </i>
          </p>

          <PaginateWrapper
            preserve_options={"Ref_Dash"}
            dataQuery={this.getReferrals}
            search={true}
            archived_toggle={{ title: "Archived", field: "archived" }}
            dropdown={{
              title: "Referral Status",
              field: "status",
              options: this.state.statusList,
            }}
            render={(items) => (
              <ReferralList
                data={items}
                action={(id) => this.props.history.push(`/referral/${id}`)}
                showCM={true}
              />
            )}
          />
        </Segment>
        {this.renderAssignCaseManagers()}
        {this.renderReassignCaseManagers()}
        {this.renderAssignNurseManagers()}
        {this.renderReassignNurseManagers()}
      </Segment>
    );
  }
}
