import React, { Component } from "react";
import {
  Table,
  Message,
  Button,
  Icon,
  Segment,
  Label,
  Modal,
  Header,
  Form
} from "semantic-ui-react";
// import moment from "moment";

import api from "../../../../actions/api"

// Requires data field names
class emailAutomationsList extends Component {

  state = {
    emails: [],
    openTemplateModal: false,
    assign: {
      code: null,
      id: null,
      template: null
    },
    templates: [],
    errors: {},
  }

  getData = () => {
    console.log("props", this.props);
    api.email_automation.getAll().then((res) => {
      this.setState({emails: res})
    })

    api.emails.getAll().then((res)=>{
      this.setState({templates: res.filter((i)=> i.archived == false).map((item)=>{
        return { value: item._id, text: item.template_name, key: item._id };
      })})
    })
  }

  componentDidMount(){
    this.getData();
  }

  openTemplateModal(item){
    this.setState({
      openTemplateModal: true,
      assign: {
        code: item.code,
        id: item._id,
        template: item.template._id
      }
    })
  }

  handleDropdownChange = (e, data) => {
    this.setState({ assign: { ...this.state.assign, [data.name]: data.value } });
  };

  validate = (data) => {
    const errors = {};
    if (
      data.template === ""
    )
      errors.template = "Template Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.assign);

    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      api.email_automation.editAutomation({template: this.state.assign.template}, this.state.assign.id).then(()=>{
        this.setState({openTemplateModal: false});
        this.getData();
      })
    }
  };

  renderTemplateModal(){
    const closeModal = () => {
      this.setState({ openTemplateModal: false });
    };
    
    return (
      <Modal
        open={this.state.openTemplateModal}
        onClose={closeModal}
        centered={false}
      >
        <Header icon="file alternate" content="Assign Template" />
        <Modal.Content>
          <Segment>
            <p>Assign Template for Automation - <strong>#{this.state.assign.code}</strong></p>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  name="template"
                  label="Template"
                  value={this.state.assign.template}
                  onChange={this.handleDropdownChange}
                  placeholder="Select Template"
                  options={this.state.templates !== undefined ? this.state.templates : []}
                  selection
                  search
                  error={this.state.errors.template}
                  width="14"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Button
                  floated="left"
                  color="red"
                  onClick={closeModal}
                >
                  <Icon name="close" />
                  Cancel
                </Form.Button>
                <Form.Button
                  floated="right"
                  color="green"
                  onClick={this.handleSubmit}
                >
                  <Icon name="checkmark" />
                  Assign
                </Form.Button>
              </Form.Group>
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  renderList() {
    if (this.state.emails && this.state.emails.length) {
      return this.state.emails.map((item) => {
        return (
          <Table.Row key={item._id}>
            <Table.Cell>{item.code}</Table.Cell>
            <Table.Cell>{item.description}</Table.Cell>
            <Table.Cell>{item.template ? item.template.template_name : "No Template Assigned!"} </Table.Cell>
            <Table.Cell><Button content="Assign Template" icon="file alternate" size="mini" onClick={()=> this.openTemplateModal(item)} /></Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  render() {
    return <Segment>
      {this.renderTemplateModal()}
      {(this.state.emails.length === 0) ? <Message>
          <Segment textAlign="center" basic>
            <p>No Email Automations To Display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add Email Automation
              </Button>
            )}
          </Segment>
        </Message> : <Table stackable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Automation Code</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Template</Table.HeaderCell>
            <Table.HeaderCell>&nbsp;</Table.HeaderCell>
            {/* <Table.HeaderCell width={1} textAlign="center">
               
              <Button positive icon onClick={()=> {return false}
                  // this.props.history.push("/settings/email_templates/add")
                }>
                <Icon name="plus" /> Add
              </Button>
            </Table.HeaderCell>*/}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>}
    </Segment>
  }
}

export default emailAutomationsList;
