import React, { Component } from "react";
import {
  Table,
  Button,
  Icon,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import moment from "moment";
import api from "../../../../actions/api";
import AppConfig from "../../../../_appConfig";
import { toast } from "react-semantic-toasts";
import { Authentication } from "../../../../lib/sso";

export default class testList extends Component {
  renderList() {
    if (this.props.data && this.props.data.length) {
      const list = this.props.data.map((data) => {
        let sampleTypes =
          data.assessment &&
          data.assessment.company &&
          data.assessment.company.sampleTypes &&
          data.assessment.company.sampleTypes.find((e) => e.id === data.type);

        let specifics =
          data.assessment &&
          data.assessment.company &&
          data.assessment.company.specifics &&
          data.assessment.company.specifics.find((e) => e.id === data.specific);

        return (
          <Table.Row
            key={data._id}
            onClick={() => {
              if (!Authentication.can("organisation_admin.permission")) {
                if (data.result_file) {
                  api.files.getFilelink(data.result_file).then((res) => {
                    window.open(AppConfig.api_server + res, "_blank");
                  });
                } else {
                  toast({
                    type: "error",
                    icon: "plus",
                    title: <h3>No Result</h3>,
                    description: (
                      <span>
                        <p>
                          No Result collected. Use Collect Results button to
                          download results if available.
                        </p>
                      </span>
                    ),
                    time: 4000,
                  });
                }
              }

              // this.props.action(data._id);
            }}
            className={
              moment.utc().diff(moment.utc(data.createdAt), "days") > 7 &&
              data.status === "New"
                ? "redItem"
                : ""
            }
          >
            <Table.Cell>{data.testNum}</Table.Cell>
            <Table.Cell>{data.barcode}</Table.Cell>
            <Table.Cell>
              {data.taken && moment(data.taken).format("DD-MM-YYYY HH:mm")}
            </Table.Cell>
            <Table.Cell>
              {sampleTypes ? sampleTypes.name : data.specific}
            </Table.Cell>
            <Table.Cell>{specifics ? specifics.name : data.type}</Table.Cell>
            <Table.Cell>
              {data.createdAt &&
                moment(data.createdAt).format("DD-MM-YYYY HH:mm")}
            </Table.Cell>

            <Table.Cell>
              <Header>A{data.assessment && data.assessment.assNum}</Header>
            </Table.Cell>
            <Table.Cell>
              {data.assessment &&
                data.assessment.employee &&
                data.assessment.employee.firstName +
                  " " +
                  data.assessment.employee.lastName +
                  " (#" +
                  (data.assessment.employee.ref
                    ? data.assessment.employee.ref
                    : "") +
                  ")"}
            </Table.Cell>
            <Table.Cell>
              {data.cm && data.cm.firstName
                ? data.cm.firstName + " " + data.cm.lastName
                : "Not Assigned"}
            </Table.Cell>
            <Table.Cell>
              {data.status === "Received" ? (
                <>
                  {data.status} <Icon name="file" />
                </>
              ) : (
                data.status
              )}
            </Table.Cell>
            {/* <Table.Cell></Table.Cell> */}
          </Table.Row>
        );
      });
      return list;
    }
  }

  render() {
    if (this.props.data && this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add New Test
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <>
        <Button
          positive
          icon="plus"
          content="Add Test"
          onClick={() => this.props.add()}
        />
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Barcode</Table.HeaderCell>
              <Table.HeaderCell>Test Date &amp; Time</Table.HeaderCell>
              <Table.HeaderCell>Test Type</Table.HeaderCell>
              <Table.HeaderCell>Specimen Type</Table.HeaderCell>
              <Table.HeaderCell>Submitted</Table.HeaderCell>
              <Table.HeaderCell>Health Journey #</Table.HeaderCell>
              <Table.HeaderCell>Client</Table.HeaderCell>
              {/* <Table.HeaderCell>Date of Birth</Table.HeaderCell> */}
              <Table.HeaderCell>Assigned Clinician</Table.HeaderCell>
              {/* <Table.HeaderCell>Date</Table.HeaderCell> */}
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderList()}</Table.Body>
        </Table>
      </>
    );
  }
}
