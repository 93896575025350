import React, { Component } from "react";
import {
  Table,
  Message,
  Button,
  Icon,
  Popup,
  Segment,
} from "semantic-ui-react";

class resourceCategoryList extends Component {
  renderList() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        const link = () => this.props.action(item._id);

        return (
          <Table.Row key={item._id}>
            <Table.Cell onClick={link}>{item.title}</Table.Cell>
            <Table.Cell />
          </Table.Row>
        );
      });
    }
  }
  render() {
    if (this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>No Resource Categories To Display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add Resource Categories
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <Table stackable selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            {this.props.allowAdd && (
              <React.Fragment>
                <Table.HeaderCell width={1} textAlign="center">
                  <Popup
                    position="top right"
                    trigger={
                      <Button positive icon onClick={this.props.add}>
                        <Icon name="plus" />
                      </Button>
                    }
                    content={"Add Resource Category"}
                  />
                </Table.HeaderCell>
              </React.Fragment>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}

export default resourceCategoryList;
