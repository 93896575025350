import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import Case from "./case";
import CasesDash from "./dash";
import CaseMenu from "./menu";
import CaseList from "./list";
import AddCaseNote from "./forms/addCaseNote";
import GetCaseNote from "./forms/getCaseNote";
import EditCase from "./forms/editCase";

export default class CasePages extends Component {
  render() {
    return (
      <Segment basic className="no-pad no-marg ">
        <CaseMenu />
        <Segment basic className="pageHolder">
          <Switch>
            <Route exact path={`/case/list`} component={CaseList} />
            <Route exact path={`/case/:id/addnote`} component={AddCaseNote} />
            <Route exact path={`/case/:id/note/:id2`} component={GetCaseNote} />
            <Route path={`/case/:id/edit`} component={EditCase} />
            <Route path={`/case/:id`} component={Case} />
            <Route exact path={``} component={CasesDash} />
          </Switch>
        </Segment>
      </Segment>
    );
  }
}
