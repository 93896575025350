import React, { Component } from "react";
import { Segment, Modal, Header, Loader } from "semantic-ui-react";
import api from "../../../../actions/api";
import AddServiceForm from "./forms/addServiceForm";
import PaginateWrapper from "../../common/OLD_PaginateWrapper";
import ServiceList from "../../common/lists/serviceList";

class index extends Component {
  state = {
    services: [],
    serviceModal: false,
    refreshList: false,
  };

  getData = (pageNum, pageSize, search, filters) => {
    return api.services.allPaged({ pageNum, pageSize, search, filters });
  };

  handlePositionChange = (item, direction) => {
    this.setState({ refreshList: true });
    if (direction === "up" && item.order_number !== 1) {
      api.services
        .adjustOrder(item._id, { adjust_direction: "up" })
        .then(() => {
          this.setState({ refreshList: false });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ refreshList: false });
        });
    } else if (direction === "down") {
      api.services
        .adjustOrder(item._id, { adjust_direction: "down" })
        .then(() => {
          this.setState({ refreshList: false });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ refreshList: false });
        });
    } else {
      this.setState({ refreshList: false });
    }
  };

  renderAddModal() {
    const handleClose = () => this.setState({ serviceModal: false });

    const handleAdd = (data) => {
      this.setState({ refreshList: true });
      api.services
        .add(data)
        .then(() => {
          this.setState({
            serviceModal: false,
            refreshList: false,
          });
        })
        .catch((e) => {
          this.setState({ refreshList: false });
        });
    };

    return (
      <Modal
        open={this.state.serviceModal}
        onClose={handleClose}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="book" content="Add Service" />
        <Modal.Content>
          <AddServiceForm submit={handleAdd} close={handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Service List
        </Header>
        <Segment className="border">
          {this.renderAddModal()}
          {this.state.refreshList === false ? (
            <PaginateWrapper
              dataQuery={this.getData}
              search={true}
              active={"Archived"}
              render={(items) => (
                <ServiceList
                  data={items}
                  action={(data) => {
                    if (data) {
                      this.props.history.push(
                        `/settings/services/${data}/edit`
                      );
                    }
                  }}
                  add={() => this.setState({ serviceModal: true })}
                  position_change={(data, order_direction) => {
                    if (data && order_direction) {
                      this.handlePositionChange(data, order_direction);
                    }
                  }}
                  allowAdd={true}
                />
              )}
            />
          ) : (
            <Segment basic>
              <Loader active />
            </Segment>
          )}
        </Segment>
      </Segment>
    );
  }
}

export default index;
