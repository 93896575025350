import React, { Component } from "react";
import { Form, Segment, Icon, Header, Label, Divider } from "semantic-ui-react";
import api from "../../actions/api";
import { DateInput } from "semantic-ui-calendar-react";
import PostCoder from "../../components/postCoder";
import AppConfig from "../../../../_appConfig";
import moment from "moment";

// import moment from "moment";
// import ReactPhoneInput from "react-phone-input-2";

const gender = [
  { text: "Male", value: "Male" },
  { text: "Female", value: "Female" },
  { text: "Other / Prefer Not To Say", value: "Other / Prefer Not To Say" },
];

export default class EditEmployee extends Component {
  state = {
    data: {
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      work_status: "",
      home_email: "",
      mobile_number: "",
      phone_number: "",
      address: {
        address: "",
        addressline1: "",
        addressline2: "",
        street: "",
        posttown: "",
        county: "",
        postcode: "",
      },
      meta: {
        height: 0,
        gender: "",
      },
      email: "",
      work_number: "",
      position: "",
      primary_benefit: "",
      secondary_benefit: "",
      third_benefit: "",
      leaver_date: "",
      archived: false,
    },
    homeEmailError: false,
    emailError: false,
    benefits: [],
    working_statuses: [],
    permissions: [],
    errors: {},
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Promise.all([
      api.settings.all(),
      api.employee.get(this.props.match.params.id),
      api.employee.listPermission(),
    ])
      .then((res) => {
        this.setState({
          data: {
            ...this.state.data,
            ...res[1],
            dob:
              res[1].dob !== undefined
                ? moment(res[1].dob).format("DD-MM-YYYY")
                : "",
          },
          working_statuses: res[0].workStatuses.map((item, i) => {
            return { text: item, value: item, key: i };
          }),
          permissions: res[2],
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  handlePermissionDropdownChange = (e, data) => {
    if (data.value.length === 2) {
      data.value.splice(0, 1);
      this.setState({
        data: { ...this.state.data, [data.name]: data.value },
      });
    } else if (data.value.length < 2) {
      this.setState({
        data: { ...this.state.data, [data.name]: data.value },
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleDateChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  handleAddressChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        address: {
          ...this.state.data.address,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  addressUpdate = (addr) => {
    this.setState({
      data: {
        ...this.state.data,
        address: {
          address: addr.summaryline,
          addressline1: addr.addressline1,
          addressline2: addr.addressline2,
          street: addr.street,
          posttown: addr.posttown,
          county: addr.county,
          postcode: addr.postcode,
          latitude: addr.latitude,
          longitude: addr.longitude,
          organisation: addr.organisation,
        },
      },
    });
  };

  validate = (data) => {
    let errors = {};

    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.firstName) errors.firstName = "Can't Be Empty";
    if (!data.lastName) errors.lastName = "Can't Be Empty";
    if (!data.ref) errors.ref = "Can't Be Empty";
    if (!data.email) errors.email = "Can't Be Empty.";
    if (
      data.email &&
      /^[\w\.\+\-]+@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(data.email) === false
    )
      errors.email = "Invalid Format.";
    if (
      data.home_email &&
      /^[\w\.\+\-]+@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(data.home_email) ===
        false
    )
      errors.home_email = "Invalid Format.";
    if (
      !data.dob ||
      (data.dob && moment(data.dob, "DD-MM-YYYY", true).isValid() === false)
    )
      errors.dob = "Invalid Format.";
    if (!data.work_number) errors.work_number = "Can't Be Empty.";
    if (data.work_number && data.work_number.toString().length < 4)
      errors.work_number = "Invalid Number.";
    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors: errors });

    if (Object.keys(errors).length === 0) {
      api.employee
        .editEmployee(this.props.match.params.id, this.state.data)
        .then(() => {
          this.props.history.push(`/employee/${this.props.match.params.id}`);
          this.setState({ homeEmailError: false, emailError: false });
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.message === "Dupe email not allowed."
          ) {
            this.setState({ emailError: true });
          }

          if (
            e.response &&
            e.response.data &&
            e.response.data.message === "Dupe home email not allowed."
          ) {
            this.setState({ homeEmailError: true });
          }
        });
    }
  };

  render() {
    return (
      <Segment basic>
        <Header as={"h1"} textAlign="center" className="no-marg sectionTitle ">
          Edit Employee
        </Header>
        <Segment className="border">
          <Form>
            <h3>Personal Details</h3>
            <Form.Group widths="equal">
              <Form.Input
                name="ref"
                label="Reference"
                value={this.state.data.ref}
                onChange={this.handleChange}
                placeholder="Reference"
                error={this.state.errors.ref}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field width={2}>
                <Form.Input
                  name="title"
                  label="Title"
                  value={this.state.data.title}
                  onChange={this.handleChange}
                  placeholder="Mr,Mrs...."
                  error={this.state.errors.title}
                />
              </Form.Field>
              <Form.Field width={4}>
                <Form.Input
                  name="firstName"
                  label="First Name"
                  value={this.state.data.firstName}
                  onChange={this.handleChange}
                  placeholder="First Name"
                  error={this.state.errors.firstName}
                />
              </Form.Field>
              <Form.Field width={3}>
                <Form.Input
                  name="middleName"
                  label="Middle Name"
                  value={
                    this.state.data.middleName ? this.state.data.middleName : ""
                  }
                  onChange={this.handleChange}
                  placeholder="Middle Name"
                />
              </Form.Field>
              <Form.Field width={4}>
                <Form.Input
                  name="lastName"
                  label="Last Name"
                  value={this.state.data.lastName}
                  onChange={this.handleChange}
                  placeholder="Last Name"
                  error={this.state.errors.lastName}
                />
              </Form.Field>
              <Form.Field
                width={3}
                error={this.state.errors.dob !== undefined ? true : false}
              >
                <DateInput
                  autoComplete={"off"}
                  animation="off"
                  label="Date of Birth"
                  name="dob"
                  value={this.state.data.dob ? this.state.data.dob : ""}
                  iconPosition="left"
                  onChange={this.handleDateChange}
                  startMode={"year"}
                  placeholder="Date Of Birth"
                  closable
                  dateFormat={"DD-MM-YYYY"}
                />
                {this.state.errors.dob && (
                  <Label pointing prompt>
                    {this.state.errors.dob}
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="home_email"
                label="Home Email"
                autoComplete={"off"}
                value={
                  this.state.data.home_email ? this.state.data.home_email : ""
                }
                onChange={this.handleChange}
                placeholder="Home Email"
                error={
                  this.state.errors.home_email ||
                  (this.state.homeEmailError === true
                    ? "Duplicate email."
                    : false)
                }
              />

              <Form.Field>
                <label>Mobile Number</label>
                <input
                  name="mobile_number"
                  autoComplete={"off"}
                  value={
                    this.state.data.mobile_number
                      ? this.state.data.mobile_number
                      : ""
                  }
                  onChange={this.handleChange}
                  placeholder="07973 123456"
                  error={this.state.errors.mobile_number}
                />
              </Form.Field>
              <Form.Field>
                <label>Home Number</label>
                <input
                  name="phone_number"
                  autoComplete={"off"}
                  value={
                    this.state.data.phone_number
                      ? this.state.data.phone_number
                      : ""
                  }
                  onChange={this.handleChange}
                  placeholder="01233 123456"
                  error={this.state.errors.phone_number}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Dropdown
                  name="work_status"
                  label="Working Status"
                  value={
                    this.state.data.work_status
                      ? this.state.data.work_status
                      : ""
                  }
                  onChange={this.handleDropdownChange}
                  placeholder="Please Select Working Status"
                  options={this.state.working_statuses}
                  selection
                />
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  name="gender"
                  label="Gender"
                  value={this.state.data.meta && this.state.data.meta.gender}
                  onChange={this.onMetaDropdownChange}
                  placeholder="Please Select Gender"
                  options={gender}
                  selection
                />
              </Form.Field>
              <Form.Input
                type="number"
                name="height"
                label="Height (M)"
                value={
                  this.state.data.meta && this.state.data.meta.height
                    ? this.state.data.meta.height
                    : ""
                }
                onChange={this.onMetaChange}
                placeholder="Height (M)"
              />
            </Form.Group>
            <Form.Dropdown
              label="Select User Role(s)"
              placeholder="Permission Groups"
              name="groups"
              value={this.state.data.groups}
              onChange={this.handlePermissionDropdownChange}
              options={this.state.permissions}
              search
              selection
              multiple
              error={this.state.errors.groups}
            />
            <Form.Group widths="equal">
              <Form.Field>
                <label>Address Lookup</label>
                {/* <label>Please Use Demo Postcode: NR14 7PZ</label> */}
                <PostCoder
                  apiKey={AppConfig.postcoder_api_key}
                  addressSelectedCallback={this.addressUpdate}
                  identifier={AppConfig.postcoder_identifier}
                  country={"UK"}
                  showAddress={false}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="addressline1"
                label="Address Line 1"
                value={this.state.data.address.addressline1}
                onChange={this.handleAddressChange}
                placeholder="Address Line 1"
                error={this.state.errors.addressline1}
              />
              <Form.Input
                name="addressline2"
                label="Address Line 2"
                value={this.state.data.address.addressline2}
                onChange={this.handleAddressChange}
                placeholder="Address Line 2"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="street"
                label="Street"
                value={this.state.data.address.street}
                onChange={this.handleAddressChange}
                placeholder="Street"
                error={this.state.errors.street}
              />
              <Form.Input
                name="posttown"
                label="Town / City"
                value={this.state.data.address.posttown}
                onChange={this.handleAddressChange}
                placeholder="Postal Town / City"
                error={this.state.errors.posttown}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="county"
                label="County"
                value={this.state.data.address.county}
                onChange={this.handleAddressChange}
                placeholder="County"
                error={this.state.errors.county}
              />
              <Form.Input
                name="postcode"
                label="Postcode"
                value={this.state.data.address.postcode}
                onChange={this.handleAddressChange}
                placeholder="Postcode"
                error={this.state.errors.postcode}
              />
            </Form.Group>
            {/* <Form.Group widths="equal">
              <Form.Field>
                <Form.Dropdown
                  name="gender"
                  label="Gender"
                  value={this.state.data.meta && this.state.data.meta.gender}
                  onChange={this.onMetaDropdownChange}
                  placeholder="Please Select Gender"
                  options={gender}
                  selection
                />
              </Form.Field>
              <Form.Input
                type="number"
                name="height"
                label="Height"
                value={
                  this.state.data.meta && this.state.data.meta.height
                    ? this.state.data.meta.height
                    : ""
                }
                onChange={this.onMetaChange}
                placeholder="Height"
              />
            </Form.Group> */}
            <h3>Work Details</h3>
            <Form.Group widths="equal">
              <Form.Input
                type="email"
                name="email"
                label="Work Email"
                value={this.state.data.email}
                onChange={this.handleChange}
                placeholder="Email"
                error={
                  this.state.errors.email ||
                  (this.state.emailError === true ? "Duplicate email." : false)
                }
              />
              <Form.Field
                error={
                  this.state.errors.work_number !== undefined ? true : false
                }
              >
                <div
                  style={{
                    paddingBottom: "14px",
                    paddingTop: "5px",
                    fontSize: ".92857143em",
                  }}
                >
                  <label>
                    <strong>Work Phone</strong>
                  </label>
                  <input
                    name="work_number"
                    autoComplete={"off"}
                    value={
                      this.state.data.work_number
                        ? this.state.data.work_number
                        : ""
                    }
                    onChange={this.handleChange}
                    placeholder="07973 123456"
                    error={this.state.errors.work_number}
                  />
                </div>
                {this.state.errors.work_number && (
                  <Label pointing prompt>
                    {this.state.errors.work_number}
                  </Label>
                )}
              </Form.Field>
              {this.state.data.company !== undefined ? (
                <Form.Input
                  name="position"
                  label="Position"
                  value={this.state.data.position}
                  onChange={this.handleChange}
                  placeholder="Clerk, Driver, HR Manager...."
                />
              ) : (
                <React.Fragment />
              )}
            </Form.Group>
            {/* <Form.Group inline>
              <Checkbox
                label="Archived"
                name="archived"
                checked={this.state.data.archived}
                onClick={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      archived: !this.state.data.archived,
                      leaver_date: "",
                    },
                  });
                }}
              />
            </Form.Group> */}
            <Divider />
            <Form.Group widths="equal">
              <Form.Button
                floated="left"
                color="red"
                onClick={() => {
                  window.history.back();
                }}
              >
                <Icon name="arrow left" />
                Back
              </Form.Button>
              <Form.Button
                floated="right"
                type="submit"
                positive
                onClick={this.handleSubmit}
              >
                <Icon name="pencil" />
                Update
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
      </Segment>
    );
  }
}
