import React from "react";
import Authentication from "../Authentication";

 class Can extends React.Component {

    render(){
        let userCan = Authentication.can(this.props.has, this.props.context);
        if (this.props.passThrough){
            let func = this.props.children;
            if(Array.isArray(func)){
                func = func[0]
            }
            if(typeof func === "function"){
                return func(userCan);
            }
        }
        if (userCan){
            return this.props.children;
        }
        return this.props.no();
    }
}
Can.defaultProps = {
    has: "",
    context: {},
    no: () => null
};
export default Can;