import React, { Component } from "react";
import { Menu, Header } from "semantic-ui-react";
import { NavLink, withRouter } from "react-router-dom";
import { FaListUl } from "react-icons/fa";

class CaseMenu extends Component {
  state = {
    activeItem: "",
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  render() {
    const { activeItem } = this.state;
    return (
      <Menu className="subMenu case" stackable>
        <Menu.Item>
          <Header>Case Menu</Header>
        </Menu.Item>
        <Menu.Item
          exact
          as={NavLink}
          to={this.props.match.url}
          name="dash"
          active={activeItem === "dash"}
          onClick={this.handleItemClick}
        >
          Dash
        </Menu.Item>

        <Menu.Item
          exact
          as={NavLink}
          to={this.props.match.url + "/list"}
          name="list"
          active={activeItem === "list"}
          onClick={this.handleItemClick}
        >
          <FaListUl />
          List
        </Menu.Item>
      </Menu>
    );
  }
}
export default withRouter(CaseMenu);
