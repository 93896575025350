import React, { Component } from "react";
import _ from "lodash";
import {
  Button,
  Grid,
  Input,
  Pagination,
  Segment,
  Table,
  Select,
  Message,
  Icon,
  Label,
} from "semantic-ui-react";
import Moment from "react-moment";

export default class CaseNotesList extends Component {
  state = {
    column: null,
    direction: null,
    pageSize: 15,
    currentPage: 1,
    query: {
      search: "",
      archived: true,
    },
  };

  /**
   * Handlers
   */
  handleSort = (clickedColumn) => () => {
    const { column, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: "ascending",
      });
      return;
    }
    this.setState({
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handlePageSizeChange = (props, e) => {
    this.setState({
      pageSize: e.value,
      currentPage: 1, // Reset to one even if we are a few pages in
    });
  };

  handlePageChange = (e, { activePage }) => {
    this.setState({ currentPage: activePage });
  };

  handleSearchQuery = (e) => {
    this.setState(
      {
        query: {
          ...this.state.query,
          search: e.target.value,
        },
      },
      () => {
        this.props.onQuery(this.state.query);
      }
    );
  };

  handleStatusQuery = (data) => {
    this.setState(
      {
        query: {
          ...this.state.query,
          archived: data,
        },
      },
      () => {
        this.props.onQuery(this.state.query);
      }
    );
  };

  /**
   * Renders
   */
  renderList() {
    const { column, direction } = this.state;
    let data = _.sortBy(this.props.logs, column);
    if (direction === "descending") {
      data = data.reverse();
    }
    // Return the list in the page form
    data = data.slice(
      (this.state.currentPage - 1) * this.state.pageSize,
      this.state.currentPage * this.state.pageSize
    );
    const logs = data.map((c) => {
      return (
        <Table.Row key={c._id} onClick={() => this.props.action(c._id)}>
          <Table.Cell collapsing>{c.refNum}</Table.Cell>
          <Table.Cell collapsing>{c.logType}</Table.Cell>
          <Table.Cell collapsing>
            <Moment format={"DD-MM-YYYY HH:mm"}>{c.createdAt}</Moment>
          </Table.Cell>
          <Table.Cell>
            {c.message.split("\n").map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              );
            })}
          </Table.Cell>
          <Table.Cell collapsing>
            {c.logDue ? (
              <Moment format={"DD-MM-YYYY HH:mm"}>{c.logDue}</Moment>
            ) : (
              "--"
            )}{" "}
          </Table.Cell>
          <Table.Cell collapsing>{c.loggedBy}</Table.Cell>
          <Table.Cell collapsing>
            <Label icon="file" content={c.files ? c.files.length : 0} />
          </Table.Cell>
          <Table.Cell collapsing textAlign="center">
            {c.visible ? (
              <Icon name="check" color="green" />
            ) : (
              <Icon name="close" color="red" />
            )}
          </Table.Cell>
          <Table.Cell collapsing textAlign="center">
            {c.myActivities ? (
              <Icon name="check" color="green" />
            ) : (
              <Icon name="close" color="red" />
            )}
          </Table.Cell>
          <Table.Cell collapsing textAlign="center">
            {c.sendEmailCA || c.sendEmailCM || c.sendEmailEmp ? (
              <Icon name="check" color="green" />
            ) : (
              <Icon name="close" color="red" />
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
    if (logs.length === 0) {
      return (
        <Message info>
          <Segment textAlign="center" basic>
            <Message.Header>No Records</Message.Header>
            <p>There are no records to display</p>
            <Button icon positive size="tiny" onClick={this.props.add}>
              <Icon name="plus" /> Add Note
            </Button>
          </Segment>
        </Message>
      );
    }
    return (
      <Table stackable selectable sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === "ref" ? direction : null}
              onClick={this.handleSort("ref")}
            >
              Ref
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "logType" ? direction : null}
              onClick={this.handleSort("logType")}
            >
              Type
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "date" ? direction : null}
              onClick={this.handleSort("date")}
            >
              Date
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "message" ? direction : null}
              onClick={this.handleSort("message")}
            >
              Message
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "dueDate" ? direction : null}
              onClick={this.handleSort("dueDate")}
            >
              Due Date
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "loggedBy" ? direction : null}
              onClick={this.handleSort("loggedBy")}
            >
              Logged By
            </Table.HeaderCell>
            <Table.HeaderCell>Documents</Table.HeaderCell>
            <Table.HeaderCell>Visible</Table.HeaderCell>
            <Table.HeaderCell>Activity</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            {/* <Table.HeaderCell width={1} textAlign="right">
              <Button fluid icon positive size="tiny" onClick={this.props.add}>
                <Icon name="plus" />
              </Button>
            </Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        <Table.Body>{logs}</Table.Body>
      </Table>
    );
  }

  renderPaginationArea() {
    let totalRecords = this.props.logs.length;
    let totalPages = totalRecords / this.state.pageSize;
    return (
      <Segment
        basic
        className="no-pad "
        style={{ justifyContent: "flex-end", display: "flex" }}
      >
        {totalPages !== 0 && (
          <Pagination
            size="mini"
            activePage={this.state.currentPage}
            onPageChange={this.handlePageChange}
            totalPages={totalPages}
            style={{ marginRight: "1rem" }}
          />
        )}
        <Select
          name={"PageSize"}
          options={[
            { key: 15, value: 15, text: "15 records" },
            { key: 30, value: 30, text: "30 records" },
            { key: 50, value: 50, text: "50 records" },
          ]}
          value={this.state.pageSize}
          onChange={this.handlePageSizeChange}
        />
      </Segment>
    );
  }

  render() {
    return (
      <Segment basic>
        <Grid>
          <Grid.Row>
            {/*<Grid.Column width={5} textAlign="center">
              <Segment basic className="no-pad ">
                 <Button.Group className="buttonToggle" size="large">
                  <Button
                    active={this.state.query.archived}
                    onClick={() => this.handleStatusQuery(true)}
                  >
                    Active
                  </Button>
                  <Button
                    active={!this.state.query.archived}
                    onClick={() => this.handleStatusQuery(false)}
                  >
                    Closed
                  </Button>
                </Button.Group> 
              </Segment>
            </Grid.Column>*/}
            <Grid.Column width={4}>
              <Segment basic className="no-pad ">
                <Input
                  className="inputStyle"
                  autoComplete="off"
                  name="name"
                  fluid
                  placeholder="Search notes"
                  icon="search"
                  onChange={this.handleSearchQuery}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={5}>&nbsp;</Grid.Column>
            <Grid.Column width={7}>{this.renderPaginationArea()}</Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>{this.renderList()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>{this.renderPaginationArea()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
