import React, { Component } from "react";
import {
  Table,
  Message,
  Button,
  Icon,
  Popup,
  Segment,
} from "semantic-ui-react";
import moment from "moment";

class systemLegalDocsList extends Component {
  renderList() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        return (
          <Table.Row key={item._id}>
            <Table.Cell>{item.type}</Table.Cell>
            <Table.Cell>{item.file}</Table.Cell>
            <Table.Cell>
              {moment(item.createdAt).format("DD/MM/YYYY HH:mm")}
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
        );
      });
    }
  }

  render() {
    if (this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>No System Legal Documents To Display</p>
            <p>
              <i>Note: The Most Recent Upload(s) Will Be Displayed Above</i>
            </p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add System Legal Document
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <Table stackable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>File Name</Table.HeaderCell>
            <Table.HeaderCell>Upload Date</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              <Popup
                position="top right"
                trigger={
                  <Button positive icon onClick={this.props.add}>
                    <Icon name="plus" />
                  </Button>
                }
                content={"Add System Legal Document"}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}

export default systemLegalDocsList;
