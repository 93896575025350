import React, { Component } from "react";
import { Segment, Header, Card } from "semantic-ui-react";

export default class dashboard extends Component {
  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Report Dashboard
        </Header>
        <Segment className="border">
          <Card.Group itemsPerRow="2">
            <Card
              onClick={() => {
                this.props.history.push("/settings/reports/referral_report");
              }}
            >
              <Card.Content>
                <Card.Header>Referral Report</Card.Header>
                <Card.Meta>
                  Referrals filtered by: company, date and referral source.{" "}
                </Card.Meta>
              </Card.Content>
            </Card>
            <Card
              onClick={() => {
                this.props.history.push("/settings/reports/case_report");
              }}
            >
              <Card.Content>
                <Card.Header>Case Report</Card.Header>
                <Card.Meta>Cases filtered by: company and date. </Card.Meta>
              </Card.Content>
            </Card>
            <Card
              onClick={() => {
                this.props.history.push("/settings/reports/service_report");
              }}
            >
              <Card.Content>
                <Card.Header>Service Report</Card.Header>
                <Card.Meta>
                  Services filtered by: company, date and click through rate.
                </Card.Meta>
              </Card.Content>
            </Card>
            <Card
              onClick={() => {
                this.props.history.push("/settings/reports/employee_report");
              }}
            >
              <Card.Content>
                <Card.Header>Employee Report</Card.Header>
                <Card.Meta>
                  Employees filtered by: company, date and employment status.
                </Card.Meta>
              </Card.Content>
            </Card>
          </Card.Group>
        </Segment>
      </Segment>
    );
  }
}
