import React, { Component } from "react";
import api from "../../../actions/api";
import { Segment, Header, Button, Icon, Modal, Form } from "semantic-ui-react";
import AssessmentDataList from "../common/lists/assessmentList";
import PaginateWrapper from "../common/PaginateWrapper";
import { Authentication, Can } from "../../../lib/sso";
import AssignCaseManagers from "../../_cn/components/forms/assignCaseManagers";

export default class AssessmentList extends Component {
  state = {
    data: {},
    statusList: [],
    referrals: [],
    companies: [],
    cases: [],
    employees: [],
    clinicianList: [],
  };

  componentDidMount() {
    api.company.getManagedCompanyEmployeesDropdown().then((employees) => {
      this.setState({ employees });
    });

    api.company.getAllClinicians().then((clinicians) => {
      let clinicianList = [
        { text: "Not Assigned", value: "Not Assigned" },
        // { text: "C1", value: "62fa07a2b595960ad5a3f479" },
      ];

      clinicians.map((item, i) => {
        clinicianList.push({
          text: `${item.firstName} ${item.lastName} (#${item.ref})`,
          value: item._id,
          key: i,
        });
      });

      this.setState({
        clinicianList,
      });
    });

    api.settings.all().then((res) => {
      let statusList = [{ text: "New", value: "New" }];

      res.assessmentStatuses &&
        res.assessmentStatuses.map((item, i) => {
          statusList.push({ text: item, value: item, key: i });
        });

      this.setState({
        statusList,
      });
      if (Authentication.can("navigator_admin.permission")) {
        api.assessment.getAll().then((res) => {
          console.log(
            "🚀 ~ file: list.js ~ line 43 ~ AssessmentList ~ api.assessment.getAll ~ res",
            res
          );
          let companies = this.state.companies;
          res.map((s) => {
            if (s.company) {
              if (!this.state.companies.includes(s.company._id)) {
                companies.push(s.company._id);
              }
            }
          });
          this.setState({
            referrals: res,
            companies,
          });
        });
      } else {
        api.assessment.getAllCM().then((res) => {
          let companies = this.state.companies;
          res.map((s) => {
            if (!this.state.companies.includes(s.company._id)) {
              companies.push(s.company._id);
            }
          });
          this.setState({
            referrals: res,
            companies,
          });
        });
      }
    });
  }

  getReferrals = (pageNum, pageSize, search, filters) => {
    if (Authentication.can("navigator_admin.permission")) {
      return api.assessment.getAllPaged({ pageNum, pageSize, search, filters });
    }
    if (
      Authentication.can("navigator_case_manager.permission") === true ||
      Authentication.can("organisation_case_manager.permission") === true
    ) {
      return api.assessment.getAllCMPaged({
        pageNum,
        pageSize,
        search,
        filters,
      });
    }
    return new Promise((resolve) => resolve([])); // Return a empty array in a promise as required by the PaginateWrapper
  };

  getCases = (pageNum, pageSize, search, filters) => {
    if (Authentication.can("navigator_admin.permission")) {
      return api.case.getAllPaged({ pageNum, pageSize, search, filters });
    }
    if (
      Authentication.can("navigator_case_manager.permission") === true ||
      Authentication.can("organisation_case_manager.permission") === true
    ) {
      return api.case.getAllCMPaged({ pageNum, pageSize, search, filters });
    }
    return new Promise((resolve) => resolve([])); // Return a empty array in a promise as required by the PaginateWrapper
  };

  renderReassignCaseManagers() {
    console.log("props", this.props, "state", this.state);
    const handleClose = () => this.setState({ reassignCaseManager: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.case
        .assignMultiCaseManager(data)
        .then((res) => {
          this.getData();
          handleClose();
          this.setState({ reload: false });
        })
        .catch((e) => {
          this.setState({ reload: false });
        });
    };

    return (
      <Modal
        open={this.state.reassignCaseManager}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="clone"
          content="ReAssigning referral(s) to company referral manager"
        />
        <Modal.Content>
          <AssignCaseManagers
            close={handleClose}
            submit={handleSubmit}
            reassign={true}
            referrals={this.state.referrals}
            company={
              this.props.company
                ? this.props.company._id
                : this.state.companies.length > 0
                ? this.state.companies[0]
                : null
            }
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderAssignCaseManagers() {
    const handleClose = () => this.setState({ assignCaseManager: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.case
        .assignMultiCaseManager(data)
        .then((res) => {
          this.getData();
          handleClose();
          this.setState({ reload: false });
        })
        .catch((e) => {
          this.setState({ reload: false });
        });
    };

    return (
      <Modal
        open={this.state.assignCaseManager}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="linkify"
          content="Assigning referral(s) to company referral manager"
        />
        <Modal.Content>
          <AssignCaseManagers
            close={handleClose}
            submit={handleSubmit}
            referrals={this.getReferrals}
            company={
              this.props.company
                ? this.props.company._id
                : this.state.companies.length > 0
                ? this.state.companies[0]
                : null
            }
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderReassignNurseManagers() {
    console.log("props", this.props, "state", this.state);
    const handleClose = () => this.setState({ reassignNurseManager: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.case
        .assignMultiCaseManager(data)
        .then((res) => {
          this.getData();
          handleClose();
          this.setState({ reload: false });
        })
        .catch((e) => {
          this.setState({ reload: false });
        });
    };

    return (
      <Modal
        open={this.state.reassignNurseManager}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="clone"
          content="ReAssigning case(s) to nurse case manager"
        />
        <Modal.Content>
          <AssignCaseManagers
            close={handleClose}
            submit={handleSubmit}
            reassign={true}
            referrals={this.state.referrals}
            nurseManager
            company={
              this.props.company
                ? this.props.company._id
                : this.state.companies.length > 0
                ? this.state.companies[0]
                : null
            }
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderAssignNurseManagers() {
    const handleClose = () => this.setState({ assignNurseManager: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.case
        .assignMultiCaseManager(data)
        .then((res) => {
          this.getData();
          handleClose();
          this.setState({ reload: false });
        })
        .catch((e) => {
          this.setState({ reload: false });
        });
    };

    return (
      <Modal
        open={this.state.assignNurseManager}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="linkify"
          content="Assigning case(s) to nurse case manager"
        />
        <Modal.Content>
          <AssignCaseManagers
            close={handleClose}
            submit={handleSubmit}
            referrals={this.getReferrals}
            nurseManager
            company={
              this.props.company
                ? this.props.company._id
                : this.state.companies.length > 0
                ? this.state.companies[0]
                : null
            }
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderAssessmentAddModal() {
    const handleClose = () => this.setState({ addAssessmentModal: false });

    const addAssessment = () => {
      api.assessment.addAssessment(this.state.data.patient).then((res) => {
        this.props.history.push(`/assessment/${res.id}`);
      });
    };

    return (
      <Modal
        open={this.state.addAssessmentModal}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="file" content="Add Health Journey" />
        <Modal.Content>
          <Segment>
            <Form>
              <Form.Group widths="equal">
                <Form.Dropdown
                  name="patient"
                  label="Client"
                  value={this.state.data.patient}
                  onChange={(e, data) => {
                    this.setState({
                      data: { ...this.state.data, [data.name]: data.value },
                    });
                  }}
                  placeholder="Please Select Client for new Health Journey"
                  options={this.state.employees}
                  selection
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Button
                  type="submit"
                  color="red"
                  floated="left"
                  content="Cancel"
                  icon="close"
                  onClick={() => handleClose()}
                />
                <Form.Button
                  disabled={!this.state.data.patient}
                  color="green"
                  floated="right"
                  content="Add"
                  icon="plus"
                  onClick={() => addAssessment()}
                />
              </Form.Group>
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className=" pageTitle">
          Health Journeys List
        </Header>
        <Segment className="border">
          <Can has={"assessment.reassign.later"}>
            <Button
              // floated="right"
              positive
              onClick={() => this.setState({ assignCaseManager: true })}
            >
              <Icon name="linkify" />
              <Icon name="user" /> Assign To Clinician
            </Button>
            <Button
              // floated="right"
              color="orange"
              onClick={() => this.setState({ reassignCaseManager: true })}
            >
              <Icon name="clone" />
              <Icon name="user" /> ReAssign To Clinician
            </Button>
          </Can>
          {/*  
          {!Authentication.can("organisation_case_manager.permission") &&
            !Authentication.can("organisation_clinician.permission") &&
            !Authentication.can("navigator_admin.permission") && (
              
            )}
          {!Authentication.can("navigator_admin.permission") &&
            !Authentication.can("organisation_clinician.permission")(
              
            )}*/}
          {/*Authentication.can("organisation_case_manager.permission") ||
            (Authentication.can("navigator_admin.permission") && (
              <Button
                // floated="right"
                color="orange"
                onClick={() => this.setState({ reassignNurseManager: true })}
              >
                <Icon name="clone" />
                <Icon name="medkit" /> ReAssign To Nurse Case Manager
              </Button>
            ))}
          {Authentication.can("navigator_admin.permission") && (
            <Button
              // floated="right"
              positive
              onClick={() => this.setState({ assignNurseManager: true })}
            >
              <Icon name="linkify" />
              <Icon name="medkit" /> Assign To Nurse Case Manager
            </Button>
          )*/}
          <p>&nbsp;</p>
          <p>
            <i>
              Use search below to find a Client, Clinician{" "}
              {/*, Date of Birth{" "}
              <strong>(DD/MM/YYYY format)</strong> */}{" "}
              or <strong>exact</strong> Ref number
            </i>
          </p>
          <PaginateWrapper
            preserve_options={"Ref_Dash"}
            dataQuery={this.getReferrals}
            search={true}
            archived_toggle={{ title: "Archived", field: "archived" }}
            dropdown={{
              title: "Health Journey Status",
              field: "status",
              options: this.state.statusList,
            }}
            dropdown_two={{
              title: "Assigned Clinician",
              field: "cm",
              options: this.state.clinicianList,
            }}
            render={(items) => (
              <AssessmentDataList
                data={items}
                action={(id) => this.props.history.push(`/assessment/${id}`)}
                showCM={true}
                add={() => {
                  this.setState({ addAssessmentModal: true });
                }}
              />
            )}
          />
        </Segment>
        {this.renderAssignCaseManagers()}
        {this.renderReassignCaseManagers()}
        {this.renderAssignNurseManagers()}
        {this.renderReassignNurseManagers()}
        {this.renderAssessmentAddModal()}
      </Segment>
    );
  }
}
