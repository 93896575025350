import React, { Component } from "react";
import { Segment, Modal, Header, Loader } from "semantic-ui-react";
import api from "../../../../actions/api";
import AddBenefitForm from "./forms/addBenefitForm";
import PaginateWrapper from "../../common/OLD_PaginateWrapper";
import BenefitList from "../../common/lists/settingsBenefitList";

export default class BenefitListDash extends Component {
  state = {
    benefits: [],
    benefitModal: false,
    refreshList: false,
  };

  getData = (pageNum, pageSize, search, filters) => {
    return api.benefits.allPaged({ pageNum, pageSize, search, filters });
  };

  renderAddBenefitModal() {
    const handleClose = () => this.setState({ benefitModal: false });

    const handleAdd = (data) => {
      this.setState({ refreshList: true });
      api.benefits
        .add(data)
        .then(() => {
          this.setState({
            benefitModal: false,
            refreshList: false,
          });
        })
        .catch((e) => {
          this.setState({ refreshList: false });
        });
    };

    return (
      <Modal
        open={this.state.benefitModal}
        onClose={handleClose}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="list" content="Add Benefit" />
        <Modal.Content>
          <AddBenefitForm submit={handleAdd} close={handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className=" pageTitle">
          Benefit List
        </Header>
        <Segment className="border">
          {this.renderAddBenefitModal()}
          {this.state.refreshList === false ? (
            <PaginateWrapper
              dataQuery={this.getData}
              search={true}
              active={"Archived"}
              render={(items) => (
                <BenefitList
                  data={items}
                  action={(data) => {
                    if (data) {
                      this.props.history.push(`/settings/benefits/${data}`);
                    }
                  }}
                  add={() => this.setState({ benefitModal: true })}
                  allowAdd={true}
                />
              )}
            />
          ) : (
            <Segment basic>
              <Loader active />
            </Segment>
          )}
        </Segment>
      </Segment>
    );
  }
}
