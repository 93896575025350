import React, { Component } from "react";
import {
  Form,
  Segment,
  Icon,
  Message,
  Divider,
  Image,
} from "semantic-ui-react";

export default class add extends Component {
  state = {
    data: {
      title: "",
      description: "",
      splash_image: null,
    },
    preview_upload: null,
    isHovered: false,
    loading: false,
    errors: {},
  };

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  onFileLoad = (e) => {
    if (e.currentTarget.files[0] === undefined) {
      return;
    }

    let file = e.currentTarget.files[0];
    let allowedTypes = ["image/jpeg", "image/png"];

    let isAllowed = false;

    for (let i in allowedTypes) {
      let type = allowedTypes[i];
      if (file.type === type) {
        isAllowed = true;
      }
    }

    if (!isAllowed) {
      alert("Only Image files are allowed to be uploaded.");
      return;
    }

    this.setState(
      {
        data: {
          ...this.state.data,
          splash_image: file,
        },
        preview_upload: URL.createObjectURL(file),
      },
      () => {
        URL.revokeObjectURL(file);
      }
    );
  };

  validate = (data) => {
    const errors = {};

    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.description) errors.description = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      let formData = new FormData();
      let keys = Object.keys(this.state.data);

      for (let i in keys) {
        formData.append(keys[i], this.state.data[keys[i]]);
      }

      this.props.submit(formData);
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="title"
              label="Title"
              value={this.state.data.title}
              onChange={this.handleChange}
              placeholder="Title"
              error={this.state.errors.title}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.TextArea
              name="description"
              label="Description"
              value={this.state.data.description}
              onChange={this.handleChange}
              placeholder="Description"
              error={this.state.errors.description}
            />
          </Form.Group>
          {this.state.data.splash_image === null ? (
            <Segment basic className="inner-container no-hpad">
              <div
                className={`draggable-container ${
                  this.state.isHovered && "draggable-container-hovered"
                }`}
              >
                <input
                  type="file"
                  id="file-browser-input"
                  name="file-browser-input"
                  ref={(input) => (this.fileInput = input)}
                  onDragOver={() => this.setState({ isHovered: true })}
                  onDragLeave={() => this.setState({ isHovered: false })}
                  onChange={(e) => this.onFileLoad(e)}
                />
                <div className="helper-text">
                  <p>
                    Drop Picture Here Or <br />
                    Click To Browse (<b>MAX 5MB</b>)
                  </p>
                </div>
              </div>
            </Segment>
          ) : (
            <Segment basic className="no-hpad">
              <Message
                positive
                icon
                onDismiss={() =>
                  this.setState({
                    data: { ...this.state.data, splash_image: null },
                    preview_upload: null,
                  })
                }
              >
                <Icon name="checkmark" />
                <Message.Header>File Uploaded Successfully</Message.Header>
              </Message>
              <Image
                className="preview-image"
                src={this.state.preview_upload}
              />
            </Segment>
          )}
          <Divider clearing />
          <Form.Group widths="equal">
            <Form.Button
              floated="left"
              color="red"
              onClick={() => this.props.close()}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              floated="right"
              color="green"
              onClick={() => this.handleSubmit()}
              loading={this.state.loading}
            >
              <Icon name="plus" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
