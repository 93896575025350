import React, { Component } from "react";
import { Segment, Modal, Header, Loader } from "semantic-ui-react";
import api from "../../../actions/api";
import StaffList from "../common/lists/staffList";
import PaginateWrapper from "../common/OLD_PaginateWrapper";
import AddStaffForm from "./forms/addStaffForm";

export default class StaffListPage extends Component {
  state = {
    addStaff: false,
    emailError: false,
    loading: false,
    data: {
      isPN: true,
    },
  };

  getData = (pageNum, pageSize, search, filters) => {
    return api.team.getTeamMembersPaged({
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  renderAddEmployee() {
    const handleClose = () => this.setState({ addStaff: false });

    const onSubmit = (data) => {
      this.setState({ loading: true });
      api.team
        .add(data)
        .then(() => {
          this.setState({ addStaff: false, loading: false });
        })
        .catch((e) => {
          console.log(e);

          if (
            e.response &&
            e.response.data &&
            e.response.data.message === "Dupe email not allowed."
          ) {
            this.setState({ emailError: true });
          }

          this.setState({ loading: false });
        });
    };

    return (
      <Modal
        open={this.state.addStaff}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="user" content="Add Staff Member" />
        <Modal.Content>
          <Segment>
            <AddStaffForm
              submit={onSubmit}
              close={handleClose}
              emailError={this.state.emailError}
            />
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Staff
        </Header>
        {this.renderAddEmployee()}
        <Segment className="border">
          {this.state.loading === false ? (
            <PaginateWrapper
              dataQuery={this.getData}
              search={true}
              active={"Archived"}
              render={(items) => (
                <StaffList
                  staff={items}
                  action={(data) => {
                    if (data) {
                      this.props.history.push(`/staff/${data}`);
                    }
                  }}
                  allowAdd={true}
                  add={() => this.setState({ addStaff: true })}
                />
              )}
            />
          ) : (
            <Segment basic>
              <Loader active></Loader>
            </Segment>
          )}
        </Segment>
      </Segment>
    );
  }
}
