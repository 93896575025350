import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import CompanyList from "./companyListDash";
import AddCompany from "./forms/addCompanyForm";
import CompanyMenu from "./companyMenu";
import Company from "./company";
import EditCompany from "./forms/editCompanyForm";
import CompanySettings from "./companySettings";
import ViewAssignedBenefit from "./assigned_benefits/assignedBenefitPage";
import EditAssignedBenefit from "./assigned_benefits/forms/updateAssignedBenefit";
import RouteScopeCheck from "../common/RouteScopeCheck";
import { Authentication } from "../../../lib/sso";
import api from "../../../actions/api";

import ResourcesEdit from "./resources/forms/edit";

class CompanyPages extends Component {
  state = {
    company: {},
  };
  componentDidMount() {
    api.company.myCompany().then((company) => {
      this.setState({ company });
    });
  }

  render() {
    return (
      <Segment basic className="no-pad no-marg ">
        <CompanyMenu />
        <Segment basic className="pageHolder">
          <Switch>
            <Route
              path={`/company/:id/benefit/:id2/edit`}
              component={EditAssignedBenefit}
            />
            <Route
              path={`/company/:id/benefit/:id2`}
              component={ViewAssignedBenefit}
            />
            {Authentication.can("organisation_admin.permission") &&
              this.state.company &&
              this.state.company.c1h === true && (
                <Route
                  exact
                  path={`/company/add`}
                  component={RouteScopeCheck("company.add", AddCompany)}
                />
              )}
            {Authentication.can("navigator_admin.permission") && (
              <Route
                exact
                path={`/company/add`}
                component={RouteScopeCheck("company.add", AddCompany)}
              />
            )}
            <Route exact path={`/company/list`} component={CompanyList} />
            <Route
              exact
              path={`/company/:id/settings/resource/:resource`}
              component={ResourcesEdit}
            />
            <Route
              exact
              path={`/company/:id/settings`}
              component={RouteScopeCheck("company.edit", CompanySettings)}
            />
            
            <Route
              exact
              path={`/company/:id/edit`}
              component={RouteScopeCheck("company.edit", EditCompany)}
            />
            <Route path={`/company/:id`} component={Company} />
            <Route exact path={``} component={CompanyList} />
          </Switch>
        </Segment>
      </Segment>
    );
  }
}

export default withRouter(CompanyPages);
