import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Table,
  Menu,
  Icon,
  Message,
} from "semantic-ui-react";
import api from "../../../../actions/api";
import moment from "moment";
import { Link } from "react-router-dom";

export default class BenefitPage extends Component {
  state = {
    benefit: {},
    companies: [],
    activeItem: "companies",
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Promise.all([
      api.benefits.show(this.props.match.params.id),
      api.benefits.getCompanies(this.props.match.params.id),
    ]).then((res) => {
      this.setState({
        benefit: {
          ...res[0],
          assigned_services: res[0].assigned_services.filter(
            (item) => item.archived === false
          ),
        },
        companies: res[1],
      });
    });
  };

  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });

  dateCheck = (date) => {
    let colour = "red";
    if (date) {
      let now = new Date().toString();
      if (date < now) {
        colour = "green";
      }
    }
    return colour;
  };

  renderMenu() {
    const { activeItem } = this.state;

    return (
      <Menu className="subMenu" stackable>
        <Menu.Item
          name="companies"
          active={activeItem === "companies"}
          onClick={this.handleMenuClick}
        >
          Companies
        </Menu.Item>
        <Menu.Item
          name="services"
          active={activeItem === "services"}
          onClick={this.handleMenuClick}
        >
          Assigned Services
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item
            as={Link}
            to={`/settings/benefits/${this.props.match.params.id}/edit`}
          >
            <Icon name="pencil" />
            Edit Benefit
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }

  renderCompanies() {
    const list = this.state.companies.map((company) => {
      let past = false;

      if (moment(company.renewal_date).isBefore(moment())) {
        past = true;
      }

      return (
        <Table.Row
          key={company._id}
          negative={past}
          positive={!past}
          onClick={() => {
            this.props.history.push(`/company/${company.company._id}`);
          }}
        >
          <Table.Cell>{company.company.name}</Table.Cell>
          <Table.Cell>{company.benefit_number}</Table.Cell>
          <Table.Cell>{company.start_date}</Table.Cell>
          <Table.Cell>{company.renewal_date}</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Segment basic>
        <Header>Companies With This Benefit</Header>
        <Table stackable selectable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Ref</Table.HeaderCell>
              <Table.HeaderCell>Start date</Table.HeaderCell>
              <Table.HeaderCell>Renewal Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.companies.length !== 0 ? (
              list
            ) : (
              <Table.Row>
                <Table.Cell colSpan="4">No Data</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }

  renderServices() {
    const list = this.state.benefit.assigned_services.map((item) => {
      return (
        <Table.Row key={item._id}>
          <Table.Cell>{item.title}</Table.Cell>
          <Table.Cell>{item.description_short}</Table.Cell>
          <Table.Cell>
            {item.service_resources &&
              item.service_resources.map((resource, index) => {
                return index < item.service_resources.length - 1
                  ? resource.service_type + ", "
                  : resource.service_type;
              })}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Segment basic>
        <Header>Assigned Benefit Services</Header>
        <Table stackable selectable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Service</Table.HeaderCell>
              <Table.HeaderCell>Short Description</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.benefit.assigned_services &&
            this.state.benefit.assigned_services.length !== 0 ? (
              list
            ) : (
              <Table.Row>
                <Table.Cell colSpan="3">No Data</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }

  render() {
    const { benefit, activeItem } = this.state;

    return (
      <React.Fragment>
        {this.state.loading === false &&
        benefit.archived !== undefined &&
        benefit.archived !== false ? (
          <Message icon info>
            <Icon name="info circle" />
            <Message.Content>
              <Message.Header>Benefit Is Archived</Message.Header>
              This record is archived and will not display on lists.
            </Message.Content>
          </Message>
        ) : (
          <React.Fragment />
        )}
        <Segment className="no-pad benefitPage">
          <Segment basic>
            <Grid>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Segment basic className="no-pad compDetails">
                    <Header as={"h1"}>{benefit.title}</Header>
                    {/* <Header as={"h4"}>
                    Benefit Number:
                    <span>{benefit.benefit_number}</span>
                  </Header>
                  <Header as={"h4"}>
                    Insurer:
                    <span>{benefit.insurer}</span>
                  </Header>
                  <Header as={"h4"}>
                    Underwritten:
                    <span>{benefit.underwritten}</span>
                  </Header> */}
                    <Header as={"h4"}>
                      Allowance:
                      <span>
                        {benefit.allowance !== undefined &&
                        benefit.allowance !== 0
                          ? `£${benefit.allowance}`
                          : "N/A"}
                      </span>
                    </Header>
                    <Header as={"h4"}>
                      Payment Frequency:
                      <span>{benefit.payment_frequency}</span>
                    </Header>
                    <Header as={"h4"}>
                      Address:
                      <span>
                        {benefit.address !== undefined &&
                        benefit.address.address !== undefined
                          ? benefit.address.address
                          : "None Provided"}
                      </span>
                    </Header>
                  </Segment>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Segment style={{ marginTop: "1rem" }}>
                    <Header>Notes</Header>
                    {benefit.notes !== undefined && benefit.notes !== ""
                      ? benefit.notes
                      : "No Notes To Display."}
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          {this.renderMenu()}
          <Segment className="no-pad" basic>
            {activeItem === "companies" && this.renderCompanies()}
            {activeItem === "services" && this.renderServices()}
          </Segment>
        </Segment>
      </React.Fragment>
    );
  }
}
