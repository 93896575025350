import React, { Component } from "react";
import { Header, Segment, Modal, Button, Icon } from "semantic-ui-react";
import api from "../../actions/api";
import { Authentication } from "../../../../lib/sso";
import ReferralTable from "../../components/lists/referralList";
import PaginateWrapper from "../../components/PaginateWrapper";
import Company_Receiver from "../../contexts/company_context/Company_Receiver";
import AssignCaseManagers from "../../components/forms/assignCaseManagers";

class ReferralList extends Component {
  state = {
    reload: true,
    assignCaseManager: false,
    reassignCaseManager: false,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.company !== prevProps.company) {
      this.setState({
        reload: false,
      });
    }
  }

  getData = () => {
    this.props.company._refresh();
  };

  getReferrals = (pageNum, pageSize, search, filters) => {
    return api.company.listReferralsPaged(this.props.company._id, {
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  gotoReferral = (data) => {
    if (data) {
      this.props.history.push(`/referral/${data}`);
    }
  };

  renderReferrals() {
    {
      /* <Can has={"company.benefit.employee.assign"}> </Can> */
    }

    return (
      <Segment className="border">
        {!Authentication.can("organisation_clinician.permission") && (
          <>
            <Button
              // floated="right"
              positive
              onClick={() => this.setState({ assignCaseManager: true })}
            >
              <Icon name="linkify" /> Assign To Referral Manager
            </Button>
            <Button
              // floated="right"
              color="orange"
              onClick={() => this.setState({ reassignCaseManager: true })}
            >
              <Icon name="clone" /> ReAssign To Referral Manager
            </Button>
            <p>
              <i>
                Use search below to find a Patient, Referral Manager, Nurse Case
                Manager or <strong>exact</strong> Ref number
              </i>
            </p>
          </>
        )}

        {this.state.reload === false && (
          <PaginateWrapper
            dataQuery={this.getReferrals}
            search={true}
            render={(items) => (
              <ReferralTable data={items} action={this.gotoReferral} />
            )}
          />
        )}
      </Segment>
    );
  }

  renderReassignCaseManagers() {
    const handleClose = () => this.setState({ reassignCaseManager: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.case
        .assignMultiCaseManager(data)
        .then((res) => {
          this.getData();
          handleClose();
          this.setState({ reload: false });
        })
        .catch((e) => {
          this.setState({ reload: false });
        });
    };

    return (
      <Modal
        open={this.state.reassignCaseManager}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="clone"
          content="ReAssigning referral(s) to company referral manager"
        />
        <Modal.Content>
          <AssignCaseManagers
            close={handleClose}
            submit={handleSubmit}
            reassign={true}
            referrals={this.getReferrals}
            company={this.props.company._id}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderAssignCaseManagers() {
    const handleClose = () => this.setState({ assignCaseManager: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.case
        .assignMultiCaseManager(data)
        .then((res) => {
          this.getData();
          handleClose();
          this.setState({ reload: false });
        })
        .catch((e) => {
          this.setState({ reload: false });
        });
    };

    return (
      <Modal
        open={this.state.assignCaseManager}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="linkify"
          content="Assigning referral(s) to company referral manager"
        />
        <Modal.Content>
          <AssignCaseManagers
            close={handleClose}
            submit={handleSubmit}
            referrals={this.getReferrals}
            company={this.props.company._id}
          />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Segment basic>
        <Header className={"sectionTitle"}>Referral List.</Header>
        {this.renderReferrals()}
        {this.renderAssignCaseManagers()}
        {this.renderReassignCaseManagers()}
      </Segment>
    );
  }
}

export default Company_Receiver(ReferralList, (company) => {
  return {
    company,
  };
});
