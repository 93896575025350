import React from "react";
import {
  Pagination,
  Segment,
  Select,
  Message,
  Button,
  Grid,
  Form,
} from "semantic-ui-react";

const pageSizes = [10, 25, 50, 100];

export default class PaginateWrapper extends React.Component {
  state = {
    pageSize: pageSizes[1],
    pageNum: 1,
    totalPages: 0,
    totalItems: 0,
    search: "",
    sort: "ASC",
    filters: {},
    items: [],
    loading: true,
    globalError: null,
  };

  componentDidMount() {
    if (this.props.preserve_options !== undefined) {
      let search = sessionStorage.getItem(
        `${this.props.preserve_options}_search`
      );

      let sort = this.props.sort
        ? sessionStorage.getItem(`${this.props.preserve_options}_sort`)
        : null;
      let filters = sessionStorage.getItem(
        `${this.props.preserve_options}_filters`
      );
      let page_num = sessionStorage.getItem(
        `${this.props.preserve_options}_pageNum`
      );
      let page_size = sessionStorage.getItem(
        `${this.props.preserve_options}_pageSize`
      );

      this.setState(
        {
          pageSize: page_size !== null ? parseFloat(page_size) : pageSizes[1],
          pageNum: page_num !== null ? parseFloat(page_num) : 1,
          search: search !== null ? search : "",
          sort: sort !== null ? sort : "ASC",
          filters:
            filters !== null
              ? JSON.parse(filters)
              : this.props.archived_toggle !== undefined
              ? { [this.props.archived_toggle.field]: false }
              : {},
        },
        () => this.queryData()
      );
    } else if (this.props.archived_toggle !== undefined) {
      this.setState(
        { filters: { [this.props.archived_toggle.field]: false } },
        () => this.queryData()
      );
    } else {
      this.queryData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.dataQuery !== this.props.dataQuery) {
      this.queryData();
    }
  }

  handleSearch = (e, { name, value }) =>
    this.setState({ [name]: value, pageNum: 1 }, () => {
      if (this.props.preserve_options !== undefined) {
        sessionStorage.setItem(`${this.props.preserve_options}_pageNum`, 1);

        sessionStorage.setItem(`${this.props.preserve_options}_search`, value);
      }
      this.queryData();
    });

  handleDropdownChange = (e, data) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [data.name]: data.value,
        },
        pageNum: 1,
      },
      () => {
        if (this.props.preserve_options !== undefined) {
          sessionStorage.setItem(`${this.props.preserve_options}_pageNum`, 1);

          sessionStorage.setItem(
            `${this.props.preserve_options}_filters`,
            JSON.stringify(this.state.filters)
          );
        }
        this.queryData();
      }
    );
  };

  queryData() {
    this.setState(
      {
        loading: true,
        globalError: null,
      },
      () => {
        this.props
          .dataQuery(
            this.state.pageNum,
            this.state.pageSize,
            this.state.search,
            this.state.filters,
            this.state.sort
          )
          .then((data) => {
            this.setState({
              pageNum: data.page,
              totalPages: data.pages,
              totalItems: data.total,
              items: data.docs,
            });
          })
          .catch((err) => {
            let globalError =
              "There was an unexpected error while retrieving data from the server";

            if (err.response !== undefined) {
              globalError = err.response.data.message;
            }

            this.setState({
              globalError,
            });
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  }

  render() {
    return (
      <Segment className="no-pad" basic loading={this.state.loading}>
        {this.state.globalError && (
          <Message negative>
            <Message.Header>ERROR</Message.Header>
            {this.state.globalError}
          </Message>
        )}
        <Grid columns="2">
          {(this.props.search === true ||
            this.props.dropdown !== undefined) && (
            <Grid.Row columns={1} style={{ paddingBottom: "0px" }}>
              <Grid.Column>
                <Form>
                  <Form.Group widths="equal">
                    {this.props.search === true ? (
                      <Form.Input
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearch}
                        placeholder="Search..."
                      />
                    ) : (
                      <React.Fragment />
                    )}
                    {this.props.dropdown !== undefined ? (
                      <Form.Dropdown
                        name={this.props.dropdown.field}
                        value={this.state.filters[this.props.dropdown.field]}
                        onChange={this.handleDropdownChange}
                        placeholder={`Filter By ${this.props.dropdown.title}...`}
                        options={this.props.dropdown.options}
                        clearable
                        selection
                      />
                    ) : (
                      <React.Fragment />
                    )}
                    {this.props.dropdown_two !== undefined ? (
                      <Form.Dropdown
                        name={this.props.dropdown_two.field}
                        value={
                          this.state.filters[this.props.dropdown_two.field]
                        }
                        onChange={this.handleDropdownChange}
                        placeholder={`Filter By ${this.props.dropdown_two.title}...`}
                        options={this.props.dropdown_two.options}
                        clearable
                        selection
                      />
                    ) : (
                      <React.Fragment />
                    )}
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row
            columns={1}
            style={{ paddingTop: "0px", paddingBottom: "0px" }}
          >
            <Grid.Column
              textAlign="right"
              style={{
                paddingTop:
                  this.props.search !== undefined ||
                  this.props.dropdown !== undefined ||
                  this.props.dropdown_two !== undefined
                    ? "0px"
                    : "14px",
                paddingBottom: "0px",
              }}
            >
              <label>Page Size: </label>
              <Select
                options={pageSizes.map((i) => {
                  return {
                    key: i,
                    value: i,
                    text: i,
                  };
                })}
                value={this.state.pageSize}
                onChange={(e, data) => {
                  this.setState(
                    {
                      pageSize: data.value,
                      pageNum: 1,
                    },
                    () => {
                      if (this.props.preserve_options !== undefined) {
                        sessionStorage.setItem(
                          `${this.props.preserve_options}_pageNum`,
                          1
                        );
                        sessionStorage.setItem(
                          `${this.props.preserve_options}_pageSize`,
                          data.value
                        );
                      }
                      this.queryData();
                    }
                  );
                }}
              />
            </Grid.Column>
          </Grid.Row>
          {(this.props.archived_toggle !== undefined ||
            this.props.sort !== undefined) && (
            <Grid.Row style={{ paddingBottom: "0px" }}>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                {this.props.sort !== undefined ? (
                  <>
                    <Button.Group fluid>
                      <Button
                        content={`Sort ${this.props.sort.title} ${
                          this.state.sort === "ASC" ? "Descending" : "Ascending"
                        }`}
                        onClick={() => {
                          if (this.props.preserve_options !== undefined) {
                            sessionStorage.setItem(
                              `${this.props.preserve_options}_sort`,
                              this.state.sort === "ASC" ? "DSC" : "ASC"
                            );
                          }

                          this.setState(
                            {
                              sort: this.state.sort === "ASC" ? "DSC" : "ASC",
                            },
                            () => {
                              this.queryData();
                            }
                          );
                        }}
                      />
                    </Button.Group>
                  </>
                ) : (
                  <React.Fragment />
                )}
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8}>
                {this.props.archived_toggle !== undefined ? (
                  <Button.Group fluid>
                    <Button
                      content={`Show ${this.props.archived_toggle.title}`}
                      positive={
                        this.state.filters[this.props.archived_toggle.field] ===
                        true
                      }
                      onClick={() =>
                        this.setState(
                          {
                            filters: {
                              ...this.state.filters,
                              [this.props.archived_toggle.field]: true,
                            },
                          },
                          () => {
                            if (this.props.preserve_options !== undefined) {
                              sessionStorage.setItem(
                                `${this.props.preserve_options}_filters`,
                                JSON.stringify(this.state.filters)
                              );
                            }
                            this.queryData();
                          }
                        )
                      }
                    />
                    <Button
                      content={`Hide ${this.props.archived_toggle.title}`}
                      negative={
                        this.state.filters[this.props.archived_toggle.field] ===
                        false
                      }
                      onClick={() =>
                        this.setState(
                          {
                            filters: {
                              ...this.state.filters,
                              [this.props.archived_toggle.field]: false,
                            },
                          },
                          () => {
                            if (this.props.preserve_options !== undefined) {
                              sessionStorage.setItem(
                                `${this.props.preserve_options}_filters`,
                                JSON.stringify(this.state.filters)
                              );
                            }
                            this.queryData();
                          }
                        )
                      }
                    />
                  </Button.Group>
                ) : (
                  <React.Fragment />
                )}
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>{this.props.render(this.state.items)}</Grid.Column>
          </Grid.Row>
          {this.state.items.length >= 1 && (
            <Grid.Row columns={1}>
              <Grid.Column textAlign="right">
                <Pagination
                  style={{ flexWrap: "wrap" }}
                  defaultActivePage={this.state.pageNum}
                  totalPages={this.state.totalPages}
                  onPageChange={(e, { activePage }) => {
                    this.setState({ pageNum: activePage }, () => {
                      if (this.props.preserve_options !== undefined) {
                        sessionStorage.setItem(
                          `${this.props.preserve_options}_pageNum`,
                          activePage
                        );
                      }
                      this.queryData();
                    });
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    );
  }
}
