import React, { Component } from "react";
import {
  Button,
  Divider,
  Form,
  Icon,
  Segment,
  Message,
} from "semantic-ui-react";

class uploadFile extends Component {
  state = {
    data: {
      file: null,
    },
    isHovered: false,
    loading: false,
    errors: {},
  };

  onSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors }, () => console.log(this.state));
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      this.props.submit(this.state.data);
    }
  };

  onFileLoad = (e) => {
    if (e.currentTarget.files[0] === undefined) {
      return;
    }

    let file = e.currentTarget.files[0];
    let allowedTypes = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/pdf",
      "image/jpeg",
      "image/png",
    ];

    let isAllowed = false;

    for (let i in allowedTypes) {
      let type = allowedTypes[i];
      if (file.type === type) {
        isAllowed = true;
      }
    }

    if (!isAllowed) {
      alert("Only PDF or image files are allowed to be uploaded");
      return;
    }

    this.setState({
      data: {
        ...this.state.data,
        file: file,
      },
    });
  };

  validate = (data) => {
    const errors = {};
    if (!data.file) errors.file = "Can't be empty";
    return errors;
  };

  render() {
    return (
      <Segment>
        <Form>
          {this.state.data.file === null ? (
            <Segment
              basic
              className="inner-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="sub-header">Drag A .PDF Or Image File Here</div>
              <div
                className={`draggable-container ${
                  this.state.isHovered && "draggable-container-hovered"
                }`}
              >
                <input
                  type="file"
                  id="file-browser-input"
                  name="file-browser-input"
                  onDragOver={() => this.setState({ isHovered: true })}
                  onDragLeave={() => this.setState({ isHovered: false })}
                  onChange={(e) => this.onFileLoad(e)}
                />
                <div className="helper-text">
                  Drop file here or <br />
                  click to browse (max 5mb)
                </div>
              </div>
            </Segment>
          ) : (
            <Segment basic>
              <Message
                positive
                icon="checkmark"
                header="File Uploaded Successfully"
                onDismiss={() =>
                  this.setState({ data: { ...this.state.data, file: null } })
                }
              />
            </Segment>
          )}
          <Divider clearing />
          <Form.Group widths={"equal"} className={"form-actions"}>
            <Form.Field>
              <Button color="red" floated={"left"} onClick={this.props.close}>
                <Icon name="close" /> Cancel
              </Button>
            </Form.Field>
            <Form.Field>
              <Button
                color="green"
                onClick={() => this.onSubmit()}
                floated="right"
              >
                <Icon name="plus" /> Add
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default uploadFile;
