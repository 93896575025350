import React, { Component } from "react";
import {
  Button,
  Divider,
  Form,
  Icon,
  Segment,
  Message,
  Tab,
} from "semantic-ui-react";
import api from "../../../../actions/api";
import FileManagerList from "../lists/fileManagerList";

class uploadFile extends Component {
  state = {
    data: {
      file: null,
      description: null,
      private_file: false,
    },
    uploadError: null,
    isHovered: false,
    loading: false,
    errors: {},
  };

  componentDidMount() {
    this.loadFolders();
  }

  loadFolders(item, search = false) {
    console.log("searching?", search, item);
    this.setState({ loading: true });
    if (item) {
      api.files.getFolder(item).then((res) => {
        let ret = {
          files: res.files,
          folder: res.folder,
          folders: res.folders,
          loading: false,
        };

        console.log(ret);

        if (search) {
          this.setState({
            loading: false,
            search: ret,
          });
        } else {
          this.setState(ret);
        }
      });
      api.files.getFolderPath(item).then((res) => {
        if (search) {
          this.setState({
            search: {
              ...this.state.search,
              path: res,
            },
          });
        } else {
          this.setState({
            path: res,
          });
        }
      });
    } else {
      api.files.getAll().then((res) => {
        let ret = {
          path: "/home",
          folder: null,
          folders: res.folders,
          files: res.files,
          loading: false,
        };

        if (search) {
          this.setState({
            loading: false,
            search: ret,
          });
        } else {
          this.setState(ret);
        }
      });
    }
  }

  onSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors }, () =>
      console.log("🚀 ~ file: uploadFile.js ~ uploadFile ~ errors", this.state)
    );

    if (
      Object.keys(errors).length === 0 ||
      (errors.file && this.props.bypassEmpty)
    ) {
      this.setState({ loading: true });
      this.props.submit(this.state.data);
    }
  };

  onSecondarySubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors }, () =>
      console.log("🚀 ~ file: uploadFile.js ~ uploadFile ~ errors", this.state)
    );

    if (
      Object.keys(errors).length === 0 ||
      (errors.file && this.props.bypassEmpty)
    ) {
      this.setState({ loading: true });
      this.props.secondarySubmit(this.state.data);
    }
  };

  onSubmitExisting() {
    console.log("res", this.state);
    this.setState({ loading: true });
    this.props.submit(this.state.data);
  }

  onFileLoad = (e) => {
    if (e.currentTarget.files[0] === undefined) {
      return;
    }

    let file = e.currentTarget.files[0];
    let size = file.size / 1000000;

    let allowedTypes = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/pdf",
      "image/jpeg",
      "image/png",
    ];

    let isAllowed = false;

    for (let i in allowedTypes) {
      let type = allowedTypes[i];
      if (file.type === type) {
        isAllowed = true;
      }
    }

    if (size > 5) {
      this.setState({
        uploadError: "Filesize is greater than 5mb!",
      });
      return;
    }

    if (!isAllowed) {
      // alert("Only PDF or image files are allowed to be uploaded");
      this.setState({
        // uploadError: "Only PDF or image files are allowed to be uploaded"
        uploadError:
          "Only PDF, Documents or image files are allowed to be uploaded",
      });
      return;
    }

    this.setState({
      data: {
        ...this.state.data,
        file: file,
      },
    });
  };

  validate = (data) => {
    const errors = {};
    if (!data.file) errors.file = "Can't be empty";
    return errors;
  };

  uploadForm() {
    return (
      <Form>
        {this.state.uploadError ? (
          <Segment basic>
            <Message
              negative
              icon="exclamation triangle"
              header="File Upload Error"
              content={this.state.uploadError}
              onDismiss={() =>
                this.setState({
                  uploadError: null,
                  data: { ...this.state.data, file: null },
                })
              }
            />
          </Segment>
        ) : this.state.data.file === null ? (
          <Segment
            basic
            className="inner-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {/* <div className="sub-header">Drag A .PDF Or Image File Here</div> */}
            <div className="sub-header">
              Drag A PDF, Document or Image File Here
            </div>
            <div
              className={`draggable-container ${
                this.state.isHovered && "draggable-container-hovered"
              }`}
            >
              <input
                type="file"
                id="file-browser-input"
                name="file-browser-input"
                onDragOver={() => this.setState({ isHovered: true })}
                onDragLeave={() => this.setState({ isHovered: false })}
                onChange={(e) => this.onFileLoad(e)}
              />
              <div className="helper-text">
                Drop file here or <br />
                click to browse (max{" "}
                {this.props.max_upload ? this.props.max_upload : 5}mb)
              </div>
            </div>
          </Segment>
        ) : (
          <Segment basic>
            <Message
              positive
              icon="checkmark"
              header="File Uploaded Successfully"
              onDismiss={() =>
                this.setState({ data: { ...this.state.data, file: null } })
              }
            />
          </Segment>
        )}
        <Divider clearing />
        <Form.Input
          label={"File Description"}
          name={"description"}
          onChange={(e) =>
            this.setState({
              data: { ...this.state.data, description: e.target.value },
            })
          }
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              this.onSubmit();
            }
          }}
          value={this.state.data.description}
        />
        <Form.Checkbox
          name="private_file"
          label="Private File"
          checked={this.state.data.private_file}
          onChange={() =>
            this.setState({
              data: {
                ...this.state.data,
                private_file: !this.state.data.private_file,
              },
            })
          }
        />
        <Form.Group widths={"equal"} className={"form-actions"}>
          <Form.Field>
            <Button color="red" floated={"left"} onClick={this.props.close}>
              <Icon name="close" /> Cancel
            </Button>
          </Form.Field>
          <Form.Field>
            <Button
              color="green"
              onClick={() => this.onSubmit()}
              floated="right"
              loading={this.state.loading && !this.props.bypassLoading}
            >
              <Icon
                name={this.props.submitIcon ? this.props.submitIcon : "plus"}
              />{" "}
              {this.props.submitTitle ? this.props.submitTitle : "Add"}
            </Button>
            {this.props.secondarySubmit && (
              <Button
                color="orange"
                onClick={() => this.onSecondarySubmit()}
                floated="right"
                loading={this.state.loading && !this.props.bypassLoading}
              >
                <Icon
                  name={
                    this.props.secondarySubmitIcon
                      ? this.props.secondarySubmitIcon
                      : "pencil"
                  }
                />{" "}
                {this.props.secondarySubmitTitle
                  ? this.props.secondarySubmitTitle
                  : "Secondary"}
              </Button>
            )}
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }

  renderExisting() {
    let { folders, folder, path, files, search, selectedFile } = this.state;
    return (
      <Form>
        <Segment basic>
          <FileManagerList
            useExisting={true}
            search={search}
            folder={folder}
            folders={folders}
            path={path}
            files={files}
            border="false"
            loadFolders={(a, b) => this.loadFolders(a, b)}
            selectExisting={(id) => {
              this.setState({
                data: {
                  ...this.state.data,
                  selectedFile: id,
                },
              });
            }}
          />
          <Divider clearing />
          <Form.Checkbox
            name="private_file"
            label="Private File"
            checked={this.state.data.private_file}
            onChange={() =>
              this.setState({
                data: {
                  ...this.state.data,
                  private_file: !this.state.data.private_file,
                },
              })
            }
          />
          <Form.Group widths={"equal"} className={"form-actions"}>
            <Form.Field>
              <Button color="red" floated={"left"} onClick={this.props.close}>
                <Icon name="close" /> Cancel
              </Button>
            </Form.Field>
            <Form.Field>
              <Button
                color="green"
                onClick={() => this.onSubmitExisting()}
                floated="right"
              >
                <Icon name="plus" /> Select File
              </Button>
            </Form.Field>
          </Form.Group>
        </Segment>
      </Form>
    );
  }

  render() {
    const panes = [
      { menuItem: "New File", render: () => this.uploadForm() },
      !this.props.hideExisting && {
        menuItem: "Existing File",
        render: () => this.renderExisting(),
      },
    ];

    return (
      <Segment>
        {this.props.enableFileManager ? (
          <Tab panes={panes} />
        ) : (
          this.uploadForm()
        )}
      </Segment>
    );
  }
}

export default uploadFile;
