import React, { Component } from "react";
import { Button, Divider, Form, Icon, Segment } from "semantic-ui-react";

class add extends Component {
  state = {
    data: {
      title: "",
    },
    loading: false,
    errors: {},
  };

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors }, () => console.log(this.state));
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      this.props.submit(this.state.data);
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.title) errors.title = "Can't be empty";
    return errors;
  };

  render() {
    const { data, errors } = this.state;
    return (
      <Segment>
        <Form>
          <Form.Field>
            <Form.Input
              label="Title"
              error={errors.title}
              autoComplete="off"
              type="text"
              name="title"
              value={data.title}
              onChange={this.onChange}
            />
          </Form.Field>
          <Divider clearing />
          <Form.Group widths={"equal"} className={"form-actions"}>
            <Form.Field>
              <Button color="red" floated={"left"} onClick={this.props.close}>
                <Icon name="close" /> Cancel
              </Button>
            </Form.Field>
            <Form.Field>
              <Button
                color="green"
                onClick={() => this.onSubmit()}
                floated="right"
              >
                <Icon name="plus" /> Add
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default add;
