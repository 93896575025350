import React, { Component } from "react";
import {
  Table,
  Message,
  Button,
  Icon,
  Popup,
  Segment,
} from "semantic-ui-react";
import moment from "moment";

class templateList extends Component {
  renderList() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        return (
          <Table.Row key={item._id} onClick={() => this.props.action(item._id)}>
            <Table.Cell width="10">{item.description}</Table.Cell>
            <Table.Cell width="2" textAlign="center">
              {item.createdAt !== undefined
                ? moment(item.createdAt).format("DD/MM/YYYY HH:mm")
                : "[NO DATA]"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {item.consent_default === true ? (
                <Icon name="check" color="green" />
              ) : (
                <Icon name="close" color="red" />
              )}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {item.referral_default === true ? (
                <Icon name="check" color="green" />
              ) : (
                <Icon name="close" color="red" />
              )}
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
        );
      });
    }
  }

  render() {
    if (this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>No Templates To Display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add Template
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <Table stackable selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Upload Date</Table.HeaderCell>
            <Table.HeaderCell>Consent Default</Table.HeaderCell>
            <Table.HeaderCell>Referral Default</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              <Popup
                position="top center"
                trigger={
                  <Button positive icon onClick={this.props.add}>
                    <Icon name="plus" />
                  </Button>
                }
                content={"Create Template"}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}

export default templateList;
