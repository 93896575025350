import React, { Component } from "react";
import { Segment, Modal, Header, Loader, Card } from "semantic-ui-react";
import api from "../../../../actions/api";
import AddForm from "./forms/add";
import PaginateWrapper from "../../common/OLD_PaginateWrapper";
import List from "../../common/lists/systemLegalDocsList";
import AppConfig from "../../../../_appConfig";

export default class index extends Component {
  state = {
    current_files: {
      privacy_policy: { name: "", file: null },
      terms_and_conditions: { name: "", file: null },
    },
    addModal: false,
    loadingRecent: false,
    refreshList: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ loadingRecent: true });
    api.system_legal_docs
      .getCurrent()
      .then((res) => {
        this.setState({
          loadingRecent: false,
          current_files: {
            privacy_policy: res.privacy_policy,
            terms_and_conditions: res.terms_and_conditions,
          },
        });
      })
      .catch(() => {
        this.setState({ loadingRecent: false });
      });
  };

  getLegalDocs = (pageNum, pageSize, search, filters) => {
    return api.system_legal_docs.allPaged({
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  renderAddModal() {
    const handleClose = () => this.setState({ addModal: false });

    const handleAdd = (data) => {
      this.setState({ refreshList: true });
      api.system_legal_docs
        .add(data)
        .then(() => {
          this.setState({
            addModal: false,
            refreshList: false,
          });
        })
        .catch((e) => {
          this.setState({ refreshList: false });
        });
    };

    return (
      <Modal
        open={this.state.addModal}
        onClose={handleClose}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="upload" content="Add System Legal Document" />
        <Modal.Content>
          <AddForm submit={handleAdd} close={handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Segment basic className="no-pad">
        {this.renderAddModal()}
        <Header as={"h1"} textAlign="center" className="pageTitle">
          System Legal Document Management
        </Header>
        <Segment className="border">
          <Header as={"h2"} textAlign="center" className="pageSubTitle">
            Current Document(s)
          </Header>
          {(this.state.current_files.privacy_policy.file !== null ||
            this.state.current_files.terms_and_conditions.file !== null) &&
          this.state.loadingRecent === false ? (
            <Segment basic className="no-vmarg">
              <Card.Group itemsPerRow="2">
                {this.state.current_files.privacy_policy.file !== null ? (
                  <Card
                    onClick={() => {
                      window.open(
                        AppConfig.api_server +
                          this.state.current_files.privacy_policy.file,
                        "_blank"
                      );
                    }}
                  >
                    <Card.Content>
                      <Card.Header>Privacy Policy</Card.Header>
                      <Card.Meta>Click To Download</Card.Meta>
                      <Card.Description>
                        {this.state.current_files.privacy_policy.name}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                ) : (
                  <React.Fragment />
                )}
                {this.state.current_files.terms_and_conditions.file !== null ? (
                  <Card
                    onClick={() => {
                      window.open(
                        AppConfig.api_server +
                          this.state.current_files.terms_and_conditions.file,
                        "_blank"
                      );
                    }}
                  >
                    <Card.Content>
                      <Card.Header>Terms And Conditions</Card.Header>
                      <Card.Meta>Click To Download</Card.Meta>
                      <Card.Description>
                        {this.state.current_files.terms_and_conditions.name}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                ) : (
                  <React.Fragment />
                )}
              </Card.Group>
            </Segment>
          ) : this.state.loadingRecent === true ? (
            <Segment basic padded="very">
              <Loader active />
            </Segment>
          ) : (
            <React.Fragment />
          )}
        </Segment>
        <Segment className="border">
          <Header as={"h2"} textAlign="center" className="pageSubTitle">
            Previous Upload List
          </Header>
          {this.state.refreshList === false ? (
            <PaginateWrapper
              dataQuery={this.getLegalDocs}
              render={(items) => (
                <List
                  data={items}
                  //   action={(data) => {
                  //     if (data) {
                  //       this.props.history.push(
                  //         `/settings/system_legal_document/${data}/edit`
                  //       );
                  //     }
                  //   }}
                  add={() => this.setState({ addModal: true })}
                  allowAdd={true}
                />
              )}
            />
          ) : (
            <Segment basic>
              <Loader active />
            </Segment>
          )}
        </Segment>
      </Segment>
    );
  }
}
