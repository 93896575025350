import React, { Component } from "react";
import { Form, Segment, Divider } from "semantic-ui-react";
import api from "../../../actions/api";

class addReferralForm extends Component {
  state = {
    data: {
      type: "",
      reason: "",
      condition: "",
      additional_details: "",
      severity: "",
      // assigned_manager: "",
    },
    type_list: [],
    reason_list: [],
    condition_list: [],
    manager_list: [],
    severity_list: [],
    errors: {},
    submit_load: false,
  };

  componentDidMount() {
    Promise.all([api.settings.all()])
      .then((res) => {
        this.setState({
          type_list: res[0].refTypes.map((item) => {
            return { text: item, value: item, key: item };
          }),
          reason_list: res[0].refReasons.map((item) => {
            return { text: item, value: item, key: item };
          }),
          condition_list: res[0].refConditions.map((item, i) => {
            return { text: item, value: item, key: i };
          }),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  validate = (data) => {
    const errors = {};
    if (!data.type) errors.type = "Can't Be Empty";
    if (!data.reason) errors.reason = "Can't Be Empty";
    if (!data.condition) errors.condition = "Can't Be Empty";
    // if (!data.assigned_manager) errors.assigned_manager = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ submit_load: true });
      this.props.submit(this.state.data);
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Dropdown
              error={this.state.errors.type}
              name="type"
              label="Referral Type"
              value={this.state.data.type}
              onChange={this.handleDropdownChange}
              placeholder="Please Select Referral Type"
              options={this.state.type_list}
              selection
              search
              noResultsMessage={"Could Not Find Any Referral Types"}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Dropdown
              error={this.state.errors.reason}
              name="reason"
              label="Referral Reason"
              value={this.state.data.reason}
              onChange={this.handleDropdownChange}
              placeholder="Please Select Referral Reason"
              options={this.state.reason_list}
              selection
              search
              noResultsMessage={"Could Not Find Any Referral Reasons"}
            />
            <Form.Dropdown
              error={this.state.errors.condition}
              name="condition"
              label="Condition"
              value={this.state.data.condition}
              onChange={this.handleDropdownChange}
              placeholder="Please Condition"
              options={this.state.condition_list}
              selection
              search
              noResultsMessage={"Could Not Find Any Conditions"}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.TextArea
              name="additional_details"
              label="Additional Details"
              value={this.state.data.additional_details}
              onChange={this.handleChange}
              placeholder="Please Type Any Additional Details"
            />
          </Form.Group>
          <Form.Group widths="equal">
            {/* <Form.Dropdown
              error={this.state.errors.severity}
              name="severity"
              label="Severity"
              value={this.state.data.severity}
              onChange={this.handleDropdownChange}
              placeholder="Please Select Level Of Severity"
              options={this.state.severity_list}
              selection
              search
              noResultsMessage={"Could Not Find Any Severity Types"}
            /> */}
            {/* <Form.Dropdown
              error={this.state.errors.assigned_manager}
              name="assigned_manager"
              label="Assigned Manager"
              value={this.state.data.assigned_manager}
              onChange={this.handleDropdownChange}
              placeholder="Please Select Assigned Manager"
              options={this.state.manager_list}
              selection
              search
              noResultsMessage={"Could Not Find Any Managers"}
            /> */}
          </Form.Group>
          <Divider />

          <Form.Group widths="equal">
            <Form.Button type="submit" color="red" floated="left" content="Cancel" icon="close" onClick={this.props.cancel} />
            <Form.Button color="green" floated="right" content="Submit" icon="checkmark" loading={this.state.submit_load} onClick={() => this.handleSubmit()} />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default addReferralForm;
