import React, { Component } from "react";
import {
  Table,
  Message,
  Button,
  Icon,
  Popup,
  Segment,
} from "semantic-ui-react";
import moment from "moment";

export default class healthNoticeList extends Component {
  renderList() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        return (
          <Table.Row key={item._id} onClick={() => this.props.action(item._id)}>
            <Table.Cell>{item.title}</Table.Cell>
            <Table.Cell>{item.short_description}</Table.Cell>
            <Table.Cell>{item.start_date ? moment(item.start_date).format("DD/MM/YYYY HH:mm") : ""}</Table.Cell>
            <Table.Cell>{item.end_date ? moment(item.end_date).format("DD/MM/YYYY HH:mm") : ""}</Table.Cell>
            <Table.Cell />
          </Table.Row>
        );
      });
    }
  }
  render() {
    if (this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>No Health Notices To Display</p>
            {this.props.allowAdd && (
              <Button icon positive onClick={() => this.props.add()}>
                <Icon name="plus" /> Add Notice
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <Table stackable selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Start</Table.HeaderCell>
            <Table.HeaderCell>End</Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <Popup
                position="top right"
                trigger={
                  <Button positive icon onClick={this.props.add}>
                    <Icon name="plus" />
                  </Button>
                }
                content={"Add Notice"}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}
