import React, { Component } from "react";
import { Menu, Dropdown, Icon, Header } from "semantic-ui-react";
import { Authentication } from "../../../lib/sso";
import { withRouter, Link } from "react-router-dom";
import BuildNumber from "../../../_buildNumber";

class TopNav extends Component {
  state = {
    activeItem: "",
  };

  componentDidUpdate(prevProps, prevState) {}

  goto = (data) => {
    this.props.history.push(data);
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  handleLogout = () => {
    Authentication.logout();
  };

  render() {
    const { activeItem } = this.state;
    let context = Authentication.getUserData();

    let fText = "";

    if (Authentication.can("navigator_admin.permission")) {
      fText = "Navigator Admin: ";
    } else if (Authentication.can("navigator_case_manager.permission")) {
      fText = "Nurse Manager: ";
    } else if (Authentication.can("organisation_case_manager.permission")) {
      fText = "Organisation Case Manager: ";
    } else if (Authentication.can("organisation_admin.permission")) {
      fText = "Organisation Admin: ";
    } else if (Authentication.can("organisation_clinician.permission")) {
      fText = "Organisation Clinician: ";
    }

    return (
      <Menu className="footer" fixed="bottom">
        <Menu.Item>
          <Header as={"h3"} style={{ color: "#fff" }}>
            {fText}
          </Header>
        </Menu.Item>

        {/*
        <Dropdown
          labeled
          text={`${context.firstName} ${context.lastName}`}
          item
          icon={false}
          className="menuDropdown"
        >
          <Dropdown.Menu>
            {/* <Dropdown.Header>
              {context.firstName} {context.lastName}
            </Dropdown.Header> */}
        {/* {Authentication.can("navigator_admin.permission") && ( *-/}
            <Dropdown.Item as={Link} to={`/staff/${context.sub}`}>
              <Icon name="user" />
              My Profile
            </Dropdown.Item>
            {/* )} *-/}
            <Dropdown.Item onClick={() => this.handleLogout()}>
              <Icon name="sign out" />
              Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        {Authentication.can("navigator_admin.permission") && (
          <Menu.Item
            as={Link}
            to="/settings"
            name={"settings"}
            active={activeItem === "settings"}
            onClick={this.handleItemClick}
          >
            <Icon name="cogs" />
            Settings
          </Menu.Item>
        )}
        <Menu.Item position="right">
          <Icon name="bug" />
          Build: #{BuildNumber}
        </Menu.Item>
      </Menu>
    );
  }
}
export default withRouter(TopNav);
