import React, { Component } from "react";
import {Segment, Header, Form, Message} from "semantic-ui-react";
import api from "../../../../actions/api";
import PasswordChangeFields from "../../../common/passwordChangeFields";

class resetPasswordForm extends Component {
  state = {
    user: {},
    new_password: null,
    loading: true,
    formError: null,
    errors: {},
  };
  componentDidMount() {
    api.employee.getEmployee(this.props.match.params.id).then(res => {
      this.setState({
        user: res,
        loading: false
      })
    })
  }
  handleSubmit = () => {
      this.setState({
        loading: true,
        formError: null
      }, () => {
        api.user.reset_password(this.props.match.params.id, {
          new_password: this.state.new_password,
        }).then(() => {
          window.history.back();
        }).catch((e) => {
          this.setState({
            loading: false,
            formError: e.response.data.message
          });
          console.log(e);
        });
      });
  };
  handlePassword = (password) => {
    this.setState({
      new_password: password
    });
  }
  render() {
    return (
      <Segment basic loading={this.state.loading}>
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Resetting '{this.state.user.firstName} {this.state.user.lastName}' Password
        </Header>
        <Segment className="no-marg border">
          {this.state.formError && <Message negative><Message.Header>ERROR</Message.Header>{this.state.formError}</Message> }
          <Form>
            <PasswordChangeFields onChange={this.handlePassword} labels={true} />
            <Form.Group widths="equal">
              <Form.Button
                floated="left"
                negative
                onClick={() => {
                  window.history.back();
                }}
                icon="arrow left"
                content="Cancel"
              />
              <Form.Button
                floated="right"
                onClick={this.handleSubmit}
                positive
                disabled={this.state.new_password === null}
                loading={this.state.loading}
                icon="plus"
                content="Submit"
              />
            </Form.Group>
          </Form>
        </Segment>
      </Segment>
    );
  }
}
export default resetPasswordForm;