import React, { Component } from "react";
import { Segment, Header, Form, Icon } from "semantic-ui-react";
import api from "../../../../../actions/api";

class editTemplateForm extends Component {
  state = {
    data: {
      description: "",
      consent_default: false,
      referral_default: false,
      file: null,
      archived: false,
    },
    submit_load: false,
    errors: {},
  };

  componentDidMount() {
    api.templates.getOne(this.props.match.params.id).then((res) => {
      this.setState({ data: res });
    });
  }

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  validate = (data) => {
    const errors = {};
    if (!data.description) errors.description = "Can't be empty";
    if (!data.file) errors.file = "Can't be empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ submit_load: true });
      api.templates
        .update(this.props.match.params.id, this.state.data)
        .then(() => this.props.history.push(`/settings/templates`))
        .catch((e) => {
          console.log(e);
          this.setState({ submit_load: false });
        });
    }
  };

  render() {
    const { data, errors } = this.state;
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Edit Service
        </Header>
        <Segment className="no-marg border">
          <Form>
            <Form.Field>
              <Form.Input
                label="Description"
                error={errors.description}
                autoComplete="off"
                type="text"
                name="description"
                value={data.description}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Group widths="equal">
              <Form.Checkbox
                label="Consent Default"
                checked={data.consent_default}
                onClick={() => {
                  this.setState({
                    data: {
                      ...data,
                      consent_default: !data.consent_default,
                      referral_default: false,
                    },
                  });
                }}
              />
              <Form.Checkbox
                label="Referral Default"
                checked={data.referral_default}
                onClick={() => {
                  this.setState({
                    data: {
                      ...data,
                      referral_default: !data.referral_default,
                      consent_default: false,
                    },
                  });
                }}
              />
            </Form.Group>
            <Form.Checkbox
              label="Archived"
              name="archived"
              checked={data.archived}
              onClick={() => {
                this.setState({
                  data: {
                    ...data,
                    archived: !data.archived,
                  },
                });
              }}
            />
            <Form.Group widths="equal">
              <Form.Button
                floated="left"
                color="red"
                onClick={() => this.props.history.push(`/settings/templates`)}
              >
                <Icon name="close" />
                Cancel
              </Form.Button>
              <Form.Button
                floated="right"
                color="green"
                onClick={() => this.handleSubmit()}
                loading={this.state.submit_load}
              >
                <Icon name="plus" />
                Submit
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default editTemplateForm;
