export default {
  api_server: "/api",
  localStoragePrefix: "navigator__",
  postcoder_api_key: "PCWKN-SRZBC-9ZXD9-JL4SE",
  postcoder_identifier: "Patient_Navigator",
  auth: {
    clientId: "5f3fa01449bd087b37c535e5",
    loginServer: "https://portal.patientnavigator.co.uk",
    sessionTimeout: 15
  },
};
