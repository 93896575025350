import React, { Component } from "react";
import {
  Table,
  Button,
  Modal,
  Header,
  Form,
  Segment,
  Message,
  Icon,
  Input,
} from "semantic-ui-react";
import {
  FaArrowsAlt,
  FaFile,
  FaFilePdf,
  FaFileWord,
  FaFileImage,
  FaFolder,
  FaFolderMinus,
  FaFolderOpen,
  FaPencilAlt,
  FaPlus,
  FaTrash,
  FaLock,
  FaLongArrowAltDown,
  FaUpload,
  FaSearch,
  FaUps,
  FaArrowUp,
  FaBriefcaseMedical,
} from "react-icons/fa";
import api from "../../../../actions/api";
import { Authentication } from "../../../../lib/sso";
import AppConfig from "../../../../_appConfig";
import UploadFileForm from "../../common/forms/uploadFile";
import DocViewer, {
  DocViewerRenderers,
  MSDocRenderer,
} from "@cyntler/react-doc-viewer";

class fileManagerList extends Component {
  state = {
    files: [],
    folders: [],
    folder: null,
    loading: true,
    createFolderModal: false,
    renameFolderModal: false,
    moveModal: false,
    deleteFolderModal: false,
    deleteFileModal: false,
    uploadFileModal: false,
    rootFolder: null,
    data: {},
    errors: {},
    path: "/home",
    selectedFile: null,
    selectedFileName: null,
    fileSearch: "",
  };

  /*
      componentDidMount() {
          console.log("props", this.props);
            this.setState({
                folders: this.props.folders,
                folder: this.props.folder,
                path: this.props.path,
                files: this.props.files,
                rootFolder: this.props.rootFolder
            });
      } */

  renderNewFolder() {
    const handleClose = () => this.setState({ createFolderModal: false });

    let folder_title = null;

    const handleSubmit = () => {
      if (folder_title == null) {
        this.setState({
          errors: {
            ...this.state.errors,
            title_error: true,
          },
        });
        return;
      }

      api.files
        .addFolder({
          title: folder_title,
          parent: this.props.folder ? this.props.folder._id : null,
        })
        .then((res) => {
          this.props.loadFolders(
            this.props.folder ? this.props.folder._id : null
          );
          handleClose();
        })
        .catch((e) => {
          console.log("error", e);
        });
    };
    return (
      <Modal
        open={this.state.createFolderModal}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="folder" content="Create Folder" />
        <Modal.Content>
          <Segment>
            <Form>
              <Form.Input
                autoFocus
                type="text"
                name="folder_title"
                label="Name"
                value={folder_title}
                onChange={(e) => {
                  folder_title = e.target.value;
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSubmit();
                  }
                }}
                placeholder="Folder Title"
                error={this.state.errors.title_error}
              />
              {this.state.errors.title_error === true ? (
                <Message
                  negative
                  icon="warning"
                  header={"Name is Required to create a new Folder!"}
                />
              ) : (
                <React.Fragment />
              )}
              <Form.Group widths="equal">
                <Form.Button floated="left" color="red" onClick={handleClose}>
                  <Icon name="close" />
                  Cancel
                </Form.Button>
                <Form.Button
                  floated="right"
                  color="green"
                  onClick={handleSubmit}
                >
                  <Icon name="plus" />
                  Submit
                </Form.Button>
              </Form.Group>
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  renderRenameFolder() {
    const handleClose = () => this.setState({ renameFolderModal: false });

    let folder_title = null;

    const handleSubmit = () => {
      if (folder_title == null) {
        this.setState({
          errors: {
            ...this.state.errors,
            title_error: true,
          },
        });
        return;
      }

      api.files
        .renameFolder(this.state.data.folder, {
          title: folder_title,
        })
        .then((res) => {
          this.props.loadFolders(
            this.props.folder ? this.props.folder._id : null
          );
          handleClose();
        })
        .catch((e) => {
          console.log("error", e);
        });
    };
    return (
      <Modal
        open={this.state.renameFolderModal}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="folder pencil" content="Rename Folder" />
        <Modal.Content>
          <Segment>
            <Form>
              <Form.Input
                autoFocus
                type="text"
                name="folder_title"
                label="Name"
                value={folder_title}
                onChange={(e) => {
                  folder_title = e.target.value;
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSubmit();
                  }
                }}
                placeholder="Folder Title"
                error={this.state.errors.title_error}
              />
              {this.state.errors.title_error === true ? (
                <Message
                  negative
                  icon="warning"
                  header={"Name is Required to rename Folder!"}
                />
              ) : (
                <React.Fragment />
              )}
              <Form.Group widths="equal">
                <Form.Button floated="left" color="red" onClick={handleClose}>
                  <Icon name="close" />
                  Cancel
                </Form.Button>
                <Form.Button
                  floated="right"
                  color="orange"
                  onClick={handleSubmit}
                >
                  <Icon name="pencil" />
                  Rename
                </Form.Button>
              </Form.Group>
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  renderDeleteFolder() {
    const handleClose = () => this.setState({ deleteFolderModal: false });

    const handleSubmit = () => {
      api.files
        .deleteFolder(this.state.data.folder)
        .then((res) => {
          this.props.loadFolders(
            this.props.folder ? this.props.folder._id : null
          );
          handleClose();
        })
        .catch((e) => {
          console.log("error", e);
        });
    };
    return (
      <Modal
        open={this.state.deleteFolderModal}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="trash" content="Delete Folder" />
        <Modal.Content>
          <Segment>
            <p>
              Are you sure you wish the delete the folder? this will remove all
              Folders and Files within!
            </p>
            <Form>
              <Form.Group widths="equal">
                <Form.Button floated="left" color="red" onClick={handleClose}>
                  <Icon name="close" />
                  Cancel
                </Form.Button>
                <Form.Button
                  floated="right"
                  color="orange"
                  onClick={handleSubmit}
                >
                  <Icon name="trash" />
                  Delete
                </Form.Button>
              </Form.Group>
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  renderDeleteFile() {
    const handleClose = () => this.setState({ deleteFileModal: false });

    let { data } = this.state;

    const handleSubmit = () => {
      api.files
        .deleteFile(data.removeFile)
        .then((res) => {
          this.props.loadFolders(
            this.props.folder ? this.props.folder._id : null,
            null
          );
          handleClose();
        })
        .catch((e) => {
          console.log("error", e);
        });
    };

    //removeFile , removeFileName, removeFileType

    let type = data.removeFileType;
    console.log(
      "🚀 ~ file: fileManagerList.js ~ line 324 ~ fileManagerList ~ renderDeleteFile ~ data",
      data
    );
    let fileIcon = null;

    switch (type) {
      case "image/png":
      case "image/jpeg":
        fileIcon = <FaFileImage size="1.5em" color="purple" />;
        break;
      case "application/pdf":
        fileIcon = <FaFilePdf size="1.5em" color="purple" />;
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/msword":
        fileIcon = <FaFileWord size="1.5em" color="purple" />;
        break;
      default:
        fileIcon = <FaFile size="1.5em" color="purple" />;
        break;
    }

    return (
      <Modal
        open={this.state.deleteFileModal}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="trash" content="Delete File" />
        <Modal.Content>
          <Segment>
            <p>
              {fileIcon} {data.removeFileName}
            </p>
            <p>
              Are you sure you wish the delete this file? This will remove the
              file from any Case / Referral linked
            </p>
            <Form>
              <Form.Group widths="equal">
                <Form.Button floated="left" color="red" onClick={handleClose}>
                  <Icon name="close" />
                  Cancel
                </Form.Button>
                <Form.Button
                  floated="right"
                  color="orange"
                  onClick={handleSubmit}
                >
                  <Icon name="trash" />
                  Delete
                </Form.Button>
              </Form.Group>
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  renderUploadFile() {
    const close = () => {
      this.setState({ uploadFileModal: false });
    };

    const handleSubmit = (data) => {
      api.files
        .uploadFile({
          // type: this.state.uploadFileModal,
          folder: this.props.folder ? this.props.folder._id : null,
          file: data.file,
          description: data.description,
          selectedFile: data.selectedFile,
          private_file: data.private_file,
        })
        .then((res) => {
          this.props.loadFolders(
            this.props.folder ? this.props.folder._id : null
          );
          close();
        })
        .catch((e) => {
          console.log(e);
        });
    };

    return (
      <Modal
        open={this.state.uploadFileModal}
        onClose={close}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="upload" content="Upload File" />
        <Modal.Content>
          <UploadFileForm
            hideExisting={this.props.hideExisting}
            submit={handleSubmit}
            close={close}
            enableFileManager
            fileManagerType={{ type: "cases", case: this.state.case }}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderViewFile() {
    const close = () => {
      this.setState({ viewFileModal: false });
    };
    return (
      <Modal
        open={this.state.viewFileModal}
        onClose={close}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="search" content="View File" />
        <Modal.Content>
          <Segment>
            <DocViewer
              style={{ height: 600 }}
              pluginRenderers={DocViewerRenderers}
              documents={
                this.state.data.view ? [{ uri: this.state.data.view }] : []
              }
            />
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colspan="2">
                  <Form.Group widths="equal">
                    <Form.Button floated="left" color="grey" onClick={close}>
                      <Icon name="close" />
                      Close
                    </Form.Button>
                  </Form.Group>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  renderMove() {
    const handleClose = () => this.setState({ moveModal: false });

    const handleSubmit = () => {
      this.setState({ loading: true });

      let data = {
        move: this.state.data.move,
        move_type: this.state.data.move_type,
        folder: this.props.search.folder ? this.props.search.folder._id : null,
      };

      console.log("move", data);

      Promise.all([api.files.move(data)]).then((res) => {
        if (res[0].success) {
          console.log(res);
          this.props.loadFolders(
            this.props.folder ? this.props.folder._id : null
          );
          handleClose();
          this.setState({ loading: false });
        }
        return true;
      });
    };

    let { folders, folder, path, rootFolder } = this.props.search;

    return (
      <Modal
        open={this.state.moveModal}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="folder pencil" content="Move" />
        <Modal.Content>
          <Segment>
            <p>
              <strong>Info:</strong> Please navigate to the folder below then
              click "<strong>Move Here</strong>".
            </p>
            <Table selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colspan="3">
                    <FaFolder /> {path}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell width="6">
                    Folder / Document Filename
                  </Table.HeaderCell>
                  <Table.HeaderCell width="3">Size</Table.HeaderCell>
                </Table.Row>
                {folder ? (
                  <Table.Row>
                    <Table.HeaderCell colspan="2">
                      {rootFolder !== folder._id ? (
                        <Button
                          color="orange"
                          size="small"
                          onClick={() => {
                            this.props.loadFolders(folder.parent, true);
                          }}
                        >
                          <FaFolderMinus /> Back
                        </Button>
                      ) : (
                        ""
                      )}
                      <br />
                      <br />
                      <FaFolderOpen size="1.5em" />
                      {folder.protected ? <FaLock /> : ""} {folder.title}
                    </Table.HeaderCell>
                  </Table.Row>
                ) : (
                  ""
                )}
              </Table.Header>
              <Table.Body>
                {folders &&
                  folders.map((item, index) => {
                    if (
                      (this.state.data.move_type === "folder" &&
                        this.state.data.move !== item._id) ||
                      this.state.data.move_type === "file"
                    )
                      return (
                        <Table.Row key={"folder_" + index}>
                          <Table.Cell
                            colspan="1"
                            style={folder ? { paddingLeft: "25px" } : {}}
                            onClick={() => {
                              this.props.loadFolders(item._id, true);
                            }}
                          >
                            <FaFolder size="1.5em" />
                            {item.protected ? <FaLock size="1.5em" /> : ""}{" "}
                            {item.title}
                          </Table.Cell>
                          <Table.Cell>
                            {item.folder_size
                              ? item.folder_size.toFixed(2)
                              : "N/A"}
                          </Table.Cell>
                        </Table.Row>
                      );
                  })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colspan="2">
                    <Form.Group widths="equal">
                      <Form.Button
                        floated="left"
                        color="red"
                        onClick={handleClose}
                      >
                        <Icon name="close" />
                        Cancel
                      </Form.Button>
                      <Form.Button
                        floated="right"
                        color="orange"
                        onClick={handleSubmit}
                      >
                        <Icon name="arrows alternate" />
                        Move Here
                      </Form.Button>
                    </Form.Group>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
            <Form>
              {/*
            <Dropdown
              text='Folder'
              icon='folder'
              floating
              labeled
              button
              className='icon'
            >
              <Dropdown.Menu>
                <Dropdown.Header content='Search Folder' />
                <Input icon='search' iconPosition='left' name='search' />
                <Dropdown.Header content='root' />
                <Dropdown.Divider />
                <Dropdown.Item
                  icon="folder"
                  text='Companies'
                />
                <Dropdown.Divider />
                <Dropdown.Item><FaFolder/><FaLock/> Liquid Computing</Dropdown.Item>
                <Dropdown.Item
                  icon="folder"
                  text='Cases'
                />
                <Dropdown.Item
                  icon="folder"
                  text='Referalls'
                />
                
              </Dropdown.Menu>
            </Dropdown>
            {/*  
              <Form.Input
                type="text"
                name="folder_title"
                label="Name"
                value={folder_title}
                onChange={(e)=>{folder_title = e.target.value}}
                placeholder="Folder Title"
                error={this.state.errors.title_error}
                />*/}
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  setRename(folder, title) {
    this.setState({
      renameFolderModal: true,
      data: {
        folder,
        title,
      },
    });
  }

  setDelete(folder) {
    this.setState({
      deleteFolderModal: true,
      data: {
        folder,
      },
    });
  }

  setDeleteFile(removeFile, removeFileName, removeFileType) {
    this.setState({
      deleteFileModal: true,
      data: {
        removeFile,
        removeFileName,
        removeFileType,
      },
    });
  }

  setMove(type, id) {
    Promise.all([this.props.loadFolders(null, true)]).then(() => {
      this.setState({
        loading: false,
        moveModal: true,
        data: {
          move_type: type,
          move: id,
        },
      });
    });
  }

  setView(view) {
    this.setState({
      viewFileModal: true,
      data: {
        view,
      },
    });
  }

  setUploadFile() {
    this.setState({
      uploadFileModal: true,
    });
  }

  handleSearch = (e, { name, value }) => {
    this.setState({ fileSearch: value });
    let { folder } = this.props;
    this.props.loadFolders(
      folder ? folder._id : null,
      this.props.search == true ? true : false,
      value
    );
  };

  render() {
    let {
      folders,
      folder,
      path,
      files,
      rootFolder,
      search = false,
      border = true,
      useExisting = false,
      selectExisting = null,
      caseFolder = null,
      refFolder = null,
      assFolder = null,
    } = this.props;
    let { selectedFile, selectedFileName } = this.state;
    return (
      <Segment className={border == true && "border"}>
        {this.renderNewFolder()}
        {this.renderRenameFolder()}
        {search && this.renderMove()}
        {this.renderDeleteFolder()}
        {this.renderDeleteFile()}
        {this.renderUploadFile()}
        {this.renderViewFile()}
        <Table selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colspan="4">
                <FaFolder /> {path}
                <Input
                  floated={"right"}
                  style={{ width: "20%" }}
                  name="fileSearch"
                  value={this.state.search}
                  onChange={this.handleSearch}
                  placeholder="Search..."
                />
                {!useExisting && Authentication.can("fileManager.addFolder") && (
                  <Button
                    size="tiny"
                    color="green"
                    floated="right"
                    onClick={() => {
                      this.setState({ createFolderModal: true });
                    }}
                  >
                    <FaPlus /> New Folder
                  </Button>
                )}
                {!useExisting && Authentication.can("fileManager.addFile") && (
                  <Button
                    size="tiny"
                    color="green"
                    floated="right"
                    onClick={() => this.setUploadFile()}
                  >
                    <FaUpload /> Upload File
                  </Button>
                )}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell width="6">
                Folder / Document Filename
              </Table.HeaderCell>
              <Table.HeaderCell width="3">Description</Table.HeaderCell>
              <Table.HeaderCell width="3">Size</Table.HeaderCell>
              {!useExisting && (
                <Table.HeaderCell width="4">Actions</Table.HeaderCell>
              )}
            </Table.Row>
            {folder ? (
              <Table.Row>
                <Table.HeaderCell colspan="4">
                  {/*folder && (
                    <Button
                      color="grey"
                      size="small"
                      onClick={() => {
                        this.props.loadFolders();
                      }}
                    >
                      <FaArrowUp /> Back To Top
                    </Button>
                    )*/}
                  {rootFolder !== folder._id ? (
                    <Button
                      color="orange"
                      size="small"
                      onClick={() => {
                        this.props.loadFolders(folder.parent);
                      }}
                    >
                      <FaFolderMinus /> Back
                    </Button>
                  ) : (
                    ""
                  )}
                  <br />
                  <br />
                  <FaFolderOpen size="1.5em" />
                  {folder.protected ? <FaLock size="1.5em" /> : ""}{" "}
                  {folder.title} {folder._id === caseFolder && "(Case)"}
                  {folder._id === refFolder && "(Referral)"}
                  {folder._id === assFolder && "(Assessment)"}
                </Table.HeaderCell>
              </Table.Row>
            ) : (
              ""
            )}
            {caseFolder && caseFolder !== folder._id ? (
              <Table.Row>
                <Table.HeaderCell
                  colspan="4"
                  onClick={() => {
                    this.props.loadFolders(caseFolder);
                  }}
                >
                  <FaBriefcaseMedical size="1.5em" /> View Case Folder
                </Table.HeaderCell>
              </Table.Row>
            ) : (
              ""
            )}
            {refFolder && refFolder !== folder._id ? (
              <Table.Row>
                <Table.HeaderCell
                  colspan="4"
                  onClick={() => {
                    this.props.loadFolders(refFolder);
                  }}
                >
                  <FaBriefcaseMedical size="1.5em" /> View Referral Folder
                </Table.HeaderCell>
              </Table.Row>
            ) : (
              ""
            )}
            {this.state.fileSearch && (
              <Table.Row>
                <Table.HeaderCell colspan="4">
                  <FaFile size="1.5em" />
                  <FaSearch size="1.5em" /> {files.length} File(s) found for
                  search of: "{this.state.fileSearch}"
                </Table.HeaderCell>
              </Table.Row>
            )}
          </Table.Header>
          <Table.Body>
            {folders &&
              folders.map((item, index) => {
                return (
                  <Table.Row key={"folder_" + index}>
                    <Table.Cell
                      colspan="1"
                      style={folder ? { paddingLeft: "25px" } : {}}
                      onClick={() => {
                        this.props.loadFolders(item._id);
                      }}
                    >
                      <FaFolder size="1.5em" />
                      {item.protected ? <FaLock size="1.5em" /> : ""}{" "}
                      {item.title}
                    </Table.Cell>
                    <Table.Cell>&nbsp;</Table.Cell>
                    <Table.Cell>
                      {item.folder_size ? item.folder_size.toFixed(2) : "N/A"}
                    </Table.Cell>
                    {!useExisting && (
                      <Table.Cell>
                        {Authentication.can("fileManager.moveFolder") &&
                          search &&
                          !item.protected && (
                            <Button
                              size="tiny"
                              color="orange"
                              onClick={() => this.setMove("folder", item._id)}
                            >
                              <FaArrowsAlt /> Move
                            </Button>
                          )}
                        {Authentication.can("fileManager.renameFolder") &&
                          !item.protected && (
                            <Button
                              size="tiny"
                              color="orange"
                              onClick={() =>
                                this.setRename(item._id, item.title)
                              }
                            >
                              <FaPencilAlt /> Rename
                            </Button>
                          )}
                        {Authentication.can("fileManager.deleteFolder") &&
                          !item.protected && (
                            <Button
                              size="tiny"
                              color="red"
                              onClick={() => {
                                this.setDelete(item._id);
                              }}
                            >
                              <FaTrash /> Delete
                            </Button>
                          )}
                      </Table.Cell>
                    )}
                  </Table.Row>
                );
              })}

            {files &&
              files.map((item, index) => {
                let type = item.file ? item.file.contentType : "";
                let fileIcon = null;
                let viewable = false;

                switch (type) {
                  case "image/png":
                  case "image/jpeg":
                    viewable = true;
                    fileIcon = <FaFileImage size="1.5em" color="purple" />;
                    break;
                  case "application/pdf":
                    viewable = true;
                    fileIcon = <FaFilePdf size="1.5em" color="purple" />;
                    break;
                  case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                  case "application/msword":
                    fileIcon = <FaFileWord size="1.5em" color="purple" />;
                    break;
                  default:
                    fileIcon = <FaFile size="1.5em" color="purple" />;
                    break;
                }

                let sliceFrom = 0;

                if (item.file.filename) {
                  sliceFrom = item.file.filename.indexOf("SCANNED__") + 9;
                }

                let filename = item.file
                  ? item.file.filename.slice(
                      sliceFrom,
                      item.file.filename.length
                    )
                  : "N/A";

                console.log("folder", folder);

                return (
                  <Table.Row
                    key={"files_" + index}
                    onClick={() => {
                      if (useExisting && selectExisting) {
                        this.setState({
                          selectedFile:
                            this.state.selectedFile == item._id
                              ? null
                              : item._id,
                          selectedFileName:
                            this.state.selectedFile == item._id
                              ? null
                              : path + "/" + filename,
                        });

                        selectExisting(item._id ? item._id : null);
                      }
                    }}
                    active={selectedFile == item._id}
                  >
                    <Table.Cell style={folder ? { paddingLeft: "25px" } : {}}>
                      {fileIcon} {filename}
                    </Table.Cell>
                    <Table.Cell>{item.description}</Table.Cell>
                    <Table.Cell>
                      {item.file ? this.formatBytes(item.file.length) : "N/A"}
                    </Table.Cell>
                    {!useExisting && item.file && (
                      <Table.Cell>
                        {viewable && (
                          <Button
                            size="tiny"
                            onClick={() => {
                              api.files
                                .getFilelink(item.file._id)
                                .then((res) => {
                                  this.setView(
                                    AppConfig.api_server + res,
                                    "_blank"
                                  );
                                });
                            }}
                          >
                            <FaSearch /> View
                          </Button>
                        )}
                        {item.file && (
                          <Button
                            size="tiny"
                            onClick={() => {
                              //download
                              api.files
                                .getFilelink(item.file._id)
                                .then((res) => {
                                  window.open(
                                    AppConfig.api_server + res,
                                    "_blank"
                                  );
                                });
                            }}
                          >
                            <FaLongArrowAltDown /> Download
                          </Button>
                        )}
                        {Authentication.can("fileManager.moveFile") && search && (
                          <Button
                            size="tiny"
                            color="orange"
                            onClick={() => this.setMove("file", item._id)}
                          >
                            <FaArrowsAlt /> Move
                          </Button>
                        )}
                        {Authentication.can("fileManager.deleteFile") && (
                          <Button
                            size="tiny"
                            color="red"
                            onClick={() =>
                              this.setDeleteFile(item._id, filename, type)
                            }
                          >
                            <FaTrash /> Delete
                          </Button>
                        )}
                      </Table.Cell>
                    )}
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        {useExisting && (
          <p>
            Selected file:{" "}
            {selectedFileName ? selectedFileName : "Please select a file"}
          </p>
        )}
      </Segment>
    );
  }
}

export default fileManagerList;
