import React, { Component } from "react";
import { Form, Icon, Segment, Divider } from "semantic-ui-react";

class editNoteForm extends Component {
  state = { data: { message: "" }, errors: {} };

  componentDidMount() {
    if (this.props.data !== undefined) {
      this.setState({ data: this.props.data });
    }
  }

  handleNoteChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  validate = (data) => {
    const errors = {};
    if (!data.message) errors.message = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Field>
            <Form.TextArea
              label="Note"
              name={"message"}
              value={this.state.data.message}
              onChange={this.handleNoteChange}
              placeholder="Note"
              error={this.state.errors.message}
            />
          </Form.Field>
          <Divider clearing />
          <Form.Group widths="equal">
            <Form.Button
              floated="left"
              color="red"
              onClick={() => this.props.close()}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              floated="right"
              color="green"
              onClick={this.handleSubmit}
            >
              <Icon name={this.props.data !== undefined ? "pencil" : "plus"} />
              {this.props.data !== undefined ? "Update" : "Add"}
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default editNoteForm;
