import React, { Component } from "react";
import {
  Table,
  Message,
  Button,
  Icon,
  Popup,
  Segment,
} from "semantic-ui-react";
import {Can} from "../../../../lib/sso";

class companyNoticeList extends Component {
  renderList() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        return (
          <Table.Row key={item._id} onClick={() => this.props.action(item)}>
            <Table.Cell>{item.title}</Table.Cell>
            <Table.Cell>{item.short_description}</Table.Cell>
            <Table.Cell />
          </Table.Row>
        );
      });
    }
  }
  render() {
    if (this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>No Company Notices To Display</p>
              <Can has={"company.notices.add"}>
                  {this.props.allowAdd && (
                      <Button icon positive onClick={() => this.props.add()}>
                          <Icon name="plus" /> Add Notice
                      </Button>
                  )}
              </Can>
          </Segment>
        </Message>
      );
    }
    return (
      <Table stackable selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell width={1}>
                <Can has={"company.notices.add"}>
                    <Popup
                        position="top right"
                        trigger={
                            <Button positive icon onClick={this.props.add}>
                                <Icon name="plus" />
                            </Button>
                        }
                        content={"Add Notice"}
                    />
                </Can>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}

export default companyNoticeList;
