import React, { Component } from "react";
import { Segment, Header, Loader } from "semantic-ui-react";
import api from "../../../../actions/api";
import PaginateWrapper from "../../common/OLD_PaginateWrapper";
import List from "../../common/lists/emailTemplateList";

export default class index extends Component {
  state = {
    refreshList: false,
  };

  getData = (pageNum, pageSize, search, filters) => {
    return api.system_legal_docs.allPaged({
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Email Template Management
        </Header>
        <Segment className="border">
          {this.state.refreshList === false ? (
            /*
            <PaginateWrapper
              dataQuery={this.getData}
              render={(items) => ( */
                <List
                  // data={items}
                  //   action={(data) => {
                  //     if (data) {
                  //       this.props.history.push(
                  //         `/settings/system_legal_document/${data}/edit`
                  //       );
                  //     }
                  //   }}
                  allowAdd={true}
                  history={this.props.history}
                /> /*
              )}
            /> */
          ) : (
            <Segment basic>
              <Loader active />
            </Segment>
          )}
        </Segment>
      </Segment>
    );
  }
}
