import React, { Component } from "react";
import { Button, Message, Segment } from "semantic-ui-react";
import IndexPN from "./_pn";
import IndexCN from "./_cn";
import { Authentication } from "../lib/sso";

export default class RootIndex extends Component {
  render() {
    if (Authentication.can("navigator_admin.permission")) {
      return <IndexPN />;
    } else if (Authentication.can("navigator_case_manager.permission")) {
      return <IndexPN />;
    } else if (Authentication.can("organisation_case_manager.permission")) {
      return <IndexPN />;
    } else if (
      Authentication.can("organisation_admin.permission") ||
      Authentication.can("organisation_clinician.permission")
    ) {
      return <IndexCN />;
    }
    return (
      <Segment>
        <Message warning>
          <Message.Header>
            You do not have permission to access this portal
          </Message.Header>
          <Button onClick={() => Authentication.logout()}>Log Out</Button>
        </Message>
      </Segment>
    );
  }
}
