import React from "react";
import {Authentication} from "../../../lib/sso";
import {Message} from "semantic-ui-react";

const ForbiddenMessage = (props) => {
    return (
        <Message negative><Message.Header>Forbidden</Message.Header>You do not have permission to access this page.</Message>
    );
}
export default (scope, child) => {
    if(!Authentication.can(scope)){
        return () => <ForbiddenMessage />;
    }
    return child;
}