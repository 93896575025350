import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Icon,
  Menu,
  Modal,
  Select,
  Divider,
  Table,
  Button,
  Loader,
  Message,
} from "semantic-ui-react";
import api from "../../../actions/api";
import { Link } from "react-router-dom";
import EditReferralForm from "../common/forms/editReferralForm";
import NotesList from "../common/lists/referralNotes";
import PaginateWrapper from "../common/OLD_PaginateWrapper";
import CancelReferralForm from "./forms/cancelReferralForm";
import UploadFileForm from "../common/forms/uploadFile";
import AppConfig from "../../../_appConfig";
import { Authentication } from "../../../lib/sso";
import FileManagerList from "../common/lists/fileManagerList";
import CaseNotesList from "../common/lists/caseNotes";

export default class Referral extends Component {
  state = {
    referral: {},
    activeItem: "notes",
    editReferral: false,
    addCM: false,
    addNM: false,
    cancelModal: false,
    refreshNotes: false,
    uploadFileModal: "",
    referral_default_link: null,
    consent_default_link: null,
    referral_form_link: null,
    consent_form_link: null,
    CM: {
      data: [],
      formList: [],
    },
    NM: {
      data: [],
      formList: [],
    },
    data: {
      referral: this.props.match.params.id,
      employee: "",
      company: "",
      cm: "",
    },
    files: [],
    folder: [],
    folders: [],
    path: "",
    filtered: [],
    ref_notes: [],
  };

  componentDidMount() {
    this._getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.referral !== prevState.referral) {
      this.setState({
        data: {
          ...this.state.data,
          employee: this.state.referral.employee._id,
          company: this.state.referral.employee.company,
        },
      });
    }
  }

  _getData = () => {
    this.setState({ refreshNotes: true });
    Promise.all([
      api.referral.getReferral(this.props.match.params.id),
      api.team.getCaseManagers(),
      api.team.getNurseManagers(),
      api.templates.getDefaultLinks(),
      api.referral.notes.getReferralNotes(this.props.match.params.id),
    ])
      .then((res) => {
        this.loadFolders(res[0].refFolder, true);
        if (res[0].consent_form !== undefined) {
          api.referral.getReferralFilelink(res[0].consent_form).then((res) => {
            this.setState({ consent_form_link: res });
          });
        }

        if (res[0].referral_form !== undefined) {
          api.referral.getReferralFilelink(res[0].referral_form).then((res) => {
            this.setState({ referral_form_link: res });
          });
        }

        this.setState({
          referral: res[0],
          CM: res[1],
          NM: res[2],
          refreshNotes: false,
          consent_default_link:
            res[3].filter((item) => item.consent_default !== undefined)
              .length !== 0
              ? res[3].filter((item) => item.consent_default !== undefined)[0]
                  .consent_default
              : null,
          referral_default_link:
            res[3].filter((item) => item.referral_default !== undefined)
              .length !== 0
              ? res[3].filter((item) => item.referral_default !== undefined)[0]
                  .referral_default
              : null,
          filtered: res[4],
          ref_notes: res[4],
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ refreshNotes: false });
      });
  };

  getNotes = (pageNum, pageSize, search, filters) => {
    return api.referral.notes.listAllPaged(this.props.match.params.id, {
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  gotoCase = (id) => {
    if (id) {
      this.props.history.push(`/case/${id}`);
    }
  };

  assignCM = () => {
    let data = this.state.data;

    if (this.state.referral.case) {
      api.referral.editReferal(this.state.referral._id, data).then(() => {
        this.setState({
          addCM: false,
        });
        this._getData();
      });
    } else {
      api.referral.editReferal(this.state.referral._id, data).then(() => {
        this.setState({
          addCM: false,
        });
      });
    }
  };

  assignNM = () => {
    let data = this.state.data;

    if (this.state.referral.case) {
      api.case
        .editCase(this.state.referral.case._id, { cm: data.nm })
        .then(() => {
          this.setState({
            addNM: false,
          });
          this._getData();
        });
    } else {
      api.case
        .addCase({
          referral: data.referral,
          employee: data.employee,
          company: data.company,
          cm: data.nm,
        })
        .then((res) => {
          this.setState({
            addNM: false,
          });
          this._getData();
        });
    }
  };

  createCase = () => {
    let data = this.state.data;
    api.case
      .addCase({
        referral: data.referral,
        employee: data.employee,
        company: data.company,
      })
      .then(() => {
        this._getData();
      });
  };

  renderMenu() {
    const { activeItem, referral } = this.state;
    let notCompanyCaseManager =
      Authentication.can("organisation_case_manager.permission") !== true;

    return (
      <Menu className="subMenu" stackable>
        {referral.status !== "Cancelled" ? (
          <Menu.Item
            name="notes"
            onClick={() =>
              this.props.history.push(
                `/referral/${this.state.referral._id}/addnote`
              )
            }
          >
            <Icon name="plus" /> Add Referral Note
          </Menu.Item>
        ) : (
          <React.Fragment />
        )}
        {this.state.referral.case && (
          <Menu.Item
            name="summary"
            active={activeItem === "summary"}
            onClick={this.handleMenuClick}
          >
            Summary
          </Menu.Item>
        )}
        <Menu.Item
          name="notes"
          active={activeItem === "notes"}
          onClick={this.handleMenuClick}
        >
          Notes
        </Menu.Item>
        <Menu.Item
          name="forms"
          active={activeItem === "forms"}
          onClick={this.handleMenuClick}
        >
          Forms
        </Menu.Item>
        <Menu.Item
          name="documents"
          active={activeItem === "documents"}
          onClick={this.handleMenuClick}
        >
          Referral Documents
        </Menu.Item>

        {referral.status !== "Cancelled" &&
        !Authentication.can("navigator_admin.permission") ? (
          <React.Fragment>
            {referral.cm ? (
              <Menu.Item onClick={() => this.setState({ addCM: true })}>
                <Icon name="user" />
                Change Referral Manager
              </Menu.Item>
            ) : (
              <Menu.Item onClick={() => this.setState({ addCM: true })}>
                <Icon name="user" />
                Assign Referral Manager
              </Menu.Item>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
        {referral.status !== "Cancelled" &&
          (Authentication.can("navigator_admin.permission") ||
            Authentication.can("navigator_case_manager.permission")) && (
            <React.Fragment>
              {referral.case && referral.case.cm ? (
                <Menu.Item onClick={() => this.setState({ addNM: true })}>
                  <Icon name="medkit" />
                  Change Nurse Case Manager
                </Menu.Item>
              ) : (
                <Menu.Item onClick={() => this.setState({ addNM: true })}>
                  <Icon name="medkit" />
                  Assign Nurse Case Manager
                </Menu.Item>
              )}
            </React.Fragment>
          )}
        {(!referral.case && Authentication.can("navigator_admin.permission")) ||
        Authentication.can("navigator_case_manager.permission") ? (
          <Menu.Item onClick={() => this.createCase()}>
            <Icon name="add" />
            Add Case
          </Menu.Item>
        ) : (
          <React.Fragment />
        )}
        {referral.case === undefined ? (
          <Menu.Item onClick={() => this.setState({ cancelModal: true })}>
            <Icon name="close" />
            {referral.status !== "Cancelled"
              ? "Cancel Referral"
              : "Edit Cancellation"}
          </Menu.Item>
        ) : (
          <React.Fragment />
        )}
        <Menu.Menu position="right">
          <Menu.Item onClick={() => this.setState({ editReferral: true })}>
            <Icon name="pencil" />
            Edit Referral
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }

  renderEditReferral() {
    const closeReferralModal = () => {
      this.setState({ editReferral: false });
    };

    const updateReferral = (data) => {
      api.referral
        .editReferal(this.props.match.params.id, data)
        .then(() => {
          closeReferralModal();
          this._getData();
        })
        .catch((e) => {
          console.log(e);
        });
    };
    return (
      <Modal
        open={this.state.editReferral}
        onClose={closeReferralModal}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="medkit" content="Edit Referral" />
        <Modal.Content>
          <EditReferralForm
            submit={updateReferral}
            cancel={closeReferralModal}
            data={this.state.referral}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderUploadFile() {
    const close = () => {
      this.setState({ uploadFileModal: "" });
    };

    const handleSubmit = (data) => {
      api.referral
        .uploadReferralFile(this.props.match.params.id, {
          type: this.state.uploadFileModal,
          file: data.file,
          description: data.description,
          selectedFile: data.selectedFile,
        })
        .then(() => {
          close();
          this._getData();
        })
        .catch((e) => {
          console.log(e);
        });
    };

    return (
      <Modal
        open={this.state.uploadFileModal !== ""}
        onClose={close}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="upload" content="Upload File" />
        <Modal.Content>
          <UploadFileForm submit={handleSubmit} close={close} />
        </Modal.Content>
      </Modal>
    );
  }

  renderCancelReferral() {
    const handleClose = () => this.setState({ cancelModal: false });

    const handleSubmit = (data) => {
      api.referral
        .editReferal(this.props.match.params.id, {
          ...this.state.data,
          cancellation_meta: data,
        })
        .then(() => {
          handleClose();
          this._getData();
        })
        .catch((e) => {
          console.log(e);
          handleClose();
        });
    };

    return (
      <Modal
        open={this.state.cancelModal}
        onClose={handleClose}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="close" content="Cancel Referral" />
        <Modal.Content>
          <CancelReferralForm
            submit={handleSubmit}
            cancel={handleClose}
            data={
              this.state.referral.cancellation_meta !== undefined
                ? this.state.referral.cancellation_meta
                : {}
            }
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderAddNurseManager() {
    const { NM, data } = this.state;
    let disabled = true;
    if (data.nm !== "") {
      disabled = false;
    }
    let NMs = [];

    NM.formList.map((c) => {
      // if (this.state.referral.company._id === c.company) {
      NMs.push(c);
      // }
    });

    return (
      <Modal
        open={this.state.addNM}
        onClose={() => this.setState({ addNM: false })}
        centered={false}
        size="small"
        closeOnDimmerClick={true}
      >
        <Header icon="medkit" content="Assign Nurse Case Manager" />
        <Modal.Content>
          <Segment textAlign="center">
            <Header>Pick Nurse Case Manager</Header>
            <Select
              options={NMs.length > 0 ? NMs : []}
              name="cm"
              onChange={this.handleDropdownChange}
              value={data.nm}
              search
            />
            <Button
              positive
              onClick={this.assignNM}
              attached={"right"}
              disabled={disabled}
            >
              Update Nurse Case Manager
            </Button>
            <Divider />
            <Header>Nurse Case Manager List</Header>
            <Segment className={"scrollList1"} basic>
              <Table sortable striped selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Nurse Manager</Table.HeaderCell>
                    <Table.HeaderCell>Active Cases</Table.HeaderCell>
                    <Table.HeaderCell>Closed Cases</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {NM.data.map((c) => {
                    // if (this.state.referral.company._id === c.company) {
                    return (
                      <Table.Row
                        key={c._id}
                        onClick={() =>
                          this.setState({
                            data: {
                              ...this.state.data,
                              nm: c._id,
                            },
                          })
                        }
                      >
                        <Table.Cell>
                          {c.firstName} {c.lastName}
                        </Table.Cell>
                        <Table.Cell>{c.ActiveCases}</Table.Cell>
                        <Table.Cell>{c.ClosedCases}</Table.Cell>
                      </Table.Row>
                    );
                    // }
                  })}
                </Table.Body>
              </Table>
            </Segment>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  renderAddCaseManager() {
    const { CM, data } = this.state;
    let disabled = true;
    if (data.cm !== "") {
      disabled = false;
    }
    let CMs = [];

    CM.formList.map((c) => {
      // if (this.state.referral.company._id === c.company) {
      CMs.push(c);
      // }
    });

    return (
      <Modal
        open={this.state.addCM}
        onClose={() => this.setState({ addCM: false })}
        centered={false}
        size="small"
        closeOnDimmerClick={true}
      >
        <Header icon="medkit" content="Assign Referral Manager" />
        <Modal.Content>
          <Segment textAlign="center">
            <Header>Pick Referral Manager</Header>
            <Select
              options={CMs.length > 0 ? CMs : []}
              name="cm"
              onChange={this.handleDropdownChange}
              value={data.cm}
              search
            />
            <Button
              positive
              onClick={this.assignCM}
              attached={"right"}
              disabled={disabled}
            >
              Update Referral Manager
            </Button>
            <Divider />
            <Header>Referral Manager List</Header>
            <Segment className={"scrollList1"} basic>
              <Table sortable striped selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Referral Manager</Table.HeaderCell>
                    <Table.HeaderCell>Active Cases</Table.HeaderCell>
                    <Table.HeaderCell>Closed Cases</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {CM.data.map((c) => {
                    // if (this.state.referral.company._id === c.company) {
                    return (
                      <Table.Row
                        key={c._id}
                        onClick={() =>
                          this.setState({
                            data: {
                              ...this.state.data,
                              cm: c._id,
                            },
                          })
                        }
                      >
                        <Table.Cell>
                          {c.firstName} {c.lastName}
                        </Table.Cell>
                        <Table.Cell>{c.ActiveCases}</Table.Cell>
                        <Table.Cell>{c.ClosedCases}</Table.Cell>
                      </Table.Row>
                    );
                    // }
                  })}
                </Table.Body>
              </Table>
            </Segment>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  handleClientFilter = (query) => {
    let updatedList = this.state.ref_notes;
    updatedList = updatedList.filter((item) => {
      return (
        item.logType.toLowerCase().search(query.search.toLowerCase()) !== -1 ||
        item.message.toLowerCase().search(query.search.toLowerCase()) !== -1
      );
    });
    this.setState({ filtered: updatedList });
  };

  renderNotes() {
    return (
      <Segment>
        {this.state.refreshNotes === false ? (
          <CaseNotesList
            logs={this.state.filtered}
            onQuery={this.handleClientFilter}
            action={(a) =>
              this.props.history.push(
                `/referral/${this.props.match.params.id}/note/${a}`
              )
            }
            add={() =>
              this.props.history.push(`/referral/${this.props.match.params.id}/addnote`)
            }
          />
        ) : (
          /*
          <PaginateWrapper
            dataQuery={this.getNotes}
            render={(items) => <NotesList action={(a) => this.props.history.push()} data={items} />}
          />*/
          <Segment basic padded>
            <Loader active />
          </Segment>
        )}
      </Segment>
    );
  }

  loadFolders(item, search = false, findFile) {
    this.setState({ loading: true });
    if (findFile) {
      api.files.searchFiles({ search: findFile }).then((res) => {
        this.setState({
          folders: [],
          files: res,
          loading: false,
        });
      });

      return true;
    }
    if (item) {
      api.files.getFolder(item).then((res) => {
        this.setState({
          files: res.files,
          folder: res.folder,
          folders: res.folders,
          loading: false,
        });
      });
      api.files.getFolderPath(item).then((res) => {
        this.setState({
          path: res,
        });
      });
    } else {
      api.files.getAll().then((res) => {
        this.setState({
          path: "/home",
          folder: null,
          folders: res.folders,
          files: res.files,
          loading: false,
        });
      });
    }
  }

  renderDocuments() {
    return (
      <Segment>
        <FileManagerList
          rootFolder={""}
          refFolder={this.state.referral.refFolder}
          caseFolder={this.state.referral.caseFolder}
          folder={this.state.folder}
          folders={this.state.folders}
          files={this.state.files}
          path={"/ref"}
          loadFolders={(e, b, s) => this.loadFolders(e, b, s)}
        />
      </Segment>
    );
  }

  renderForms() {
    return (
      <Segment>
        {this.state.referral.consent_form === undefined ? (
          <Message icon info>
            <Icon name="info circle" />
            <Message.Content>
              <Message.Header>Consent Form Requires Signature</Message.Header>
              <Segment basic style={{ paddingLeft: "0px" }}>
                <Button
                  content="Download Default .PDF"
                  color="blue"
                  icon="download"
                  onClick={() => {
                    if (this.state.consent_default_link !== null) {
                      window.open(
                        AppConfig.api_server + this.state.consent_default_link,
                        "_blank"
                      );
                    }
                  }}
                />
                <Button
                  content="Upload"
                  color="green"
                  icon="upload"
                  onClick={() => {
                    this.setState({ uploadFileModal: "consent_form" });
                  }}
                />
              </Segment>
            </Message.Content>
          </Message>
        ) : (
          <Message icon positive>
            <Icon name="check" />
            <Message.Content>
              <Message.Header>Consent Form Has Been Uploaded</Message.Header>
              <Segment basic style={{ paddingLeft: "0px" }}>
                <Button
                  content="Download Stored File"
                  color="blue"
                  icon="download"
                  onClick={() => {
                    if (this.state.consent_form_link !== null) {
                      window.open(
                        AppConfig.api_server + this.state.consent_form_link,
                        "_blank"
                      );
                    }
                  }}
                />
                <Button
                  content="Upload And Replace"
                  color="green"
                  icon="upload"
                  onClick={() => {
                    this.setState({ uploadFileModal: "consent_form" });
                  }}
                />
              </Segment>
            </Message.Content>
          </Message>
        )}
        {this.state.referral.referral_form === undefined ? (
          <Message icon info>
            <Icon name="info circle" />
            <Message.Content>
              <Message.Header>Referral Form Requires Signature</Message.Header>
              <Segment basic style={{ paddingLeft: "0px" }}>
                <Button
                  content="Download Default .PDF"
                  color="blue"
                  icon="download"
                  onClick={() => {
                    if (this.state.referral_default_link !== null) {
                      window.open(
                        AppConfig.api_server + this.state.referral_default_link,
                        "_blank"
                      );
                    }
                  }}
                />
                <Button
                  content="Upload"
                  color="green"
                  icon="upload"
                  onClick={() => {
                    this.setState({ uploadFileModal: "referral_form" });
                  }}
                />
              </Segment>
            </Message.Content>
          </Message>
        ) : (
          <Message icon positive>
            <Icon name="check" />
            <Message.Content>
              <Message.Header>Referral Form Has Been Uploaded</Message.Header>
              <Segment basic style={{ paddingLeft: "0px" }}>
                <Button
                  content="Download Stored File"
                  color="blue"
                  icon="download"
                  onClick={() => {
                    if (this.state.referral_form_link !== null) {
                      window.open(
                        AppConfig.api_server + this.state.referral_form_link,
                        "_blank"
                      );
                    }
                  }}
                />
                <Button
                  content="Upload And Replace"
                  color="green"
                  icon="upload"
                  onClick={() => {
                    this.setState({ uploadFileModal: "referral_form" });
                  }}
                />
              </Segment>
            </Message.Content>
          </Message>
        )}
      </Segment>
    );
  }

  renderSummary() {
    return (
      <Segment className="case">
        <p style={{ textAlign: "justify", whiteSpace: "pre-line" }}>
          {this.state.referral.case && this.state.referral.case.company_summary}{" "}
        </p>
      </Segment>
    );
  }

  render() {
    const { activeItem } = this.state;
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="no-marg pageTitle">
          Referral R{this.state.referral.refNum}
        </Header>
        {this.renderEditReferral()}
        {this.renderAddCaseManager()}
        {this.renderAddNurseManager()}
        {this.renderCancelReferral()}
        {this.renderUploadFile()}
        <Segment basic className="no-marg no-pad">
          <Segment basic className="no-pad">
            <Grid stackable>
              <Grid.Row stretched>
                <Grid.Column width={6}>
                  <Grid className="no-marg dataGrid" celled>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Patient:</Header>
                        {this.state.referral.employee && (
                          <span>
                            <Link
                              to={`/employee/${this.state.referral.employee._id}`}
                            >
                              {this.state.referral.employee.firstName}{" "}
                              {this.state.referral.employee &&
                                this.state.referral.employee.lastName}
                            </Link>
                          </span>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Company: </Header>
                        {this.state.referral.company && (
                          <span>
                            <Link
                              to={`/company/${this.state.referral.company._id}`}
                            >
                              {this.state.referral.company.name}
                            </Link>
                          </span>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Type: </Header>
                        <span>{this.state.referral.type} </span>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column>
                        <Header>Reason: </Header>
                        <span>{this.state.referral.reason} </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Condition:</Header>
                        <span>{this.state.referral.condition}</span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Insurer:</Header>
                        <span>{this.state.referral.insurer}</span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Status:</Header>
                        <span>{this.state.referral.status}</span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Referral Manager:</Header>
                        <span>
                          {this.state.referral.cm
                            ? this.state.referral.cm.firstName +
                              " " +
                              this.state.referral.cm.lastName
                            : "Not Assigned"}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Grid className="no-marg dataGrid" celled>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Details:</Header>
                        <p style={{ textAlign: "justify" }}>
                          {this.state.referral.additional_details}{" "}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Grid
                    className={
                      this.state.referral.case &&
                      this.state.referral.case.refNum
                        ? "no-marg caseVGrid selectable"
                        : this.state.referral &&
                          this.state.referral.status === "Cancelled"
                        ? "no-marg dataGrid"
                        : "no-marg caseVGrid"
                    }
                    celled
                    onClick={() =>
                      this.state.referral.case &&
                      this.gotoCase(this.state.referral.case._id)
                    }
                  >
                    {this.state.referral &&
                    this.state.referral.status !== "Cancelled" ? (
                      <Grid.Row>
                        <Grid.Column textAlign="center">
                          <Header className="no-pad no-marg">Case #</Header>
                          <Header as={"h1"} className="no-pad no-marg">
                            {this.state.referral.case &&
                            this.state.referral.case.refNum
                              ? this.state.referral.case.refNum
                              : "No Case Created"}
                          </Header>
                          {this.state.referral.case &&
                          this.state.referral.case.refNum ? (
                            <React.Fragment>
                              <Header as={"h2"} className="no-pad no-marg">
                                {this.state.referral.case &&
                                this.state.referral.case.cm
                                  ? this.state.referral.case.cm.firstName +
                                    " " +
                                    this.state.referral.case.cm.lastName
                                  : "No Nurse Manager Assigned!"}
                              </Header>
                              <Header className="no-pad no-marg">&nbsp;</Header>
                            </React.Fragment>
                          ) : (
                            <React.Fragment />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    ) : (
                      <Grid.Column>
                        <Header>Cancellation Reason:</Header>
                        <p>
                          {this.state.referral.cancellation_meta &&
                          this.state.referral.cancellation_meta.reason
                            ? this.state.referral.cancellation_meta.reason
                            : "ERROR"}
                        </p>
                        <Header>Cancellation Details:</Header>
                        <p>
                          {this.state.referral.cancellation_meta &&
                          this.state.referral.cancellation_meta
                            .additional_details
                            ? this.state.referral.cancellation_meta
                                .additional_details
                            : "ERROR"}
                        </p>
                      </Grid.Column>
                    )}
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          {this.renderMenu()}
          {activeItem === "summary" && this.renderSummary()}
          {activeItem === "notes" && this.renderNotes()}
          {activeItem === "forms" && this.renderForms()}
          {activeItem === "documents" && this.renderDocuments()}
        </Segment>
      </Segment>
    );
  }
}
