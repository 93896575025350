import React, { Component } from "react";
import { Form, Segment } from "semantic-ui-react";
import api from "../../../../actions/api";

class cancelReferralForm extends Component {
  state = {
    data: { reason: "", additional_details: "" },
    cancelReasons: [],
    errors: {},
    loading: false,
  };

  componentDidMount() {
    api.settings.formLists().then((res) => {
      this.setState({ cancelReasons: res.declinedRefReasons });
    });

    this.setState({ data: this.props.data });
  }

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  validate = (data) => {
    let errors = {};

    if (!data.reason) errors.reason = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors: errors });

    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Dropdown
              error={this.state.errors.reason}
              name="reason"
              label="Cancellation Reason"
              value={this.state.data.reason}
              onChange={this.handleDropdownChange}
              placeholder="Please Select Cancellation Reasoning"
              options={this.state.cancelReasons}
              selection
              search
              noResultsMessage={"Could Not Find Any Cancellation Reasons"}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.TextArea
              name="additional_details"
              label="Cancellation Reason"
              value={this.state.data.additional_details}
              onChange={this.onChange}
              placeholder="Any Further Details"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Button
              type="submit"
              color="red"
              floated="left"
              content="Cancel"
              icon="close"
              onClick={() => {
                this.props.cancel();
              }}
            />
            <Form.Button
              color="green"
              floated="right"
              content={`${
                this.props.data !== {} ? "Update" : "Submit"
              } Cancellation`}
              icon="checkmark"
              loading={this.state.submit_load}
              onClick={() => this.handleSubmit()}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default cancelReferralForm;
