import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import SettingsDash from "./dash";
import SettingsMenu from "./settingsMenu";

import BenefitPage from "./benefits/benefitPage";
import BenefitList from "./benefits/benefitListDash";
import EditBenefitPage from "./benefits/editBenefitPage";

import ServiceList from "./services";
import editServiceForm from "./services/forms/editServiceForm";

import TemplateList from "./templates";
import EditTemplateForm from "./templates/forms/editTemplateForm";

import HelpList from "./help";
import EditHelpCategoryForm from "./help/forms/edit";

import HealthNoticeList from "./health_notices";
import EditHealthNoticeForm from "./health_notices/forms/edit";

import SplashScreenList from "./mobile_splash_screen";
import EditSplashScreen from "./mobile_splash_screen/forms/edit";

import Reports from "./reports";

import SystemLegalDocs from "./system_legal_docs";

import EmailAutomations from "./email_automations";

import EmailTemplates from "./email_templates";
import EmailTemplatesEdit from "./email_templates/forms/edit";

import ResourceCategories from "./resource_management/resource_categories/index";
import Circular1Lab from "./resource_management/Circular1Lab/index";
import ResourceCategoriesEdit from "./resource_management/resource_categories/forms/edit";

import Resources from "./resource_management/resources/index";
import ResourcesEdit from "./resource_management/resources/forms/edit";

import MicrosoftBookings from "./microsoft_bookings";

export default class SettingsPages extends Component {
  render() {
    return (
      <Segment basic className="no-pad no-marg ">
        <SettingsMenu />
        <Segment basic className="pageHolder">
          <Switch>
            <Route exact path={`/settings/resources`} component={Resources} />
            <Route
              exact
              path={`/settings/resources/:id/edit`}
              component={ResourcesEdit}
            />
            <Route
              exact
              path={`/settings/resource_categories`}
              component={ResourceCategories}
            />
            <Route
              exact
              path={`/settings/circular1`}
              component={Circular1Lab}
            />
            <Route
              exact
              path={`/settings/resource_categories/:id/edit`}
              component={ResourceCategoriesEdit}
            />
            <Route
              exact
              path={`/settings/email_automations`}
              component={EmailAutomations}
            />
            <Route
              exact
              path={`/settings/email_templates`}
              component={EmailTemplates}
            />
            <Route
              exact
              path={`/settings/email_templates/add`}
              component={EmailTemplatesEdit}
            />
            <Route
              exact
              path={`/settings/email_templates/edit/:id`}
              component={EmailTemplatesEdit}
            />
            <Route
              exact
              path={`/settings/system_legal_docs`}
              component={SystemLegalDocs}
            />
            <Route
              exact
              path={`/settings/health_notices`}
              component={HealthNoticeList}
            />
            <Route
              exact
              path={`/settings/health_notices/:id/edit`}
              component={EditHealthNoticeForm}
            />
            <Route
              exact
              path={`/settings/templates`}
              component={TemplateList}
            />
            <Route
              exact
              path={`/settings/templates/:id/edit`}
              component={EditTemplateForm}
            />
            <Route
              exact
              path={`/settings/splash_screens`}
              component={SplashScreenList}
            />
            <Route
              path={`/settings/splash_screens/:id/edit`}
              component={EditSplashScreen}
              exact
            />
            <Route
              exact
              path={`/settings/help_categories`}
              component={HelpList}
            />
            <Route
              path={`/settings/help_categories/:id/edit`}
              component={EditHelpCategoryForm}
              exact
            />
            <Route exact path={`/settings/services`} component={ServiceList} />
            <Route
              path={`/settings/services/:id/edit`}
              component={editServiceForm}
              exact
            />
            <Route exact path={`/settings/benefits`} component={BenefitList} />
            <Route
              path={`/settings/benefits/:id/edit`}
              component={EditBenefitPage}
            />
            <Route path={`/settings/benefits/:id`} component={BenefitPage} />
            <Route path={`/settings/reports`} component={Reports} />
            <Route path={'/settings/microsoft_bookings'} component={MicrosoftBookings} />
            <Route exact path={`/settings`} component={SettingsDash} />
          </Switch>
        </Segment>
      </Segment>
    );
  }
}
