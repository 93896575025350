import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import ReferralDash from "./dash";
import ReferralList from "./list";
import ReferralMenu from "./menu";
import Referral from "./show";
import AddNote from "./forms/addNoteForm";
import getReferralNote from "./forms/getNoteForm";

export default class ReferralPages extends Component {
  render() {
    return (
      <Segment basic className="no-pad no-marg">
        <ReferralMenu />
        <Segment basic className="pageHolder">
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}/list`}
              component={ReferralList}
            />
            <Route
              exact
              path={`${this.props.match.url}/:id/addnote`}
              component={AddNote}
            />
            <Route exact path={`${this.props.match.url}/:id/note/:id2`} component={getReferralNote} />
            <Route path={`${this.props.match.url}/:id`} component={Referral} />
            <Route
              exact
              path={`${this.props.match.url}`}
              component={ReferralDash}
            />
          </Switch>
        </Segment>
      </Segment>
    );
  }
}
