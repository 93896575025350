import React, { Component } from "react";
import { Menu, Header } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

export default class HomeMenu extends Component {
  state = {
    activeItem: "",
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  render() {
    const { activeItem } = this.state;
    return (
      <Menu className="subMenu">
        <Menu.Item>
          <Header>Home Menu</Header>
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to={"/"}
          exact
          name="dash"
          active={activeItem === "dash"}
          onClick={this.handleItemClick}
        >
          Dash
        </Menu.Item>

        <Menu.Item
          as={NavLink}
          to={"/details"}
          exact
          name="details"
          active={activeItem === "details"}
          onClick={this.handleItemClick}
        >
          Details
        </Menu.Item>
      </Menu>
    );
  }
}
