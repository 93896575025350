import React, { Component } from "react";
import { Segment, Grid, Table, Button } from "semantic-ui-react";
import api from "../../../actions/api";
import SettingsList from "../common/lists/settingList";
import AppConfig from "../../../_appConfig";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, ContentState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

export default class SettingsDash extends Component {
  state = {
    settings: {},
    activeItem: "basic",
    benefits: [],
    benefitModal: false,
  };

  componentDidMount() {
    this.getData();
  }

  getHtml = (content) => {
    const bodyFromHtml = htmlToDraft(content);
    const { contentBlocks, entityMap } = bodyFromHtml;
    const bodyState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );

    return EditorState.createWithContent(bodyState);
  };

  getData = () => {
    Promise.all([api.settings.all(), api.benefits.all()]).then((res) => {
      let s1 = res[0];
      s1.defaultFooter = res[0].defaultFooter
        ? this.getHtml(res[0].defaultFooter)
        : "";
      this.setState({
        settings: s1,
        benefits: res[1],
      });
    });
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  handleSubmit = (data) => {
    console.log("🚀 ~ file: dash.js ~ line 34 ~ SettingsDash ~ data", data);
    return api.settings.update(data).then(() => {
      this.getData();
    });
  };

  handleDelete = (data) => {
    return api.settings.update(data).then(() => {
      this.getData();
    });
  };

  setOptions = (key, types) => {
    if (types) {
      const data = types;
      let dataArray = [];
      for (var i in data) {
        dataArray.push({
          key: i,
          text: data[i].name,
          value: data[i].name,
        });
      }
      this.setState({ [key]: dataArray });
    }
  };

  addBenefit = (data) => {
    api.benefits.add(data).then(() => {
      this.getData();
      this.setState({
        benefitModal: false,
      });
    });
  };

  renderBasic() {
    return (
      <Grid stackable className="no-marg">
        <Grid.Row columns="2">
          <Grid.Column>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.state.settings.refReasons
                    ? this.state.settings.refReasons
                    : []
                }
                placeholder="Enter Referral Reason"
                title="Referral Reasons"
                field="refReasons"
              />
            </Segment>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.state.settings.refTypes
                    ? this.state.settings.refTypes
                    : []
                }
                placeholder="Enter Referral Type"
                title="Referral Types"
                field="refTypes"
              />
            </Segment>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.state.settings.declinedRefReasons
                    ? this.state.settings.declinedRefReasons
                    : []
                }
                placeholder="Enter Decline Reason"
                title="Declined Referral Reasons"
                field="declinedRefReasons"
              />
            </Segment>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.state.settings.referralStatuses
                    ? this.state.settings.referralStatuses
                    : []
                }
                placeholder="Enter Referral Status"
                title="Referral Statuses"
                field="referralStatuses"
              />
            </Segment>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.state.settings.assessmentStatuses
                    ? this.state.settings.assessmentStatuses
                    : []
                }
                placeholder="Enter Health Journey Status"
                title="Health Journey Statuses"
                field="assessmentStatuses"
              />
            </Segment>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.state.settings.workStatuses
                    ? this.state.settings.workStatuses
                    : []
                }
                placeholder="Enter Working Status"
                title="Working Statuses"
                field="workStatuses"
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.state.settings.refConditions
                    ? this.state.settings.refConditions
                    : []
                }
                placeholder="Enter Referral Condition"
                title="Referral Conditions"
                field="refConditions"
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderBenefit() {
    const benefits = this.state.benefits.map((data) => {
      return (
        <Table.Row
          className="pointer"
          key={data._id}
          onClick={() => {
            this.props.history.push(`/settings/benefits/${data._id}`);
          }}
        >
          <Table.Cell>{data.title}</Table.Cell>
          <Table.Cell>{data.insurer}</Table.Cell>
          <Table.Cell>{data.category_of_cover}</Table.Cell>
          <Table.Cell>{data.renewal_date}</Table.Cell>
          <Table.Cell>{data.start_date}</Table.Cell>
          <Table.Cell />
        </Table.Row>
      );
    });
    return (
      <Segment basic>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Insurer</Table.HeaderCell>
              <Table.HeaderCell>Category Of Cover</Table.HeaderCell>
              <Table.HeaderCell>Renewal Date</Table.HeaderCell>
              <Table.HeaderCell>Start Date</Table.HeaderCell>
              <Table.HeaderCell width="1" textAlign="center">
                <Button
                  color="green"
                  icon="plus"
                  onClick={() => this.setState({ benefitModal: true })}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{benefits}</Table.Body>
        </Table>
      </Segment>
    );
  }

  handleWYSIWYGUpload = (file) => {
    // return;
    return new Promise((resolve, reject) => {
      api.resources
        .uploadFile(this.props.match.params.id, {
          type: "WYSIWYG",
          file: file,
        })

        .then((res) => {
          resolve({ data: { link: AppConfig.api_server + res } });
        });
    });
  };

  render() {
    const { activeItem } = this.state;

    return (
      <Segment basic className="no-pad">
        {activeItem === "basic" && this.renderBasic()}
        {activeItem === "benefit" && this.renderBenefit()}
        <Segment>
          <p>
            <strong>Default Footer Content </strong>
          </p>
          <Editor
            editorState={this.state.settings.defaultFooter}
            onEditorStateChange={(editorState) => {
              this.setState({
                settings: {
                  ...this.state.settings,
                  defaultFooter: editorState,
                },
                temporary_html: "",
              });
            }}
            editorStyle={{
              borderLeft: "1px solid #F1F1F1",
              borderRight: "1px solid #F1F1F1",
              borderBottom: "1px solid #F1F1F1",
              height: "300px",
            }}
            toolbarStyle={{ marginBottom: "0px" }}
            // toolbarCustomButtons={[<ShortcodeDropdown />]}
            toolbar={{
              image: {
                uploadCallback: this.handleWYSIWYGUpload,
                previewImage: true,
                urlEnabled: false,
                alignmentEnabled: true,
                defaultSize: { width: "100%", height: "100%" },
              },
            }}
          />
          <br />
          <Button
            primary
            content="Update"
            onClick={() =>
              this.handleSubmit({
                defaultFooter: draftToHtml(
                  convertToRaw(
                    this.state.settings.defaultFooter.getCurrentContent()
                  )
                ),
              })
            }
          />
        </Segment>
      </Segment>
    );
  }
}
