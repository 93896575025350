import React from "react";
import {
  Table,
  Message,
  Segment,
  Button,
  Icon,
  Label,
} from "semantic-ui-react";
import moment from "moment";
import api from "../../../../actions/api";
import { toast } from "react-semantic-toasts";

export default class EmployeeList extends React.PureComponent {
  
  navigateTo(id){
    this.props.action(id)
  }
  
  renderList() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        return (
          <Table.Row key={item._id}>
            <Table.Cell onClick={() => this.navigateTo(item._id)}>{item.ref}</Table.Cell>
            <Table.Cell onClick={() => this.navigateTo(item._id)}>{`${item.firstName} ${item.lastName}`}</Table.Cell>
            <Table.Cell onClick={() => this.navigateTo(item._id)}>{`${item.groups}`}</Table.Cell>
            <Table.Cell onClick={() => this.navigateTo(item._id)}>
              {item.position !== undefined && item.position !== ""
                ? item.position
                : "[UNASSIGNED]"}
            </Table.Cell>
            <Table.Cell onClick={() => this.navigateTo(item._id)}>{item.email}</Table.Cell>
            <Table.Cell>
              {item.primary_benefit !== undefined ? (
                <Label
                  color={
                    moment(item.primary_benefit.renewal_date).isBefore(
                      moment()
                    ) === true
                      ? "red"
                      : "grey"
                  }
                  key={item.primary_benefit._id}
                >
                  {item.primary_benefit.benefit.title}
                </Label>
              ) : (
                <React.Fragment />
              )}
              {item.secondary_benefit !== undefined ? (
                <Label
                  color={
                    moment(item.secondary_benefit.renewal_date).isBefore(
                      moment()
                    ) === true
                      ? "red"
                      : "grey"
                  }
                  key={item.secondary_benefit._id}
                >
                  {item.secondary_benefit.benefit.title}
                </Label>
              ) : (
                <React.Fragment />
              )}
              {item.third_benefit !== undefined ? (
                <Label
                  color={
                    moment(item.third_benefit.renewal_date).isBefore(
                      moment()
                    ) === true
                      ? "red"
                      : "grey"
                  }
                  key={item.third_benefit._id}
                >
                  {item.third_benefit.benefit.title}
                </Label>
              ) : (
                <React.Fragment />
              )}
            </Table.Cell>
            <Table.Cell>
              <Button style={{margin: "5px"}} size="tiny" content="Resend Login" icon="mail" color="orange" onClick={() => {
                api.employee.resendOnboarding(item._id).then((res) => {
                  toast({
                    type: "success",
                    icon: "mail",
                    title: <h3>Onboarding Email Resent</h3>,
                    description: (
                      <span>
                        <p>Successfully resent onboarding email.</p>
                      </span>
                    ),
                    time: 4000,
                  });
                })
              }}/>
              <Button style={{margin: "5px"}} size="tiny" content="Reset Password" icon="lock" color="orange" onClick={() => {
                api.employee.resetPassword(item._id).then((res) => {
                  toast({
                    type: "success",
                    icon: "mail",
                    title: <h3>Employee password reset email sent</h3>,
                    description: (
                      <span>
                        <p>Successfully sent email to employee for password reset.</p>
                      </span>
                    ),
                    time: 4000,
                  });
                })
              }}/>
            </Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  render() {
    if (this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add New Employee
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <Table stackable selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Reference</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Position</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Benefits</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}
