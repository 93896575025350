import React, { Component } from "react";
import { Segment, Header, Form, Icon } from "semantic-ui-react";
import api from "../../../../../../actions/api";

class edit extends Component {
  state = {
    data: {
      title: "",
      archived: false,
    },
    submit_load: false,
    errors: {},
  };

  componentDidMount() {
    api.resource_categories.get(this.props.match.params.id).then((res) => {
      this.setState({ data: res });
    });
  }

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  validate = (data) => {
    const errors = {};
    if (!data.title) errors.title = "Can't be empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ submit_load: true });
      api.resource_categories
        .edit(this.props.match.params.id, this.state.data)
        .then(() => this.props.history.push(`/settings/resource_categories`))
        .catch((e) => {
          console.log(e);
          this.setState({ submit_load: false });
        });
    }
  };

  render() {
    const { data, errors } = this.state;
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Edit Resource Cateogry
        </Header>
        <Segment className="no-marg border">
          <Form>
            <Form.Field>
              <Form.Input
                label="Title"
                error={errors.title}
                autoComplete="off"
                type="text"
                name="title"
                value={data.title}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Checkbox
              label="Archived"
              name="archived"
              checked={data.archived}
              onClick={() => {
                this.setState({
                  data: {
                    ...data,
                    archived: !data.archived,
                  },
                });
              }}
            />
            <Form.Group widths="equal">
              <Form.Button
                floated="left"
                color="red"
                onClick={() =>
                  this.props.history.push(`/settings/resource_categories`)
                }
              >
                <Icon name="close" />
                Cancel
              </Form.Button>
              <Form.Button
                floated="right"
                color="green"
                onClick={() => this.handleSubmit()}
                loading={this.state.submit_load}
              >
                <Icon name="plus" />
                Submit
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default edit;
