import React, { Component } from "react";
import { Form, Divider, Segment, Icon, Checkbox } from "semantic-ui-react";

export default class addNoticeForm extends Component {
  state = {
    data: {
      title: "",
      short_description: "",
      long_description: "",
      archived: false,
    },
    errors: {},
  };

  componentDidMount() {
    if (this.props.data !== null) {
      this.setState({ data: this.props.data });
    }
  }

  handleChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  validate = (data) => {
    let errors = {};

    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.short_description) errors.short_description = "Can't Be Empty";
    if (!data.long_description) errors.long_description = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors: errors });

    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    }
  };

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths={"equal"}>
            <Form.Input
              name="title"
              label="Title"
              value={this.state.data.title}
              onChange={this.handleChange}
              placeholder="Title"
              error={this.state.errors.title}
            />
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.TextArea
              name="short_description"
              label="Short Description"
              value={this.state.data.short_description}
              onChange={this.handleChange}
              placeholder="Short Description"
              error={this.state.errors.short_description}
            />
            <Form.TextArea
              name="long_description"
              label="Long Description"
              value={this.state.data.long_description}
              onChange={this.handleChange}
              placeholder="Long Description"
              error={this.state.errors.long_description}
            />
          </Form.Group>
          {this.props.data === null ? (
            <React.Fragment />
          ) : (
            <Form.Group inline>
              <Checkbox
                label="Archived"
                name="archived"
                checked={this.state.data.archived}
                onClick={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      archived: !this.state.data.archived,
                    },
                  });
                }}
              />
            </Form.Group>
          )}
          <Divider />
          <Form.Group widths="equal">
            <Form.Button floated="left" onClick={this.props.close} negative>
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              floated="right"
              onClick={this.handleSubmit}
              color="green"
            >
              <Icon name="plus" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
