import React, { Component } from "react";
import {
  Menu,
  Grid,
  Segment,
  Table,
  Message,
  Icon,
  Header,
  Loader,
} from "semantic-ui-react";
import api from "../../../../actions/api";
import { Link } from "react-router-dom";
import moment from "moment";
import { Can } from "../../../../lib/sso";

class assignedBenefitPage extends Component {
  state = {
    benefit: {},
    companies: [],
    activeItem: "services",
    loading: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ loading: true });
    Promise.all([
      api.company.benefits.getBenefit(
        this.props.match.params.id,
        this.props.match.params.id2
      ),
    ])
      .then((res) => {
        this.setState({
          benefit: {
            ...res[0],
            benefit: {
              ...res[0].benefit,
              assigned_services: res[0].benefit.assigned_services.filter(
                (item) => item.archived === false
              ),
            },
          },
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ loading: false });
      });
  };

  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });

  renderMenu() {
    const { activeItem } = this.state;

    return (
      <Menu className="subMenu" stackable>
        <Menu.Item
          name="services"
          active={activeItem === "services"}
          onClick={this.handleMenuClick}
        >
          Services
        </Menu.Item>
        <Menu.Menu position="right">
          <Can has="company.benefit.edit">
            <Menu.Item
              as={Link}
              to={`/company/${this.props.match.params.id}/benefit/${this.props.match.params.id2}/edit`}
            >
              <Icon name="pencil" />
              Edit Assigned Benefit
            </Menu.Item>
          </Can>
        </Menu.Menu>
      </Menu>
    );
  }

  renderServices() {
    let benefit = this.state.benefit.benefit;
    const list = benefit.assigned_services.map((item) => {
      return (
        <Table.Row key={item._id}>
          <Table.Cell>{item.title}</Table.Cell>
          <Table.Cell>{item.description_short}</Table.Cell>
          <Table.Cell>
            {item.service_resources &&
              item.service_resources.map((resource, index) => {
                return index < item.service_resources.length - 1
                  ? resource.service_type + ", "
                  : resource.service_type;
              })}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Segment basic>
        <Header>Assigned Benefit Services</Header>
        <Table stackable selectable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Service</Table.HeaderCell>
              <Table.HeaderCell>Short Description</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {benefit.assigned_services &&
            benefit.assigned_services.length !== 0 ? (
              list
            ) : (
              <Table.Row>
                <Table.Cell colSpan="3">No Data</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }

  render() {
    const { activeItem } = this.state;
    const benefit = this.state.benefit.benefit;

    if (benefit !== undefined) {
      return (
        <React.Fragment>
          {this.state.loading === false &&
          benefit.archived !== undefined &&
          benefit.archived !== false ? (
            <Message icon info>
              <Icon name="info circle" />
              <Message.Content>
                <Message.Header>Benefit Is Archived</Message.Header>
                This record is archived and will not display on lists.
              </Message.Content>
            </Message>
          ) : (
            <React.Fragment />
          )}
          <Segment className="no-pad benefitPage">
            <Segment basic>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Segment basic className="no-pad compDetails">
                      <Header as={"h1"}>
                        {benefit.title !== undefined ? benefit.title : ""}
                      </Header>
                      {/* <Header as={"h4"}>
                    Benefit Number:
                    <span>{benefit.benefit_number}</span>
                  </Header>
                  <Header as={"h4"}>
                    Insurer:
                    <span>{benefit.insurer}</span>
                  </Header>
                  <Header as={"h4"}>
                    Underwritten:
                    <span>{benefit.underwritten}</span>
                  </Header> */}
                      <Header as={"h4"}>
                        Start Date:
                        <span>
                          {this.state.benefit.start_date !== undefined &&
                          this.state.benefit.start_date !== 0
                            ? `${moment(this.state.benefit.start_date).format(
                                "DD/MM/YYYY"
                              )}`
                            : "N/A"}
                        </span>
                      </Header>
                      <Header as={"h4"}>
                        Renewal Date:
                        <span>
                          {this.state.benefit.renewal_date !== undefined &&
                          this.state.benefit.renewal_date !== 0
                            ? `${moment(this.state.benefit.renewal_date).format(
                                "DD/MM/YYYY"
                              )}`
                            : "N/A"}
                        </span>
                      </Header>
                      <Header as={"h4"}>
                        Allowance:
                        <span>
                          {this.state.benefit.allowance !== undefined &&
                          this.state.benefit.allowance !== 0
                            ? `£${this.state.benefit.allowance}`
                            : "N/A"}
                        </span>
                      </Header>
                      <Header as={"h4"}>
                        Payment Frequency:
                        <span>
                          {benefit.payment_frequency !== undefined &&
                            benefit.payment_frequency}
                        </span>
                      </Header>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Segment style={{ marginTop: "1rem" }}>
                      <Header>Notes</Header>
                      {benefit.notes !== undefined && benefit.notes !== ""
                        ? benefit.notes
                        : "No Notes To Display."}
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            {this.renderMenu()}
            <Segment className="no-pad" basic>
              {activeItem === "services" && this.renderServices()}
            </Segment>
          </Segment>
        </React.Fragment>
      );
    } else {
      return (
        <Segment basic>
          <Loader active />
        </Segment>
      );
    }
  }
}

export default assignedBenefitPage;
