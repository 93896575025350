import React, { Component } from "react";
import {
  Header,
  Segment,
  Message,
  Table,
  Divider,
  Statistic,
  Button,
  Icon,
  Modal,
  Input,
  Form,
} from "semantic-ui-react";
import moment from "moment";
import Moment from "react-moment";
import Chart from "react-apexcharts";
import { DateInput } from "semantic-ui-calendar-react";

export default class BMI extends Component {
  state = {
    readings: [],
    data: {
      weight: 0,
      date: new moment().format("DD/MM/YYYY"),
    },
    BMIResults: [],
    add: false,
    current: {
      weight: 0,
      BMI: 0,
    },
  };

  componentDidMount() {
    if (this.props.data) {
      this.setState(
        {
          readings: this.props.data,
        },
        () => {
          this.getResults();
          if (
            this.state.readings.length &&
            this.props.user &&
            this.props.user.height
          ) {
            this.setState({
              current: {
                weight: this.state.readings[0].weight,
                BMI: this.calcCurrent(
                  this.state.readings[0].weight,
                  this.props.user.height
                ),
              },
            });
          }
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.setState(
        {
          readings: this.props.data,
        },
        () => {
          this.getResults();
          this.setState({
            current: {
              weight: this.state.readings[0].weight,
              BMI: this.calcCurrent(
                this.state.readings[0].weight,
                this.props.user.height
              ),
            },
          });
        }
      );
    }
  }

  calcCurrent = (weight = 0, height = 0) => {
    let bmi = weight / (height * height);
    return bmi.toFixed(0);
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleDateChange = (event, { name, value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  submit = () => {
    if (this.state.data.weight !== 0) {
      this.props.add(this.state.data).then(() => {
        this.setState({
          add: false,
        });
      });
    }
  };

  getResults = () => {
    if (this.props.user && this.props.user.height) {
      this.setState({
        BMIResults: this.props.data.map((i) => {
          return {
            x: moment(i.date).format("DD/MM/YY HH:MM"),
            y: (
              i.weight /
              (this.props.user.height * this.props.user.height)
            ).toFixed(2),
          };
        }),
      });
    }
  };

  closeAdd = () => {
    this.setState({ add: false });
  };

  renderChart() {
    let chartOptions = {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "BMI Score",
        align: "left",
      },
      yaxis: {
        title: {
          text: "B M I",
        },
        min: 15,
        max: 70,
        forceNiceScale: true,
      },
      xaxis: {
        title: {
          text: "Date",
        },
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      series: [
        {
          name: "Your BMI",
          data: this.state.BMIResults,
        },
      ],
    };
    return (
      <Segment basic className="no-pad">
        {this.state.BMIResults.length > 2 ? (
          <Chart
            height={350}
            options={chartOptions}
            series={chartOptions.series}
            type="line"
          />
        ) : (
          <Message
            info
            icon="info circle"
            header="Results Chart"
            content="Chart will appear when sufficient amount of results are added."
          />
        )}
      </Segment>
    );
  }

  renderTable() {
    const list = this.state.readings.map((data, i) => {
      let bmi = 0;

      if (this.props.user && this.props.user.height) {
        bmi = this.calcCurrent(data.weight, this.props.user.height);
      } else {
        bmi = "Requires Height";
      }

      return (
        <Table.Row key={i}>
          <Table.Cell>
            <Moment format={"DD/MM/YY"}>{data.date}</Moment>
          </Table.Cell>
          <Table.Cell>{data.weight}</Table.Cell>
          <Table.Cell>{bmi}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table unstackable textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Weight</Table.HeaderCell>
            <Table.HeaderCell>BMI</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.length !== 0 ? (
            list
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3">No Data To Display</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }

  renderCurrent() {
    return (
      <Segment basic>
        <Header textAlign="center">Current</Header>
        <Statistic.Group
          widths={
            this.props.user &&
            this.props.user.height &&
            this.state.readings.length !== 0
              ? 2
              : 1
          }
        >
          {this.props.user &&
          this.props.user.height &&
          this.state.readings.length !== 0 ? (
            <React.Fragment>
              <Statistic>
                <Statistic.Value>{this.state.current.BMI}</Statistic.Value>
                <Statistic.Label>BMI</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>{this.state.current.weight}</Statistic.Value>
                <Statistic.Label>Weight KG</Statistic.Label>
              </Statistic>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Statistic>
                <Statistic.Value></Statistic.Value>
                <Statistic.Label>
                  Requires Height And Weight To Display Current Reading
                </Statistic.Label>
              </Statistic>
            </React.Fragment>
          )}
        </Statistic.Group>
        <Divider />
        <Button positive fluid onClick={() => this.setState({ add: true })}>
          <Icon name="plus" /> Add Reading
        </Button>
      </Segment>
    );
  }

  renderAddModal() {
    return (
      <Modal
        open={this.state.add}
        onClose={this.closeAdd}
        size="small"
        centered={true}
      >
        <Modal.Header>
          <Icon name="weight" /> Add Weight
        </Modal.Header>
        <Modal.Content>
          <Segment>
            <Header>
              Employee Height:{" "}
              {this.props.user ? this.props.user.height : "Not Provided"} (M)
            </Header>
            <Form>
              <Form.Group widths="2">
                <Form.Field>
                  <label>Date</label>
                  <DateInput
                    autoComplete={"off"}
                    animation="off"
                    name="date"
                    iconPosition="left"
                    value={this.state.data.date}
                    onChange={this.handleDateChange}
                    startMode={"day"}
                    placeholder="Date"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Weight (KG)</label>
                  <Input
                    fluid
                    name="weight"
                    placeholder={"Weight (KG)"}
                    onChange={this.handleChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Button
                  floated="left"
                  color="red"
                  onClick={() => this.closeAdd()}
                >
                  <Icon name="close" />
                  Cancel
                </Form.Button>
                <Form.Button
                  floated="right"
                  color="green"
                  onClick={() => this.submit()}
                >
                  <Icon name="plus" />
                  Submit
                </Form.Button>
              </Form.Group>
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Segment basic className="pagHolder">
        {this.renderAddModal()}
        <Header textAlign="center" as={"h1"}>
          BMI
        </Header>
        {this.renderCurrent()}
        {this.renderChart()}
        <Divider />
        {this.renderTable()}
      </Segment>
    );
  }
}
