import React, { Component } from "react";
import { Form, Segment, Icon, Table, Button } from "semantic-ui-react";

class add extends Component {
  state = {
    data: {
      category_title: "",
      categories: [],
    },
    temp_data: {
      question: "",
      answer: "",
    },
    submit_load: false,
    errors: {},
  };

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleTempChange = (event) => {
    this.setState({
      temp_data: {
        ...this.state.temp_data,
        [event.target.name]: event.target.value,
      },
    });
  };

  validate = (data) => {
    const errors = {};
    if (!data.category_title) errors.category_title = "Can't Be Empty";
    if (data.categories.length === 0) errors.categories = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ submit_load: true });
      this.props.submit(this.state.data);
    }
  };

  renderBody() {
    return this.state.data.categories.map((item, index) => {
      return (
        <Table.Row key={index}>
          <Table.Cell>{item.question}</Table.Cell>
          <Table.Cell>{item.answer}</Table.Cell>
          <Table.Cell>
            <Button
              icon="trash" color="red"
              onClick={() => {
                let array = this.state.data.categories;

                array.splice(index, 1);

                this.setState({
                  data: {
                    ...this.state.data,
                    categories: array,
                  },
                });
              }}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="category_title"
              label="Category Title"
              value={this.state.data.category_title}
              onChange={this.handleChange}
              placeholder="Category Title"
              error={this.state.errors.category_title}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.TextArea
              name="question"
              label="Question"
              value={this.state.temp_data.question}
              onChange={this.handleTempChange}
              placeholder="Question"
              error={this.state.errors.categories}
            />
            <Form.TextArea
              name="answer"
              label="Answer"
              value={this.state.temp_data.answer}
              onChange={this.handleTempChange}
              placeholder="Answer"
              error={this.state.errors.categories}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field width="2">
              <Form.Button
                fluid
                icon="plus"
                color="green"
                content="Add Q/A"
                disabled={
                  this.state.temp_data.question !== "" &&
                  this.state.temp_data.answer !== ""
                    ? false
                    : true
                }
                onClick={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      categories: [
                        ...this.state.data.categories,
                        {
                          question: this.state.temp_data.question,
                          answer: this.state.temp_data.answer,
                        },
                      ],
                    },
                    temp_data: {
                      question: "",
                      answer: "",
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Question</Table.HeaderCell>
                <Table.HeaderCell>Answer</Table.HeaderCell>
                <Table.HeaderCell width="2">Options</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.data.categories.length !== 0 ? (
                this.renderBody()
              ) : (
                <Table.Row>
                  <Table.Cell>No Data Added</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Form.Group widths="equal">
            <Form.Button
              floated="left"
              color="red"
              onClick={() => this.props.close()}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button
              floated="right"
              color="green"
              onClick={() => this.handleSubmit()}
              loading={this.state.submit_load}
            >
              <Icon name="plus" />
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

export default add;
