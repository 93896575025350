import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import Dash from "./dash";
import Menu from "./menu";

export default class ReportsPages extends Component {
  render() {
    return (
      <Segment basic className="no-pad no-marg ">
        <Menu />
        <Segment basic className="pageHolder">
          <Switch>
            <Route exact path={``} component={Dash} />
          </Switch>
        </Segment>
      </Segment>
    );
  }
}
