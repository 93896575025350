import React from "react";
import {
  Table,
  Segment,
  Button,
  Input,
  Form,
  Header,
  Divider,
  Icon,
} from "semantic-ui-react";

export default class SettingList extends React.Component {
  state = {
    textvalue: "",
    formData: [],
  };

  componentDidMount() {
    this.setState({
      formData: this.props.data.map((item) => {
        if (typeof item !== "object") {
          item = { title: item, required: false };
        }
        return item;
      }),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        formData: this.props.data,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      textvalue: e.target.value,
    });
  };

  handleSubmit = () => {
    let fd = this.state.formData;
    if (fd !== undefined) {
      fd.push({ title: this.state.textvalue, required: false });
    }

    this.setState({
      formData: fd,
      textvalue: "",
    });
    let field = this.props.field;
    const data = {};
    data[field] = this.state.formData;
    this.props.submit(data);
  };

  handleDelete = (obj) => {
    let newList = this.state.formData.filter((item, i) => {
      return obj !== item.title;
    });
    this.setState({
      formData: newList,
    });
    let field = this.props.field;
    const data = {};
    data[field] = newList;
    this.props.delete(data);
  };

  render() {
    const form = (
      <Segment basic>
        <Form onSubmit={this.handleSubmit}>
          <Input
            action={{ icon: "plus", color: "green" }}
            onChange={this.handleChange}
            value={this.state.textvalue}
            placeholder={this.props.placeholder}
            fluid
          />
        </Form>
      </Segment>
    );

    let list;

    if (this.state.formData) {
      list = this.state.formData.map((item, i) => {
        return (
          <Table.Row key={i}>
            <Table.Cell>{item.title}</Table.Cell>

            <Table.Cell>
              {this.props.editable ? (
                <Button
                  circular
                  icon={item.required ? "check" : "close"}
                  size="tiny"
                  color={item.required ? "green" : "red"}
                  onClick={() => {
                    let formData = this.state.formData;

                    let theItem = formData[i];
                    theItem.required = !item.required;

                    this.setState({ formData });

                    let field = this.props.field;
                    const data = {};
                    data[field] = this.state.formData;
                    this.props.submit(data);
                  }}
                />
              ) : (
                <Icon
                  name={item.required ? "check" : "close"}
                  color={item.required ? "green" : "red"}
                />
              )}
            </Table.Cell>
            <Table.Cell>
              {this.props.editable ? (
                <Form.Dropdown
                  name="action"
                  value={item.action ? item.action : "none"}
                  onChange={(e, d) => {
                    let formData = this.state.formData;

                    let theItem = formData[i];
                    theItem.action = d.value;

                    this.setState({ formData });
                    let field = this.props.field;
                    const data = {};
                    data[field] = this.state.formData;
                    this.props.submit(data);
                  }}
                  options={[
                    { value: "none", text: "None", key: "none" },
                    { value: "new", text: "New PA Referral", key: "new" },
                  ]}
                  selection
                />
              ) : item.action ? (
                item.action
              ) : (
                "none"
              )}
            </Table.Cell>

            {this.props.editable && (
              <Table.Cell>
                <Button
                  circular
                  icon="close"
                  size="tiny"
                  color="red"
                  onClick={() => this.handleDelete(item.title)}
                />
              </Table.Cell>
            )}
          </Table.Row>
        );
      });
    }

    return (
      <Segment basic>
        <Header>{this.props.title}</Header>
        <Divider />
        {this.props.editable && form}
        <Table stackable className={"settingTable"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Outcome</Table.HeaderCell>
              <Table.HeaderCell>Required Notes &amp; Docs</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
              {this.props.editable && (
                <Table.HeaderCell>Remove</Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>{list}</Table.Body>
        </Table>
      </Segment>
    );
  }
}
