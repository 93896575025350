import React, { Component } from "react";
import {
  Segment,
  Header,
  Grid,
  Menu,
  Icon,
  Modal,
  TextArea,
  Form,
  Button,
  Divider,
  Table,
  Select,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../actions/api";
import CaseNotesList from "../common/lists/caseNotes";
import FileManagerList from "../common/lists/fileManagerList";
import { Authentication } from "../../../lib/sso";

export default class Case extends Component {
  state = {
    note_modal: false,
    addSummary: false,
    activeItem: Authentication.can("organisation_case_manager.permission")
      ? "documents"
      : "notes",
    case: {},
    CM: {
      data: [],
      formList: [],
    },
    data: {
      cm: "",
    },
    settings: {},
    current_note_item: {},
    case_notes: [],
    filtered: [],
    files: [],
    folder: [],
    folders: [],
    path: "",
  };

  componentDidMount() {
    this.getData();
  }
  closeSummary = () => {
    this.setState({
      addSummary: false,
    });
  };
  getData = () => {
    api.case.getCase(this.props.match.params.id).then((data) => {
      console.log("data", data);
      this.loadFolders(data.caseFolder, true);
      this.setState({
        case: data,
        data: {
          company_summary: data.company_summary,
        },
      });
    });

    api.case.getCaseNotes(this.props.match.params.id).then((data) => {
      this.setState({
        filtered: data,
        case_notes: data,
      });
    });

    api.settings.formLists().then((settings) => {
      this.setState({
        settings,
      });
    });

    api.team.getNurseManagers().then((CM) => {
      this.setState({
        CM,
      });
    });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  addCompanySummary = () => {
    api.case
      .editCase(this.props.match.params.id, this.state.data)
      .then((res) => {
        this.closeSummary();
        this.getData();
      })
      .catch((e) => {
        this.closeSummary();
        console.log(e);
      });
  };
  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });

  handleClientFilter = (query) => {
    let updatedList = this.state.case_notes;
    updatedList = updatedList.filter((item) => {
      return (
        item.logType.toLowerCase().search(query.search.toLowerCase()) !== -1 ||
        item.message.toLowerCase().search(query.search.toLowerCase()) !== -1
      );
    });
    this.setState({ filtered: updatedList });
  };

  renderMenu() {
    const { activeItem } = this.state;
    return (
      <Menu className="subMenu case" stackable>
        {!Authentication.can("organisation_case_manager.permission") && (
          <Menu.Item
            name="notes"
            onClick={() =>
              this.props.history.push(`/case/${this.state.case._id}/addnote`)
            }
          >
            <Icon name="plus" /> Add Case Note
          </Menu.Item>
        )}
        {!Authentication.can("organisation_case_manager.permission") && (
          <Menu.Item
            name="notes"
            active={activeItem === "notes"}
            onClick={this.handleMenuClick}
          >
            Case Notes
          </Menu.Item>
        )}
        <Menu.Item
          name="documents"
          active={activeItem === "documents"}
          onClick={this.handleMenuClick}
        >
          Case Documents
        </Menu.Item>
        {!Authentication.can("organisation_case_manager.permission") && (
          <Menu.Item
            name="summary"
            active={activeItem === "summary"}
            onClick={() => this.setState({ addSummary: true })}
          >
            Update Summary for Company
          </Menu.Item>
        )}
        {Authentication.can("navigator_admin.permission") ||
        Authentication.can("navigator_case_manager.permission") ? (
          <React.Fragment>
            {this.state.case.cm ? (
              <Menu.Item onClick={() => this.setState({ addCM: true })}>
                <Icon name="user" />
                Change Nurse Case Manager
              </Menu.Item>
            ) : (
              <Menu.Item onClick={() => this.setState({ addCM: true })}>
                <Icon name="user" />
                Assign Nurse Case Manager
              </Menu.Item>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}

        <Menu.Menu position="right">
          <Menu.Item
            onClick={() =>
              this.props.history.push(
                `/case/${this.props.match.params.id}/edit`
              )
            }
          >
            <Icon name="pencil" />
            Edit Case
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
  renderSummaryModal() {
    return (
      <Modal
        open={this.state.addSummary}
        onClose={this.closeSummary}
        centered={false}
      >
        <Header icon="sticky note" content="Add Summary for Company" />
        <Modal.Content>
          <Segment>
            <Form>
              <TextArea
                name={"company_summary"}
                onChange={this.onChange}
                value={this.state.data.company_summary}
              />
              <Divider />
              <Form.Group widths={2}>
                <Form.Field>
                  <Button negative onClick={this.closeSummary}>
                    <Icon name="close" /> Close
                  </Button>
                </Form.Field>
                <Form.Field>
                  <Button
                    positive
                    floated="right"
                    onClick={this.addCompanySummary}
                  >
                    <Icon name="save" /> Update
                  </Button>
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }
  renderNotes() {
    return (
      <Segment>
        <CaseNotesList
          logs={this.state.filtered}
          onQuery={this.handleClientFilter}
          action={(a) =>
            this.props.history.push(`/case/${this.state.case._id}/note/${a}`)
          }
          add={() =>
            this.props.history.push(`/case/${this.state.case._id}/addnote`)
          }
        />
      </Segment>
    );
  }

  loadFolders(item, search = false, findFile) {
    this.setState({ loading: true });
    if (findFile) {
      api.files.searchFiles({ search: findFile }).then((res) => {
        this.setState({
          folders: [],
          files: res,
          loading: false,
        });
      });

      return true;
    }
    if (item) {
      api.files.getFolder(item).then((res) => {
        this.setState({
          files: res.files,
          folder: res.folder,
          folders: res.folders,
          loading: false,
        });
      });
      api.files.getFolderPath(item).then((res) => {
        this.setState({
          path: res,
        });
      });
    } else {
      api.files.getAll().then((res) => {
        this.setState({
          path: "/home",
          folder: null,
          folders: res.folders,
          files: res.files,
          loading: false,
        });
      });
    }
  }

  renderDocuments() {
    return (
      <Segment>
        <FileManagerList
          refFolder={this.state.case.refFolder}
          caseFolder={this.state.case.caseFolder}
          rootFolder={this.state.folder._id}
          folder={this.state.folder}
          folders={this.state.folders}
          files={this.state.files}
          path={"/case"}
          loadFolders={(e, b, s) => this.loadFolders(e, b, s)}
        />
      </Segment>
    );
  }

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  assignCM = () => {
    let data = this.state.data;

    api.case
      .editCase(this.props.match.params.id, {
        cm: data.cm,
      })
      .then(() => {
        this.setState({
          addCM: false,
        });
        this.getData();
      });
  };

  renderAddCaseManager() {
    const { CM, data } = this.state;
    let disabled = true;
    if (data.cm !== "") {
      disabled = false;
    }
    let CMs = [];
    CM.formList.map((c) => {
      // if (this.state.referral.company._id === c.company) {
      CMs.push(c);
      // }
    });
    return (
      <Modal
        open={this.state.addCM}
        onClose={() => this.setState({ addCM: false })}
        centered={false}
        size="small"
        closeOnDimmerClick={true}
      >
        <Header icon="medkit" content="Assign Nurse Manager" />
        <Modal.Content>
          <Segment textAlign="center">
            <Header>Pick Nurse Case Manager.</Header>
            <Select
              options={CMs.length > 0 ? CMs : []}
              name="cm"
              onChange={this.handleDropdownChange}
              value={data.cm}
              search
            />
            <Button
              positive
              onClick={this.assignCM}
              attached={"right"}
              disabled={disabled}
            >
              Update Nurse Case Manager
            </Button>
            <Divider />
            <Header>Nurse Case Manager List</Header>
            <Segment className={"scrollList1"} basic>
              <Table sortable striped selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Referral Manager</Table.HeaderCell>
                    <Table.HeaderCell>Active Cases</Table.HeaderCell>
                    <Table.HeaderCell>Closed Cases</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {CM.data.map((c) => {
                    // if (this.state.referral.company._id === c.company) {
                    return (
                      <Table.Row
                        key={c._id}
                        onClick={() =>
                          this.setState({
                            data: {
                              ...this.state.data,
                              cm: c._id,
                            },
                          })
                        }
                      >
                        <Table.Cell>
                          {c.firstName} {c.lastName}
                        </Table.Cell>
                        <Table.Cell>{c.ActiveCases}</Table.Cell>
                        <Table.Cell>{c.ClosedCases}</Table.Cell>
                      </Table.Row>
                    );
                    // }
                  })}
                </Table.Body>
              </Table>
            </Segment>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  renderCosts() {
    return <Segment>Costs maybe dunno</Segment>;
  }

  renderNew() {
    return <Segment>Something here</Segment>;
  }

  render() {
    const { activeItem } = this.state;
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="no-marg pageTitle case">
          {this.state.case.company ? this.state.case.company.name + " - " : ""}
          Case C{this.state.case.refNum}
        </Header>
        <Segment basic className="no-marg no-pad">
          <Segment basic className=" no-pad">
            {this.renderSummaryModal()}
            {this.renderAddCaseManager()}
            <Grid stackable>
              <Grid.Row stretched>
                <Grid.Column width={6}>
                  <Grid className="no-marg dataGrid case" celled>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Patient:</Header>
                        {this.state.case.employee && (
                          <span>
                            <Link
                              to={`/employee/${this.state.case.employee._id}`}
                            >
                              {this.state.case.employee.firstName}{" "}
                              {this.state.case.employee &&
                                this.state.case.employee.lastName}
                            </Link>
                          </span>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Patient Mobile Number:</Header>
                        {this.state.case.employee &&
                          this.state.case.employee.mobile_number}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Patient Home Number:</Header>
                        {this.state.case.employee &&
                          this.state.case.employee.phone_number}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Patient Email:</Header>
                        {this.state.case.employee &&
                          this.state.case.employee.email}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Patient Home Email:</Header>
                        {this.state.case.employee &&
                          this.state.case.employee.home_email}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Patient Insurer:</Header>
                        {this.state.case.referral &&
                          this.state.case.referral.insurer}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Nurse Case Manager:</Header>
                        <span>
                          {this.state.case.cm ? (
                            <Link
                              to={`/staff/${this.state.case.cm._id}`}
                            >{`${this.state.case.cm.firstName} ${this.state.case.cm.lastName}`}</Link>
                          ) : (
                            "No Nurse Manager Assigned!"
                          )}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Case Status:</Header>
                        {this.state.case.status && (
                          <span>{this.state.case.status}</span>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Referral:</Header>
                        {this.state.case.referral && (
                          <Link
                            to={`/referral/${this.state.case.referral._id}`}
                          >
                            <span>R{this.state.case.referral.refNum}</span>
                          </Link>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Referral Type:</Header>
                        {this.state.case.referral &&
                          this.state.case.referral.type}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Referral Reason:</Header>
                        {this.state.case.referral &&
                          this.state.case.referral.reason}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>Referral Condition:</Header>
                        {this.state.case.referral &&
                          this.state.case.referral.condition}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Grid className="no-marg dataGrid case" celled>
                    <Segment basic>
                      <Header>Summary from Company:</Header>{" "}
                      <p style={{ whiteSpace: "pre-line" }}>
                        {this.state.case.company_summary !== undefined
                          ? this.state.case.company_summary
                          : "None Provided"}
                      </p>
                    </Segment>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Grid className="no-marg dataGrid case" celled>
                    <Segment basic>
                      <Header>Details:</Header>{" "}
                      <p style={{ whiteSpace: "pre-line" }}>
                        {this.state.case.additional_details !== undefined
                          ? this.state.case.additional_details
                          : "None Provided"}
                      </p>
                    </Segment>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          {this.renderMenu()}
          {activeItem === "notes" &&
            !Authentication.can("organisation_case_manager.permission") &&
            this.renderNotes()}
          {activeItem === "documents" && this.renderDocuments()}
          {activeItem === "new" && this.renderNew()}
          {activeItem === "costs" && this.renderCosts()}
        </Segment>
      </Segment>
    );
  }
}
