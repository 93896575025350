import React, { Component } from "react";
import { Form, Segment, Header } from "semantic-ui-react";
import api from "../../../actions/api";
import PostCoder from "../../../components/postCoder";
import AppConfig from "../../../../../_appConfig";

class editReferralForm extends Component {
  state = {
    data: {
      type: "",
      reason: "",
      condition: "",
      additional_details: "",
      severity: "",
      insurer: "",
      insurer_address: {
        address: "",
        addressline1: "",
        addressline2: "",
        street: "",
        posttown: "",
        county: "",
        postcode: "",
      },
      // assigned_manager: "",
      archived: false,
    },
    type_list: [],
    reason_list: [],
    condition_list: [],
    manager_list: [],
    severity_list: [],
    status_list: [],
    errors: {},
    submit_load: false,
  };

  componentDidMount() {
    console.log("this.props", this.props);
    Promise.all([
      api.settings.all(),
      api.referral.getReferral(this.props.data._id),
    ])
      .then((res) => {
        this.setState({
          data: { ...this.state.data, ...res[1] },
          type_list: res[0].refTypes.map((item) => {
            return { text: item, value: item, key: item };
          }),
          reason_list: res[0].refReasons.map((item) => {
            return { text: item, value: item, key: item };
          }),
          condition_list: res[0].refConditions.map((item, i) => {
            return { text: item, value: item, key: i };
          }),
          status_list: res[0].referralStatuses.map((item, i) => {
            return { text: item, value: item, key: i };
          }),
        });
      })
      .catch((e) => {
        console.log(e);
      });

    if (this.props.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleAddressChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        insurer_address: {
          ...this.state.data.insurer_address,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  addressUpdate = (addr) => {
    this.setState({
      data: {
        ...this.state.data,
        insurer_address: {
          address: addr.summaryline,
          addressline1: addr.addressline1,
          addressline2: addr.addressline2,
          street: addr.street,
          posttown: addr.posttown,
          county: addr.county,
          postcode: addr.postcode,
          latitude: addr.latitude,
          longitude: addr.longitude,
          organisation: addr.organisation,
        },
      },
    });
  };

  validate = (data) => {
    const errors = {};
    if (!data.type) errors.type = "Can't Be Empty";
    if (!data.reason) errors.reason = "Can't Be Empty";
    if (!data.condition) errors.condition = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ submit_load: true });
      api.referral
        .editReferal(this.props.data._id, this.state.data)
        .then(() => {
          window.history.back();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  render() {
    console.log("data", this.state.data);
    return (
      <Segment basic>
        <Header as={"h1"} textAlign="center" className="no-marg sectionTitle ">
          Edit Referral Form
        </Header>
        <Segment>
          <Form>
            <Form.Group widths="equal">
              <Form.Dropdown
                error={this.state.errors.type}
                name="type"
                label="Referral Type"
                value={this.state.data.type}
                onChange={this.handleDropdownChange}
                placeholder="Please Select Referral Type"
                options={this.state.type_list}
                selection
                search
                noResultsMessage={"Could Not Find Any Referral Types"}
              />
              <Form.Dropdown
                error={this.state.errors.status}
                name="status"
                label="Status"
                value={this.state.data.status}
                onChange={this.handleDropdownChange}
                options={this.state.status_list}
                selection
                search
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Dropdown
                error={this.state.errors.reason}
                name="reason"
                label="Referral Reason"
                value={this.state.data.reason}
                onChange={this.handleDropdownChange}
                placeholder="Please Select Referral Reason"
                options={this.state.reason_list}
                selection
                search
                noResultsMessage={"Could Not Find Any Referral Reasons"}
              />
              <Form.Dropdown
                error={this.state.errors.condition}
                name="condition"
                label="Condition"
                value={this.state.data.condition}
                onChange={this.handleDropdownChange}
                placeholder="Please Condition"
                options={this.state.condition_list}
                selection
                search
                noResultsMessage={"Could Not Find Any Conditions"}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="insurer"
                label="Insurer"
                value={this.state.data.insurer}
                onChange={this.handleChange}
                placeholder="Insurer"
                error={this.state.errors.insurer}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Address Lookup</label>
                {/* <label>Please Use Demo Postcode: NR14 7PZ</label> */}
                <PostCoder
                  apiKey={AppConfig.postcoder_api_key}
                  addressSelectedCallback={this.addressUpdate}
                  identifier={AppConfig.postcoder_identifier}
                  country={"UK"}
                  showAddress={false}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="addressline1"
                label="Address Line 1"
                value={
                  this.state.data.insurer_address &&
                  this.state.data.insurer_address.addressline1
                }
                onChange={this.handleAddressChange}
                placeholder="Address Line 1"
                error={this.state.errors.addressline1}
              />
              <Form.Input
                name="addressline2"
                label="Address Line 2"
                value={
                  this.state.data.insurer_address &&
                  this.state.data.insurer_address.addressline2
                }
                onChange={this.handleAddressChange}
                placeholder="Address Line 2"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="street"
                label="Street"
                value={
                  this.state.data.insurer_address &&
                  this.state.data.insurer_address.street
                }
                onChange={this.handleAddressChange}
                placeholder="Street"
                error={this.state.errors.street}
              />
              <Form.Input
                name="posttown"
                label="Town / City"
                value={
                  this.state.data.insurer_address &&
                  this.state.data.insurer_address.posttown
                }
                onChange={this.handleAddressChange}
                placeholder="Postal Town / City"
                error={this.state.errors.posttown}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="county"
                label="County"
                value={
                  this.state.data.insurer_address &&
                  this.state.data.insurer_address.county
                }
                onChange={this.handleAddressChange}
                placeholder="County"
                error={this.state.errors.county}
              />
              <Form.Input
                name="postcode"
                label="Postcode"
                value={
                  this.state.data.insurer_address &&
                  this.state.data.insurer_address.postcode
                }
                onChange={this.handleAddressChange}
                placeholder="Postcode"
                error={this.state.errors.postcode}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.TextArea
                name="additional_details"
                label="Additional Details"
                value={this.state.data.additional_details}
                onChange={this.handleChange}
                placeholder="Please Type Any Additional Details"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Checkbox
                label="Archived"
                name="archived"
                style={{ floated: "right" }}
                checked={this.state.data.archived}
                onClick={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      archived: !this.state.data.archived,
                    },
                  });
                }}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Button
                type="submit"
                color="red"
                floated="left"
                content="Cancel"
                icon="close"
                onClick={() => {
                  window.history.back();
                }}
              />
              <Form.Button
                color="green"
                floated="right"
                content="Submit"
                icon="checkmark"
                loading={this.state.submit_load}
                onClick={() => this.handleSubmit()}
              />
            </Form.Group>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default editReferralForm;
