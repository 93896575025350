import React, { Component } from "react";
import { Header, Segment, Loader } from "semantic-ui-react";
import api from "../../actions/api";
import Company_Receiver from "../../contexts/company_context/Company_Receiver";
import PaginateWrapper from "../../components/PaginateWrapper";
import EmployeeTable from "../../components/lists/employeeList";

class EmployeeList extends Component {
  state = {
    list: [],
    reload: true,
    total: 0
  };
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.company !== prevProps.company) {
      this.setState({
        reload: false,
      });
    }
  }
  getData = () => {
    this.props.company._refresh();
    api.company.getDash(this.props.company._id).then((res) => {
      this.setState({
          total: res.emp,
      });
    });
  };
  getEmployees = (pageNum, pageSize, search, filters) => {
    return api.company.getCompanyEmployeesPaged(
      this.props.company._id,
      {
        pageNum,
        pageSize,
        search,
        filters,
      },
      () => {
        this.setState({
          reload: false,
        });
      }
    );
  };
  goto = (id) => {
    this.props.history.push(`/employee/${id}`);
  };
  render() {
    return (
      <Segment basic>
        <Header className={"sectionTitle"}>Employee List ({this.state.total})</Header>

        {this.state.reload === false ? (
          <Segment className="border">
            <PaginateWrapper
              ref={(ref) => (this.employeesPaginate = ref)}
              dataQuery={this.getEmployees}
              search={true}
              render={(items) => (
                <EmployeeTable data={items} action={this.goto} />
              )}
            />
          </Segment>
        ) : (
          <Segment basic>
            <Loader active />
          </Segment>
        )}
      </Segment>
    );
  }
}
export default Company_Receiver(EmployeeList, (company) => {
  return {
    company,
  };
});
