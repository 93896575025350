import React from "react";
import API from "../../actions/api";
import {Form, Icon, List, Message, Segment} from "semantic-ui-react";
import {PasswordPolicy, charsets} from "password-sheriff";


export default class PasswordChangeFields extends React.Component {
    state = {
        policy: {
            charset: {}
        },
        password: "",
        password_confirm: "",
        loading: true
    }

    componentDidMount() {
        API.user.password_policy().then(res => {
            this.setState({
                policy: res
            });
        }).catch(err => {
            // TODO
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }

    validatePassword = () => {
        const requirements = this.passwordRequirements();
        const totalFailed = requirements.filter(item => !item.passed).length;
        if(totalFailed >= 1){
            // Password failed, alert the parent
            this.props.onChange(null);
        } else {
            // Password passed, alert the parent
            this.props.onChange(this.state.password);
        }
    }
    passwordRequirements = () => {
        let requirements = [
            {
                text: "Password & Confirm Password fields match",
                passed: this.state.password !== "" ? (this.state.password === this.state.password_confirm) : false
            },
            {
                text: "Must be longer then " + this.state.policy.minLength + " characters",
                passed: this.state.password.length >= this.state.policy.minLength
            }
        ];
        if(this.state.policy.charset.lowercase){
            let passwordPolicy = new PasswordPolicy({
                contains: {
                    expressions: [charsets.lowerCase]
                }
            });
            requirements.push({
                text: "Must contain lowercase letters (a-z)",
                passed: passwordPolicy.check(this.state.password)
            });
        }
        if(this.state.policy.charset.uppercase){
            let passwordPolicy = new PasswordPolicy({
                contains: {
                    expressions: [charsets.upperCase]
                }
            });
            requirements.push({
                text: "Must contain uppercase letters (A-Z)",
                passed: passwordPolicy.check(this.state.password)
            });
        }
        if(this.state.policy.charset.special){
            let passwordPolicy = new PasswordPolicy({
                contains: {
                    expressions: [charsets.specialCharacters]
                }
            });
            requirements.push({
                text: "Must contain special characters e.g. # @ < > ? ! £ $ % & * - _ / ~ [ ] { } = +",
                passed: passwordPolicy.check(this.state.password)

            });
        }
        if(this.state.policy.charset.numbers){
            let passwordPolicy = new PasswordPolicy({
                contains: {
                    expressions: [charsets.numbers]
                }
            });
            requirements.push({
                text: "Must contain numbers (0-9)",
                passed: passwordPolicy.check(this.state.password)
            });
        }
        return requirements;
    }
    renderPasswordRequirements(){
        const requirements = this.passwordRequirements();
        return (
            <List as={"ul"}>{requirements.map((item, index) => {
                return (
                    <List.Item as={"li"} key={index} style={{color: (item.passed ? "green" : "red")}}>{item.passed ? <Icon name={"check square"} /> : <Icon name={"exclamation triangle"} />} {item.text}</List.Item>
                )
            })}</List>
        )
    }
    render() {
        return (
            <Segment basic loading={this.state.loading}>
                {this.props.labels ? <React.Fragment>
                    <Form.Field>
                        <Form.Input
                            label={"Password"}
                            type={"password"}
                            name={"password"}
                            onChange={(e) => this.setState({password: e.target.value}, this.validatePassword)}
                            value={this.state.password}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            label={"Confirm Password"}
                            type={"password"}
                            name={"password_confirm"}
                            onChange={(e) => this.setState({password_confirm: e.target.value}, this.validatePassword)}
                            value={this.state.password_confirm}
                        />
                    </Form.Field>
                </React.Fragment> : <React.Fragment>
                    <Form.Field>
                        <Form.Input
                            icon="lock"
                            iconPosition="left"
                            type={"password"}
                            placeholder="Password"
                            name={"password"}
                            onChange={(e) => this.setState({password: e.target.value}, this.validatePassword)}
                            value={this.state.password}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            icon="lock"
                            iconPosition="left"
                            type={"password"}
                            placeholder="Confirm Password"
                            name={"password_confirm"}
                            onChange={(e) => this.setState({password_confirm: e.target.value}, this.validatePassword)}
                            value={this.state.password_confirm}
                        />
                    </Form.Field>
                </React.Fragment>}
                <Message info>
                    <Message.Header><Icon name={"info circle"} /> Password must meet the following requirements:</Message.Header>
                    {this.renderPasswordRequirements()}
                </Message>
            </Segment>
        );
    }
}