import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ReferralReport from "./reports/referral_report";
import CaseReport from "./reports/case_report";
import ServiceReport from "./reports/service_report";
import EmployeeReport from "./reports/employee_report";
import Dashboard from "./dashboard";

export default class index extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.url}/`} component={Dashboard} />
        <Route
          exact
          path={`${this.props.match.url}/referral_report`}
          component={ReferralReport}
        />
        <Route
          exact
          path={`${this.props.match.url}/case_report`}
          component={CaseReport}
        />
        <Route
          exact
          path={`${this.props.match.url}/service_report`}
          component={ServiceReport}
        />
        <Route
          exact
          path={`${this.props.match.url}/employee_report`}
          component={EmployeeReport}
        />
      </Switch>
    );
  }
}
