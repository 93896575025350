import React, { Component } from "react";
import { Segment, Header } from "semantic-ui-react";
import CasesList from "../common/lists/caseList";
import api from "../../../actions/api";
import PaginateWrapper from "../common/PaginateWrapper";
import { Authentication } from "../../../lib/sso";

export default class CaseList extends Component {
  getData = (pageNum, pageSize, search, filters) => {
    return Authentication.can("navigator_admin.permission") === true
      ? api.case.getAllPaged({
          pageNum,
          pageSize,
          search,
          filters,
        })
      : Authentication.can("navigator_case_manager.permission") === true || Authentication.can("organisation_case_manager.permission") ===
      true
      ? api.case.getAllCMPaged({
          pageNum,
          pageSize,
          search,
          filters,
        })
      : [];
  };

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle case">
          Cases
        </Header>
        <Segment className="border-case">
          <PaginateWrapper
            preserve_options={"Case_Dash"}
            dataQuery={this.getData}
            search={true}
            archived_toggle={{ title: "Archived", field: "archived" }}
            dropdown={{
              title: "Case Status",
              field: "status",
              options: [
                { text: "Active", value: "Active" },
                { text: "Inactive", value: "Inactive" },
              ],
            }}
            render={(items) => (
              <CasesList
                data={items}
                action={(id) => this.props.history.push(`/case/${id}`)}
                showCM
              />
            )}
          />
        </Segment>
      </Segment>
    );
  }
}
