import React from "react";
import Company from "./Company_Context";

const joinProps = (a, b) => {
  return {
    ...a,
    ...b,
  };
};
export default (Compo, mapContext) => {
  return (props) => {
    return <Company.Consumer>{(context) => <Compo {...joinProps(props, mapContext(context))} />}</Company.Consumer>;
  };
};
