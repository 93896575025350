import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import StaffList from "./staffListDash";
import StaffPage from "./staff";
import StaffEdit from "./forms/editStaffForm";
import StaffMenu from "./staffMenu";
import { Authentication } from "../../../lib/sso";

export default class StaffPages extends Component {
  render() {
    return (
      <Segment basic className="no-pad no-marg">
        {this.props.history &&
        this.props.history.location.pathname.search(/list|edit/) === -1 ? (
          <StaffMenu />
        ) : (
          <React.Fragment />
        )}
        <Segment basic className="pageHolder">
          <Switch>
            {Authentication.can("navigator_admin.permission") && (
              <Route exact path={`/staff/list`} component={StaffList} />
            )}
            <Route path={`/staff/:id/edit`} component={StaffEdit} />
            <Route path={`/staff/:id`} component={StaffPage} />
          </Switch>
        </Segment>
      </Segment>
    );
  }
}
