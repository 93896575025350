import React, { Component } from "react";
import {
  Segment,
  Header,
  Form,
  Icon,
  Image,
  Modal,
  Loader,
  Divider,
  Grid,
} from "semantic-ui-react";
import api from "../../../../../../actions/api";
import UploadFileForm from "../../../../common/forms/uploadFile";
import AppConfig from "../../../../../../_appConfig";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

class edit extends Component {
  state = {
    data: {
      title: "",
      description_short: "",
      description_long: "",
      resource_category: "",
      display_type: "",
      wysiwyg_data: "",
      archived: false,
    },
    temporary_html: "",
    resource_categories: [],
    icon_url: "",
    banner_url: "",
    pdf_url: "",
    file_type: "",
    upload_file: false,
    submit_load: false,
    loading: false,
    errors: {},
  };

  componentDidMount() {
    this._getData();
  }

  _getData() {
    this.setState({ loading: true });
    Promise.all([
      api.resources.get(this.props.match.params.id),
      api.resource_categories.list(),
    ])
      .then((res) => {
        let converted_html = "";

        if (
          res[0] &&
          res[0].wysiwyg_data !== "" &&
          res[0].wysiwyg_data !== undefined
        ) {
          const blocksFromHtml = htmlToDraft(res[0].wysiwyg_data);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );

          converted_html = EditorState.createWithContent(contentState);
        }
        this.setState(
          {
            data: {
              ...res[0],
              wysiwyg_data: converted_html,
            },
            resource_categories: res[1]
              .filter((item) => item.archived === false)
              .map((item) => {
                return { text: item.title, value: item._id };
              }),
          },
          () => {
            if (res[0].icon !== undefined && res[0].icon !== "") {
              api.services.getFilelink(res[0].icon).then((res_two) => {
                this.setState({ icon_url: res_two, loading: false });
              });
            }

            if (res[0].banner !== undefined && res[0].banner !== "") {
              api.services.getFilelink(res[0].banner).then((res_two) => {
                this.setState({ banner_url: res_two, loading: false });
              });
            }

            if (res[0].pdf_file !== undefined && res[0].pdf_file !== "") {
              api.services.getFilelink(res[0].pdf_file).then((res_two) => {
                this.setState({ pdf_url: res_two, loading: false });
              });
            }

            if (
              res[0].icon === undefined ||
              res[0].icon === "" ||
              res[0].banner === undefined ||
              res[0].banner === "" ||
              res[0].pdf_file === undefined ||
              res[0].pdf_file === ""
            ) {
              this.setState({ loading: false });
            }
          }
        );
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  }

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleDropdownChange = (e, data) => {
    this.setState({
      data: { ...this.state.data, [data.name]: data.value },
    });
  };

  handleWYSIWYGUpload = (file) => {
    return new Promise((resolve, reject) => {
      api.resources
        .uploadFile(this.props.match.params.id, {
          type: "WYSIWYG",
          file: file,
        })
        .then((res) => {
          resolve({ data: { link: AppConfig.api_server + res } });
        });
    });
  };

  validate = (data) => {
    const errors = {};

    if (!data.title) errors.title = "Can't Be Empty";
    if (!data.description_short) errors.description_short = "Can't Be Empty";
    if (!data.display_type) errors.display_type = "Can't Be Empty";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ submit_load: true });
      api.resources
        .edit(this.props.match.params.id, {
          ...this.state.data,
          wysiwyg_data: this.state.data.wysiwyg_data
            ? draftToHtml(
                convertToRaw(this.state.data.wysiwyg_data.getCurrentContent())
              )
            : "",
          resource_category:
            this.state.data.resource_category === ""
              ? null
              : this.state.data.resource_category,
        })
        .then(() => this.props.history.push(`/settings/resources`))
        .catch((e) => {
          console.log(e);
          this.setState({ submit_load: false });
        });
    }
  };

  renderUploadFile() {
    const close = () => {
      this.setState({ upload_file: false, submit_load: false });
    };

    const handleSubmit = (data) => {
      api.resources
        .uploadFile(this.props.match.params.id, {
          type: this.state.file_type,
          file: data.file,
          description: data.description,
          selectedFile: data.selectedFile
        })
        .then(() => {
          const errors = this.validate(this.state.data);
          this.setState({ errors: errors });
          if (Object.keys(errors).length === 0) {
            this.setState({ submit_load: true });
            api.resources
              .edit(this.props.match.params.id, {
                ...this.state.data,
                wysiwyg_data: this.state.data.wysiwyg_data
                  ? draftToHtml(
                      convertToRaw(
                        this.state.data.wysiwyg_data.getCurrentContent()
                      )
                    )
                  : "",
                resource_category:
                  this.state.data.resource_category === ""
                    ? null
                    : this.state.data.resource_category,
              })
              .then(() => {
                close();
                this._getData();
              })
              .catch(() => {
                close();
              });
          }
        })
        .catch((e) => {
          this.setState({ upload_file: false });
          console.log(e);
        });
    };

    return (
      <Modal
        open={this.state.upload_file === true}
        onClose={close}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="upload" content="Upload File" />
        <Modal.Content>
          <UploadFileForm submit={handleSubmit} close={close} max_upload={10} />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="pageTitle">
          Edit Resource
        </Header>
        <Segment className="no-marg border">
          {this.renderUploadFile()}
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                name="title"
                label="Resource Name"
                value={this.state.data.title}
                onChange={this.handleChange}
                placeholder="Title"
                error={this.state.errors.title}
              />
              <Form.Dropdown
                name="resource_category"
                label="Resource Category"
                value={this.state.data.resource_category}
                onChange={this.handleDropdownChange}
                options={this.state.resource_categories}
                error={this.state.errors.resource_categories}
                selection
                placeholder="Resource Category"
                clearable
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.TextArea
                name="description_short"
                label="Description (Short)"
                value={this.state.data.description_short}
                onChange={this.handleChange}
                placeholder="Description (Short)"
                error={this.state.errors.description_short}
              />
              {this.state.data.display_type !== "WYSIWYG" && (
                <Form.TextArea
                  name="description_long"
                  label="Description (Long)"
                  value={this.state.data.description_long}
                  onChange={this.handleChange}
                  placeholder="Description (Long)"
                />
              )}
            </Form.Group>
            <Divider />
            <Grid>
              <Grid.Row columns="2">
                <Grid.Column>
                  <Header content="Icon Image" textAlign="center" />
                  {this.state.icon_url !== "" ? (
                    <Segment
                      basic
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Image
                        src={AppConfig.api_server + this.state.icon_url}
                        size="medium"
                        className="pointer preview-image"
                        onClick={() => {
                          this.setState({
                            upload_file: true,
                            file_type: "Icon",
                          });
                        }}
                      />
                    </Segment>
                  ) : this.state.loading === false &&
                    this.state.icon_url === "" ? (
                    <Form.Button
                      fluid
                      color="green"
                      content="Upload Icon"
                      icon="image"
                      onClick={() => {
                        this.setState({ upload_file: true, file_type: "Icon" });
                      }}
                    />
                  ) : (
                    <Segment padded="very" basic>
                      <Loader active />
                    </Segment>
                  )}
                </Grid.Column>
                <Grid.Column>
                  <Header content="Banner Image" textAlign="center" />
                  {this.state.banner_url !== "" ? (
                    <Segment
                      basic
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Image
                        src={AppConfig.api_server + this.state.banner_url}
                        size="medium"
                        className="pointer preview-image"
                        onClick={() => {
                          this.setState({
                            upload_file: true,
                            file_type: "Banner",
                          });
                        }}
                      />
                    </Segment>
                  ) : this.state.loading === false &&
                    this.state.banner_url === "" ? (
                    <Form.Button
                      fluid
                      color="green"
                      content="Upload Banner"
                      icon="image"
                      onClick={() => {
                        this.setState({
                          upload_file: true,
                          file_type: "Banner",
                        });
                      }}
                    />
                  ) : (
                    <Segment padded="very" basic>
                      <Loader active />
                    </Segment>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
            <Form.Dropdown
              name="display_type"
              label="Additional Data Display Type"
              value={this.state.data.display_type}
              onChange={this.handleDropdownChange}
              options={[
                { text: "None", value: "None" },
                { text: "PDF", value: "PDF" },
                { text: "WYSIWYG", value: "WYSIWYG" },
              ]}
              error={this.state.errors.display_type}
              selection
              placeholder="Display Type"
            />
            {this.state.data.display_type === "PDF" ? (
              <React.Fragment>
                {this.state.pdf_url !== "" ? (
                  <React.Fragment>
                    <Form.Button
                      fluid
                      color="green"
                      content="Replace PDF"
                      icon="image"
                      onClick={() => {
                        this.setState({
                          upload_file: true,
                          file_type: "PDF",
                        });
                      }}
                    />
                    {this.state.loading === false && (
                      <embed
                        src={AppConfig.api_server + this.state.pdf_url}
                        width="100%"
                        height="800px"
                      />
                    )}
                  </React.Fragment>
                ) : this.state.loading === false &&
                  this.state.pdf_url === "" ? (
                  <Form.Button
                    fluid
                    color="green"
                    content="Upload PDF"
                    icon="image"
                    onClick={() => {
                      this.setState({ upload_file: true, file_type: "PDF" });
                    }}
                  />
                ) : (
                  <Segment padded="very" basic>
                    <Loader active />
                  </Segment>
                )}
              </React.Fragment>
            ) : this.state.data.display_type === "WYSIWYG" ? (
              <React.Fragment>
                <Editor
                  editorState={this.state.data.wysiwyg_data}
                  onEditorStateChange={(editorState) => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        wysiwyg_data: editorState,
                      },
                      temporary_html: "",
                    });
                  }}
                  editorStyle={{
                    borderLeft: "1px solid #F1F1F1",
                    borderRight: "1px solid #F1F1F1",
                    borderBottom: "1px solid #F1F1F1",
                  }}
                  toolbarStyle={{ marginBottom: "0px" }}
                  toolbar={{
                    image: {
                      uploadCallback: this.handleWYSIWYGUpload,
                      previewImage: true,
                      urlEnabled: false,
                      alignmentEnabled: false,
                      defaultSize: { width: "100%", height: "100%" },
                    },
                  }}
                />
                <Divider hidden />
                <Header content="Raw HTML View" textAlign="center" />
                <p>
                  WARNING: If you don't know how to write HTML it is reccomended
                  that you use the WYSIWYG editor above as directly modifying
                  the data could break the code.
                </p>
                <Form.TextArea
                  rows="10"
                  value={
                    this.state.temporary_html === ""
                      ? this.state.data.wysiwyg_data
                        ? draftToHtml(
                            convertToRaw(
                              this.state.data.wysiwyg_data.getCurrentContent()
                            )
                          )
                        : ""
                      : this.state.temporary_html
                  }
                  onFocus={() => {
                    if (this.state.temporary_html === "") {
                      this.setState({
                        temporary_html: this.state.data.wysiwyg_data
                          ? draftToHtml(
                              convertToRaw(
                                this.state.data.wysiwyg_data.getCurrentContent()
                              )
                            )
                          : "",
                      });
                    }
                  }}
                  onChange={(e) => {
                    this.setState({ temporary_html: e.target.value });
                  }}
                />
                {this.state.temporary_html !== "" && (
                  <Form.Button
                    fluid
                    color="green"
                    icon="code"
                    content="Save Direct HTML Edit Changes"
                    onClick={() => {
                      const blocksFromHtml = htmlToDraft(
                        this.state.temporary_html
                      );
                      const { contentBlocks, entityMap } = blocksFromHtml;
                      const contentState = ContentState.createFromBlockArray(
                        contentBlocks,
                        entityMap
                      );

                      let converted_html =
                        EditorState.createWithContent(contentState);

                      this.setState({
                        data: {
                          ...this.state.data,
                          wysiwyg_data: converted_html,
                        },
                        temporary_html: "",
                      });
                    }}
                  />
                )}
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
            <Divider />
            <Form.Checkbox
              label="Archived"
              name="archived"
              checked={this.state.data.archived}
              onClick={() => {
                this.setState({
                  data: {
                    ...this.state.data,
                    archived: !this.state.data.archived,
                  },
                });
              }}
            />
            <Form.Group widths="equal">
              <Form.Button
                floated="left"
                color="red"
                onClick={() => this.props.history.push(`/settings/resources`)}
              >
                <Icon name="close" />
                Cancel
              </Form.Button>
              <Form.Button
                floated="right"
                color="green"
                onClick={() => this.handleSubmit()}
                loading={this.state.submit_load}
              >
                <Icon name="plus" />
                Submit
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default edit;
